import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';
import { messages } from 'constantes';
import { withApi, withNotification } from 'wrappers';
import OnlineIndicator from '../OnlineIndicator';

const CheckboxInput = (props) => {
  const {
    doPut, appError, appInfo, id, url, isChecked, forwardedRef, disabledCheckView, onChange,
  } = props;
  const [isCheck, setIsCheck] = useState(!!isChecked);

  /**
   * @name onChangeField
   * @desc Metodo que realiza consulta al api para cambiar el valor
   * @returns {Promise<void>}
   */
  const onChangeField = async () => {
    setIsCheck(!isCheck);

    const params = {
      url,
      data: { id, value: !isCheck },
    };

    try {
      await doPut(params);
      forwardedRef.current.refresh();
      appInfo(messages.crud.update);
    } catch (error) {
      appError(messages.crud.fail, error);
    }
  };

  return (
    <Checkbox
      key={id}
      disabled={disabledCheckView}
      checked={isCheck}
      onChange={() => (onChange ? onChange(setIsCheck, isCheck, id) : onChangeField())}
    />
  );
};

OnlineIndicator.defaultProps = {
  isChecked: false,
};

CheckboxInput.propTypes = {
  // Es el id del row a enviar
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  // Variable que checkea el checkbox deacuerdo  lo que llegue en el row de la tabla
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  // Habilita o deshabilita para usar en viewForms
  disabledCheckView: PropTypes.bool,
  // Url de consulta al api
  url: PropTypes.string.isRequired,
  doPut: PropTypes.func,
  appInfo: PropTypes.func.isRequired,
  appError: PropTypes.func.isRequired,
  // Actualiza la tabla
  forwardedRef: PropTypes.oneOfType([PropTypes.object]),
};

// export default CheckboxInput;
export default withApi(withNotification(CheckboxInput));
