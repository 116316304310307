import React from 'react';
import { Chip as MuiChip } from '@material-ui/core';
import styled from 'styled-components';
import { blue } from '@material-ui/core/colors';
import PropTypes from 'prop-types';

const ChipComponent = styled(MuiChip)`
height: 20px;
padding: 4px 0;
margin: 2px;
font-size: 90%;
background-color: ${blue[500]};
color: white;
`;

function MultiChip(props) {
  const { data } = props;
  return data.map(element => <ChipComponent label={element} key={element} />);
}

MultiChip.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

export default MultiChip;
