import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';

export default function RadarChart({
  height = '350',
  title = 'Radar Chart',
  series = [
    {
      name: 'Series 1',
      data: [80, 50, 30, 40, 100, 20],
    },
    {
      name: 'Series 2',
      data: [20, 30, 40, 80, 20, 80],
    },
    {
      name: 'Series 3',
      data: [44, 76, 78, 13, 43, 10],
    },
  ],
  categories = ['2011', '2012', '2013', '2014', '2015', '2016'],
  color = '#33b2df'
}) {
  const options = {
    chart: {
      height,
      type: 'radar',
      dropShadow: {
        enabled: true,
        blur: 1,
        left: 1,
        top: 1,
      },
    },
    title: {
      text: title,
    },
    stroke: {
      width: 2,
    },
    fill: {
      opacity: 0.3,
    },
    xaxis: {
      categories,
    },
    colors: [color],
  };

  return (
    <Chart
      options={options}
      series={series}
      type="radar"
      height={height}
    />
  );
}

RadarChart.propTypes = {
  height: PropTypes.string,
  title: PropTypes.string,
  series: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  ),
  categories: PropTypes.oneOfType([PropTypes.array]),
  color: PropTypes.string
};
