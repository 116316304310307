import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SplashScreen from 'components/SplashScreen';
import { setUserData, logout } from 'actions/accountActions';
import authService from 'services/AuthService';
import apiServiceFecth from 'services/ApiServiceFecth';

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logout()),
      });

      authService.handleAuthentication();

      if (authService.isAuthenticated()) {
        const { infosesion = {} } = await apiServiceFecth.checkToken().then((response) => {
          if (response.ok) {
            return response.json();
          }
          authService.storeSessionData(null);
          return {};
        });

        if (infosesion !== {}) {
          await dispatch(setUserData(infosesion));
        }
      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
};

export default Auth;
