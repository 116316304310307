import styled from 'styled-components';
import TypographyBase from '@material-ui/core/Typography';
import Icon from '@material-ui/icons/Lock';
import { makeStyles } from '@material-ui/core/styles';

export const UseStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    paddingTop: 100,
    paddingBottom: 100,
  },
}));

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const IconCircle = styled.div`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e5e5e5;
  height: 150px;
  width: 150px;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const IconLock = styled(Icon)`
  font-size: 6rem;
  color: #a7a7a7;
`;

export const Paragraph = styled(TypographyBase)`
  text-align: center;
  padding: 10px 50px;
  font-size: 1.3rem;
`;

export const Title = styled(TypographyBase)`
  text-align: center;
  margin-bottom: 20px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
