import React from 'react';
import PropTypes from 'prop-types';
import { Chip, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

/*
* Los colores del componente se manejan de la siguiente manera:
* colorChip: maneja el color de la chip y de la linea del divider.
* textColor: maneja el color del texto.
*/

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 8,
    marginBottom: 6,
  },
}));

function DividerChip({
  colorChip, textColor, label, element,
}) {
  const classes = useStyles();
  return (
    <Grid item container xs={12} alignItems="center" className={classes.container}>
      <Chip
        size="small"
        label={label}
        style={{ fontSize: 16, backgroundColor: colorChip, color: textColor }}
      />
      <Grid item xs style={{ backgroundColor: colorChip, height: 1 }} />
      {element && (element)}
    </Grid>
  );
}

DividerChip.defaultProps = {
  colorChip: '#6FA4F9',
  textColor: '#FFFFFF',
};

DividerChip.propTypes = {
  colorChip: PropTypes.string,
  textColor: PropTypes.string,
  label: PropTypes.string.isRequired,
  element: PropTypes.node,
};

export default DividerChip;
