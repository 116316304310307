import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApi, withNotification } from 'wrappers';
import messages from 'constantes/messages';
import { ConfirmModal } from 'components/dialogs';
import {
  Table, DefaultActions, Loader,
} from 'components';
import ImpuestoForm from './ImpuestoForm';

const initialState = {
  showForm: false,
  idToEdit: null,
  idToDelete: null,
  row: null,
};

export class ImpuestosTable extends Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.state = {
      loading: true,
      conceptos: [],
      ...initialState,
    };
    this.closeImpuestoModal = this.closeImpuestoModal.bind(this);
    this.onSuccessSubmitImpuesto = this.onSuccessSubmitImpuesto.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onAdd = this.onAdd.bind(this);
  }

  componentDidMount = () => {
    this.init();
  };

  /**
   * Initialize this component's state
   */
  init = async () => {
    try {
      // TODO: codificar la parte de la solicitud
      const { doGet, url } = this.props;
      const paramas = {
        url,
        data: this.data,
      };
      const { conceptos, totales: { bruto, descuento, neto } } = await doGet(paramas);
      this.setState({
        loading: false,
        conceptos,
        ...initialState,
      });
      this.props.setTopLabel([
        { title: 'Total bruto', label: bruto ?? 0, type: 'number' },
        { title: 'Total descuento', label: descuento ?? 0, type: 'number' },
        { title: 'Total neto', label: neto ?? 0, type: 'number' },
      ]);
    } catch (error) {
      this.props.appError(messages.dataFetch.fail);
    }
  };

  /**
   * To be executed after submitting a tax and the process has been
   * successfull.
   */
  onSuccessSubmitImpuesto = () => {
    this.init();
  };

  /**
   * To be executed after submitting a tax and the process has thrown an
   * exception.
   */
  onFail = () => {
    this.setState({
      showForm: false,
    });
  };

  /**
   * To be executed after clicking the add button
   */
  onAdd = () => this.setState({ showForm: true, idToEdit: null });

  /**
   * To be executed when the edit button is clicked
   */
  onEdit = (obj) => {
    this.setState({
      showForm: true,
      idToEdit: obj.id,
      row: obj,
    });
  };

  /**
   * Shows the confirm dialgo when deleting a tax
   */
  onDelete = (obj) => {
    this.setState({ idToDelete: obj.id, row: obj });
  };

  closeDeleteConfirm = () => {
    this.setState({ ...initialState });
  };

  deleteImpuesto = async () => {
    try {
      const { idToDelete, row } = this.state;
      const { url, doDelete } = this.props;
      const params = {
        url,
        data: {
          ...this.data,
          conceptoId: idToDelete,
          valor: row.valorImpuesto,
        },
      };

      await doDelete(params);
      await this.init();
      this.props.appWarning(messages.crud.delete);
    } catch (error) {
      this.props.appError(messages.crud.fail, error);
    } finally {
      this.setState({ idToDelete: null });
    }
  };

  /**
   * This method closes the impuesto modal.
   */
  closeImpuestoModal = () => this.setState({ showForm: false });

  render() {
    const {
      initFormComponent, url, permissions, disabledButtonCreate, disabledButtonUpdate,
      disabledButtonDelete,
    } = this.props;
    const {
      conceptos, showForm, idToDelete, idToEdit, row, loading,
    } = this.state;
    const metadataFields = [
      { label: 'Nombre', name: 'nombre' },
      { label: 'Operación', name: 'operacion' },
      { label: 'Operador', name: 'operador' },
      { label: 'Valor', name: 'valorImpuesto' },
      { label: 'Base', name: 'baseImpuesto' },
      { label: 'Total', name: 'total' },
      {
        label: 'Acciones',
        name: 'acciones',
        component: rows => (
          <DefaultActions
            row={rows}
            onEdit={this.onEdit}
            onDelete={this.onDelete}
            disabledUpdate={disabledButtonUpdate}
            disabledDelete={disabledButtonDelete}
          />
        ),
      },
    ];

    const columns = [
      { name: 'nombre', label: 'Nombre' },
      { name: 'detalles.valorImpuesto', label: 'Valor impuesto', type: 'number' },
      { name: 'baseImpuesto', label: 'Base impuesto' },
      { name: 'operador', label: 'Operador' },
      { name: 'baseCalculo', label: 'Base cálculada', type: 'number' },
      { name: 'total', label: 'Total', type: 'number' },
    ];

    return loading ? <Loader /> : (
      <>
        {Boolean(showForm) && (
          <ImpuestoForm
            id={idToEdit}
            initFormComponent={initFormComponent}
            url={url}
            data={this.data}
            row={row}
            onCancel={this.closeImpuestoModal}
            onSubmit={this.onSuccessSubmitImpuesto}
            onFail={this.onFail}
          />
        )}
        {Boolean(idToDelete) && (
          <ConfirmModal
            open
            title="Eliminar impuesto"
            message="¿Desea eliminar este elemento?"
            onAccept={this.deleteImpuesto}
            onClose={this.closeDeleteConfirm}
          />
        )}
        <Table
          onCreate={this.onAdd}
          data={conceptos}
          columns={columns}
          createPermissions={permissions.createPermissions}
          disableCreateButton={disabledButtonCreate}
          expandable={impuesto => (
            <Table columns={metadataFields} data={impuesto.detalles} />
          )}
        />
      </>
    );
  }
}

ImpuestosTable.propTypes = {
  appError: PropTypes.func.isRequired,
  appWarning: PropTypes.func.isRequired,
  url: PropTypes.string,
  initFormComponent: PropTypes.string,
  doGet: PropTypes.func,
  data: PropTypes.oneOfType([PropTypes.object]),
  doDelete: PropTypes.func.isRequired,
  setTopLabel: PropTypes.func,
  permissions: PropTypes.oneOfType([PropTypes.object]),
  disabledButtonCreate: PropTypes.bool,
  disabledButtonUpdate: PropTypes.bool,
  disabledButtonDelete: PropTypes.bool,
};

export default withNotification(withApi(ImpuestosTable));
