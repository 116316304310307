import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Dialog, Grid, DialogTitle, DialogContent, DialogActions,
} from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import {
  SelectBase, TextBase, ButtonSave, NumberBase, ButtonCancelar,
} from 'components/Controls';
import { withNotification, withApi } from 'wrappers';
import messages from 'constantes/messages';

const validationSchema = Yup.object({
  concepto: Yup.number().required('Campo requerido'),
  operacion: Yup.number().required('Campo requerido'),
  operador: Yup.number().required('Campo requerido'),
  valor: Yup.number()
    .min(0, 'El valor no puede ser menor de 0')
    .typeError('El valor debe ser numérico').required('Campo requerido'),
  base: Yup.number()
    .min(0, 'La base no puede ser menor de 0')
    .typeError('El valor debe ser numérico').required('Campo requerido'),
});

class ImpuestoForm extends Component {
  constructor(props) {
    super(props);
    this.conceptos = [];
    this.operadores = [];
    this.operaciones = [];
    this.formTitle = '';
    this.state = {
      loading: true,
      concepto: '',
      operacion: '',
      abreviatura: '',
      valor: '',
      operador: '',
      base: '',
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount = () => {
    this.init();
  }

  init = async () => {
    try {
      const formData = await this.loadFields();
      const {
        valorImpuesto: valor = '',
        baseImpuesto: base = '',
        operadorId: operador = '',
        operacionId: operacion = '',
        id: concepto = '',
      } = formData.concepto;
      this.conceptos = this.getConceptos(formData.conceptos);
      this.operadores = this.getOperadores(formData.operadores);
      this.operaciones = this.getOperaciones(formData.operaciones);
      this.setState({
        concepto,
        valor,
        operador,
        operacion,
        base,
        loading: false,
      });
    } catch (error) {
      this.props.appError(messages.dataFetch.fail, error);
      this.props.onFail();
    }
  }

  loadFields = async () => {
    const {
      id, doGet, data, row,
      initFormComponent,
    } = this.props;
    const params = {
      url: initFormComponent,
      data: id
        ? { ...data, conceptoId: id, valorImpuesto: row.valorImpuesto }
        : { ...data },
    };
    return doGet(params);
  }

  getConceptos = data => (
    data.map(({ id: value, nombre: label }) => ({ label, value }))
  );

  getOperadores = data => (
    data.map(({ id: value, nombre: label }) => ({ label, value }))
  );

  getOperaciones = data => (
    data.map(({ id: value, nombre: label }) => ({ label, value }))
  );

  mapValues = ({
    concepto, operador, operacion, ...rest
  }) => ({
    concepto_id: concepto,
    operador_id: operador,
    operacion_id: operacion,
    ...rest,
  });

  onSubmit = async (formValues) => {
    try {
      const {
        id, url, data, doPost, doPut,
      } = this.props;
      const { valor } = this.state;
      const values = this.mapValues(formValues);
      const params = {
        url,
        data: {
          ...data,
          ...values,
          valorAnterior: valor || 0,
        },
      };
      const method = id ? doPut : doPost;
      await method(params);
      if (id) {
        this.props.appInfo(messages.crud.update);
      } else {
        this.props.appSuccess(messages.crud.new);
      }
    } catch (error) {
      this.props.appError(messages.crud.fail);
      this.props.onFail();
    } finally {
      this.props.onSubmit();
    }
  }

  render() {
    const { onCancel } = this.props;
    const {
      loading, concepto, operacion, abreviatura, valor, operador, base,
    } = this.state;
    return !loading && (
      <Dialog open onClose={onCancel} fullWidth maxWidth="sm">
        <Formik
          enableReinitialize
          initialValues={{
            concepto, abreviatura, valor, operador, base, operacion,
          }}
          validationSchema={validationSchema}
          onSubmit={this.onSubmit}
        >
          {subProps => (
            <Form>
              <DialogTitle>{this.formTitle}</DialogTitle>
              <DialogContent>
                <Grid container spacing={2} direction="row">
                  <Grid item md={9} xs={8}>
                    <Field name="concepto" label="Concepto tributario" component={SelectBase} items={this.conceptos} />
                  </Grid>
                  <Grid item md={3} xs={4}>
                    <Field label="Abreviatura" disabled name="abreviatura" component={TextBase} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Field label="Valor" name="valor" component={NumberBase} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Field label="Operacion" name="operacion" component={SelectBase} items={this.operaciones} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Field label="Operador" name="operador" component={SelectBase} items={this.operadores} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Field label="Base" name="base" component={NumberBase} />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <ButtonCancelar onClick={onCancel} />
                <ButtonSave label="Guardar" disabled={subProps.isSubmitting} />
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  }
}

ImpuestoForm.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  appError: PropTypes.func.isRequired,
  appInfo: PropTypes.func.isRequired,
  appSuccess: PropTypes.func.isRequired,
  // appWarning: PropTypes.func.isRequired,
  doGet: PropTypes.func.isRequired,
  doPost: PropTypes.func.isRequired,
  doPut: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
  initFormComponent: PropTypes.string,
  url: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object]),
  row: PropTypes.oneOfType([PropTypes.object]),
};

export default withNotification(withApi(ImpuestoForm));
