import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton, makeStyles, Tooltip,
} from '@material-ui/core';
import {
  PlusCircle as PlusIcon,
} from 'react-feather';
import { DEFAULT_MUI_DATATABLE_BUTTON_SIZE } from 'constantes/constants';

const useStyles = makeStyles(theme => ({
  icon: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

/**
 * Componente que muestra el boton ADD
 * @param {*} param0
 */
const CreateButtonActions = ({
  onClick,
}) => {
  const classes = useStyles();
  return (
    <Tooltip title="Crear">
      <IconButton
        component="div"
        size={DEFAULT_MUI_DATATABLE_BUTTON_SIZE}
        className={classes.icon}
        onClick={onClick}
      >
        <PlusIcon />
      </IconButton>
    </Tooltip>
  );
};

CreateButtonActions.propTypes = {
  onClick: PropTypes.func,
};

export default CreateButtonActions;
