import React from 'react';
import { Chip as MuiChip } from '@material-ui/core';
import styled from 'styled-components';
import {
  red, green, grey, blue, orange,
} from '@material-ui/core/colors';
import PropTypes from 'prop-types';

export const ChipColors = {
  BLUE: 'BLUE',
  RED: 'RED',
  GREEN: 'GREEN',
  GREY: 'GREY',
  ORANGE: 'ORANGE',
};

const ChipComponent = styled(MuiChip)`
height: 20px;
padding: 4px 0;
font-size: 90%;
background-color: ${props => props.rgbcolor};
color: ${props => (props.fontcolor ? props.fontcolor : 'white')};
margin: 2px;
`;

/**
 * @return {string}
 */
function Chip({
  color, label, onClick, fontcolor, ...rest
}) {
  const {
    GREY, GREEN, RED, BLUE, ORANGE,
  } = ChipColors;
  let rgb = color;
  switch (color) {
  case GREY:
    rgb = grey['500'];
    break;
  case GREEN:
    rgb = green['500'];
    break;
  case RED:
    rgb = red['500'];
    break;
  case BLUE:
    rgb = blue['500'];
    break;
  case ORANGE:
    rgb = orange['500'];
    break;
  default:
    break;
  }
  return (
    <ChipComponent
      label={label}
      rgbcolor={rgb}
      fontcolor={fontcolor}
      onClick={onClick}
      {...rest}
    />
  );
}

Chip.propTypes = {
  // Colores posibles a usar son, green, red, grey, blue
  // En caso de necesitar otro, pasar el color en formato RGB Hexadecimal
  color: PropTypes.string.isRequired,
  // Texto a mostrar dentro del chip
  label: PropTypes.string,
  onClick: PropTypes.func,
  fontcolor: PropTypes.string,
};

export default Chip;
