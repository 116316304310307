import React from 'react';
import PropTypes from 'prop-types';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core';
import { DP_DEFAULT_FORMAT, PRODUCE_STRING } from './constants';
import { finalValuesCases } from './functions';

/**
 *
 * props:
 *  produces: permite especificar el tipo de dato que generará el componente
 *  al momento de establecer un valor. Los valores posibles son string o date
 *
 *  format: permite especificar el formato con que se mostrarán las fechas
 *  por defecto se utiliza YYYY-MM-DD
 */

// TODO: corregir alerta de moment al momento de borrar el valor del input desde el teclado

function DatePickerBase({
  produces,
  format,
  label,
  field,
  onChangeProps,
  disablePast,
  disableFuture,
  minDate,
  maxDate,
  form: {
    setFieldValue, errors, touched, setFieldTouched, ...form
  },
  field: { name, value },
  ...rest
}) {
  const styles = makeStyles({
    textHelp: {
    },
  });
  const classes = styles();
  const hasError = touched[name] && Boolean(errors[name]);
  return (
    <KeyboardDatePicker
      fullWidth
      autoComplete="off"
      inputVariant="outlined"
      margin="dense"
      name={name}
      clearable
      className={classes.textHelp}
      autoOk
      error={hasError}
      helperText={hasError && errors[name]}
      label={label}
      format={format}
      clearLabel="Limpiar"
      okLabel="Aceptar"
      cancelLabel="Cancelar"
      todayLabel="Hoy"
      value={value || null}
      disablePast={disablePast}
      disableFuture={disableFuture}
      animateYearScrolling={false}
      minDate={minDate}
      maxDate={maxDate}
      onChange={(valueToSet) => {
        let finalValue = valueToSet;
        if (valueToSet) {
          finalValue = finalValuesCases(finalValue, produces, format);
        }
        setFieldTouched(name, true);
        setFieldValue(name, finalValue);
        if (onChangeProps) {
          onChangeProps(finalValue, form);
        }
      }}
      {...rest}
    />
  );
}

DatePickerBase.defaultProps = {
  produces: PRODUCE_STRING,
  label: '',
  format: DP_DEFAULT_FORMAT,
  disablePast: true,
  disableFuture: false,
  maxDate: '3050-01-01',
};

DatePickerBase.propTypes = {
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  produces: PropTypes.string,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  label: PropTypes.string,
  field: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  format: PropTypes.string,
  form: PropTypes.shape({
    errors: PropTypes.shape(),
    touched: PropTypes.shape(),
    setFieldTouched: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  onChangeProps: PropTypes.func,
};

export default DatePickerBase;
