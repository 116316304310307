import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import { ButtonCancelar } from 'components/Controls';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const GeneralErrorList = ({
  errors, open, onClose, secondIdentifier = '',
}) => {
  const [expanded, setExpanded] = useState(null);

  const handleChange = (expandedPr) => {
    if (expandedPr === expanded) {
      setExpanded(null);
    } else {
      setExpanded(expandedPr);
    }
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Errores</DialogTitle>
      <DialogContent>
        {errors.map((item) => {
          if (item.errores instanceof Array) {
            return [];
          }
          return (
            <Accordion
              expanded={expanded === `${item.fila}`}
              onChange={() => handleChange(item.fila)}
              transitionProps={{ unmountOnExit: true }}
              key={`${item.fila}`}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" sx={{ width: '33%', flexShrink: 0 }}>
                  {`${item.fila} - ${secondIdentifier !== '' ? item[secondIdentifier] : ''}`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul style={{ marginLeft: 30, marginRight: 30 }}>
                  {Object.keys(item.errores).map((iitem, i) => (
                    <li
                      key={`list-item-${String(i)}`}
                    >
                      <Typography variant="subtitle1">{item.errores[iitem]}</Typography>
                    </li>
                  ))}
                </ul>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </DialogContent>
      <DialogActions>
        <ButtonCancelar onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
};

GeneralErrorList.propTypes = {
  errors: PropTypes.arrayOf([PropTypes.object]).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  secondIdentifier: PropTypes.string,
};

export default GeneralErrorList;
