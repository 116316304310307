import React from 'react';
import PropTypes from 'prop-types';
import Tag from './Tag';

const MultipleRenderer = ({ value = [], config = {}, index = 0 }) => {
  const { variant, color } = config;
  const {
    label = 'nombre',
    tooltip,
  } = config.dataProvider || {};
  const items = Array.isArray(value) ? value : JSON.parse(value || '[]');
  return (
    <div>
      {items.map((itemVal, itemIndex) => {
        const tagKey = `tagged-${index}-${itemIndex}`;
        return (
          <Tag
            key={tagKey}
            color={color}
            variant={variant}
            label={itemVal[label]}
            tooltip={tooltip ? itemVal[tooltip] : null}
          />
        );
      })}
    </div>
  );
};

MultipleRenderer.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  config: PropTypes.oneOfType([PropTypes.object]),
  index: PropTypes.oneOfType([PropTypes.number]),
};

export default MultipleRenderer;
