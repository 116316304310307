import React from 'react';
import {
  CardContent, Button, Container, Card, Box, Typography,
} from '@material-ui/core';
import Page from 'components/Page';
import { Link } from 'react-router-dom';
import {
  ActionsWrapper, IconWrapper, IconCircle, IconLock, Paragraph, Title, UseStyles,
} from './styled';

const classes = UseStyles;

const PermissionsPage = () => (
  <Page
    className={classes.root}
    title="404: Not found"
  >
    <Container maxWidth="lg" style={{ marginTop: 20 }}>
      <Box mt={8} />
      <Box mt={8}>
        <Card>
          <CardContent>
            <IconWrapper>
              <IconCircle>
                <IconLock />
              </IconCircle>
            </IconWrapper>
            <Title variant="h2">¡UY!, lo sentimos</Title>
            <Paragraph>
              Aún no tienes acceso a esta página
            </Paragraph>
            <Typography
              align="center"
              variant="subtitle2"
              color="textSecondary"
            >
              Solicita al administrador de la aplicación los privilegios para
              ver este contenido.
            </Typography>
            <ActionsWrapper>
              <Button variant="contained" color="primary" component={Link} to="/">
                Dashboard
              </Button>
            </ActionsWrapper>
          </CardContent>
        </Card>
      </Box>
    </Container>
  </Page>
);

export default PermissionsPage;
