import React, { useState } from 'react';
import {
  makeStyles,
  Typography,
  Popover,
  IconButton,
} from '@material-ui/core';
import {
  Tree,
  TreeNode,
} from 'react-organizational-chart';
import {
  Menu,
} from 'react-feather';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';

const styles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const Card = ({
  node = {},
  functions = {},
}) => {
  const {
    nombre = '',
    cargo = '',
    color = '#ffffff',
  } = node;

  const useStyles = makeStyles({
    menuContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    card: {
      margin: '0px 10px',
      paddingTop: 0,
      display: 'flex',
      justifyContent: 'center',
    },
    cardBody: {
      textAlign: 'center',
      padding: 10,
      background: color,
      width: 300,
      borderRadius: 16,
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      transition: 'box-shadow 0.2s ease-in-out',
      '&:hover': {
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
      },
    },
    icon: {
      width: 15,
      height: 15,
    },
    name: {
      color: '#393939',
    },
    text: {
      color: '#444444',
    },
    ul: {
      listStyle: 'none',
      fontSize: 10,
      textAlign: 'center',
    },
    listItem: {
      cursor: 'pointer',
      padding: 10,
      borderBottom: '1px solid #C4C4C4',
      transition: 'box-shadow 0.2s ease-in-out, background 0.2s ease-in-out',
      '&:hover': {
        background: '#C4C4C4',
        boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
      },
      popover: {
        boxShadow: '0 4px 40px rgba(0, 0, 0, 0.2)',
      },
    },
    alert: {
      width: '100%',
      margin: '10px 0px',
    },
  });
  const classes = useStyles();

  const {
    onEdit,
    onCreate,
    onDelete,
  } = functions;

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopover = e => setAnchorEl(e.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Popover
        id={id}
        className={classes.popover}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <ul className={classes.ul}>
          {/* eslint-disable-next-line */}
          <li
            className={classes.listItem}
            onClick={() => onEdit(node)}
          >
            <Typography variant="body1">editar</Typography>
          </li>
          {/* eslint-disable-next-line */}
          <li
            className={classes.listItem}
            onClick={() => onDelete(node)}
          >
            <Typography variant="body1">eliminar</Typography>
          </li>
          {/* eslint-disable-next-line */}
          <li
            className={classes.listItem}
            onClick={() => onCreate(node)}
          >
            <Typography variant="body1">nuevo nodo</Typography>
          </li>
        </ul>
      </Popover>
      <div className={classes.card}>
        <div
          className={classes.cardBody}
        >
          <div className={classes.menuContainer}>
            <IconButton
              onClick={handlePopover}
            >
              <Menu
                className={classes.icon}
              />
            </IconButton>
          </div>
          <Typography
            variant="h3"
            className={classes.text}
          >
            {nombre}
          </Typography>
          <Typography
            className={classes.text}
            variant="body1"
          >
            {cargo}
          </Typography>
        </div>
      </div>
    </>
  );
};

Card.propTypes = {
  node: PropTypes.oneOfType([PropTypes.object]),
  functions: PropTypes.oneOfType([PropTypes.object]),
};

const renderTree = (nodes, principal, functions) => (
  principal ? (
    nodes.map((item, i) => (
      <Tree
        key={`tree-item-${String(i)}`}
        label={<Card node={item} functions={functions} />}
      >
        {Array.isArray(item.children)
          ? item.children.map(node => renderTree(node, false, functions))
          : null}
      </Tree>
    ))
  ) : (
    <TreeNode
      label={<Card node={nodes} functions={functions} />}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map(node => renderTree(node, false, functions))
        : null}
    </TreeNode>
  )
);

function OrganizationalTree({
  data = [],
  onEdit,
  onCreate,
  onDelete,
}) {
  const classes = styles();
  return (
    <>
      <div className={classes.container}>
        {data.length === 0 ? (
          <Alert
            className={classes.alert}
            variant="outlined"
            severity="warning"
          >
            No hay datos para mostrar...
          </Alert>
        ) : (
          renderTree(data, true, {
            onEdit,
            onCreate,
            onDelete,
          })
        )}
      </div>
    </>
  );
}

OrganizationalTree.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  onEdit: PropTypes.func,
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
};

export default OrganizationalTree;
