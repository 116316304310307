import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  Avatar,
} from '@material-ui/core';
import endPoints from 'endPoints/endPoints';
import { withApi } from 'wrappers';
import AuthService from 'services/AuthService';
import ApiServiceFetch from 'services/ApiServiceFecth';
import { blobToBase64 } from 'utils/helpers';
import navConfig from './navConfig';
import renderNavItems from './renderNavItems';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 300,
  },
  desktopDrawer: {
    width: 300,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    marginTop: 15,
    width: 64,
    height: 64,
  },
}));

function NavBar({ openMobile, onMobileClose, doGet }) {
  const classes = useStyles();
  const location = useLocation();
  const [user, setuser] = useState({});
  const [imgSrc, setImgSrc] = useState('');

  const getImage = async (thumbnail) => {
    const url = `${endPoints.general.images.base}/${thumbnail}`;
    const resp = await ApiServiceFetch.get({ url });
    if (resp.ok) {
      const blob = await resp.blob();
      const src = await blobToBase64(blob);
      setImgSrc(src);
    }
  };

  useEffect(() => {
    if (user !== {}) {
      setuser(AuthService.getSessionInfo());
      const url = endPoints.seguridad.usuarios.initFormPerfil;
      doGet({ url }).then((item) => {
        const {
          allData,
        } = item || {};
        const {
          image,
        } = allData || {};
        const {
          thumbnail,
        } = image || {};
        getImage(thumbnail);
      });
    }
    // eslint-disable-next-line
  }, [user.name]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={imgSrc}
              />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.name}`}
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {`${user.user_name}`}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config, key) => (
            <List
              key={`${String(key)}`}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  doGet: PropTypes.func,
};

export default withApi(NavBar);
