import React from 'react';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router';
import {
  List,
} from '@material-ui/core';
import { withPermissionsChecker } from 'wrappers';
import NavItem from './NavItem';

function renderNavItems({ items, checkAccess, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item) => reduceChildRoutes({
          acc, item, checkAccess, ...rest,
        }),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0,
  modulo = item.title,
  checkAccess,
  checkHasAny,
}) {
  if (!item.forbidden) {
    if (checkHasAny(item.permission)) {
      const key = `${modulo}-${item.title}`;
      if (item.items) {
        const open = matchPath(pathname, {
          path: item.href,
          exact: false,
        });

        acc.push(
          <NavItem
            depth={depth}
            icon={item.icon}
            key={key}
            info={item.info}
            open={Boolean(open)}
            title={item.title}
            modulo={modulo}
          >
            {renderNavItems({
              depth: depth + 1,
              pathname,
              items: item.items,
              modulo,
              checkAccess,
              checkHasAny,
            })}
          </NavItem>,
        );
      } else if (checkAccess(item.permission)) {
        acc.push(
          <NavItem
            depth={depth}
            href={item.href}
            icon={item.icon}
            key={key}
            info={item.info}
            title={item.title}
            path={item.path}
          />,
        );
      } else {
        acc.push(<></>);
      }
    } else {
      acc.push(<></>);
    }
  } else {
    acc.push(<></>);
  }

  return acc;
}

renderNavItems.propTypes = {
  items: PropTypes.string,
  circularMenu: PropTypes.bool,
  checkAccess: PropTypes.func,
};

reduceChildRoutes.propTypes = {
  acc: PropTypes.string,
  item: PropTypes.string,
  modulo: PropTypes.string,
  pathname: PropTypes.string,
  depth: PropTypes.number,
  circularMenu: PropTypes.bool,
  checkAccess: PropTypes.func,
  checkHasAny: PropTypes.func,
};

export default withPermissionsChecker(renderNavItems);
