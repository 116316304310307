import React from 'react';
import {
  Popover,
  useMediaQuery,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const GeneralPopover = ({
  open, onClose, anchorEl, children, id,
}) => {
  const isSmallView = useMediaQuery(theme => theme.breakpoints.down('xs'));

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      id={id}
      style={{
        pointerEvents: isSmallView ? 'auto' : 'none',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {children}
    </Popover>
  );
};

GeneralPopover.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.element.isRequired,
  children: PropTypes.node,
  id: PropTypes.string,
};

export default GeneralPopover;
