import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton, makeStyles, Tooltip,
} from '@material-ui/core';
import {
  Download as DownloadIcon,
} from 'react-feather';
import { DEFAULT_MUI_DATATABLE_BUTTON_SIZE } from 'constantes/constants';

const useStyles = makeStyles(theme => ({
  icon: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

/**
 * Componente que muestra el boton Guardar
 * @param {*} param0
 */
const SaveButton = ({
  onClick,
}) => {
  const classes = useStyles();
  return (
    <Tooltip title="Guardar">
      <IconButton
        size={DEFAULT_MUI_DATATABLE_BUTTON_SIZE}
        className={classes.icon}
        onClick={onClick}
      >
        <DownloadIcon />
      </IconButton>
    </Tooltip>
  );
};

SaveButton.propTypes = {
  onClick: PropTypes.func,
};

export default SaveButton;
