import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton, makeStyles, Tooltip,
} from '@material-ui/core';
import { Percent } from 'react-feather';

const useStyles = makeStyles(theme => ({
  icon: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

/**
 * This component allows to render a button specific to create.
 * @param {*} param0
 */
const PercentButton = ({
  onClick, disabled, ...rest
}) => {
  const classes = useStyles();
  return (
    <Tooltip title="¿Lista por porcentaje?">
      <IconButton
        component="div"
        className={classes.icon}
        onClick={() => onClick()}
        {...rest}
      >
        <Percent className={classes.icon} fontSize="large" />
      </IconButton>
    </Tooltip>
  );
};

PercentButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default (PercentButton);
