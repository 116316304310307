import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import { Loader } from 'components';
import {
  ButtonCancelar,
  ButtonGeneral,
} from '../Controls';
import BaseModal from './BaseModal';

/**
 * This component allows to render a confirmation modal customizable.
 *
 */
const ConfirmModal = ({
  open,
  onClose,
  title,
  modalProps,
  message,
  ajusteMessage1,
  onCancel,
  onAccept,
  isWaiting,
  isSubmitting,
}) => {
  const onPressCancel = () => {
    if (onCancel) {
      onCancel();
    }
    onClose();
  };
  return (
    <BaseModal
      open={open}
      onClose={onClose}
      title={title}
      {...modalProps}
    >
      {isSubmitting ? <Loader /> : (
        <>
          {title && (<DialogTitle align="center">{title}</DialogTitle>)}
          {message && (
            <DialogContent>
              <Typography variant="body2" align="center">{message}</Typography>
            </DialogContent>
          )}
          {ajusteMessage1 && (
            <DialogContent>
              <Typography variant="body2" align="center">{ajusteMessage1}</Typography>
            </DialogContent>
          )}
          <DialogActions>
            <ButtonCancelar onClick={onPressCancel} />
            <ButtonGeneral
              onClick={onAccept}
              label="Aceptar"
              color={green[500]}
              icon={<Check style={{ marginRight: 12, fontSize: 20 }} />}
              disabled={isWaiting}
            />
          </DialogActions>
        </>
      )}
    </BaseModal>
  );
};

ConfirmModal.propTypes = {
  // Message to be displayed in the body section.
  message: PropTypes.string,
  ajusteMessage1: PropTypes.string,
  // other props for the modal base.
  modalProps: PropTypes.oneOfType([PropTypes.object]),
  // Event to be executed on the accept button.
  onAccept: PropTypes.func,
  // Event to be executed on the cancel button.
  onCancel: PropTypes.func,
  // Event to close the modal
  onClose: PropTypes.func,
  // Indicate whether the modal is opened or closed.
  open: PropTypes.bool,
  // Main title for the modal.
  title: PropTypes.string,
  isWaiting: PropTypes.bool,
};

export default ConfirmModal;
