import React, {
  Suspense,
  Fragment,
  memo,
} from 'react';
import {
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import LoadingScreen from 'components/LoadingScreen';
// import { withPermissionsChecker } from 'wrappers';
import useAuth from 'hooks/useAuth';
import authService from 'services/AuthService';
import PermissionsPage from 'views/pages/Errors/permissions';
import { routesConfig } from './RouteConfig';

const MapRoute = ({ Component, route }, key) => (
  <Route
    key={String(key)}
    path={route.path}
    exact={route.exact}
    render={() => {
      if (authService.isTokenValid()) {
        return <Component />;
      }
      return <Redirect to="/login" />;
    }}
  />
);

MapRoute.propTypes = {
  Component: PropTypes.node,
  route: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(),
  ]),
};

const renderRoutes = (checkAccess, routes) => (routes ? (
  <Switch>
    {routes.map((route, i) => {
      const Guard = route.guard || Fragment;
      const Layout = route.layout || Fragment;
      const Component = route.component;
      const Permission = route.permission || [];
      if (checkAccess && Permission) {
        if (!checkAccess(Permission)) {
          return MapRoute({ Component: PermissionsPage, route }, i);
        }
      }

      return (
        <Route
          key={`${String(i)}`}
          path={route.path}
          exact={route.exact}
          render={props => (
            <Guard>
              <Layout>
                {route.routes
                  ? renderRoutes(checkAccess, route.routes)
                  : <Component {...props} />}
              </Layout>
            </Guard>
          )}
        />
      );
    })}
  </Switch>
) : null);

const Routes = () => {
  const { checkAccessAuth } = useAuth();
  return (
    <Suspense fallback={<LoadingScreen />}>
      {renderRoutes(checkAccessAuth, routesConfig)}
    </Suspense>
  );
};

export default memo(Routes);
