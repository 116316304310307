import React from 'react';
import { withPermissionsChecker } from 'wrappers';
import { IconButton } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PlusCircle as PlusIcon } from 'react-feather';

const IconButtonBase = ({
  checkAccess, permission, url,
}) => (
  <RouterLink to={!checkAccess(permission) ? '#' : url}>
    <IconButton
      disabled={!checkAccess(permission)}
    >
      <PlusIcon />
    </IconButton>
  </RouterLink>
);

IconButtonBase.propTypes = {
  checkAccess: PropTypes.func,
  url: PropTypes.string,
  permission: PropTypes.arrayOf(PropTypes.string),
};

export default withPermissionsChecker(IconButtonBase);
