import React from 'react';
import PropTypes from 'prop-types';
import { TableHead, TableRow, TableCell } from '@material-ui/core';

function Header({ columns }) {
  return (
    <TableHead>
      <TableRow>
        {columns.map(({ label }, index) => (
          <TableCell key={String(index)} align="center">
            {label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

Header.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
  })).isRequired,
};

export default Header;
