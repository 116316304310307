import React, {
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import endPoints from 'endPoints/endPoints';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
} from '@material-ui/core';
import authService from 'services/AuthService';
import { logout } from 'actions/accountActions';
import { withApi } from 'wrappers';
import { SocketContext } from 'context/SocketContext';
import ApiServiceFetch from 'services/ApiServiceFecth';
import { blobToBase64 } from 'utils/helpers';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
}));

function Account({ doGet }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const ref = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  const [user, setuser] = useState({});
  const [imgSrc, setImgSrc] = useState('');
  const context = useContext(SocketContext);
  const getImage = async (thumbnail) => {
    const url = `${endPoints.general.images.base}/${thumbnail}`;
    const resp = await ApiServiceFetch.get({ url });
    if (resp.ok) {
      const blob = await resp.blob();
      const src = await blobToBase64(blob);
      setImgSrc(src);
    }
  };
  useEffect(() => {
    if (user !== {}) {
      setuser(authService.getSessionInfo());
      const url = endPoints.seguridad.usuarios.initFormPerfil;
      doGet({ url }).then((item) => {
        const {
          allData,
        } = item || {};
        const {
          image,
        } = allData || {};
        const {
          thumbnail,
        } = image || {};
        getImage(thumbnail);
      });
    }
    // eslint-disable-next-line
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      const { setSocket } = context;
      setSocket(null);
      handleClose();
      await dispatch(logout());
      authService.logout();
      history.replace('/');
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
          src={imgSrc}
        />
        <Hidden smDown>
          <Typography
            variant="h6"
            color="inherit"
          >
            {/* {'nombre'} */}
            {`${user.name}`}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem
          component={RouterLink}
          to="/app/account"
        >
          perfil
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          Salir
        </MenuItem>
      </Menu>
    </>
  );
}

Account.propTypes = {
  doGet: PropTypes.func,
};

export default withApi(Account);
