import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator
} from '@material-ui/lab';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import { green } from '@material-ui/core/colors';
import { Card, CardContent, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import Content from './Content';

function CustomizedTimeLine(content, data, index, totalCount) {
  const { oppositeContent, content: rightContent } = content;

  return data ? (
    <TimelineItem key={`Item${String(index)}`}>
      <TimelineOppositeContent>
        {oppositeContent.map((oppositeItem, oppositeIndex) => (
          <Content
            content={oppositeItem}
            data={data}
            key={`Content${String(oppositeIndex)}`}
          />
        ))}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot style={{ color: green[500] }} variant="outlined">
          <CheckIcon />
        </TimelineDot>
        {totalCount > (index + 1) ? <TimelineConnector /> : null}
      </TimelineSeparator>
      <TimelineContent style={{ flex: '4' }}>
        <Card>
          <CardContent>
            <Grid container>
              {rightContent.map((itemContent, indexContent) => (
                <Content
                  content={itemContent}
                  data={data}
                  key={`Content${String(indexContent)}`}
                />
              ))}
            </Grid>
          </CardContent>
        </Card>
      </TimelineContent>
    </TimelineItem>
  ) : null;
}

function TimeLineVirtuoso({
  data,
  content,
}) {
  return (
    <Timeline>
      {data.map((item, index) => CustomizedTimeLine(content, item, index, data.length))}
    </Timeline>
  );
}

TimeLineVirtuoso.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  data: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

export default TimeLineVirtuoso;
