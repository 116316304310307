import React from 'react';
import PropTypes from 'prop-types';
import DownloadPdfButton from '../Controls/DownloadPdfButton';
import CreateButtonActions from '../Controls/CreateButtonActions';
import SaveButton from '../Controls/SaveButton';
import ViewButton from '../Controls/ViewButton';
import DeleteButton from '../Controls/DeleteButton';
import UpdateButton from '../Controls/UpdateButton';
import DuplicateButton from '../Controls/DuplicateButton';
import EmailNotifierButton from '../Controls/EmailNotifierButton';

function DefaultActions({
  row,
  onNew,
  onEdit,
  onView,
  onSave,
  children,
  onDelete,
  onDownload,
  onSendEmail,
  onDuplicate,
  disableddouble,
  disabledUpdate,
  disabledDelete,
  disabledOnView,
  disabledSendEmail,
  doublePermissions,
  deletePermissions,
  updatePermissions,
  viewPermissions,
  emailnotificationPermissions,
}) {
  return (
    <>
      {onDuplicate && (
        <DuplicateButton
          onClick={() => onDuplicate(row)}
          requiredPermissions={doublePermissions}
          disabled={disableddouble}
        />
      )}
      {onView && (
        <ViewButton
          onClick={() => onView(row)}
          requiredPermissions={viewPermissions}
          disabled={disabledOnView}
        />
      )}
      {onEdit && (
        <UpdateButton
          onClick={() => onEdit(row)}
          requiredPermissions={updatePermissions}
          disabled={disabledUpdate}
        />
      )}
      {onDelete && (
        <DeleteButton
          onClick={() => onDelete(row)}
          requiredPermissions={deletePermissions}
          disabled={disabledDelete}
        />
      )}
      {onSave && (
        <SaveButton
          onClick={() => onSave(row)}
          requiredPermissions={updatePermissions}
        />
      )}
      {onNew && (
        <CreateButtonActions
          onClick={() => onNew(row)}
        />
      )}
      {onDownload && (
        <DownloadPdfButton
          onClick={() => onDownload(row)}
        />
      )}
      {onSendEmail && (
        <EmailNotifierButton
          onClick={() => onSendEmail(row)}
          requiredPermissions={emailnotificationPermissions}
          disabled={disabledSendEmail}
        />
      )}
      {children}
    </>
  );
}

DefaultActions.propTypes = {
  // Data del registro a retornar
  row: PropTypes.oneOfType([PropTypes.object]),
  // Funcion para el UpdateButton
  onEdit: PropTypes.func,
  onDownload: PropTypes.func,
  // Funcion para el DeleteButton
  onDelete: PropTypes.func,
  // Funcion para el ViewButton
  onView: PropTypes.func,
  // Funcion para el DuplicateButton
  onSendEmail: PropTypes.func,
  // Funcion para el DuplicateButton
  onDuplicate: PropTypes.func,
  // Funcion para el SaveButton
  onSave: PropTypes.func,
  // Funcion para el new button
  onNew: PropTypes.func,
  // Componentes adicionales
  children: PropTypes.node,
  // Permisos para editar
  updatePermissions: PropTypes.oneOfType([PropTypes.array]),
  // Permisos para eliminar
  deletePermissions: PropTypes.oneOfType([PropTypes.array]),
  viewPermissions: PropTypes.oneOfType([PropTypes.array]),
  // Permisos para enviar correo
  emailnotificationPermissions: PropTypes.oneOfType([PropTypes.array]),
  // Permisos para duplicar
  doublePermissions: PropTypes.oneOfType([PropTypes.array]),
  disableddouble: PropTypes.bool,
  disabledUpdate: PropTypes.bool,
  disabledDelete: PropTypes.bool,
  disabledOnView: PropTypes.bool,
  disabledSendEmail: PropTypes.bool,
};

export default DefaultActions;
