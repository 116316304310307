import React from 'react';
import PropTypes from 'prop-types';
import ReplayIcon from '@material-ui/icons/Replay';
import { Button } from '@material-ui/core';
import { withPermissionsChecker } from 'wrappers';

/**
 * This component allows to render a button specific to save actions.
 * @param {*} param0
 */
const RefreshFormButton = ({
  className, onClick, requiredPermissions, style, checkAccess, color, disabled, label = 'Refrescar', ...rest
}) => (
  <Button
    className={className}
    style={style}
    color={color}
    variant="contained"
    disabled={!checkAccess(requiredPermissions) || disabled}
    onClick={() => onClick()}
    startIcon={<ReplayIcon />}
    {...rest}
  >
    {label}
  </Button>
);

RefreshFormButton.defaultProps = {
  requiredPermissions: [],
  className: '',
  style: {},
  disabled: false,
};

RefreshFormButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  style: PropTypes.shape(),
  onClick: PropTypes.func,
  checkAccess: PropTypes.func,
  requiredPermissions: PropTypes.arrayOf(PropTypes.string),
  color: PropTypes.string,
};

export default withPermissionsChecker(RefreshFormButton);
