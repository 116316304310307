import React, { useEffect, useState } from 'react';
import { withApi, withNotification } from 'wrappers';
import {
  DialogActions,
  DialogContent,
  Grid,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import {
  ButtonCancelar,
  ButtonSave,
  SelectBase,
  TextAreaBase,
  TextBase,
} from 'components';
import endPoints from 'endPoints/endPoints';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { messages } from 'constantes';

const validationSchema = Yup.object({
  correo: Yup.string()
    .email('El campo correo es inválido')
    .required('El campo correo es requerido'),
  descripcion: Yup.string().required('El campo descripción es requerido'),
});

const useStyles = makeStyles({
  email: {
    '& .MuiInputBase-input': {
      textTransform: 'none',
    },
  },
});

const SugerenciaForm = ({
  doGet,
  genericException,
  doPost,
  appSuccess,
  onClose,
}) => {
  const classes = useStyles();

  const ep = endPoints.centroAyuda.procesos.sugerencias;

  const [state, setState] = useState({
    correo: '',
    tipoSugerencia: '',
    modelo: '',
    modelos: [],
    titulo: '',
    descripcion: '',
  });

  const loadValues = () => {
    const url = `${ep.base}/initformcomponent`;
    return doGet({ url });
  };

  const init = async () => {
    try {
      const resp = await loadValues();
      const {
        modelos,
      } = resp || {};
      const modelosSelect = modelos.map(item => ({
        label: item.nombre, value: item.id,
      }));
      setState({
        ...state,
        modelos: modelosSelect || [],
      });
    } catch (e) {
      genericException(e);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  const mapValues = (values) => {
    const {
      correo,
      tipoSugerencia,
      modelo,
      titulo,
      descripcion,
    } = values;

    return {
      correo,
      titulo: titulo.toUpperCase(),
      descripcion: descripcion.toUpperCase(),
      tipo_sugerencia: tipoSugerencia.toUpperCase(),
      process_id: modelo,
    };
  };

  const onSubmit = async (formValues) => {
    try {
      await doPost({
        url: ep.base,
        data: mapValues(formValues),
      });
      appSuccess(messages.crud.new);
      onClose();
    } catch (e) {
      genericException(e);
    }
  };

  return (
    <>
      <DialogTitle>
        SUGERENCIAS
      </DialogTitle>
      <Formik
        enableReinitialize
        initialValues={state}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values }) => (
          <Form>
            <DialogContent>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={12} xl={12}>
                  <Field
                    label="Correo"
                    name="correo"
                    component={TextBase}
                    className={classes.email}
                  />
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                  <Field
                    label="Tipo de sugerencia"
                    name="tipoSugerencia"
                    component={TextBase}
                  />
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                  <Field
                    label="Modelo"
                    name="modelo"
                    component={SelectBase}
                    items={values.modelos}
                  />
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                  <Field
                    label="Título"
                    name="titulo"
                    component={TextBase}
                  />
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                  <Field
                    label="Descripción"
                    name="descripcion"
                    component={TextAreaBase}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <ButtonCancelar onClick={onClose} />
              <ButtonSave label="Guardar" />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
};

SugerenciaForm.propTypes = {
  doGet: PropTypes.func,
  doPost: PropTypes.func,
  appSuccess: PropTypes.func,
  genericException: PropTypes.func,
  onClose: PropTypes.func,
};

export default withApi(withNotification(SugerenciaForm));
