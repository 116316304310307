import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Grid,
  Typography,
  Hidden,
} from '@material-ui/core';
import { HelpCircle } from 'react-feather';
import GeneralPopover from 'components/Popover/GeneralPopover';

/**
 * Separa los campos de un formulario
 * ----------Texto------------
 */
const DividerForm = ({
  text, variant, style, titleHelpMessage,
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const openPopover = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const closePopover = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <>
      <GeneralPopover open={open} anchorEl={anchorEl} onClose={closePopover}>
        <Typography style={{ padding: 3 }}>
          {titleHelpMessage}
        </Typography>
      </GeneralPopover>
      <Hidden lgUp>
        <Grid container direction="row" style={style}>
          <Grid item xs={12} md={12} xl={12}>
            <Divider style={{ height: 3, marginTop: 5 }} />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            xl={12}
            style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}
          >
            <Typography variant={variant} align="center" style={{ marginTop: 3 }}>
              {text}
            </Typography>
            {titleHelpMessage && (
              <HelpCircle
                style={{ marginLeft: 5 }}
                onMouseEnter={e => openPopover(e)}
                onMouseLeave={closePopover}
                onMouseDown={e => openPopover(e)}
              />
            )}
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smDown>
        <Grid container direction="row" style={style}>
          <Grid item xs={2} md={5} xl={5}>
            <Divider style={{ height: 3, marginTop: 5 }} />
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            xl={2}
            style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}
          >
            <>
              <Typography variant={variant} align="center" style={{ marginTop: 3 }}>
                {text}
              </Typography>
              {titleHelpMessage && (
                <HelpCircle
                  style={{ marginLeft: 5 }}
                  onMouseEnter={e => openPopover(e)}
                  onMouseLeave={closePopover}
                  onMouseDown={e => openPopover(e)}
                />
              )}
            </>
          </Grid>
          <Grid item xs={2} md={5} xl={5}>
            <Divider style={{ height: 3, marginTop: 5 }} />
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};
DividerForm.propTypes = {
  // Texto que se muestra en el formulario
  text: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  style: PropTypes.shape(),
  // Mensaje de recomendaciones
  titleHelpMessage: PropTypes.string,
};

export default DividerForm;
