const APROBADO = 'APROBADO';
const PENDIENTE = 'PENDIENTE';
const TERMINADO = 'TERMINADO';
const CANCELADO_EMPRESA = 'CANCELADO_EMPRESA';
const CANCELADO_CLIENTE = 'CANCELADO_CLIENTE';
const CANCELADO_PROVEEDOR = 'CANCELADO_PROVEEDOR';
const ANULADO = 'ANULADO';
const RECHAZADO = 'RECHAZADO';
const INICIAL = 'INICIAL';
const EN_PROCESO = 'EN_PROCESO';
const ESTADOS = [
  APROBADO,
  PENDIENTE,
  TERMINADO,
  CANCELADO_EMPRESA,
  CANCELADO_CLIENTE,
  CANCELADO_PROVEEDOR,
  ANULADO,
  RECHAZADO,
  INICIAL,
  EN_PROCESO,
];

export default {
  APROBADO,
  PENDIENTE,
  TERMINADO,
  CANCELADO_EMPRESA,
  CANCELADO_CLIENTE,
  CANCELADO_PROVEEDOR,
  ANULADO,
  RECHAZADO,
  INICIAL,
  EN_PROCESO,
  labelValue: () => ESTADOS.map(estado => ({ label: estado, value: estado })),
  enableActions: estado => estado !== ANULADO
    && estado !== CANCELADO_EMPRESA
    && estado !== RECHAZADO
    && estado !== TERMINADO
    && estado !== CANCELADO_CLIENTE,
};
