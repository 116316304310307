import { Abreviaturas } from 'constantes';
import DropdownMultipleField from 'components/AdvancedForm/fields/DropdownMultipleField';
import {
  TextBase,
  SelectBase,
  SwitchBase,
  NumberBase,
} from 'components/Controls';
import {
  DatePickerBase,
} from 'components/Pickers';

/**
 * Archivo que resuelve el tipo de input dependiendo de la llave que se especifique por
 * medio de abreviaturas.
 * @author Julian Andres Osorio
 */
const fieldTypes = {};
fieldTypes[Abreviaturas.FIELD_TYPE_DATE] = DatePickerBase;
fieldTypes[Abreviaturas.FIELD_TYPE_TEXT] = TextBase;
fieldTypes[Abreviaturas.FIELD_TYPE_ALPHANUMERIC] = TextBase;
fieldTypes[Abreviaturas.FIELD_TYPE_SELECT] = SelectBase;
fieldTypes[Abreviaturas.FIELD_TYPE_NUMBER] = TextBase;
fieldTypes[Abreviaturas.FIELD_TYPE_LOGIC] = SwitchBase;
fieldTypes[Abreviaturas.FIELD_TYPE_NUMBERM] = NumberBase;
// fieldTypes[Abreviaturas.FIELD_TYPE_INPUTTAG] = InputTag;
fieldTypes[Abreviaturas.FIELD_TYPE_MULTIPLE] = DropdownMultipleField;

export default fieldTypes;
