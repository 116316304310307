import { Abreviaturas } from 'constantes';

const labelInput = {};
labelInput[Abreviaturas.FIELD_TYPE_DATE] = 'Seleccione una fecha';
labelInput[Abreviaturas.FIELD_TYPE_TEXT] = 'Ingrese la respuesta';
labelInput[Abreviaturas.FIELD_TYPE_ALPHANUMERIC] = 'Ingrese la respuesta';
labelInput[Abreviaturas.FIELD_TYPE_SELECT] = 'Seleccione un registro';
labelInput[Abreviaturas.FIELD_TYPE_NUMBER] = 'Ingrese un número';
labelInput[Abreviaturas.FIELD_TYPE_LOGIC] = '';
labelInput[Abreviaturas.FIELD_TYPE_NUMBERM] = 'Ingrese un valor';
labelInput[Abreviaturas.FIELD_TYPE_MULTIPLE] = 'Seleccione uno o mas registros';

export default labelInput;
