import React, { useState, useRef } from 'react';
import endPoints from 'endPoints/endPoints';
import PropTypes from 'prop-types';
import { withApi, withNotification } from 'wrappers';
import { messages } from 'constantes';
import * as Yup from 'yup';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  colors,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import {
  SelectBase,
  TextAreaBase,
  TextBase,
  SwitchBase,
  ButtonCancelar,
  ButtonSave,
} from 'components/Controls';
import { DatePickerBase, TimePickerBase } from 'components/Pickers';
import { EventAvailableOutlined } from '@material-ui/icons';
import DividerForm from 'components/DividerForm/DividerForm';
import Table from '../../Table/Table';
import DefaultActions from '../../Table/DefaultActions';
import ConfirmModal from '../../dialogs/ConfirmModal';

const styles = makeStyles({
  container: {
    display: 'flex',
  },
  item: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    width: 24,
    height: 24,
    borderRadius: '50%',
    marginRight: 8,
    cursor: 'pointer',
    backgroundColor: '#D7DBDD',
  },
  itemActive: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    width: 24,
    height: 24,
    borderRadius: '50%',
    marginRight: 8,
    cursor: 'pointer',
    color: 'white',
    backgroundColor: colors.teal.A700,
  },
  campos: {
    '& .MuiFormHelperText-root': {
      bottom: 0,
      position: 'relative',
    },
  },
});

const Excepcion = ({
  calendarioTrabajoId,
  doPut,
  doPost,
  doDelete,
  appInfo,
  appWarning,
  appSuccess,
  genericException,
}) => {
  const validationSchema = Yup.object({
    fecha: Yup.date().required('El campo fecha es requerido').nullable(),
    motivo: Yup.string().required('El campo motivo es requerido').nullable(),
    repetir: Yup.bool(),
    boolTerminadoFecha: Yup.bool(),
    terminacionFecha: Yup.date().nullable()
      .min(Yup.ref('fecha'), 'La fecha de finalización no puede ser menor a la fecha')
      .when(['boolTerminadoFecha', 'repetir'],
            (boolTerminadoFecha, repetir, schema) => (
              repetir && boolTerminadoFecha
                ? schema.required('el campo terminacion fecha es requerido')
                : schema
            )),
    terminacionRepeticiones: Yup.string()
      .when(['boolTerminadoFecha', 'repetir'], (boolTerminadoFecha, repetir, schema) => (
        repetir && !boolTerminadoFecha ? schema.required('el campo terminacion repeticiones es requerido') : schema
      )),
  });

  const classes = styles();

  const child = useRef();
  // eslint-disable-next-line
  const [state, setState] = useState({
    fecha: null,
    motivo: '',
    noLaborable: true,
    frecuencia: 1,
    boolTerminadoFecha: true,
    tipoFrecuencia: 'semana',
    terminacionFecha: null,
    terminacionRepeticiones: '',
    horaInicio: null,
    horaFin: null,
    repetir: false,
    festivo: false,
    dominical: false,
  });
  const [idToDelete, setIdToDelete] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [idToUpdate, setIdToUpdate] = useState(false);
  const [idSiguientes, setIdSiguientes] = useState(null);
  const today = new Date();
  const [days, setDays] = useState([
    { value: 'sunday', label: 'D', active: today.getDay() === 0 },
    { value: 'monday', label: 'L', active: today.getDay() === 1 },
    { value: 'tuesday', label: 'M', active: today.getDay() === 2 },
    { value: 'wednesday', label: 'X', active: today.getDay() === 3 },
    { value: 'thursday', label: 'J', active: today.getDay() === 4 },
    { value: 'friday', label: 'V', active: today.getDay() === 5 },
    { value: 'saturday', label: 'S', active: today.getDay() === 6 },
  ]);

  const endPoint = endPoints.general.calendarioTrabajo.excepcion;

  const handleFormModal = () => setIsOpenModal(!isOpenModal);

  const handleDeleteModal = ({ id = null } = {}) => setIdToDelete(id);

  const handleSiguientesModal = ({ id = null } = {}) => setIdSiguientes(id);

  const openModalEdit = (row) => {
    const {
      id,
      fecha,
      motivo,
      no_laborable: noLaborable,
      frecuencia,
      tipo_frecuencia: tipoFrecuencia,
      dias_semana: diasSemana,
      terminacion_fecha: terminacionFecha,
      terminacion_repeticion: terminacionRepeticiones,
      bool_terminado_fecha: boolTerminadoFecha,
      hora_inicio: horaInicio,
      hora_fin: horaFin,
      repetir = false,
      festivo = false,
      dominical = false,
    } = row;

    setState({
      ...state,
      fecha,
      motivo,
      repetir,
      noLaborable,
      frecuencia: frecuencia || state.frecuencia,
      tipoFrecuencia: tipoFrecuencia || state.tipoFrecuencia,
      terminacionFecha,
      boolTerminadoFecha,
      terminacionRepeticiones: terminacionRepeticiones || '',
      horaInicio,
      horaFin,
      festivo,
      dominical,
    });
    setDays(diasSemana || days);
    setIdToUpdate(id);
  };

  const closeModalEdit = () => {
    setState({
      fecha: null,
      motivo: '',
    });
    setIdToUpdate(null);
  };
  const columns = [
    {
      name: 'fecha',
      label: 'fecha',
      type: 'date',
    },
    {
      name: 'motivo',
      label: 'motivo'
    },
    {
      name: 'repetir',
      label: 'Periodicidad',
      type: 'check',
    },
    {
      name: 'acciones',
      label: 'acciones',
      filter: false,
      component: row => (
        <DefaultActions
          onDelete={handleDeleteModal}
          onEdit={openModalEdit}
          row={row}
        >
          <Tooltip title="siguientes eventos">
            <IconButton
              onClick={() => handleSiguientesModal(row)}
              disabled={!row.repetir}
            >
              <EventAvailableOutlined />
            </IconButton>
          </Tooltip>
        </DefaultActions>
      ),
    },
  ];

  const mapValues = (values) => {
    const {
      motivo,
      fecha,
      noLaborable,
      frecuencia,
      repetir,
      tipoFrecuencia,
      horaInicio,
      horaFin,
      terminacionFecha,
      terminacionRepeticiones,
      boolTerminadoFecha,
      festivo,
      dominical,
    } = values;

    return {
      fecha,
      motivo: motivo.toUpperCase(),
      calendario_trabajo_id: calendarioTrabajoId,
      no_laborable: noLaborable,
      frecuencia,
      bool_terminado_fecha: boolTerminadoFecha,
      tipo_frecuencia: tipoFrecuencia,
      hora_inicio: horaInicio,
      hora_fin: horaFin,
      terminacion_fecha: terminacionFecha,
      terminacion_repeticion: terminacionRepeticiones,
      dias_semana: days,
      repetir,
      festivo,
      dominical,
    };
  };

  const submitForm = async (formValues) => {
    try {
      if (idToUpdate) {
        await doPut({
          url: `${endPoint.base}/${idToUpdate}`,
          data: mapValues(formValues),
        });
        appInfo(messages.crud.update);
        closeModalEdit();
      } else {
        await doPost({
          url: endPoint.base,
          data: mapValues(formValues),
        });
        appSuccess(messages.crud.new);
        handleFormModal();
      }
      child.current.refresh();
    } catch (e) {
      genericException(e);
    }
  };

  const deleteRegister = async () => {
    try {
      await doDelete({
        url: `${endPoint.base}/${idToDelete}`,
      });
      appWarning(messages.crud.delete);
      child.current.refresh();
      handleDeleteModal();
    } catch (e) {
      genericException(e);
    }
  };

  const activeSelected = (day) => {
    day.active = !day.active;
    const found = days.findIndex(item => item.value === day.value);
    if (found > 0) {
      days[found] = day;
    }
    setDays([
      ...days,
    ]);
  };

  const changeTerminadoFecha = ({ target }, { setValues }, values) => {
    setValues({
      ...values,
      boolTerminadoFecha: !target.value,
      terminacionFecha: null,
      terminacionRepeticiones: '',
    });
  };

  const onChangeRepetir = ({ target: { checked } }, { setValues, values }) => {
    setValues({
      ...values,
      repetir: checked,
      frecuencia: 1,
      boolTerminadoFecha: true,
      tipoFrecuencia: 'semana',
      terminacionFecha: null,
      terminacionRepeticiones: '',
      horaInicio: null,
      horaFin: null,
    });
  };

  return (
    <>
      <ConfirmModal
        open={Boolean(idToDelete)}
        onClose={handleDeleteModal}
        onAccept={deleteRegister}
        title="eliminar excepción"
        message="¿Seguro desea eliminar la excepción?"
      />
      <Dialog open={Boolean(idSiguientes)} onClose={handleSiguientesModal} fullWidth maxWidth="sm">
        <DialogTitle>
          Siguientes eventos
        </DialogTitle>
        <DialogContent>
          <Table
            serverSideUrl={`${endPoint.base}/geteventos/${idSiguientes}`}
            columns={[
              {
                name: 'fecha',
                label: 'Fecha siguiente',
                type: 'date',
              },
              { name: 'dia', label: 'Día' },
            ]}
          />
        </DialogContent>
        <DialogActions>
          <ButtonCancelar onClick={handleSiguientesModal} />
        </DialogActions>
      </Dialog>
      <Dialog
        open={idToUpdate ? Boolean(idToUpdate) : isOpenModal}
        onClose={idToUpdate ? closeModalEdit : handleFormModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Creación de excepción</DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize
            initialValues={state}
            onSubmit={submitForm}
            validationSchema={validationSchema}
          >
            {({ values, ...subProps }) => (
              <Form>
                <Grid container direction="row" justify="space-between" spacing={2}>
                  <Grid item xs={12} md={6} xl={6}>
                    <Field
                      name="dominical"
                      label="Dominical"
                      component={SwitchBase}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={6}>
                    <Field
                      name="festivo"
                      label="Festivo"
                      component={SwitchBase}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={6}>
                    <Field
                      name="noLaborable"
                      label="no laborable"
                      component={SwitchBase}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={6}>
                    <Field
                      name="repetir"
                      label="Periodicidad"
                      component={SwitchBase}
                      onChangeProps={event => onChangeRepetir(event, { ...subProps, values })}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} xl={12}>
                    <Field
                      name="fecha"
                      label="fecha"
                      component={DatePickerBase}
                      disablePast={false}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} xl={12}>
                    <Field
                      name="motivo"
                      label="motivo"
                      component={TextAreaBase}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" spacing={2}>
                  {values.repetir && (
                    <>
                      <DividerForm text="Periodicidad" style={{ alignItems: 'center' }} />
                      <Grid item xs={12} md={3} xl={3}>
                        <Typography
                          style={{ paddingTop: 15 }}
                          variant="body1"
                        >
                          repetir cada
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4} xl={4}>
                        <Field
                          name="frecuencia"
                          label=""
                          component={TextBase}
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={12} md={5} xl={5}>
                        <Field
                          name="tipoFrecuencia"
                          label=""
                          component={SelectBase}
                          items={[
                            { value: 'dia', label: 'día' },
                            { value: 'semana', label: 'semana' },
                            { value: 'mes', label: 'mes' },
                            { value: 'anio', label: 'año' },
                          ]}
                        />
                      </Grid>
                      <Grid item className={classes.container} xs={12} md={12} xl={12}>
                        {values.tipoFrecuencia === 'semana' && (
                          days.map((item, i) => (
                            <div
                              key={`item-${String(i)}`}
                              aria-hidden="true"
                              className={item.active ? classes.itemActive : classes.item}
                              onClick={() => activeSelected(item)}
                            >
                              <Typography variant="subtitle1">{item.label}</Typography>
                            </div>
                          ))
                        )}
                      </Grid>
                      <Grid item xs={12} md={6} xl={6}>
                        <Field
                          name="horaInicio"
                          label="hora inicio"
                          component={TimePickerBase}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} xl={6}>
                        <Field
                          name="horaFin"
                          label="hora fin"
                          component={TimePickerBase}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} xl={12}>
                        <Field
                          name="boolTerminadoFecha"
                          label="terminado fecha"
                          component={SwitchBase}
                          onChangeProps={e => changeTerminadoFecha(e, subProps, values)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} xl={6}>
                        <Field
                          className={classes.campos}
                          name="terminacionFecha"
                          label="fecha finalización"
                          component={DatePickerBase}
                          disabled={!values.boolTerminadoFecha}
                          minDate={values.fecha}
                          disablePast={false}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} xl={6}>
                        <Field
                          className={classes.campos}
                          name="terminacionRepeticiones"
                          label="repeticiones"
                          component={TextBase}
                          type="number"
                          disabled={values.boolTerminadoFecha}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid container direction="row" justify="flex-end" spacing={2}>
                  <Grid item>
                    <ButtonCancelar onClick={idToUpdate ? closeModalEdit : handleFormModal} />
                  </Grid>
                  <Grid item>
                    <ButtonSave disabed={subProps.isSubmitting} label="Guardar" />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Table
        forwardedRef={child}
        serverSideUrl={endPoint.base}
        serverSideData={{ calendarioTrabajoId }}
        columns={columns}
        onCreate={handleFormModal}
      />
    </>
  );
};

Excepcion.propTypes = {
  calendarioTrabajoId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  doPut: PropTypes.func.isRequired,
  doPost: PropTypes.func.isRequired,
  doDelete: PropTypes.func.isRequired,
  appInfo: PropTypes.func.isRequired,
  appWarning: PropTypes.func.isRequired,
  appSuccess: PropTypes.func.isRequired,
  genericException: PropTypes.func.isRequired,
};

export default withApi(withNotification(Excepcion));
