import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import authService from 'services/AuthService';

function AuthGuard({ children }) {
  const session = authService.getSessionInfo();
  const account = useSelector(state => state.account);

  if (session && account.user) {
    if (authService.isTokenValid()) {
      return children;
    }
    return <Redirect to="/login" />;
  }
  return <Redirect to="/login" />;
}

AuthGuard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
};

export default AuthGuard;
