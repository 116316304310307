import React from 'react';
import { red, green } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import { Cancel, CheckCircle } from '@material-ui/icons';

/**
 * Check component
 *
 * @description Renders a check icon or a cancel icon based on the `activo` prop.
 * If `activo` is true, a green check icon is rendered; otherwise, a red cancel icon is shown.
 *
 * @param {Object} props - Component props.
 * @param {boolean|number} [props.activo=false] - Determines which icon to display. If true, a check icon is displayed; if false, a cancel icon is shown.
 * @param {function} props.onClickTrue - Function to call when the check icon is clicked.
 * @param {function} props.onClickFalse - Function to call when the cancel icon is clicked.
 * @param {Object} [props.style] - Custom styles applied to the icons.
 *
 * @example
 * <Check activo={true} onClickTrue={handleTrue} onClickFalse={handleFalse} />
 */
function Check({
  activo = false,
  onClickTrue,
  onClickFalse,
  style,
}) {
  return activo
    ? (
      <CheckCircle
        style={{ color: green[500], fontSize: 35, ...style }}
        onClick={onClickTrue}
      />
    )
    : (
      <Cancel
        style={{ color: red[500], fontSize: 35, ...style }}
        onClick={onClickFalse}
      />
    );
}

Check.propTypes = {
  /**
   * Depending on the value, renders a check or a cancel icon.
   */
  activo: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  onClickTrue: PropTypes.func,
  onClickFalse: PropTypes.func,
  style: PropTypes.oneOfType([PropTypes.object]),
};

export default Check;
