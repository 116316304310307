import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  MoneyConstants,
} from 'constantes';

function NumberFormatCustom(props) {
  const {
    inputRef,
    onChange,
    name,
    ...other
  } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const NumberBase = React.forwardRef((
  {
    field: {
      name, value, onBlur, onChange,
    }, form: { touched, errors }, type = 'COP', ...props
  }, ref, error = null, helperText = null,
) => {
  const [symbol, setSymbol] = useState('$');
  const hasError = touched[name] && Boolean(errors[name]);

  useEffect(() => {
    setSymbol(MoneyConstants[type] || '$');
    // eslint-disable-next-line
  }, [type]);

  return (
    <>
      <TextField
        id={name}
        inputRef={ref}
        variant="outlined"
        autoComplete="off"
        error={error !== null ? error : hasError}
        helperText={helperText !== null ? helperText : hasError && errors[name]}
        fullWidth
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        margin="dense"
        InputProps={{
          inputComponent: NumberFormatCustom,
          startAdornment: (
            <InputAdornment position="start">
              {symbol}
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </>
  );
});

NumberBase.displayName = 'NumberBase';

NumberBase.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.shape().isRequired,
    errors: PropTypes.shape().isRequired,
  }).isRequired,
  setRef: PropTypes.func,
  type: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default NumberBase;
