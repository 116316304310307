import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, Checkbox } from '@material-ui/core';
import { dotNotationExtractor, isFunction } from 'utils/functions';
import { formatter } from 'constantes/constants';
import Chip, { ChipColors } from '../../Chip';
import Check from '../../Check';
import MultiChips from '../../multiChips';

function Row({
  columns, data = {}, checked = false, setCheck, disableCheck,
}) {
  return (
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox
          checked={checked}
          onChange={() => setCheck(data)}
          disabled={checked ? false : disableCheck}
        />
      </TableCell>
      {columns.map((col, index) => {
        let cellContent = col.component || dotNotationExtractor(data, col.name);
        cellContent = isFunction(cellContent) ? cellContent(data) : cellContent;
        switch (col.type) {
        case 'number': cellContent = formatter.format(cellContent ?? 0);
          break;
        case 'money': cellContent = `$${formatter.format(Math.trunc(cellContent ?? 0))}`;
          break;
        case 'money_decimal': cellContent = `$${formatter.format(cellContent ?? 0)}`;
          break;
        case 'check': cellContent = <Check activo={cellContent} />;
          break;
        case 'active': cellContent = (
          <Chip
            activo={cellContent}
            label={cellContent ? 'activo' : 'inactivo'}
            color={cellContent ? ChipColors.GREEN : ChipColors.RED}
          />
        );
          break;
        case 'multiChips': cellContent = <MultiChips data={cellContent} />;
          break;
        case 'chip': cellContent = <Chip activo={cellContent} label={col.name} />;
          break;
        case 'percent': cellContent = `${Number(cellContent).toFixed(2)}%` ?? 0;
          break;
        case 'percentage': cellContent = `${Number(cellContent).toFixed(0)}%` ?? 0;
          break;
        default:
          break;
        }
        return (
          <TableCell
            key={`Col${String(index)}`}
            align={col.align ? col.align : 'left'}
            style={{ width: col.width ? col.width : '', color: col.color }}
          >
            {cellContent}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

Row.propTypes = {
  columns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  data: PropTypes.oneOfType([PropTypes.object]),
  checked: PropTypes.bool,
  setCheck: PropTypes.func,
  disableCheck: PropTypes.bool,
};

export default Row;
