import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import KeyboardHideIcon from '@material-ui/icons/KeyboardHide';
import ClearIcon from '@material-ui/icons/Clear';
import {
  OutlinedInput,
  CircularProgress,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';
import { withApi } from 'wrappers';
import ErrorHandler from 'utils/handlers/error-handler';
import { isFunction } from 'utils/functions';
import styles from './Styles';
import AutocompleteOptions from './AutocompleteOptions';
// tiempo de espera entre digitaciones del usuario y solicitudes al servidor
const WAIT = 1000;

/**
 * Este componente usa formik 2.x como base fundamental, evita usarlo en caso
 * contrario.
 *
 * props:
 *
 * name: el name para el campo que se manejará desde el form
 *
 * searchValue: el valor que se indicará en el input de busqueda, por defecto vacio cuando
 * no se especifica o cuando no se especifica nada en el value desde field.value y es requerido
 * cuando se especifica un value desde field.value
 *
 * labelKey: el nombre del cual se tomará el value desde las respuestas ajax. Por defecto label
 *
 * valueKey: el nombre del cual se tomará el label desde las respuestas ajax. Por defecto value
 *
 * label: un string que especifica el label del campo de busqueda. Por defecto vacio ('')
 *
 * dataProcessor: una funcion que será usada para extraer y procesar las respuestas ajax.
 * Por defecto null, cuando no se especifica, los datos serán procesados como:
 * response.json() -> then -> (json) => json.data.data
 *
 *
 * ejemplo de llamado para actualizar
 *
 *  <Field
 *   name="campoPrueba"
 *   searchValue="Daniel tobon mejia"
 *   labelKey="nombre"
 *   valueKey="id"
 *   dataProcessor={clienteProcessor}
 *   url="crm/clientes"
 *   label="campo prueba"
 *   component={AutocompleteBase}
 *   requestParams={ id: 1}
 *  />
 */

class AutocompleteBase extends Component {
  constructor(props) {
    super(props);
    const { searchValue, field: { value } } = props;
    const currentSearchValue = value ? searchValue : '';
    this.state = {
      items: [],
      isLoading: false,
      optionsOpen: false,
      searchValue: currentSearchValue,
      currentSearchValue,
    };
    this.searchInputRef = React.createRef();
    this.timeout = null;
    this.handleOptionClick = this.handleOptionClick.bind(this);
    this.handleCloseOptions = this.handleCloseOptions.bind(this);
    this.handleClickShowItems = this.handleClickShowItems.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { resetValue, searchValue } = this.props;
    if (prevProps.resetValue !== resetValue) {
      this.clearValue();
    }
    if (searchValue !== prevProps.searchValue) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ searchValue });
    }
  }

  /**
   * Método que se encarga de programar y cancelar
   * las solicitudes ajax al endpoint requerido.
   */
  filter = () => {
    clearTimeout(this.timeout);
    const { searchValue } = this.state;
    if (searchValue) {
      this.timeout = setTimeout(() => {
        this.setState({ isLoading: true }, async () => {
          const {
            url, limit, dataProcessor, requestParams,
          } = this.props;
          const fetchParams = {
            url,
            data: {
              // FIXME: remove this parameter when all forms are using the
              // autocomplete api
              perPage: limit,
              limit,
              search: searchValue,
              ...requestParams,
            },
          };
          let items = [];
          let optionsOpen = true;
          try {
            const data = await this.props.doGet(fetchParams);
            items = dataProcessor ? dataProcessor(data) : data;
          } catch (error) {
            optionsOpen = false;
            ErrorHandler.reportError(error);
          } finally {
            this.setState({
              items,
              isLoading: false,
              optionsOpen,
            });
          }
        });
      }, WAIT);
    } else {
      this.clearValue();
    }
  };

  /**
   * Método que se ejecuta al hacer click en el botón de
   * mostrar opciones. Se encarga de mostrar y ocultar las opciones
   * del autocomplete.
   */
  handleClickShowItems = () => {
    const { optionsOpen } = this.state;
    this.setState({ optionsOpen: !optionsOpen });
  };

  /**
   * Método que se pasa por props a las options del autocomplete y
   * que se ejecutará al momento de hacer click en una opcion e
   * incluso cuando se hace click en "no hay registros"
   *
   * @param option la opción seleccionada en autocompleteOptions.
   */
  handleOptionClick = (option) => {
    const {
      valueKey,
      labelKey,
      field: { name, value },
      form,
      onOptionSelected,
      labelAsValue,
    } = this.props;
    if (isFunction(onOptionSelected)) {
      onOptionSelected(option, form);
    }
    const { currentSearchValue } = this.state;
    const searchValue = option ? option[labelKey] : currentSearchValue;
    const newValue = option ? option[valueKey] : value;
    const { setFieldValue } = form;

    if (labelAsValue) {
      setFieldValue(name, searchValue);
    } else {
      setFieldValue(name, newValue);
    }
    this.setState({
      searchValue,
      currentSearchValue: option ? option[labelKey] : currentSearchValue,
      optionsOpen: false,
    }, () => this.focusInput());
  };

  /**
   * Método que se ejecuta en el momento que el usuario digita en
   * el input de búsqueda. Se encarga de establecer el valor
   * actual de busqueda, programa y cancela la ejecucion del ajax al
   * endpoint indicado.
   *
   * @param event el evento (onChange) sobre el input de búsqueda
   */
  handleUserInput = (event) => {
    this.setState({
      searchValue: event.currentTarget.value,
    }, () => {
      this.filter();
    });
  };

  /**
   * Set the focus on the search input.
   */
  focusInput = () => {
    const { current: { firstElementChild: input } } = this.searchInputRef;
    input.focus();
  };

  /**
   * Método que se ejecuta al momento de hacer click por fuera de las
   * opciones. Básicamente oculta las opciones.
   *
   */
  handleCloseOptions = () => {
    const { currentSearchValue } = this.state;
    this.setState({
      searchValue: currentSearchValue,
      optionsOpen: false,
    }, () => this.focusInput());
  };

  /**
   * Metodo que se ejecuta al momento de hacer click en el botón limpiar.
   * Limpia el valor actualmente seleccionado.
   */
  clearValue = () => {
    const { field: { name }, form, onOptionSelected } = this.props;
    if (isFunction(onOptionSelected)) {
      onOptionSelected(undefined, form);
    }
    const { setFieldValue, setFieldTouched } = form;
    this.setState(() => ({ searchValue: '', items: [], currentSearchValue: '' }),
                  () => {
                    setFieldTouched(name, true);
                    setFieldValue(name, '', true);
                    this.focusInput();
                  });
  };

  /**
   * Defines the options icon color. Prioritizes the
   * error, so if the param hasError the icon color will
   * be error color, if the options list is open then it will
   * be a primary color and by default will be a inherit
   *
   * @param {boolean} hasError indicates if the input has an error.
   */
  resolveOptionsIconColor = (hasError) => {
    if (hasError) {
      return 'error';
    }
    const { optionsOpen } = this.state;
    return optionsOpen ? 'primary' : 'inherit';
  };

  render = () => {
    const {
      isLoading, optionsOpen, searchValue, items,
    } = this.state;
    const {
      labelKey, valueKey, label, field: { name, value }, form: { errors, touched }, classes,
      disabled, error, helperText,
    } = this.props;
    const hasError = (touched[name] && Boolean(errors[name]));
    const processing = isLoading;
    const labelWidth = label.length * 7;
    const optionsIconColor = this.resolveOptionsIconColor(error !== null ? error : hasError);

    return (
      <FormControl
        fullWidth
        margin="dense"
        error={error !== null ? error : hasError}
        className={clsx(classes.textField)}
        variant="outlined"
      >
        <InputLabel>{label}</InputLabel>
        <OutlinedInput
          ref={this.searchInputRef}
          disabled={disabled || processing}
          fullWidth
          autoComplete="off"
          label={label}
          onChange={this.handleUserInput}
          error={error !== null ? error : hasError}
          labelWidth={labelWidth}
          endAdornment={(
            <InputAdornment position="end">
              {isLoading && <CircularProgress size={20} />}
              {Boolean(value) && (
                <IconButton
                  size="small"
                  disabled={disabled || processing}
                  aria-label="Limpiar"
                  onClick={this.clearValue}
                  edge="end"
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              )}
              <IconButton
                size="small"
                disabled={disabled || processing}
                aria-label="Mostrar opciones"
                onClick={this.handleClickShowItems}
                edge="end"
              >
                <KeyboardHideIcon color={optionsIconColor} />
              </IconButton>
            </InputAdornment>
          )}
          value={searchValue}
        />
        {(hasError || helperText)
          && (
            <FormHelperText>
              {helperText !== null ? helperText : errors[name]}
            </FormHelperText>
          )}
        {optionsOpen && (
          <AutocompleteOptions
            anchorEl={this.searchInputRef.current}
            currentValue={value}
            handleOptionClick={this.handleOptionClick}
            handleCloseOptions={this.handleCloseOptions}
            items={items}
            labelKey={labelKey}
            valueKey={valueKey}
            optionsOpen={optionsOpen}
          />
        )}
      </FormControl>
    );
  }
}

AutocompleteBase.defaultProps = {
  label: '',
  labelKey: 'label',
  valueKey: 'value',
  limit: 20,
  searchValue: '',
  error: null,
  helperText: null,
  disabled: false,
  labelAsValue: false,
};

AutocompleteBase.propTypes = {
  // el valor que se indicará en el input de busqueda, por defecto vacio cuando
  // no se especifica o cuando no se especifica nada en el value desde field.value y es requerido
  // cuando se especifica un value desde field.value
  searchValue: PropTypes.string,
  // Funcion que se ejecutara al momento de seleccionar un Item.
  onOptionSelected: PropTypes.func,
  // Parametros adicionales que se mandaran al llamado de la api especificado en la props url
  requestParams: PropTypes.oneOfType([PropTypes.object]),
  // Props boleana para espeficiar si el campo esta habilitado o inhabilitado para edición
  disabled: PropTypes.bool,
  // Un string que especifica el label del campo de busqueda. Por defecto vacio ('')
  label: PropTypes.string,
  field: PropTypes.shape({
    // El name para el campo que se manejará desde el form
    name: PropTypes.string.isRequired,
    // El valor para el campo que se manejará desde el form
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  // Ruta de la api para consultar los registros del input.
  url: PropTypes.string.isRequired,
  // El nombre del cual se tomará el value desde las respuestas ajax. Por defecto label
  labelKey: PropTypes.string,
  // El nombre del cual se tomará el label desde las respuestas ajax. Por defecto value
  valueKey: PropTypes.string,
  // El limite de registros que desea retornar al consumir la api.
  limit: PropTypes.number,
  // Una funcion que será usada para extraer y procesar las respuestas ajax.
  // Por defecto null, cuando no se especifica, los datos serán procesados como:
  // response.json() -> then -> (json) => json.data.data
  dataProcessor: PropTypes.func,
  // FORMIK
  form: PropTypes.shape({
    errors: PropTypes.shape(),
    touched: PropTypes.shape(),
    setFieldTouched: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    textField: PropTypes.string,
  }).isRequired,
  doGet: PropTypes.func.isRequired,
  resetValue: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  labelAsValue: PropTypes.bool,
};

export default withApi(withStyles(styles)(AutocompleteBase));
