import React from 'react';
import { PropTypes } from 'prop-types';
import {
  withApi,
  withNotification,
} from 'wrappers';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  DialogActions,
} from '@material-ui/core';
import { Estados, messages } from 'constantes';
import { Formik, Form, Field } from 'formik';
import {
  ButtonCancelar,
  SelectBase,
  TextAreaBase,
  ButtonGeneral,
} from 'components/Controls';
import FormComentario from 'components/estadoSelection/FormComentario';
import { Check } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  codigoOperacion: Yup.string().required('El campo codigo de operación es requerido.'),
});

class OperationCodeStatusModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comentario: '',
      codigoOperacion: '',
      codigosOperacion: [],
      isSubmitting: false,
    };
  }

  componentDidMount() {
    this.init();
  }

  init = async () => {
    const { genericException } = this.props;
    try {
      const { doGet, urlCodigosOperacion, aditionalParams } = this.props;
      const resp = await doGet({ url: urlCodigosOperacion, data: aditionalParams });
      this.setState({
        codigosOperacion: resp || [],
      });
    } catch (e) {
      genericException(e);
    }
  }

  cambioEstado = async (formValues) => {
    const {
      id,
      refresh,
      onClose,
      appSuccess,
      genericException,
      open,
      url,
    } = this.props;
    const { comentario, codigoOperacion } = formValues;
    const data = { comentario, codigoOperacion, estado: open };
    try {
      this.setState({
        isSubmitting: true,
      });
      const params = {
        url: `${url}/${id}`,
        data,
      };

      await this.props.doPut(params);
      onClose();
      refresh();
      appSuccess(messages.crud.update);
    } catch (error) {
      genericException(error);
    } finally {
      this.setState({
        isSubmitting: false,
      });
    }
  };

  render() {
    const {
      id,
      open,
      estado,
      onClose,
      genericException,
      onSubmitFormComentario,
    } = this.props;
    const {
      comentario,
      codigoOperacion,
      codigosOperacion,
      isSubmitting,
    } = this.state;
    return (
      (open !== estado)
        ? (
          <>
            <FormComentario
              open={Boolean(id)}
              estado={open}
              close={() => onClose()}
              isWaiting={isSubmitting}
              onSubmit={async () => {
                try {
                  this.setState({
                    isSubmitting: true,
                  });
                  await onSubmitFormComentario();
                } catch (e) {
                  genericException(e);
                } finally {
                  this.setState({
                    isSubmitting: false,
                  });
                }
              }}
            />
          </>
        )
        : (
          <Dialog
            open={Boolean(open)}
            fullWidth
            onClose={onClose}
            maxWidth="sm"
          >
            <DialogTitle>
              Cambio De Estado
            </DialogTitle>
            <DialogContent>
              <Formik
                initialValues={{
                  comentario,
                  codigoOperacion,
                  codigosOperacion,
                }}
                onSubmit={this.cambioEstado}
                validationSchema={validationSchema}
              >
                {() => (
                  <Form>
                    <Grid container direction="row" justify="center">
                      <Grid item xs={12} md={12} xl={12}>
                        <Field
                          label="Codigo de operación"
                          name="codigoOperacion"
                          component={SelectBase}
                          items={codigosOperacion}
                        />
                      </Grid>
                      <Field
                        name="comentario"
                        label="Comentario"
                        component={TextAreaBase}
                      />
                    </Grid>
                    <DialogActions>
                      <ButtonCancelar onClick={onClose} />
                      <ButtonGeneral
                        label="Aceptar"
                        color={green[500]}
                        icon={<Check style={{ marginRight: 12, fontSize: 20 }} />}
                        type="sumbit"
                        disabled={isSubmitting}
                      />
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </DialogContent>
          </Dialog>
        )
    );
  }
}

OperationCodeStatusModal.propTypes = {
  onClose: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  refresh: PropTypes.func,
  appSuccess: PropTypes.func,
  genericException: PropTypes.func,
  doPut: PropTypes.func,
  doGet: PropTypes.func,
  open: PropTypes.string,
  url: PropTypes.string,
  onSubmitFormComentario: PropTypes.func,
  urlCodigosOperacion: PropTypes.string,
  estado: PropTypes.string,
  aditionalParams: PropTypes.oneOfType([PropTypes.object]),
};

OperationCodeStatusModal.defaultProps = {
  estado: Estados.APROBADO,
};

export default withApi(withNotification(OperationCodeStatusModal));
