import React, { useState, useEffect } from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
  Dialog,
  DialogContent,
  makeStyles,
  Typography,
  DialogTitle,
  Grid,
  DialogActions,
  Button,
} from '@material-ui/core';
import {
  oneOfType,
  object,
  bool,
  number,
} from 'prop-types';
import { Calendar, X } from 'react-feather';
import clsx from 'clsx';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    margin: 0,
    width: '100%',
    justifyContent: 'center',
  },
  labelContainer: {
    width: 30,
    height: 30,
    margin: 10,
    textAlign: 'center',
  },
  label: {
    width: '100%',
    height: '100%',
    backgroundColor: '#E5E7E9',
    borderRadius: 15,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#D5DBDB',
    }
  },
  labelActive: {
    width: '100%',
    height: '100%',
    backgroundColor: '#8D32AC',
    color: 'white',
    borderRadius: 15,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#842CBF',
    }
  },
  disabled: {
    width: '100%',
    height: '100%',
    backgroundColor: '#D5DBDB',
    color: 'grey',
    borderRadius: 15,
    cursor: 'default',
  },
});

export default function DaysInputBase({
  field: { name, value },
  disabled,
  maxSelected,
  form: {
    setFieldValue,
    touched,
    errors,
  },
  ...props
}) {
  const classes = useStyles();

  const [modal, setModal] = useState(false);
  const [days, setDays] = useState([]);
  const [active, setActive] = useState([]);

  const hasError = touched[name] && Boolean(errors[name]);

  const handleModal = () => setModal(!modal);

  useEffect(() => {
    const newDays = [];
    let dias = [];
    for (let i = 1; i <= 30; i++) {
      dias.push({
        label: i,
        active: value.includes(i),
      });

      if (i % 7 === 0 || i === 30) {
        newDays.push(dias);
        dias = [];
      }
    }

    setDays(newDays);
    setActive(value || []);
    // eslint-disable-next-line
  }, [modal]);

  const handleActive = (i, j) => {
    const newDays = [...days];
    const newActive = [...active];
    newDays[i][j] = { label: days[i][j].label, active: !days[i][j].active };

    const found = newActive.findIndex(item => days[i][j].label === item);
    if (found !== -1) {
      newActive.splice(found, 1);
    } else {
      newActive.push(newDays[i][j].label);
    }
    setActive(newActive);
    setDays(newDays);
  };

  const handleAccept = () => {
    const activeSorted = active.sort();
    setFieldValue(name, activeSorted);
    handleModal();
  };

  return (
    <>
      <Dialog
        open={modal}
        onClose={handleModal}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              Seleccione los dias
            </Grid>
            <Grid item>
              <IconButton onClick={handleModal}>
                <X />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {days.map((item, i) => (
            <div key={`container-${String(i)}`} className={classes.container}>
              {item.map((it, j) => (
                <div
                  key={`number-${String(i)} -${String(j)}`}
                  className={classes.labelContainer}
                >
                  <Typography
                    variant="body1"
                    key={`label-${String(i)} -${String(j)}`}
                    className={clsx({
                      [classes.label]: true,
                      [classes.labelActive]: it.active,
                      [classes.disabled]: !it.active && active.length === maxSelected,
                    })}
                    onClick={() => {
                      if (!it.active && active.length === maxSelected) {
                        return false;
                      }
                      handleActive(i, j);
                      return true;
                    }}
                  >
                    {it.label}
                  </Typography>
                </div>
              ))}
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleAccept}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <TextField
        id={name}
        variant="outlined"
        autoComplete="off"
        fullWidth
        name={name}
        value={value}
        error={hasError}
        helperText={hasError && errors[name]}
        margin="dense"
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
            >
              <IconButton
                onClick={handleModal}
                disabled={disabled}
              >
                <Calendar />
              </IconButton>
            </InputAdornment>
          )
        }}
        onKeyPress={(e) => {
          e.preventDefault();
          return false;
        }}
        {...props}
      />
    </>
  );
}

DaysInputBase.propTypes = {
  field: oneOfType([object]),
  form: oneOfType([object]),
  disabled: bool,
  maxSelected: number,
};
