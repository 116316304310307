import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AuthService from '../services/AuthService';
import ApiServiceFecth from '../services/ApiServiceFecth';
import endPoints from '../endPoints/endPoints';

export const ApplicationContext = React.createContext({
  usuario: null,
  setUserData: () => { },
});

export class ApplicationContextProvider extends Component {
  constructor(props) {
    super(props);
    const userInfo = AuthService.getSessionInfo() || {};
    this.state = {
      usuario: null,
      email: null,
      loading: true,
      loadingPermissions: true,
      permissionsLoaded: Boolean(userInfo.user_id),
      permissions: [],
    };
    this.setUserData = this.setUserData.bind(this);
  }

  componentDidMount = async () => {
    await this.initialize();
    await this.fetchPermissions();
  };

  componentDidUpdate() {
    const { permissionsLoaded } = this.state;
    const { user_id: userId } = AuthService.getSessionInfo() || {};
    if (userId && !permissionsLoaded) {
      this.fetchPermissions();
    }
  }

  setUserData = async (usuario, email) => {
    await this.setState({
      usuario,
      email,
    });
    await this.fetchPermissions();
  };

  fetchPermissions = async () => {
    const { user_id: userId } = AuthService.getSessionInfo() || {};
    if (!userId) {
      this.setState({ loadingPermissions: false, loading: false });
    } else {
      try {
        const response = await ApiServiceFecth.get({
          url: `${endPoints.seguridad.usuarios.permisos}/${userId}`,
        });
        const jsonResponse = await response.json();
        this.setState({
          loading: false,
          loadingPermissions: false,
          permissionsLoaded: true,
          permissions: [
            // Add here permissions that can be general to all the application.
            // 'crm:maestros:clientes:list', 'dashboard:permission:create',
            // 'crm:maestros:clientes:updatee', 'crm:maestros:clientes:removee',
            ...jsonResponse,
          ],
        });
      } catch (err) {
        await this.logout();
      }
    }
  };

  initialize = async () => {
    const token = AuthService.getToken();
    // si hay un token
    if (token) {
      // validar si el token es valido en el cliente
      const isTokenValid = AuthService.isTokenValid();
      const sessionInfo = AuthService.getSessionInfo();
      // si el token es valido en el cliente pero no hay informacion
      // del usuario
      if (isTokenValid && !sessionInfo) {
        await this.logout();
      } else {
        // verificar el token en el servidor
        await this.checkToken(sessionInfo);
      }
    } else {
      // si no hay token limpiar todo lo relacionado a la sesion
      this.endingSession();
    }
  };

  checkToken = async (sessionInfo) => {
    const params = {
      url: endPoints.auth.token,
      si: true,
      bearer: true,
    };
    try {
      await ApiServiceFecth.post(params);
      this.setState({ usuario: sessionInfo.name });
    } catch (error) {
      this.endingSession();
    }
  };

  logout = async () => {
    await ApiServiceFecth.logout().then((response) => {
      this.endingSession();
      return response;
    });
  };

  endingSession = () => {
    AuthService.logout();
    this.setState({ usuario: null, loading: false, permissions: [] });
  };

  render() {
    const { children } = this.props;
    const {
      usuario,
      email,
      loading,
      permissions = [],
      loadingPermissions,
      permissionsLoaded,
    } = this.state;
    return (
      <ApplicationContext.Provider value={{
        usuario,
        email,
        setUserData: this.setUserData,
        loading,
        permissions,
        loadingPermissions,
        permissionsLoaded,
      }}
      >
        {children}
      </ApplicationContext.Provider>
    );
  }
}

ApplicationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ApplicationContextConsumer = ApplicationContext.Consumer;
