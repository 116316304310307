import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  makeStyles,
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {
  grey,
  red,
} from '@material-ui/core/colors';
import { Cancel } from '@material-ui/icons';

const useStyles = makeStyles({
  style: {
    boxShadow: 'none',
    textTransform: 'uppercase',
    fontSize: 16,
    padding: '2px 12px',
    border: '2px solid',
    borderRadius: 8,
    backgroundColor: 'white',
    borderColor: red[500] || '#000000',
    color: red[500] || '#000000',
    '&:hover': {
      backgroundColor: fade(red[500] || '#000000', 0.1),
      borderColor: red[500] || '#000000',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: fade(red[500] || '#000000', 0.5),
      borderColor: red[500] || '#000000',
    },
    '&:disabled': {
      borderColor: grey[300],
    },
  },
});

const ButtonCancelar = ({
  disabled, onClick, ...props
}) => {
  const classes = useStyles();
  return (
    <Button
      disabled={disabled}
      className={classes.style}
      onClick={onClick}
      startIcon={<Cancel />}
      {...props}
    >
      Cancelar
    </Button>
  );
};

ButtonCancelar.propTypes = {
  // Boolean que determina si esta deshabilitado
  disabled: PropTypes.bool,
  // Funcion a ejecutarse cuando se da click
  onClick: PropTypes.func,
};

export default ButtonCancelar;
