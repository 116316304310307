import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  messages,
} from 'constantes';
import {
  ButtonSave,
  ButtonCancelar
} from 'components/Controls';
import Table from 'components/Table/Table';
import DefaultActions from 'components/Table/DefaultActions';
import {
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {
  withApi,
  withNotification,
} from 'wrappers';
import { ConfirmModal } from 'components/dialogs';

const GeneralTab = ({
  config: {
    id,
    dialogSize = 'sm',
    disableCreateButton,
    disabledButtonDelete,
    endPoints: {
      base,
      notIn,
    },
    dataForTable,
    columns,
    columnsNotIn,
    messages: {
      tableTitle,
      tableHelpMessage,
      deleteTitle,
      deleteMessage,
    },
    showgeneralPopover = false,
    ConfigGeneralPopover = null,
    showCargueMasivoButtons = false,
    CargueMasivoButtons = null,
    permissions = {},
    deleteExtra = () => null,
  },
  doPost,
  doDelete,
  appSuccess,
  appWarning,
  forwardedRef,
  view = false,
  genericException,
}) => {
  const child = useRef();

  const [openModal, setOpenModal] = useState(false);
  const [idDelete, setIdDelete] = useState(null);
  const [selected, setSelected] = useState([]);

  const refreshTable = () => child.current.refresh();

  if (forwardedRef) {
    forwardedRef.current = {
      refresh: refreshTable,
    };
  }

  const handleModal = () => {
    setOpenModal(!openModal);
    setSelected([]);
  };

  const handleModalDelete = ({ id: idD = null } = {}) => setIdDelete(idD);

  const onRowSelect = (rows) => {
    const data = [];
    rows.map(item => data.push(
      { id: item.id },
    ));
    setSelected(data);
  };

  const buildActions = () => {
    const newColumns = columns.map(element => element);
    newColumns.push({
      name: 'acciones',
      label: 'acciones',
      filter: false,
      component: row => (
        <DefaultActions
          row={row}
          disabledDelete={view || disabledButtonDelete}
          onDelete={handleModalDelete}
          deletePermissions={permissions.deletePermissions}
        />
      ),
    });
    return newColumns;
  };

  const store = async () => {
    try {
      await doPost({
        url: base,
        data: {
          id,
          selected,
        },
      });
      handleModal();
      appSuccess(messages.crud.new);
      refreshTable();
    } catch (e) {
      genericException(e);
    }
  };

  const columnsTable = buildActions();

  const deleteRegister = async () => {
    try {
      await doDelete({
        url: `${base}/${idDelete}`,
      });
      child.current.refresh();
      handleModalDelete();
      appWarning(messages.crud.delete);
      deleteExtra();
    } catch (e) {
      genericException(e);
    }
  };

  return (
    <>
      {showgeneralPopover && (
        ConfigGeneralPopover
      )}
      <ConfirmModal
        open={Boolean(idDelete)}
        onClose={handleModalDelete}
        title={deleteTitle}
        message={deleteMessage}
        onAccept={deleteRegister}
      />
      <Dialog
        open={openModal}
        onClose={handleModal}
        fullWidth
        maxWidth={dialogSize}
      >
        <DialogContent>
          <Table
            columns={columnsNotIn}
            serverSideUrl={notIn}
            serverSideData={{ id }}
            onRowsSelect={onRowSelect}
            genericSearch={false}
          />
        </DialogContent>
        <DialogActions>
          <ButtonCancelar
            onClick={handleModal}
          />
          <ButtonSave
            disabled={selected.length === 0}
            label="Guardar"
            onClick={store}
          />
        </DialogActions>
      </Dialog>
      {showCargueMasivoButtons && (
        CargueMasivoButtons
      )}
      <Table
        forwardedRef={child}
        serverSideUrl={base}
        serverSideData={dataForTable}
        columns={columnsTable}
        onCreate={handleModal}
        disableCreateButton={view || disableCreateButton}
        title={tableTitle}
        titleHelpMessage={tableHelpMessage}
        createPermissions={permissions.createPermissions}
      />
    </>
  );
};

GeneralTab.propTypes = {
  config: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    dialogSize: PropTypes.string,
    disableCreateButton: PropTypes.bool,
    disabledButtonDelete: PropTypes.bool,
    endPoints: PropTypes.shape({
      base: PropTypes.string,
      notIn: PropTypes.string,
    }),
    dataForTable: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    columns: PropTypes.arrayOf([
      PropTypes.object,
    ]),
    columnsNotIn: PropTypes.arrayOf([
      PropTypes.object,
    ]),
    messages: PropTypes.shape({
      tableTitle: PropTypes.string,
      tableHelpMessage: PropTypes.string,
      deleteTitle: PropTypes.string,
      deleteMessage: PropTypes.string,
    }),
    showgeneralPopover: PropTypes.bool,
    ConfigGeneralPopover: PropTypes.func,
    showCargueMasivoButtons: PropTypes.bool,
    CargueMasivoButtons: PropTypes.node,
    permissions: PropTypes.oneOfType([PropTypes.object]),
    deleteExtra: PropTypes.func,
  }),
  forwardedRef: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  view: PropTypes.bool,
  doPost: PropTypes.func,
  doDelete: PropTypes.func,
  appSuccess: PropTypes.func,
  appWarning: PropTypes.func,
  genericException: PropTypes.func,
};

export default withApi(withNotification(GeneralTab));
