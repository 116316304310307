import React from 'react';
import PropTypes from 'prop-types';
import {
  TableBody, CircularProgress, TableRow, TableCell, Grid, Typography,
} from '@material-ui/core';
import Row from './Row';

class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };
  }

  // Funcion para evaluar cambios en los check y habilitar o deshabilitar todos
  componentDidUpdate(prevProps) {
    const { checkedAll, data } = this.props;
    if (prevProps.checkedAll !== checkedAll) {
      if (checkedAll === true) {
        data.forEach((row) => {
          this.setCheck(row, true);
        });
      } else {
        data.forEach(() => {
          this.setCheckVid();
        });
      }
    }
  }

  setCheck = (row, fromAll = false) => {
    const { onRowsSelect } = this.props;
    const index = this.verifyExist(row);
    if (index >= 0) {
      this.setState(
        (prevState) => {
          const datos = prevState.selected;
          datos.splice(index, 1);
          return ({ selected: datos });
        },
        () => {
          if (fromAll) {
            return;
          }
          onRowsSelect(this.state.selected);
        },
      );
    } else {
      this.setState(
        prevState => ({ selected: [...prevState.selected, row] }),
        () => {
          if (fromAll) {
            return;
          }
          onRowsSelect(this.state.selected);
        },
      );
    }
  };

  setCheckVid = () => {
    // const { onRowsSelect } = this.props;
    this.setState({ selected: [] });
    // onRowsSelect(this.state.selected);
  };

  verifyExist = (row) => {
    const { selected } = this.state;
    let index = -1;
    selected.forEach((element, i) => {
      if (Object.is(element, row)) {
        index = i;
      }
    });
    return index;
  };

  render() {
    const {
      isLoading, data, columns, disableCheck,
    } = this.props;
    return (
      <TableBody>
        {isLoading || !Array.isArray(data) || data.length === 0 ? (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <Grid style={{ display: 'flex', justifyContent: 'center', margin: 10 }}>
                {isLoading && <CircularProgress color="secondary" />}
                {!isLoading && <Typography>No hay datos para mostrar...</Typography>}
              </Grid>
            </TableCell>
          </TableRow>
        ) : Array.isArray(data) && data.map((row, index) => (
          <Row
            key={`R${String(index)}`}
            columns={columns}
            data={row}
            setCheck={this.setCheck}
            disableCheck={disableCheck}
            checked={this.verifyExist(row) >= 0}
          />
        ))}
      </TableBody>
    );
  }
}

Body.propTypes = {
  columns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  data: PropTypes.oneOfType([PropTypes.array]),
  isLoading: PropTypes.bool,
  checkedAll: PropTypes.bool,
  onRowsSelect: PropTypes.func,
  disableCheck: PropTypes.bool,
};

export default Body;
