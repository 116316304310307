import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton, makeStyles, Tooltip,
} from '@material-ui/core';
import {
  File as DownloadIcon,
} from 'react-feather';
import { withPermissionsChecker } from 'wrappers';

const useStyles = makeStyles(theme => ({
  icon: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

/**
 * Componente que muestra el boton descargar
 * @param {*} param0
 */
const DownloadPdfButton = ({
  onClick, requiredPermissions = [], checkAccess,
}) => {
  const classes = useStyles();
  return (
    <IconButton
      className={classes.icon}
      onClick={onClick}
      disabled={!checkAccess(requiredPermissions)}
    >
      <Tooltip title="Filtros avanzados">
        <DownloadIcon className={classes.icon} fontSize="large" />
      </Tooltip>
    </IconButton>
  );
};

DownloadPdfButton.defaultProps = {
  requiredPermissions: [],
};

DownloadPdfButton.propTypes = {
  onClick: PropTypes.func,
  checkAccess: PropTypes.func,
  requiredPermissions: PropTypes.arrayOf(PropTypes.string),
};

export default withPermissionsChecker(DownloadPdfButton);
