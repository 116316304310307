import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogTitle, DialogContent, Grid, DialogActions,
} from '@material-ui/core';
import {
  ButtonSave, SelectBase, ButtonCancelar,
} from 'components/Controls';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const EstadoProcesoModal = ({
  titleModal, closeEstadoModal, submitModalData, itemsEstadoModal, modulo, documentos,
}) => {
  const validationSchema = Yup.object({
    estado: Yup.string().required('El campo es requerido'),
  });

  let onChangeEstadoSelect = false;

  const onChangeEstado = (event, formikBag) => {
    if (modulo === 'PEDIDO' || modulo === 'OT') {
      const estado = event.target.value;
      const { setValues } = formikBag;
      setValues({
        ...formikBag.values,
        estado,
      });
      onChangeEstadoSelect = event.target.value === 'APROBADO';
    } else {
      const estado = event.target.value;
      const { setValues } = formikBag;
      setValues({
        ...formikBag.values,
        estado,
      });
    }
  };

  return (
    <Formik
      initialValues={{ estado: '', documentos_id: '' }}
      validationSchema={validationSchema}
      onSubmit={submitModalData()}
    >
      {subProps => (
        <Dialog open onClose={closeEstadoModal()} fullWidth maxWidth="xs">
          <DialogTitle>{titleModal || 'Modal estado'}</DialogTitle>
          <Form>
            <DialogContent>
              <Grid container spacing={3} direction="row">
                <Grid item md={12}>
                  <Field
                    label="Estado"
                    name="estado"
                    component={SelectBase}
                    items={itemsEstadoModal}
                    onChange={event => onChangeEstado(event, subProps)}
                  />
                </Grid>
                {onChangeEstadoSelect && (
                  <Grid item md={12}>
                    <Field
                      label="Documentos"
                      name="documentos_id"
                      component={SelectBase}
                      items={documentos}
                    />
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <ButtonCancelar onClick={closeEstadoModal()} />
              <ButtonSave label="Guardar" disabled={subProps.isSubmitting} />
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

EstadoProcesoModal.defaultProps = {
  documentos: [],
};

EstadoProcesoModal.propTypes = {
  titleModal: PropTypes.string,
  modulo: PropTypes.string,
  closeEstadoModal: PropTypes.func.isRequired,
  submitModalData: PropTypes.func.isRequired,
  itemsEstadoModal: PropTypes.arrayOf(
    PropTypes.oneOfType(PropTypes.object),
  ),
  documentos: PropTypes.arrayOf(
    PropTypes.oneOfType(PropTypes.object),
  ),
};

export default EstadoProcesoModal;
