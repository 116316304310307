import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link as LinkBase } from 'react-router-dom';

/**
 * This component gives some styling to the react-router-dom Link
 */

const Link = styled(LinkBase)`
  color: #1565c0;
  text-decoration: none;
`;

Link.propTypes = {
  to: PropTypes.string,
};

export default Link;
