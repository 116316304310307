import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

class ToggleGroupBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alignment: 0,
    };
  }

  handleChange = (event, newAlignment) => {
    this.setState({ alignment: newAlignment });
    this.props.onClick(newAlignment);
  }

  render() {
    const {
      config,
    } = this.props;

    const {
      alignment,
    } = this.state;

    return (
      <>
        <ToggleButtonGroup
          color="primary"
          value={alignment}
          exclusive
          onChange={this.handleChange}
        >
          {config.map((item, i) => (
            <ToggleButton
              key={String(i)}
              value={item.label}
              onClick={item.onClick}
            >
              {item.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </>
    );
  }
}

ToggleGroupBase.propTypes = {
  config: PropTypes.oneOfType([PropTypes.array]).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ToggleGroupBase;
