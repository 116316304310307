const TIPO = 'tipo';
const CLASIFICACION = 'clasificacion';
const MODELO = 'modelo';
const NIVEL = 'nivel';
const OPERADOR = 'operador';
const PERSONA = 'persona';
const CATEGORIA = 'categoria';

export default {
  TIPO,
  CLASIFICACION,
  MODELO,
  NIVEL,
  OPERADOR,
  PERSONA,
  CATEGORIA,
};
