import React from 'react';
import PropTypes from 'prop-types';
import DescriptionIcon from '@material-ui/icons/Description';
import {
  Button,
} from '@material-ui/core';

/**
 * This component allows to render a button specific to PDF actions.
 * @param {*} param0
 */
function ButtonPdf({
  className, style, disabled, onDowloadPdf, label = 'PDF', ...rest
}) {
  return (
    <Button
      className={className}
      style={style}
      variant="contained"
      onClick={onDowloadPdf}
      color="default"
      disabled={disabled}
      startIcon={<DescriptionIcon />}
      {...rest}
    >
      {label}
    </Button>
  );
}

ButtonPdf.defaultProps = {
  className: '',
  style: {},
  disabled: false,
};

ButtonPdf.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  style: PropTypes.shape(),
  onDowloadPdf: PropTypes.func,
};

export default ButtonPdf;
