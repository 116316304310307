import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import styles from './styles';

function EstadoChip({ estado = 'SIN ESTADO', classes, ...props }) {
  const className = estado.replace(/_/g, '-').toLowerCase();
  // const label = estado.replace(/_/g, '');
  return (
    <Chip size="small" label={estado} className={classes[className]} {...props} />
  );
}

EstadoChip.propTypes = {
  estado: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(EstadoChip);
