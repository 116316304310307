import React from 'react';
import PropTypes from 'prop-types';

import {
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
} from '@material-ui/core';

/**
 * This component allows to render a dropdown ready to work with formik
 * @param {*} param0
 */
const SelectBase = React.forwardRef(({
  items, label, error = null, helperText = null, field: {
    name, onBlur, value, onChange,
  }, form: {
    touched, errors, setFieldValue, setFieldTouched,
  }, ...props
}, ref) => {
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const handleChange = (event) => {
    if (onChange) {
      onChange(event);
    } else {
      setFieldTouched(name, true);
      setFieldValue(name, event.target.value);
    }
  };

  const hasError = touched[name] && Boolean(errors[name]);

  return (
    <FormControl
      variant="outlined"
      fullWidth
      error={error !== null ? error : hasError}
      margin="dense"
    >
      <InputLabel ref={inputLabel}>{label}</InputLabel>
      <Select
        id={name}
        inputRef={ref}
        value={value}
        onChange={handleChange}
        labelWidth={labelWidth}
        onBlur={onBlur}
        name={name}
        {...props}
      >
        <MenuItem value="">
          <em>seleccione un registro</em>
        </MenuItem>
        {items.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {(hasError || helperText)
        && (
          <FormHelperText>
            {helperText !== null ? helperText : errors[name]}
          </FormHelperText>
        )}
    </FormControl>
  );
});

SelectBase.displayName = 'SelectBase';

SelectBase.propTypes = {
  form: PropTypes.shape({
    errors: PropTypes.shape(),
    touched: PropTypes.shape(),
    setFieldTouched: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  }).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })).isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default SelectBase;
