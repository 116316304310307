import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { grey, green } from '@material-ui/core/colors';
import { Save as SaveIcon, ArrowRight as ArrowRightIcon } from 'react-feather';

const useStyles = makeStyles({
  style: {
    boxShadow: 'none',
    textTransform: 'uppercase',
    fontSize: 16,
    padding: '2px 12px',
    border: '2px solid',
    borderRadius: 8,
    backgroundColor: 'white',
    borderColor: green[500],
    color: green[500],
    '&:hover': {
      backgroundColor: fade(green[500], 0.1),
      borderColor: green[500],
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: fade(green[500], 0.5),
      borderColor: green[500],
    },
    '&:disabled': {
      borderColor: grey[300],
    },
  },
});

const ButtonSave = ({
  label, disabled, onClick, style, ...rest
}) => {
  const classes = useStyles();
  return (
    <Button
      disabled={disabled}
      className={classes.style}
      onClick={onClick}
      style={style}
      type="submit"
      startIcon={label !== 'Siguiente' ? <SaveIcon /> : <ArrowRightIcon />}
      {...rest}
    >
      {label}
    </Button>
  );
};

ButtonSave.propTypes = {
  // Texto que se va a mostrar en el botón
  label: PropTypes.string,
  // Boolean que determina si esta deshabilitado
  disabled: PropTypes.bool,
  // Funcion a ejecutarse cuando se da click
  onClick: PropTypes.func,
  style: PropTypes.oneOfType([PropTypes.object]),
};

export default ButtonSave;
