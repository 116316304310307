import React from 'react';
import { ApplicationContextConsumer } from 'context/ApplicationContext';

const checkPermissions = (userPermissions = [], neededPermissions = []) => {
  const grantedPermissions = {};
  neededPermissions.forEach((permission) => {
    grantedPermissions[permission] = userPermissions.includes(permission);
  });
  return grantedPermissions;
};

const hasAccess = (userPermissions = [], neededPermissions = []) => {
  for (let i = 0; i < neededPermissions.length; i++) {
    if (!userPermissions.includes(neededPermissions[i])) {
      return false;
    }
  }
  return true;
};

function hasAny(userPermissions = [], neededPermissions = []) {
  if (neededPermissions.length === 0) {
    return true;
  }
  for (let i = 0; i < neededPermissions.length; i++) {
    if (userPermissions.includes(neededPermissions[i])) {
      return true;
    }
  }
  return false;
}

export default (WrappedComponent, neededPermissions = []) => props => (
  <>
    <ApplicationContextConsumer>
      {({ permissions = [] }) => (
        <WrappedComponent
          {...props}
          permissions={checkPermissions(permissions, neededPermissions)}
          checkAccess={needed => hasAccess(permissions, needed)}
          checkHasAny={needed => hasAny(permissions, needed)}
        />
      )}
    </ApplicationContextConsumer>
  </>
);
