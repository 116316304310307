import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {
  Trash2 as DeleteIcon,
} from 'react-feather';
import bytesToSize from 'utils/bytesToSize';

const useStyles = makeStyles(theme => ({
  root: {},
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(6),
    outline: 'none',
    display: 'flex',
    justify: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer',
    },
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5,
  },
  image: {
    width: 130,
  },
  info: {
    marginTop: theme.spacing(1),
  },
  list: {
    maxHeight: 320,
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justify: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

function FilesDropzone({
  className, field: { name }, form: { setFieldValue },
  ...rest
}) {
  const classes = useStyles();
  const [files, setFiles] = useState([]);

  const handleDrop = async (file) => {
    setFiles(prevFiles => [...prevFiles].concat(file));
    if (file && file.length > 0) {
      setFieldValue(name, file[0]);
    }
  };

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop, multiple: false,
  });

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      {files.length <= 0 && (
        <>
          <div
            className={clsx({
              [classes.dropZone]: true,
              [classes.dragActive]: isDragActive,
            })}
            {...getRootProps()}
          >
            <input {...getInputProps()} accept=".xls, .xlsx" />
            <div>
              <img
                alt="Select file"
                className={classes.image}
                src="/static/images/undraw_add_file2_gvbb.svg"
              />
            </div>
            <div>
              <Typography
                gutterBottom
                variant="h3"
              >
                Seleccionar archivos
              </Typography>
              <Box mt={2}>
                <Typography
                  color="textPrimary"
                  variant="body1"
                >
                  Arrastre sus archivos o simplemente de click aquí para buscarlos en su equipo.
                </Typography>
              </Box>
            </div>
          </div>
        </>
      )}
      {files.length > 0 && (
        <>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <List className={classes.list}>
              {files.map((file, i) => (
                <ListItem
                  divider={i < files.length - 1}
                  key={`list-item-${String(i)}`}
                >
                  <ListItemIcon>
                    <FileCopyIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={file.name}
                    primaryTypographyProps={{ variant: 'h5' }}
                    secondary={bytesToSize(file.size)}
                  />
                  <Tooltip title="Eliminar">
                    <IconButton
                      edge="end"
                      onClick={handleRemoveAll}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </ListItem>
              ))}
            </List>
          </PerfectScrollbar>
          <div className={classes.actions}>
            <Button
              onClick={handleRemoveAll}
              size="small"
            >
              Eliminar todos
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

FilesDropzone.propTypes = {
  className: PropTypes.string,
  field: PropTypes.shape({
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape(),
    touched: PropTypes.shape(),
    setFieldTouched: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
};

export default FilesDropzone;
