import React, { useState, useEffect } from 'react';
import { withNotification, withApi } from 'wrappers';
import { Formik, Field, Form } from 'formik';
import endPoints from 'endPoints/endPoints';
import { Grid, Typography } from '@material-ui/core';
import {
  SwitchBase,
  ButtonCancelar,
  ButtonSave,
  TextBase,
  SelectBase,
} from 'components/Controls';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Abreviaturas } from 'constantes';
import GeneralPopover from 'components/Popover/GeneralPopover';
import { HelpCircle } from 'react-feather';
import Loader from '../LoadingScreen';

const CalendarioTrabajoForm = ({
  idEdit,
  onSubmit,
  closeModal,
  doGet,
  genericException,
}) => {
  const [state, setState] = useState({
    activo: true,
    abr: Abreviaturas.EMPLEADO,
    principal: false,
    limitarCalendarioEmpleado: false,
    nombre: '',
    tipoCalendario: '',
    tipoCalendarios: [],
  });
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [message, setMessage] = useState('');

  const submitForm = (formValues) => {
    onSubmit(formValues);
  };

  const validationSchema = Yup.object({
    nombre: Yup.string().required('El campo nombre es requerido'),
    tipoCalendario: Yup.string().required('El campo clasificación calendario es requerido'),
  });

  const getSelectedinfo = data => (data.map(item => (
    {
      value: item.id,
      label: item.nombre,
      abreviatura: item.abreviatura,
    }
  )));

  const loadValues = async () => {
    try {
      const {
        tiposCalendarios,
        calendarioTrabajo,
      } = await doGet({
        url: `${endPoints.general.calendarioTrabajo.base}/initformcomponent`,
        data: { calendarioTrabajoId: idEdit || '' }
      });
      const {
        nombre = '',
        activo = true,
        principal = false,
        tipo_calendario_id: tipoCalendario = '',
        limitar_calendario_empleados: limitarCalendarioEmpleado = false,
      } = calendarioTrabajo || {};

      setState({
        nombre,
        activo,
        principal,
        tipoCalendario,
        limitarCalendarioEmpleado,
        tipoCalendarios: getSelectedinfo(tiposCalendarios),
      });
      setLoading(false);
    } catch (e) {
      genericException(e);
    }
  };

  useEffect(() => {
    loadValues();
    // eslint-disable-next-line
  }, [idEdit]);

  const onChangeTipoCalendario = ({ target: { value = '' } = {} }, { setValues, values }) => {
    const {
      tipoCalendarios,
    } = values;

    const found = tipoCalendarios.find(item => item.value === value);

    let abr = Abreviaturas.EMPLEADO;

    if (found) {
      if (found.abreviatura === Abreviaturas.EMPRESA) abr = Abreviaturas.EMPRESA;
    }

    setValues({
      ...values,
      limitarCalendarioEmpleado: false,
      tipoCalendario: value,
      abr,
    });
  };

  const openPopover = (event, messagePopover) => {
    setAnchorEl(event.currentTarget);
    setMessage(messagePopover);
  };

  const closePopover = () => setAnchorEl(null);

  return idEdit && loading ? <Loader /> : (
    <>
      <GeneralPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closePopover}
      >
        <Typography style={{ padding: 3 }}>
          {message}
        </Typography>
      </GeneralPopover>
      <Formik
        enableReinitialize
        initialValues={state}
        validationSchema={validationSchema}
        onSubmit={submitForm}
      >
        {subProps => (
          <Form>
            <Grid container direction="row" justify="space-between" spacing={2}>
              <Grid item xs={12} md={4} xl={4}>
                <Field
                  name="activo"
                  label="activo"
                  component={SwitchBase}
                />
              </Grid>
              <Grid item xs={12} md={4} xl={4}>
                <Field
                  name="principal"
                  label="principal"
                  component={SwitchBase}
                />
              </Grid>
              <Grid item xs={12} md={4} xl={4} />
              <Grid item xs={12} md={12} xl={12}>
                <Field
                  name="nombre"
                  label="nombre"
                  component={TextBase}
                />
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <Field
                  name="tipoCalendario"
                  label="Clasificación calendario"
                  component={SelectBase}
                  items={subProps.values.tipoCalendarios}
                  onChange={event => onChangeTipoCalendario(event, subProps)}
                />
              </Grid>
            </Grid>
            {subProps.values.abr === Abreviaturas.EMPRESA && (
              <Grid item xs={12} md={4} xl={4}>
                <>
                  <Field
                    name="limitarCalendarioEmpleado"
                    label="limitar calendario empleado"
                    component={SwitchBase}
                  />
                  <HelpCircle
                    style={{ marginLeft: 5 }}
                    onMouseEnter={(e) => {
                      // eslint-disable-next-line
                      openPopover(e, 'Limitará que los empleados de la empresa no tengan un calendario de trabajo cuyo horario, exceda el horario de la empresa.');
                    }}
                    onMouseLeave={closePopover}
                    onMouseDown={(e) => {
                      // eslint-disable-next-line
                      openPopover(e, 'Limitará que los empleados de la empresa no tengan un calendario de trabajo cuyo horario, exceda el horario de la empresa.')
                    }}
                  />
                </>
              </Grid>
            )}
            <Grid container direction="row" justify="flex-end" spacing={2}>
              <Grid item>
                <ButtonCancelar onClick={closeModal} />
              </Grid>
              <Grid item>
                <ButtonSave label="Guardar" />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

CalendarioTrabajoForm.propTypes = {
  idEdit: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  doGet: PropTypes.func.isRequired,
  genericException: PropTypes.func.isRequired,
};

export default withApi(withNotification(CalendarioTrabajoForm));
