import React, { createContext, Component } from 'react';
import { SocketActions } from 'constantes';
import PropTypes from 'prop-types';

export const SocketContext = createContext({
  socket: null,
  setSocket: () => { },
});

export class SocketContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socket: null,
      // authWSUrl: 'https://potows.360-web.net/authenticate',
      // WSUrl: 'wss://potows.360-web.net/',
      // temporary routes till 360 works again
      authWSUrl: 'https://potows.360-erp.com/authenticate',
      WSUrl: 'wss://potows.360-erp.com/',
      // authWSUrl: 'http://172.17.0.186:5050/authenticate',
      // WSUrl: 'ws://172.17.0.186:5050/',
    };
  }

  setSocket = (socket) => {
    this.setState({
      socket,
    });
  }

  /**
   * @name emitRoomMessage
   * @desc Function in charge of emit a message to an especific room of the web socket server
   * @param {object} data
   * @paramDesc It has to be an object that contains room (Name of the web socket room),
   *  roomName (This is used to compare on the listening of the message)
   *  message (This is also used to compare on the listening)
   */
  emitRoomMessage = async (data) => {
    const { socket } = this.state;
    await socket.emit(
      SocketActions.ROOM_MESSAGE,
      { room: data.room, data: { room: data.roomName, message: data.message } },
    );
  }

  emitBroadcast = async (data) => {
    const { socket } = this.state;
    await socket.emit(SocketActions.BROADCAST, { data });
  }

  render() {
    const { children } = this.props;
    const {
      socket,
      authWSUrl,
      WSUrl,
    } = this.state;
    return (
      <SocketContext.Provider value={{
        WSUrl,
        socket,
        authWSUrl,
        setSocket: this.setSocket,
        emitRoomMessage: this.emitRoomMessage,
        emitBroadcast: this.emitBroadcast,
      }}
      >
        {children}
      </SocketContext.Provider>
    );
  }
}

SocketContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SocketContextConsumer = SocketContext.Consumer;
