import React from 'react';
import PropTypes from 'prop-types';
import { Toolbar } from '@material-ui/core';

/**
 * Componente que permite dibujar una toolbar con contenido
 * a la izquierda y a la derecha. Util para las tablas cuando
 * llevan el botón de crear
 *
 * @author Daniel Tobón Mejía
 */
function PlainToolbar({ rigthItems, leftItems }) {
  return (
    <Toolbar variant="dense">
      {leftItems}
      <div style={{ flex: '1 1 100%' }} />
      {rigthItems}
    </Toolbar>
  );
}

PlainToolbar.propTypes = {
  // El nodo que se dibujará al lado derecho
  rigthItems: PropTypes.node,
  // El nodo que se dibujará al lado izquierdo
  leftItems: PropTypes.node,
};

export default PlainToolbar;
