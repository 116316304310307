import React from 'react';
import PropTypes from 'prop-types';
import { dotNotationExtractor, isFunction } from 'utils/functions';
import { Typography, Grid } from '@material-ui/core';
import { formatter } from 'constantes/constants';

function Content({
  content,
  data,
}) {
  let valueContent = content.component || dotNotationExtractor(data, content.name) || '';
  if (isFunction(valueContent)) {
    valueContent = valueContent(data);
  } else if (content.label) {
    if (valueContent) {
      valueContent = valueContent.type === 'number' ? formatter.format(valueContent ?? 0) : valueContent;
      valueContent = (
        <Grid
          item
          container
          xs={content.xs || 6}
          direction="row"
          /* eslint-disable-next-line react/prop-types */
          justify={content.justify || 'flex-start'}
          alignItems="center"
        >
          <Typography variant={content.variant || 'subtitle2'}>
            {`${content.label}: `}
          </Typography>
        &nbsp;
          <Typography variant={content.variant || 'body1'}>
            {valueContent}
          </Typography>
        </Grid>
      );
    }
  } else if (valueContent) {
    valueContent = (
      <Grid
        container
        direction="row"
        /* eslint-disable-next-line react/prop-types */
        justify={content.justify || 'flex-start'}
        alignItems="center"
      >
        <Typography variant={content.variant || 'subtitle2'}>
          {valueContent}
        </Typography>
      </Grid>
    );
  }
  return (
    <>
      {valueContent}
    </>
  );
}

Content.propTypes = {
  content: PropTypes.shape({
    label: PropTypes.string,
    name: PropTypes.string,
    variant: PropTypes.string,
    component: PropTypes.oneOfType([PropTypes.func]),
    xs: PropTypes.string,
    justify: PropTypes.oneOf(['flex-end', 'center', 'flex-start']),
  }).isRequired,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default Content;
