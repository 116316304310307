const DOCUMENTOS_VENTAS = 'documentos_ventas';
const DOCUMENTOS_COMPRAS = 'documentos_compras';
const DOCUMENTOS_PLM = 'documentos_plm';
const CONSECUTIVOS = 'consecutivos';
const ATRIBUTOS = 'atributos';
const CLASIFICACIONESTRIBUTARIAS_ITEMS_CONCEPTOS = 'clasificacionestributarias_items_conceptos';
const CLASIFICACIONESTRIBUTARIAS_TERCEROS_CONCEPTOS = 'clasificacionestributarias_terceros_conceptos';
const CONCEPTOS_TRIBUTARIOS = 'conceptos_tributarios';
const DOCUMENTOS_INVENTARIOS = 'documentos_inventarios';
const DOCUMENTOS_WMS = 'documentos_wms';
const GRUPOS_PAGOS = 'grupos_pagos';
const PERIODICIDADES = 'periodicidades';
const PRODUCTOS = 'productos';
const TERCEROS = 'terceros';
const TIPOS_CODIGOS_BARRAS = 'tipos_codigos_barras';
const TIPOS_CONTRATOS = 'tipos_contratos';
const TIPOS_PRODUCTOS = 'tipos_productos';
const DISPOSITIVOS_ELECTRONICOS = 'dispositivos_electronicos';
const DOCUMENTOS_CRM = 'documentos_crm';
const GASTOS = 'gastos';
const TIPOS_CONTENEDORES = 'tipos_contenedores';
const DOCUMENTOS_TESORERIA = 'documentos_tesoreria';
const TARJETAS_CREDITO = 'tarjetas_creditos';
const MONEDAS = 'monedas';
const TIPOS_CUENTAS_BANCARIAS = 'tipos_cuentasbancarias';
const OPERADORES_INFORMES = 'informes';
const MEDIOS_PAGOS = 'medios_pagos';
const DOCUMENTOS_CONTABILIDAD = 'documentos_contabilidad';
const FORMAS_PAGOS = 'formas_pagos';
const TOMAS_INVENTARIO = 'tomas_inventario';
const NOTAS_CREDITOS = 'notas_creditos';
const OPERACIONES_DIAN = 'operaciones_dian';
const DOCUMENTOS_GESTION_ACTIVOS = 'documentos_gestion_activos';
const DOCUMENTOS_GOBIERNOS_CORPORATIVOS = 'documentos_gobiernos_corporativos';
const DOCUMENTOS_PQRSF = 'documentos_pqrsf';
const CLASIFICACIONES_PROVEEDORES = 'clasificaciones_proveedores';
const LICENCIAS = 'licencias';
const DOCUMENTOS_HRM = 'documentos_hrm';
const NIVELES = 'niveles';
const PRIORIDADES_IMPACTOS = 'prioridades_impactos';
const TIPOS_CALENDARIOS = 'tipos_calendarios';
const DOCUMENTOS_GESTION_CONTRATOS = 'documentos_gestion_contratos';
const PRESTACIONES_SOCIALES = 'prestaciones_sociales';
const DOCUMENTOS_GESTION_PROVEEDORES = 'documentos_gestion_proveedores';

export default {
  PRESTACIONES_SOCIALES,
  OPERACIONES_DIAN,
  DOCUMENTOS_COMPRAS,
  DOCUMENTOS_VENTAS,
  DOCUMENTOS_PLM,
  CONSECUTIVOS,
  ATRIBUTOS,
  FORMAS_PAGOS,
  CLASIFICACIONES_PROVEEDORES,
  CLASIFICACIONESTRIBUTARIAS_ITEMS_CONCEPTOS,
  CLASIFICACIONESTRIBUTARIAS_TERCEROS_CONCEPTOS,
  CONCEPTOS_TRIBUTARIOS,
  DOCUMENTOS_INVENTARIOS,
  DOCUMENTOS_WMS,
  GRUPOS_PAGOS,
  PERIODICIDADES,
  TOMAS_INVENTARIO,
  PRODUCTOS,
  TERCEROS,
  TIPOS_CODIGOS_BARRAS,
  TIPOS_CONTRATOS,
  TIPOS_PRODUCTOS,
  DISPOSITIVOS_ELECTRONICOS,
  DOCUMENTOS_CRM,
  GASTOS,
  TIPOS_CONTENEDORES,
  DOCUMENTOS_TESORERIA,
  TARJETAS_CREDITO,
  MONEDAS,
  TIPOS_CUENTAS_BANCARIAS,
  OPERADORES_INFORMES,
  MEDIOS_PAGOS,
  DOCUMENTOS_CONTABILIDAD,
  NOTAS_CREDITOS,
  DOCUMENTOS_GESTION_ACTIVOS,
  DOCUMENTOS_GOBIERNOS_CORPORATIVOS,
  DOCUMENTOS_PQRSF,
  LICENCIAS,
  DOCUMENTOS_HRM,
  NIVELES,
  PRIORIDADES_IMPACTOS,
  TIPOS_CALENDARIOS,
  DOCUMENTOS_GESTION_CONTRATOS,
  DOCUMENTOS_GESTION_PROVEEDORES,
};
