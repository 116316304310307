const COP = '$'; // Peso, dolar, y desconocidos
const EUR = '€'; // Euro
const GBP = '£'; // Libra
const JPY = '¥'; // Yen
const CHF = '₣'; // Franco
const INR = '₹'; // Rupia
const IQD = 'د.ك'; // Dinar
const AED = 'د.إ'; // Dirham
const BHD = 'د.ك';
const BIF = '₣';
const CNY = '¥';
const DJF = '₣';
const DZD = 'د.ك';
const EGP = '£';
const FKP = '£';
const GEL = '₾'; // Lari
const GHS = '₵'; // Cedi
const GIP = '£';
const GNF = '₣';
const IDR = '₹';
const JOD = 'د.ك';
const KMF = '₣';
const KPW = '원'; // Won
const KRW = '원';
const KWD = 'د.ك';
const LBP = '£';
const LKR = '₹';
const LYD = 'د.ك';
const MAD = 'د.إ';
const MUR = '₹';
const NPR = '₹';
const PKR = '₹';
const RSD = 'د.ك';
const RWF = '₣';
const SCR = '₹';
const SDG = 'د.ك';
const SHP = '£';
const SSP = '£';
const SYP = '£';
const TND = 'د.ك';
const TRY = '₺';
const XAF = '₣';

export default {
  COP,
  EUR,
  GBP,
  JPY,
  CHF,
  INR,
  IQD,
  AED,
  BHD,
  BIF,
  CNY,
  DJF,
  DZD,
  EGP,
  FKP,
  GEL,
  GHS,
  GIP,
  GNF,
  IDR,
  JOD,
  KMF,
  KPW,
  KRW,
  KWD,
  LBP,
  LKR,
  LYD,
  MAD,
  MUR,
  NPR,
  PKR,
  RSD,
  RWF,
  SCR,
  SDG,
  SHP,
  SSP,
  SYP,
  TND,
  TRY,
  XAF,
};
