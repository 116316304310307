import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton, makeStyles, Tooltip,
} from '@material-ui/core';
import {
  Edit as EditIcon,
} from 'react-feather';
import { DEFAULT_MUI_DATATABLE_BUTTON_SIZE } from 'constantes/constants';
import { withPermissionsChecker } from 'wrappers';

const useStyles = makeStyles(theme => ({
  icon: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

/**
 * This component allows to render a button specific to update
 * @param {*} param0
 */
const UpdateButton = ({
  onClick, requiredPermissions = [], checkAccess, disabled,
}) => {
  const classes = useStyles();
  return (
    <Tooltip title="Editar">
      <IconButton
        component="div"
        size={DEFAULT_MUI_DATATABLE_BUTTON_SIZE}
        className={classes.icon}
        onClick={onClick}
        disabled={!checkAccess(requiredPermissions) || disabled}
      >
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
};

UpdateButton.defaultProps = {
  requiredPermissions: [],
};

UpdateButton.propTypes = {
  onClick: PropTypes.func,
  checkAccess: PropTypes.func,
  requiredPermissions: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
};

export default withPermissionsChecker(UpdateButton);
