const APROBADO = 'APROBADO';
const CERRADO = 'CERRADO';
const EN_PROCESO = 'EN_PROCESO';
const ANULADO = 'ANULADO';
const BLOQUEADO = 'BLOQUEADO';
const ESTADOS = [
  APROBADO,
  CERRADO,
  EN_PROCESO,
  ANULADO,
  BLOQUEADO,
];

export default {
  APROBADO,
  CERRADO,
  EN_PROCESO,
  ANULADO,
  BLOQUEADO,
  labelValue: () => (ESTADOS.map(estado => ({ label: estado, value: estado }))),
  enableActions: estado => (
    estado !== ANULADO && estado !== CERRADO),
};
