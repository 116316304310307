export default theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
});

export const optionsStyles = () => ({
  popoverPaper: {
    width: '100%',
    height: '100%',
    maxHeight: 'unset',
    maxWidth: 'unset',
  },
});
