import React from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { withPermissionsChecker } from 'wrappers';

function GenericIconWithPermission({
  Icon,
  title,
  onClick,
  disabled,
  checkAccess,
  requiredPermissions,
}) {
  return (
    <Tooltip title={title}>
      <IconButton
        component="div"
        onClick={onClick}
        disabled={disabled || !checkAccess(requiredPermissions)}
      >
        <Icon />
      </IconButton>
    </Tooltip>
  );
}

GenericIconWithPermission.propTypes = {
  Icon: PropTypes.node,
  title: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  checkAccess: PropTypes.func,
  requiredPermissions: PropTypes.arrayOf(PropTypes.string),
};

export default withPermissionsChecker(GenericIconWithPermission);
