const LISTA_EMPAQUE = 'LE';
const RECEPCION = 'RP';
const DESPACHO = 'DP';
const FT = 'FT';
const PD = 'PD';
const NC = 'NC';
const ND = 'ND';
const CP = 'CP';
const OC = 'OC';
const SIR = 'SIR';
const GI = 'GI';
// Product Lifecycle Management PLM
const GESTION_CVP = 'PL';
const ORDEN_PRODUCCION = 'OP';
const DIFERENTE_DE = '!=';
const MENOR_QUE = '<';
const MENOR_IGUAL_QUE = '<=';
const IGUAL_QUE = '==';
const MAYOR_QUE = '>';
const MAYOR_IGUAL_QUE = '>=';
const B = 'B';
const BO = 'BO';
const C = 'C';
const C39 = 'C39';
const CL = 'CL';
const CT = 'CT';
const E = 'E';
const E128 = 'E128';
const E13 = 'E13';
const E14 = 'E14';
const E8 = 'E8';
const EA = 'EA';
const EB = 'EB';
const F = 'F';
const GT = 'GT';
const H = 'H';
const I = 'I';
const IG = 'IG';
const L = 'L';
const M = 'M';
const MP = 'MP';
const N = 'N';
const P = 'P';
const PJ = 'PJ';
const PN = 'PN';
const PR = 'PR';
const PT = 'PT';
const CG = 'CG';
const AGRUPACION = 'AG';
const R = 'R';
const S = 'S';
const T = 'T';
const TA = 'TA';
const AF = 'AF';
const NM = 'NM';
const TR = 'TR';
const U12 = 'U12';
const U8 = 'U8';
const V = 'V';
const RD = 'RD';
const RR = 'RR';
const VD = 'VD';
const MT = 'MT';
const D = 'D';
const FIELD_TYPE_TEXT = 'T';
const FIELD_TYPE_TEXT_AREA = 'TA';
const FIELD_TYPE_ALPHANUMERIC = 'AF';
const FIELD_TYPE_SELECT = 'S';
const FIELD_TYPE_AUTOCOMPLETE = 'A';
const FIELD_TYPE_NUMBERM = 'NM';
const FIELD_TYPE_NUMBER = 'N';
const FIELD_TYPE_LOGIC = 'L';
const FIELD_TYPE_DATE = 'F';
const FIELD_TYPE_MULTIPLE = 'M';
const FIELD_TYPE_PERCENT = 'P';
const FIELD_TYPE_TIME = 'TI';
const AL = 'AL';
const ALISTAMIENTO = 'AI';
const RECOLECCION = 'RCO';
// const BASCULA = 'BC';
// const IMPRESORA = 'IP';
const PROYECCION = 'PRY';
const COMERCIAL = 'CO';
const CONTROL = 'CR';
// const VARIANTES = 'VR';
const DESCRIPCION = 'D';
const SECCION = 'SE';
const CAPITULO = 'CP';
const PARTIDA = 'PA';
const SUBPARTIDA = 'SP';
const INCISO = 'IN';
const ALMACENAMIENTO = 'A';
const ALMACENAMIENTO_ZONAS = 'AL';
const FIRMA = 'FM';
const RECIBO_CAJA = 'RC';
const EGRESO = 'EG';
const DOCUMENTO_TESORERIA = 'DT';
const CONTRATO_TESORERIA = 'CONT';
const DINERS_CLUB = 'DC';
const VISA = 'VS';
const MASTER_CARD = 'MC';
const AMERICAN_EXPRESS = 'AX';
const MEDIO_PAGO_BANCO = 'BCO';
const MEDIO_PAGO_CAJA = 'CAJ';
const PREINSPECCION = 'PRE';
const NOTA_CREDITO = 'NC';
const PAGO = 'PG';
const PROGRAMACION_PAGO = 'PPG';
const CONTABILIDAD = 'CTB';
const CLASE = 'CLA';
const GRUPO = 'GR';
const CUENTA = 'CU';
const SUB_CUENTA = 'SCU';
const AUXILIAR = 'AUX';
const SUB_AUXILIAR_1 = 'SUBA1';
const SUB_AUXILIAR_2 = 'SUBA2';
const SUB_AUXILIAR_3 = 'SUBA3';
const SUB_AUXILIAR_4 = 'SUBA4';
const SUB_AUXILIAR_5 = 'SUBA5';
const NACIONAL = 'NAL';
const INTERNACIONAL = 'INTL';
const INSTRUMENTAL = 'Instrumental';
const INSTRUMENTAL_ABREVIATURA = 'INS';
const MOTOR = 'Motor';
const MOTOR_ABREVIATURA = 'MOT';
const TRANSACCION = 'TB';
const ENTREGAR = 'ETG';
const RECOGER = 'RCG';
const CAMBIAR = 'CB';
const CREDITO = 'CRE';
const CONTADO = 'CON';
const TOMA_COMPLETA = 'TC';
const TOMA_PARCIAL = 'TP';
const TOMA_COMPLETA_PRODUCTO = 'TCP';
const TOMA_COMPLETA_UBICACION = 'TCU';
const TOMA_COMPLETA_PRODUCTO_UBICACION = 'TCPU';
const TOMA_COMPLETA_EQUIPO = 'TCE';
const TOMA_COMPLETA_CLASIFICACION = 'TCC';
const PLAZODIAS = 'PPD';
const PLAZOFECHA = 'PPF';
const CONSIGNACION = 'CSGN';
const PREPEDIDO = 'PP';
const PEDIDO = 'PD';
const HORA_RUTA = 'HR';
const PROGRAMACION_ALISTAMIENTO = 'PPE';
const PICKING = 'PK';
const SOLICITUD_LOGISTICA = 'SL';
const REPORTE_GASTO = 'RG';
const ROTACION = 'ROTC';
const NOTA_DEBITO = 'ND';
const INGRESOS = 'ING';
const COSTOS = 'C';
const GASTOS = 'G';
const AJUSTES_CONTABLES = 'AC';
const CAUSACION_CREDITO = 'CC';
const SOLICITUD_TRASLADO = 'ST';
const UUID_INGRESO_FACTURAS = '9cf00';
const UUID_ANTICIPOS = '2d270';
const UUID_OTROS_INGRESOS = '66a43';
const DEVOLUCION_VENTAS = 'DV';
const DEVOLUCION_DINERO = 'DD';
const DEVOLUCION_PRODUCTOS = 'DP';
const ORDEN_COMPRA = 'OC';
const OCI = 'OCI';
const OCN = 'OCN';
const OTROS_INGRESOS = 'OI';
const KIT = 'KIT';
const CONFIGURACION = 'CONF';
const GESTION_ACTIVOS = 'GA';
const GOBIERNO_CORPORATIVO = 'GC';
const PQRSF = 'PQRSF';
const UNICO = 'UN';
const GENERAL = 'GN';
const COMPUESTO = 'CMP';
const PRESENCIAL = 'PS';
const CLASIFICACION_PROVEEDOR_PRODUCTOS = 'P';
const CLASIFICACION_PROVEEDOR_SERVICIOS = 'SRV';
const CLASIFICACION_PROVEEDOR_PRESTACION_SOCIAL = 'PS';
const PROGRAMACION_TOMA_INVENTARIO = 'PTI';
const EJECUCION_TOMA_INVENTARIO = 'ETI';
const AJUSTE_INVENTARIO = 'AI';
const PROGRAMACION_ALISTAMIENTO_PROCESO = 'PA';
const ALISTAMIENTO_PROCESO = 'A';
const MANTENIMIENTO = 'MAN';
const DOCUMENTOS_FINANCIEROS = 'DT';
const TRASLADOS_TESORERIA = 'TT';
const ACTIVACION = 'ACT';
const TRASLADO_ACTIVOS = 'TA';
const VENTA_ACTIVOS = 'VA';
const DESTRUCCION = 'DSC';
const OBSOLENCIA = 'OBSC';
const HURTO_ROBO = 'HR';
const PERDIDA = 'PRD';
const SALDOS_INICIALES = 'SI';
const DETERIORO_ACTIVOS = 'DA';
const DIFERENCIA_CAMBIO = 'DEC';
const DEPRECIACION = 'DEP';
const ACTIVO_MOVIMIENTO = 'AM';
const GASTOS_DIFERIDOS = 'GD';
const VALORACION_COBERTURA = 'VC';
const LIQUIDACION_IMPUESTOS = 'LI';
const INTERESES = 'INT';
const CANDIDATOS = 'CAN';
const HORAS = 'HR';
const DIAS = 'DI';
const SEMANAS = 'SEM';
const MESES = 'MES';
const MENSUAL = 'MENS';
const ANUAL = 'AN';
const SEMANAL = 'SEM';
const DIARIO = 'DI';
const LIQUIDACION_VACACIONES = 'LV';
const SOLICITUD_VACACIONES = 'SV';
const COMPROBANTE_NOMINA = 'CN';
const UNO = '1';
const TRES = '3';
const CINCO = '5';
const PRIORIDAD = 'PRI';
const IMPACTO = 'IMP';
const VACACIONES = 'VAC';
const LIQUIDACION_PRIMA_SERVICIOS = 'LPS';
const LIQUIDACION_AUXILIO_CESANTIAS = 'LAC';
const LIQUIDACION_INTERES_CESANTIAS = 'LIC';
const PROGRAMAR_CAPACITACIONES = 'PPC';
const PROGRAMACION_ENTREVISTAS = 'PPE';
const PROCESO_DISCIPLINARIO = 'PDSC';
const LLAMADOS_ATENCION = 'LLA';
const DESCARGOS = 'DSC';
const SUSPENCIONES = 'SP';
const BONIFICACIONES = 'BNF';
const ASIGNACION_TAREAS = 'AT';
const GESTION_TAREAS = 'GT';
const PROGRAMACION_TURNOS = 'PGT';
const REPORTE_TIEMPO = 'RT';
const REPORTE_HORAS_EXTRAS = 'RHE';
const SOLICITUDES_PERMISOS = 'SP';
const SOLICITUDES_VACACIONES = 'SV';
const INCAPACIDADES_MEDICAS = 'IM';
const REGISTRO_LICENCIAS = 'RGL';
const EVALUACION_DE_CANDIDATOS = 'EDC';
const EVALUACION_DESEMPENO = 'EVD';
const PLAN_DESEMPENO_INDIVIDUAL = 'PDI';
const SOLICITUD_BENEFICIO = 'SB';
const TOMA_INVENTARIO = 'TI';
const EMPRESA = 'EMPS';
const EMPLEADO = 'EMPD';
const CLIENTE = 'CLI';
const PROVEEDOR = 'PROV';
const ORDEN_APROVICIONAMIENTO = 'OA';
const GESTION_CONTRATOS = 'GCON';
const SOLICITUD_CONTRATO = 'SCON';
const SEDE_EMPRESA = 'SDE';
const PROGRAMACION_GESTION_TALENTO = 'PGT';
const SOLICITUD_MODALIDAD_TRABAJO = 'SMT';
const AUXILIO_TRANSPORTE = 'AT';
const PRIMA_SERVICIOS = 'PS';
const CESANTIAS = 'CS';
const INTERESES_CESANTIAS = 'ICS';
const EPS_SEGURIDAD_SOCIAL = 'EPS';
const FONDO_PENSIONES = 'FDP';
const GESTION_PROVEEDORES = 'GPROV';
const PROGRAMACION_CAPACITACIONES = 'PC';
const PORCENTAJE = 'PC';
const VALOR = 'VR';
const DESVINCULACION_LABORAL = 'DLAB';
const DOTACION = 'DOT';
const VENTA_POS = 'VPOS';
const CONTENEDOR = 'CON';

export default {
  INGRESOS,
  PQRSF,
  DOCUMENTOS_FINANCIEROS,
  CREDITO,
  ORDEN_COMPRA,
  PORCENTAJE,
  VALOR,
  OCI,
  OCN,
  CLASIFICACION_PROVEEDOR_PRODUCTOS,
  CLASIFICACION_PROVEEDOR_SERVICIOS,
  CLASIFICACION_PROVEEDOR_PRESTACION_SOCIAL,
  PROGRAMACION_TOMA_INVENTARIO,
  EJECUCION_TOMA_INVENTARIO,
  TOMA_INVENTARIO,
  AJUSTE_INVENTARIO,
  PROGRAMACION_ALISTAMIENTO_PROCESO,
  ALISTAMIENTO_PROCESO,
  MANTENIMIENTO,
  CONTADO,
  GESTION_ACTIVOS,
  GOBIERNO_CORPORATIVO,
  ALMACENAMIENTO,
  ALMACENAMIENTO_ZONAS,
  TOMA_COMPLETA_PRODUCTO,
  TOMA_COMPLETA_UBICACION,
  TOMA_COMPLETA_PRODUCTO_UBICACION,
  TOMA_COMPLETA_EQUIPO,
  TOMA_COMPLETA_CLASIFICACION,
  SOLICITUD_TRASLADO,
  DEVOLUCION_VENTAS,
  DEVOLUCION_DINERO,
  DEVOLUCION_PRODUCTOS,
  TRASLADOS_TESORERIA,
  PROGRAMAR_CAPACITACIONES,
  MENSUAL,
  ANUAL,
  SEMANAL,
  DIARIO,
  B,
  FT,
  PD,
  NC,
  ND,
  CP,
  OC,
  SIR,
  GI,
  BO,
  C,
  C39,
  CL,
  CT,
  E,
  E128,
  E13,
  E14,
  E8,
  EA,
  EB,
  F,
  GT,
  H,
  I,
  IG,
  L,
  LISTA_EMPAQUE,
  PREPEDIDO,
  PEDIDO,
  HORA_RUTA,
  PROGRAMACION_ALISTAMIENTO,
  PICKING,
  SOLICITUD_LOGISTICA,
  REPORTE_GASTO,
  ROTACION,
  M,
  MP,
  N,
  AF,
  NM,
  P,
  MT,
  PJ,
  PN,
  PR,
  PT,
  CG,
  AGRUPACION,
  R,
  S,
  T,
  TA,
  TR,
  U12,
  U8,
  V,
  VD,
  D,
  UNICO,
  GENERAL,
  COMPUESTO,
  PREINSPECCION,
  MENOR_IGUAL_QUE,
  MENOR_QUE,
  MAYOR_IGUAL_QUE,
  MAYOR_QUE,
  DIFERENTE_DE,
  IGUAL_QUE,
  RECEPCION,
  DESPACHO,
  RD,
  RR,
  PROYECCION,
  SECCION,
  CAPITULO,
  PARTIDA,
  SUBPARTIDA,
  INCISO,
  RECOLECCION,
  FIRMA,
  ALISTAMIENTO,
  RECIBO_CAJA,
  EGRESO,
  DOCUMENTO_TESORERIA,
  CONTRATO_TESORERIA,
  DINERS_CLUB,
  VISA,
  MASTER_CARD,
  TRANSACCION,
  AMERICAN_EXPRESS,
  MEDIO_PAGO_BANCO,
  MEDIO_PAGO_CAJA,
  CONTABILIDAD,
  CLASE,
  GRUPO,
  CUENTA,
  SUB_CUENTA,
  AUXILIAR,
  SUB_AUXILIAR_1,
  SUB_AUXILIAR_2,
  SUB_AUXILIAR_3,
  SUB_AUXILIAR_4,
  SUB_AUXILIAR_5,
  NACIONAL,
  INTERNACIONAL,
  COMPROBANTE_NOMINA,
  ENTREGAR,
  RECOGER,
  CAMBIAR,
  TOMA_PARCIAL,
  TOMA_COMPLETA,
  PLAZODIAS,
  PLAZOFECHA,
  CONSIGNACION,
  UUID_INGRESO_FACTURAS,
  UUID_ANTICIPOS,
  UUID_OTROS_INGRESOS,
  KIT,
  CONFIGURACION,
  PRESENCIAL,
  CANDIDATOS,
  HORAS,
  SEMANAS,
  DIAS,
  MESES,
  LIQUIDACION_VACACIONES,
  SOLICITUD_VACACIONES,
  UNO,
  TRES,
  CINCO,
  PRIORIDAD,
  IMPACTO,
  VACACIONES,
  LIQUIDACION_PRIMA_SERVICIOS,
  LIQUIDACION_AUXILIO_CESANTIAS,
  LIQUIDACION_INTERES_CESANTIAS,
  EMPRESA,
  EMPLEADO,
  CLIENTE,
  PROVEEDOR,
  ORDEN_APROVICIONAMIENTO,
  GESTION_CONTRATOS,
  SOLICITUD_CONTRATO,
  SEDE_EMPRESA,
  PROGRAMACION_GESTION_TALENTO,
  GESTION_PROVEEDORES,
  PROGRAMACION_CAPACITACIONES,
  DOTACION,
  CONTENEDOR,
  // VARIANTES,

  // agrupaciones de constantes
  DOCUMENTOS_HRM: [
    SOLICITUD_VACACIONES,
    LIQUIDACION_VACACIONES,
    LIQUIDACION_PRIMA_SERVICIOS,
    LIQUIDACION_AUXILIO_CESANTIAS,
    LIQUIDACION_INTERES_CESANTIAS,
    PROGRAMACION_ENTREVISTAS,
    PROCESO_DISCIPLINARIO,
    LLAMADOS_ATENCION,
    DESCARGOS,
    SUSPENCIONES,
    BONIFICACIONES,
    ASIGNACION_TAREAS,
    GESTION_TAREAS,
    PROGRAMACION_TURNOS,
    REPORTE_TIEMPO,
    REPORTE_HORAS_EXTRAS,
    SOLICITUDES_PERMISOS,
    SOLICITUDES_VACACIONES,
    INCAPACIDADES_MEDICAS,
    REGISTRO_LICENCIAS,
    COMPROBANTE_NOMINA,
    EVALUACION_DE_CANDIDATOS,
    EVALUACION_DESEMPENO,
    PLAN_DESEMPENO_INDIVIDUAL,
    SOLICITUD_BENEFICIO,
    ORDEN_APROVICIONAMIENTO,
    SOLICITUD_MODALIDAD_TRABAJO,
    PROGRAMACION_CAPACITACIONES,
    DESVINCULACION_LABORAL,
  ],
  // VARIANTES,

  // agrupaciones de constantes
  TIPOS_CALENDARIOS: [EMPRESA, EMPLEADO, CLIENTE, PROVEEDOR, SEDE_EMPRESA],
  PRIORIDADES_IMPACTOS: [PRIORIDAD, IMPACTO],
  NIVELES: [UNO, TRES, CINCO],
  PROGRAMACION_ENTREVISTAS,
  PROCESO_DISCIPLINARIO,
  LLAMADOS_ATENCION,
  DESCARGOS,
  SUSPENCIONES,
  BONIFICACIONES,
  ASIGNACION_TAREAS,
  GESTION_TAREAS,
  PROGRAMACION_TURNOS,
  REPORTE_TIEMPO,
  REPORTE_HORAS_EXTRAS,
  SOLICITUDES_PERMISOS,
  SOLICITUDES_VACACIONES,
  INCAPACIDADES_MEDICAS,
  REGISTRO_LICENCIAS,
  EVALUACION_DE_CANDIDATOS,
  EVALUACION_DESEMPENO,
  PLAN_DESEMPENO_INDIVIDUAL,
  SOLICITUD_BENEFICIO,
  LICENCIAS: [HORAS, SEMANAS, DIAS, MESES],
  PRODUCTOS: [PT, CG, IG, MP, KIT],
  PRODUCTOS_CIRUGIAS: [PT, CG],
  DOCUMENTOS_VENTAS: [FT, PD, NC, ND, VENTA_POS],
  DOCUMENTOS_COMPRAS: [SIR, OC],
  DOCUMENTOS_PLM: [GESTION_CVP, ORDEN_PRODUCCION],
  DOCUMENTOS_WMS: [EA, LISTA_EMPAQUE, RECEPCION, DESPACHO, RECOLECCION, ALISTAMIENTO, AL],
  DOCUMENTOS_INVENTARIOS: [E, S],
  DOCUMENTOS_TESORERIA: [
    RECIBO_CAJA, EGRESO, DOCUMENTO_TESORERIA, CONTRATO_TESORERIA,
    NOTA_CREDITO, NOTA_DEBITO, PAGO, COSTOS, GASTOS, PROGRAMACION_PAGO,
    INGRESOS, AJUSTES_CONTABLES, CAUSACION_CREDITO, OTROS_INGRESOS,
    TRASLADOS_TESORERIA,
  ],
  TIPOS_PRESTACIONES_SOCIALES: [
    AUXILIO_TRANSPORTE,
    PRIMA_SERVICIOS,
    VACACIONES,
    CESANTIAS,
    INTERESES_CESANTIAS,
    EPS_SEGURIDAD_SOCIAL,
    FONDO_PENSIONES,
  ],
  TIPOS_NOTAS_CREDITO: [DEVOLUCION_VENTAS, DEVOLUCION_DINERO, DEVOLUCION_PRODUCTOS],
  TIPOS_RECIBO: [RD, RR],
  TIPOS_ZONA: [R, DESPACHO, AL, ALISTAMIENTO],
  TIPOS_PRODUCTOS: [PT, MP, AGRUPACION],
  OPERACIONES_DIAN: [F, NOTA_CREDITO, NOTA_DEBITO],
  DOCUMENTOS_CRM: [CT],
  DOCUMENTOS_GESTION_ACTIVOS: [
    GESTION_ACTIVOS, ACTIVACION, TRASLADO_ACTIVOS, VENTA_ACTIVOS,
    DESTRUCCION, OBSOLENCIA, HURTO_ROBO, PERDIDA, SALDOS_INICIALES,
    DETERIORO_ACTIVOS
  ],
  DOCUMENTOS_GOBIERNOS_CORPORATIVOS: [GOBIERNO_CORPORATIVO],
  DOCUMENTOS_GESTION_CONTRATOS: [GESTION_CONTRATOS, SOLICITUD_CONTRATO],
  DOCUMENTOS_PQRSF: [PQRSF, MANTENIMIENTO],
  CATEGORIAS_ATRIBUTOS: [COMERCIAL, CONTROL, DESCRIPCION],
  PARTIDAS_ARANCELARIAS: [SECCION, CAPITULO, PARTIDA, SUBPARTIDA, INCISO],
  TIPOS_CONTENEDORES: [ALMACENAMIENTO, T, RECEPCION],
  TIPOS_PROVEEDORES: [CP, GT],
  TARJETAS_CREDITO: [DINERS_CLUB, VISA, MASTER_CARD, AMERICAN_EXPRESS],
  TIPOS_PRODUCTOS_PRODUCTO_TERMINADO: [PT, CG, KIT],
  CATEGORIAS_PRODUCTOS_TERMINADOS: [UNICO, GENERAL, COMPUESTO],
  TIPOS_CLASIFICACIONES_PROVEEDORES: [
    CLASIFICACION_PROVEEDOR_PRODUCTOS,
    CLASIFICACION_PROVEEDOR_SERVICIOS,
    CLASIFICACION_PROVEEDOR_PRESTACION_SOCIAL,
  ],
  ENCUESTAS_DETALLES: [S, N, F, L, T, M, NM, AF],
  MEDIOS_PAGOS: [MEDIO_PAGO_BANCO, MEDIO_PAGO_CAJA],
  TERCEROS: [CL, PR],
  DOCUMENTOS_CONTABILIDAD: [
    CONTABILIDAD, INGRESOS, DIFERENCIA_CAMBIO,
    DEPRECIACION, ACTIVO_MOVIMIENTO, GASTOS_DIFERIDOS, VALORACION_COBERTURA,
    LIQUIDACION_IMPUESTOS, INTERESES
  ],
  CALCULAR: [P, V],
  DOCUMENTOS_GESTION_PROVEEDORES: [GESTION_PROVEEDORES],

  FIELD_TYPE_TEXT,
  FIELD_TYPE_TEXT_AREA,
  FIELD_TYPE_SELECT,
  FIELD_TYPE_NUMBERM,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_LOGIC,
  FIELD_TYPE_DATE,
  FIELD_TYPE_MULTIPLE,
  FIELD_TYPE_ALPHANUMERIC,
  FIELD_TYPE_PERCENT,
  FIELD_TYPE_AUTOCOMPLETE,
  FIELD_TYPE_TIME,

  INSTRUMENTAL,
  INSTRUMENTAL_ABREVIATURA,
  MOTOR,
  MOTOR_ABREVIATURA,
};
