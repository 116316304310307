import React from 'react';
import PropTypes from 'prop-types';
import { KeyboardTimePicker } from '@material-ui/pickers';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import moment from 'moment';
import { PRODUCE_STRING, TP_NO_SECONDS_FORMAT } from './constants';
import { finalValuesCases } from './functions';

/**
 *
 *  format: permite especificar el formato con que se mostrarán las fechas
 *  por defecto se utiliza HH:mm:ss
 */

function TimePickerBase({
  produces,
  format,
  label,
  field,
  onChangeProps,
  row,
  form: {
    setFieldValue, errors, touched, setFieldTouched, ...form
  },
  field: { name, value },
  ...rest
}) {
  const hasError = touched[name] && Boolean(errors[name]);
  return (
    <KeyboardTimePicker
      fullWidth
      inputVariant="outlined"
      margin="dense"
      autoComplete="off"
      autoOk
      name={name}
      keyboardIcon={<AccessTimeIcon />}
      error={hasError}
      helperText={hasError && errors[name]}
      label={label}
      value={value ? moment(value, 'HH:mm') : value}
      clearLabel="Limpiar"
      okLabel="Aceptar"
      cancelLabel="Cancelar"
      animateYearScrolling={false}
      onChange={(valueToSet) => {
        let finalValue = valueToSet;
        if (valueToSet) {
          finalValue = finalValuesCases(finalValue, produces, format);
        }
        setFieldTouched(name, true);
        setFieldValue(name, finalValue);
        if (onChangeProps) {
          onChangeProps(finalValue, form, row);
        }
      }}
      {...rest}
    />
  );
}

TimePickerBase.defaultProps = {
  label: '',
  produces: PRODUCE_STRING,
  format: TP_NO_SECONDS_FORMAT,
};

TimePickerBase.propTypes = {
  produces: PropTypes.string,
  label: PropTypes.string,
  row: PropTypes.oneOfType([PropTypes.object]),
  field: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.instanceOf(moment),
      PropTypes.string,
    ]),
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  format: PropTypes.string,
  form: PropTypes.shape({
    errors: PropTypes.shape(),
    touched: PropTypes.shape(),
    setFieldTouched: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  onChangeProps: PropTypes.func,
};

export default TimePickerBase;
