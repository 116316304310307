import React from 'react';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Abreviaturas } from 'constantes';
import TextBase from './TextBase';
import NumberBase from './NumberBase';
import SelectBase from './SelectBase';
import SwitchBase from './SwitchBase';
import PercentBase from './PercentBase';
import DatePickerBase from '../Pickers/DatePickerBase';
import TimePickerBase from '../Pickers/TimePickerBase';

const KEY_ENTER = 13;

class TableInput extends React.Component {
  constructor(props) {
    super(props);
    this.initialValues = {};
  }

  componentDidMount() {
    this.getField();
  }

  getField = (subProps) => {
    const {
      tipoCampo, name, label, type, items, disabledCampo, setWidth, ampm, onChangeProps, row,
    } = this.props;
    let field;
    const props = {
      name,
      label,
      key: name,
      style: {
        width: setWidth || 150,
        height: 45,
        display: 'inline-table',
      },
      onKeyDown: event => this.onSubmit(event, subProps),
      items: items || [],
      disabled: disabledCampo,
    };
    const selectProps = {
      name,
      label,
      key: name,
      style: {
        width: setWidth || 220,
        height: 45,
        display: 'inline-table',
      },
      onBlur: event => this.onBlurComposicion(event, subProps),
      items: items || [],
      disabled: disabledCampo,
    };
    const timeProps = {
      name,
      label,
      key: name,
      style: {
        width: setWidth || 150,
        height: 45,
        display: 'inline-table',
      },
      onChangeProps,
      items: items || [],
      disabled: disabledCampo,
      ampm,
      row,
    };
    switch (tipoCampo) {
    case Abreviaturas.FIELD_TYPE_TEXT:
      field = (
        <Field
          component={TextBase}
          type={type}
          {...props}
        />
      );
      break;
    case Abreviaturas.FIELD_TYPE_SELECT:
      field = <Field component={SelectBase} {...selectProps} />;
      break;
    case Abreviaturas.FIELD_TYPE_NUMBERM:
      field = <Field component={NumberBase} {...props} />;
      break;
    case Abreviaturas.FIELD_TYPE_NUMBER:
      field = <Field type="number" component={TextBase} {...props} InputProps={{ inputProps: { min: 0 } }} />;
      break;
    case Abreviaturas.FIELD_TYPE_DATE:
      field = <Field component={DatePickerBase} {...props} />;
      break;
    case Abreviaturas.FIELD_TYPE_LOGIC:
      field = <Field component={SwitchBase} {...props} />;
      break;
    case Abreviaturas.FIELD_TYPE_PERCENT:
      field = <Field component={PercentBase} {...props} />;
      break;
    case Abreviaturas.FIELD_TYPE_TIME:
      field = <Field component={TimePickerBase} {...timeProps} />;
      break;
    default:
      field = <Field component={TextBase} {...props} />;
    }
    return field;
  };

  onSubmit = async (event, formikBag) => {
    event.persist();
    const { updateFunction, row } = this.props;
    const { values, validateForm } = formikBag;
    const errors = await validateForm();
    if (Object.keys(errors).length !== 0) {
      return null;
    }
    const { keyCode } = event;
    if (keyCode === KEY_ENTER) {
      if (updateFunction) updateFunction(values, row);
      event.target.blur();
    }
    return null;
  };

  onBlurComposicion = (event, formikBag) => {
    const { onBlur, row } = this.props;
    const { values } = formikBag;
    onBlur(values, row);
  };

  render() {
    const { value, validationSchema } = this.props;
    return (
      <Formik
        enableReinitialize
        initialValues={value}
        onSubmit={this.onSubmit}
        validationSchema={Yup.object(validationSchema)}
      >
        {subProps => <Form>{this.getField(subProps)}</Form>}
      </Formik>
    );
  }
}

TableInput.defaultProps = {
  type: '',
  tipoCampo: '',
  disabledCampo: false,
  ampm: true,
};

TableInput.propTypes = {
  // Objeto que se va a enviar al initialValues debe tener el nombre del campo y el valor inicial
  value: PropTypes.oneOfType([PropTypes.object]),
  // El label que va a tener el campo
  label: PropTypes.string,
  // El nombre del campo para poder retornar el valor
  name: PropTypes.string,
  // String donde se asigna si el campo es numérico o tipo texto
  type: PropTypes.string,

  items: PropTypes.oneOfType([PropTypes.array]),
  // Funcion que se va a ejecutar para hacer la actualizacion en el back
  updateFunction: PropTypes.func,
  // Funcion que se va a ejecutar para hacer la actualizacion en el back
  onBlur: PropTypes.func,
  // String que determina el tipo de campo usado
  tipoCampo: PropTypes.string,
  // La fila para poder sacar la información
  row: PropTypes.oneOfType([PropTypes.object]),
  // El schema de validacion que se va a utilizar TODO: Esta en proceso de completarse
  validationSchema: PropTypes.oneOfType([PropTypes.object]),
  // Boleano para deshabilitar el campo
  disabledCampo: PropTypes.bool,
  // Tamaño campo
  setWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // Formato campo horas
  ampm: PropTypes.bool,
  // Funcion para guardar los datos del campo de horas
  onChangeProps: PropTypes.func,
};

export default TableInput;
