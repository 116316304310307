import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import FieldLoader from './FieldLoader';

const FormLoader = ({ formFields = {} }) => {
  const keys = Object.keys(formFields);
  return (
    <Grid style={{ padding: 20 }} container spacing={4} direction="row">
      {keys.map(keyName => (
        <FieldLoader
          key={`field-${keyName}`}
          {...(formFields[keyName] || {})}
        />
      ))}
      <Grid item xs={12}>
        <Grid container spacing={4} direction="row" justify="flex-end">
          <FieldLoader wrapper={{ xs: 4, md: 3 }} />
          <FieldLoader wrapper={{ xs: 4, md: 3 }} />
        </Grid>
      </Grid>
    </Grid>
  );
};

FormLoader.propTypes = {
  formFields: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
  ])),
};

export default FormLoader;
