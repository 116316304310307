import React from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';

const BarChart = (props) => {
  const {
    series,
    categories,
    title,
    type,
    extraOptions,
    yAxisFormatter,
  } = props;

  const data = {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        height: 350,
        type: 'line',
        stacked: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [1, 1, 4],
        curve: 'smooth',
      },
      title: {
        text: title,
        align: 'left',
      },
      xaxis: {
        categories,
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#008FFB',
          },
          labels: {
            formatter: (value) => {
              if (yAxisFormatter) {
                return yAxisFormatter(value);
              }
              return value;
            },
            style: {
              colors: '#008FFB',
            },
          },
          tooltip: {
            enabled: true,
          },
        },
      ],
      tooltip: {
        fixed: {
          enabled: true,
          position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60,
        },
      },
      legend: {
        horizontalAlign: 'left',
        offsetX: 40,
      },
      ...extraOptions,
    },
    series,
  };

  return (
    <Chart
      options={data.options}
      series={data.series}
      type={type}
      height="300"
    />

  );
};

BarChart.defaultProps = {
  type: 'line',
};

BarChart.propTypes = {
  /*
   *   La estructura de este prop es un array con un objeto, dentro del objeto debe del
   *   venir un atributo que es data, que es un array con cada uno de los valores
   *   series = [
   *     {
   *       data: [
   *         1,
   *         2,
   *         3,
   *       ],
   *     },
   *   ];
   */
  series: PropTypes.oneOfType([PropTypes.array]).isRequired,
  /*
   * Las categories son los nombres de cada uno de los valores dentro de
   * data, de este modo y con el ejemplo anterior seria
   *
   * categories = [
   *  'P1',
   *  'P2',
   *  'P3',
   * ]
   */
  categories: PropTypes.oneOfType([PropTypes.array]).isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  extraOptions: PropTypes.oneOfType([PropTypes.object]),
  yAxisFormatter: PropTypes.func,
};

export default BarChart;
