import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell } from '@material-ui/core';
import { dotNotationExtractor, isFunction } from 'utils/functions';
import { formatter } from 'constantes/constants';
import { withStyles } from '@material-ui/core/styles';
import Chip, { ChipColors } from '../Chip';
import Check from '../Check';
import MultiChips from '../multiChips';
import MultipleRenderer from './multiple';

const styles = () => ({
  tableRow: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: '#dfdfdf',
    },
  },
  email: {
    textTransform: 'none',
  },
});

function Row({
  columns,
  data = {},
  classes,
  selected,
  markSelected,
}) {
  return (
    <TableRow
      hover
      key={data.id}
      onClick={() => markSelected(data)}
      selected={selected === data.id}
      className={classes.tableRow}
    >
      {columns.map((col, index) => {
        let cellContent = col.component || dotNotationExtractor(data, col.name);
        cellContent = isFunction(cellContent) ? cellContent(data) : cellContent;
        switch (col.type) {
        case 'number': cellContent = formatter.format(cellContent ?? 0);
          break;
        case 'money_decimal': cellContent = `$${formatter.format(Math.trunc(cellContent ?? 0))}`;
          break;
        case 'money': cellContent = `$${formatter.format(cellContent ?? 0)}`;
          break;
        case 'MULTIPLEVAL_TYPE': cellContent = <MultipleRenderer value={cellContent} index={index} />;
          break;
        case 'check': cellContent = <Check activo={cellContent} />;
          break;
        case 'multiChips': cellContent = <MultiChips data={cellContent} />;
          break;
        case 'active': cellContent = (
          <Chip
            activo={cellContent}
            label={cellContent ? 'activo' : 'inactivo'}
            color={cellContent ? ChipColors.GREEN : ChipColors.RED}
          />
        );
          break;
        case 'percent': cellContent = `${Number(cellContent).toFixed(2)}%` ?? 0;
          break;
        case 'percentage': cellContent = `${Number(cellContent).toFixed(0)}%` ?? 0;
          break;
        case 'email': cellContent = (
          <div className={classes.email}>{cellContent ? cellContent.toLowerCase() : ''}</div>
        );
          break;
        default:
          break;
        }
        return (
          <TableCell
            key={`Col${String(index)}`}
            align={col.align ? col.align : 'left'}
            style={{ width: col.width ? col.width : '', color: col.color }}
          >
            {cellContent}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

Row.propTypes = {
  columns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  data: PropTypes.oneOfType([PropTypes.object]),
  classes: PropTypes.oneOfType([PropTypes.object]),
  selected: PropTypes.number,
  markSelected: PropTypes.func,
};

export default withStyles(styles)(Row);
