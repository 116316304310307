const PEDIDOS = 'Modules\\Venta\\Entities\\Pedido';
const PREPEDIDOS = 'Modules\\Rpdental\\Entities\\Prepedido';
const DESPACHOS = 'Modules\\Wms\\Entities\\Despacho';
const HOJA_RUTA = 'Modules\\Wms\\Entities\\HojaRuta';
const PROGSOPORTE = 'Modules\\Rpdental\\Entities\\Programacion';
const PROGPICKING = 'Modules\\Wms\\Entities\\ProgramacionPickingExistencias';
const RECOLECCIONES = 'Modules\\Wms\\Entities\\Recoleccion';
const REPGASTO = 'Modules\\Rpdental\\Entities\\ReporteGasto';
const SIR = 'Modules\\Compra\\Entities\\Sir';
const TOMA_INVENTARIO = 'Modules\\Inventario\\Entities\\TomaInventario';
const INVENTARIO = 'Modules\\Inventario\\Entities\\Inventario';
const ORDEN_COMPRA = 'Modules\\Compra\\Entities\\OrdenCompra';
const TRASLADOS = 'Modules\\Inventario\\Entities\\Traslado';
const EMBARQUE = 'Modules\\Wms\\Entities\\Embarque';
const MANTENIMIENTO = 'Modules\\Inventario\\Entities\\Mantenimiento';
const PREINSPECCION = 'Modules\\Compra\\Entities\\Preinspeccion';
const GASTOS_IMPORTACION = 'Modules\\Compra\\Entities\\GastoImportacion';
const RECEPCION = 'Modules\\Wms\\Entities\\Recepcion';
const SOLICITUD = 'Modules\\Pqrsf\\Entities\\Request';
const FACTURA = 'Modules\\Venta\\Entities\\Factura';
const NOTA_CREDITO = 'Modules\\Tesoreria\\Entities\\NotaCredito';
const NOTA_DEBITO = 'Modules\\Tesoreria\\Entities\\NotaDebito';
const SOLICITUDES_TRASLADOS = 'Modules\\Inventario\\Entities\\SolicitudTraslado';
const SOLICITUDES_LOGISTICAS = 'Modules\\Wms\\Entities\\SolicitudLogistica';

export default {
  PEDIDOS,
  FACTURA,
  NOTA_CREDITO,
  NOTA_DEBITO,
  PREPEDIDOS,
  DESPACHOS,
  HOJA_RUTA,
  PROGSOPORTE,
  PROGPICKING,
  RECOLECCIONES,
  REPGASTO,
  SIR,
  TOMA_INVENTARIO,
  INVENTARIO,
  ORDEN_COMPRA,
  TRASLADOS,
  EMBARQUE,
  MANTENIMIENTO,
  PREINSPECCION,
  GASTOS_IMPORTACION,
  RECEPCION,
  SOLICITUD,
  SOLICITUDES_TRASLADOS,
  SOLICITUDES_LOGISTICAS,
};
