import React from 'react';
import PropTypes from 'prop-types';
import { Chip, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const Tag = ({
  label, variant = 'default', color = 'primary', classes, tooltip,
}) => {
  const content = (
    <Chip
      classes={{
        root: classes.root,
      }}
      label={label}
      color={color}
      size="small"
      variant={variant}
    />
  );
  return tooltip ? (<Tooltip title={tooltip} placement="top">{content}</Tooltip>) : content;
};

const styles = () => ({
  root: {
    margin: '0px 2px',
    minWidth: 35,
  },
});

Tag.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  tooltip: PropTypes.string,
  classes: PropTypes.oneOfType([PropTypes.object]),
};

export default withStyles(styles)(Tag);
