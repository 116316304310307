import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton, Tooltip, makeStyles,
} from '@material-ui/core';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { withPermissionsChecker } from 'wrappers';

const useStyles = makeStyles(theme => ({
  icon: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

/**
 * This component allows to render a button specific to find in modal.
 * @param {*} param0
 */
const FindButton = ({
  onClick, requiredPermissions = [], checkAccess,
}) => {
  const classes = useStyles();

  return (
    <IconButton
      className={classes.icon}
      onClick={onClick}
      disabled={!checkAccess(requiredPermissions)}
    >
      <Tooltip title="Filtros avanzados">
        <FindInPageIcon className={classes.icon} fontSize="large" />
      </Tooltip>
    </IconButton>
  );
};

FindButton.defaultProps = {
  requiredPermissions: [],
};

FindButton.propTypes = {
  onClick: PropTypes.func,
  checkAccess: PropTypes.func,
  requiredPermissions: PropTypes.arrayOf(PropTypes.string),
};

export default withPermissionsChecker(FindButton);
