import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TableHead, TableRow, TableCell, TableSortLabel, Checkbox,
} from '@material-ui/core';
import { withApi, withNotification } from 'wrappers';
import { HelpCircle } from 'react-feather';

const styles = {
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
};

function Header({
  columns, orderBy, order, setOrderBy, data = {}, onRowsSelect, onCheckedAll,
  checkedAll, expandable, disableCheck, selectAllData, selectAllUrl, doGet,
}) {
  const [isCheck, setIsCheck] = useState(false);

  const handleSelectAllClick = async (event) => {
    if (event.target.checked) {
      if (selectAllUrl) {
        const resp = await doGet({
          url: selectAllUrl,
          data: selectAllData,
        });
        onRowsSelect(resp);
        onCheckedAll(!checkedAll);
        setIsCheck(!isCheck);
        return;
      }
      onRowsSelect(data);
      onCheckedAll(!checkedAll);
      setIsCheck(!isCheck);
      return;
    }
    setIsCheck(!isCheck);
    onCheckedAll(!checkedAll);
    onRowsSelect([]);
  };

  return (
    <TableHead>
      <TableRow>
        {onRowsSelect
          && (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={false}
                checked={isCheck}
                onChange={handleSelectAllClick}
                disabled={disableCheck}
              />
            </TableCell>
          )}
        {expandable
          && (
            <TableCell padding="checkbox" />
          )}
        {columns.map((row, index) => (
          <TableCell
            key={`${String(index)}`}
            align="left"
            padding="default"
            sortDirection={orderBy === row.name ? order : false}
            style={{ width: row.width ? row.width : '' }}
          >
            <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
              {row.actions && Object.keys(row.actions).length > 0 && (
                <HelpCircle
                  style={{ width: 30, marginRight: 5 }}
                  onMouseEnter={e => row.actions.openPopover(e, row.actions.message)}
                  onMouseLeave={row.actions.closePopover}
                  onMouseDown={e => row.actions.openPopover(e, row.actions.message)}
                />
              )}
              <TableSortLabel
                active={orderBy === row.name}
                direction={orderBy === row.name ? order : 'asc'}
                onClick={row.filter !== false ? () => setOrderBy(row.name) : null}
                style={{ marginLeft: 30 }}
              >
                {row.label}
                {orderBy === row.name ? (
                  <span style={styles.visuallyHidden}>
                    {order === 'desc' ? 'ordenar descendientemente' : 'ordenar ascendentemente'}
                  </span>
                ) : null}
              </TableSortLabel>
            </div>

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

Header.propTypes = {
  columns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  orderBy: PropTypes.string,
  order: PropTypes.string,
  setOrderBy: PropTypes.func,
  // extraColumn: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.array]),
  onRowsSelect: PropTypes.func,
  onCheckedAll: PropTypes.func,
  checkedAll: PropTypes.bool,
  expandable: PropTypes.func,
  disableCheck: PropTypes.func,
  selectAllUrl: PropTypes.string,
  selectAllData: PropTypes.oneOfType([PropTypes.object]),
  doGet: PropTypes.func,
};

export default withApi(withNotification(Header));
