import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton, makeStyles, Tooltip,
} from '@material-ui/core';
import {
  Eye as EyeIcon,
} from 'react-feather';
import { DEFAULT_MUI_DATATABLE_BUTTON_SIZE } from 'constantes/constants';
import { withPermissionsChecker } from 'wrappers';

const useStyles = makeStyles(theme => ({
  icon: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

/**
 * Componente que muestra el boton VER
 * @param {*} param0
 */
const ViewButton = ({
  onClick, checkAccess, requiredPermissions, disabled,
}) => {
  const classes = useStyles();
  return (
    <Tooltip title="Ver información">
      <IconButton
        size={DEFAULT_MUI_DATATABLE_BUTTON_SIZE}
        className={classes.icon}
        onClick={onClick}
        disabled={disabled || !checkAccess(requiredPermissions)}
      >
        <EyeIcon />
      </IconButton>
    </Tooltip>
  );
};

ViewButton.propTypes = {
  onClick: PropTypes.func,
  checkAccess: PropTypes.func,
  disabled: PropTypes.bool,
  requiredPermissions: PropTypes.arrayOf(PropTypes.string),
};

export default withPermissionsChecker(ViewButton);
