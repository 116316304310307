const APROBADO = 'APROBADO';
const PENDIENTE = 'PENDIENTE';
const CERRADO = 'CERRADO';
const CANCELADO = 'CANCELADO';
const ANULADO = 'ANULADO';
const RECHAZADO = 'RECHAZADO';
const TERMINADO = 'TERMINADO';
const ESTADOS = [
  APROBADO,
  PENDIENTE,
  CERRADO,
  CANCELADO,
  ANULADO,
  RECHAZADO,
  TERMINADO,
];

export default {
  APROBADO,
  PENDIENTE,
  CERRADO,
  CANCELADO,
  ANULADO,
  RECHAZADO,
  TERMINADO,
  labelValue: () => (ESTADOS.map(estado => ({ label: estado, value: estado }))),
  enableActions: estado => (
    estado !== ANULADO
    && estado !== CANCELADO
    && estado !== RECHAZADO
    && estado !== CERRADO
    && estado !== APROBADO
    && estado !== TERMINADO),
};
