import React, { useState /* useEffect, useContext */ } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
// import io from 'socket.io-client';
// import AuthService from 'services/AuthService';
// import { Loader } from 'components';
// import { SocketContext } from 'context/SocketContext';
import { withApi, withNotification } from 'wrappers';
// import endPoints from 'endPoints/endPoints';
import clsx from 'clsx';
import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'visible',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'visible',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 0,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'visible',
  },
  contentContainerOpen: {
    marginLeft: 300,
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

function DashboardLayout({ children /* doGet, genericException */ }) {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(true);
  /*
  const [user, setuser] = useState({});
  const [loading, setLoading] = useState(false);
  const {
    setSocket: setSocketApplication,
    authWSUrl,
    WSUrl,
    socket,
  } = useContext(SocketContext);
  useEffect(() =>
  {
    const url = endPoints.general.getWebSocketInput;
    if (user.name === undefined || socket === null)
    {
      setuser(AuthService.getSessionInfo());
      const checkResponse = async (something, namespace) =>
      {
        const info = await something.json();
        if (info.message === 'Authenticated')
        {
          const newSocket = io(`${WSUrl}${namespace}`);
          newSocket.emit('set-session', { user: info.username });
          if (user.role)
          {
            user.role.map((role) => newSocket.emit('join', { room: role }));
          }
          setSocketApplication(newSocket);
          setLoading(true);
        }
      };

      const authWebSocket = async (data) =>
      {
        try
        {
          const resp = await doGet({ url });
          const {
            username,
            front,
            password,
          } = resp;
          const wsData = {
            userapp: username,
            apppass: password,
            appnamespace: front,
          };
          if (data)
          {
            wsData.username = data;
            const options = {
              mode: 'cors',
              method: 'POST',
              body: JSON.stringify(wsData),
              cache: 'default',
              credentials: 'same-origin',
              headers: {
                Accept: 'application/json, text/javascript, **; q=0.01',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
              },
            };
            fetch(authWSUrl, options).then((res) => checkResponse(res, wsData.appnamespace));
            setTimeout(() =>
            {
              setLoading(true);
            }, 6000);
          }
        }
        catch (e)
        {
          genericException(e);
        }
      };
      authWebSocket(user.user_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user.name,
    user.user_name,
    WSUrl,
    setSocketApplication,
    authWSUrl,
    socket,
  ]);
  */
  const handleDrawerToggle = () => {
    setMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={handleDrawerToggle}
        isMobileNavOpen={isMobileNavOpen}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div
        className={classes.wrapper}
      >
        <div className={clsx(classes.contentContainer, {
          [classes.contentContainerOpen]: isMobileNavOpen,
        })}
        >
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.element,
  // doGet: PropTypes.func,
  // genericException: PropTypes.func,
};

export default withApi(withNotification(DashboardLayout));
