import React from 'react';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table as TableM,
  Box,
  Hidden,
} from '@material-ui/core';
import { withApi, withNotification } from 'wrappers';
import { isFunction, genericExceptionHandler } from 'utils/functions';
import { DEFAULT_MUI_DATATABLE_SIZE } from 'constantes/constants';
import Head from './Head';
import Body from './Body';
import BodySelect from './select/Body';
import BodyExpandable from './expandable/Body';
import Pagination from './Pagination';
import ToolBar from './ToolBar';
import SmallView from './SmallView';

/* For refresh the table with Serverside, you will can do this steps
* ------ En el componente padre -------
* -> Crear la referencia
*    this.child = React.createRef();
* -> Enviar referencia por props
*    <TableGeneral forwardedRef={this.child} />
* -> Para ejecutar el metodo refrescar
*    this.child.current.refresh();
*/
class Table extends React.Component {
  constructor(props) {
    super(props);
    const {
      orderByColumn, orderByType, forwardedRef,
    } = props;
    this.state = {
      isLoading: true,
      page: 0,
      rowsPerPage: 100,
      order: orderByColumn && !orderByType ? 'asc' : orderByType,
      orderBy: orderByColumn,
      total: 0,
      data: [],
      filters: {},
      genericSearch: '',
      checkedAll: false,
      sendEmailExcelPermissions: ['app:general:general:sendEmailExcel'],
    };
    if (forwardedRef) {
      forwardedRef.current = {
        refresh: this.serverSide,
        props,
        state: this.state,
      };
    }
  }

  componentDidMount() {
    const { serverSideUrl } = this.props;
    if (serverSideUrl) {
      this.serverSide();
    } else {
      this.processData();
      this.setState({ isLoading: false });
    }
  }

  // Funcion para evaluar cambios en los datos
  componentDidUpdate(prevProps) {
    const { data, serverSideUrl } = this.props;
    if (prevProps.data !== data && !serverSideUrl) {
      this.processData();
    }
  }

  setGenericSearch = (valor) => {
    if (this.props.serverSideUrl) {
      this.setState({ genericSearch: valor, isLoading: true }, this.serverSide);
    } else {
      const data = this.filterData({ search: valor });
      this.setState({ data });
    }
  }

  // Funcion para cambiar los filtros
  setFilter = (filters) => {
    if (this.props.serverSideUrl) {
      this.setState({ filters, isLoading: true }, this.serverSide);
    } else {
      const data = this.filterData(filters);
      this.setState({ data });
    }
  };

  setFilterSearch = (search) => {
    const data = this.filterDataSearch(search);
    this.setState({ data });
  };

  // Funcion para cambiar de pagina
  setPage = (page) => {
    if (this.props.serverSideUrl) {
      this.setState({ page, isLoading: true }, this.serverSide);
    } else {
      this.setState({ page });
    }
  };

  // Funcion para cambiar los registros por pagina
  setRowsPerPage = (rowsPerPage) => {
    if (this.props.serverSideUrl) {
      this.setState({ rowsPerPage, page: 0, isLoading: true }, this.serverSide);
    } else {
      this.setState({ rowsPerPage, page: 0 });
    }
  };

  // Funcion para forma de ordenar, ascendente o descendente, segun seleccionada
  setOrderBy = (orderBy) => {
    const isAsc = orderBy === this.state.orderBy && this.state.order === 'asc';
    const order = isAsc ? 'desc' : 'asc';

    if (this.props.serverSideUrl) {
      this.setState({ order, orderBy, isLoading: true }, this.serverSide);
    } else {
      const data = this.orderData(order, orderBy);
      this.setState({ order, orderBy, data });
    }
  };

  // Funcion para filtrar los elementos segun parametros de busqueda sin serverside
  filterData = (filters) => {
    const { data } = this.props;
    const filter = Object.values(filters);
    return (
      data.filter(obj => (
        Object.keys(obj).some(key => (
          obj[key] === null
            ? null
            : obj[key].toString().toLowerCase().includes(filter[0].toLowerCase())
        ))
      ))
    );
  };

  // Funcion para filtrar la data de a tabla mediante el boton de search
  filterDataSearch = (query) => {
    const { data } = this.state;
    const { columns } = this.props;
    let searchArray = [];
    if (query !== '') {
      for (let i = 0; i < data.length; i++) {
        let newArray = [];
        newArray = data.find(item => item[columns[i].name] === query);
        if (newArray) {
          searchArray = [newArray];
          break;
        }
      }
    } else {
      this.setFilter([]);
    }
    return searchArray;
  };

  // Funcion que me devuelve registros organizados segun parametros recibidos
  orderData = (order, orderBy) => {
    const { data } = this.props;
    const ascending = order === 'asc' ? 1 : -1;

    return data.sort((a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1 * ascending;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1 * ascending;
      }
      return 0;
    });
  };

  // Función que filtra solo datos segun pagina y registros por pagina
  pageData = (data) => {
    if (this.props.serverSideUrl) {
      return data;
    }

    const { page, rowsPerPage } = this.state;
    const initialRow = page * rowsPerPage;
    return data.slice(initialRow, initialRow + rowsPerPage);
  };

  // Funcion encargada de cargar los datos solicitados segun parametros
  serverSide = async () => {
    const {
      serverSideUrl, serverSideData, doGet, onDataLoaded, advanceFilter,
    } = this.props;
    const { list: listData } = serverSideData || {};
    const {
      page, rowsPerPage, order, orderBy, filters, genericSearch,
    } = this.state;
    const sorts = {};
    if (orderBy && orderBy !== '') {
      sorts[orderBy] = order;
    }

    const params = {
      url: serverSideUrl,
      data: {
        perPage: rowsPerPage,
        page: page + 1,
        sorts: JSON.stringify(sorts),
        filters: JSON.stringify(filters),
        genericSearch,
        ...listData,
        ...serverSideData,
      },
    };

    try {
      this.setState({ isLoading: true });
      const resp = await doGet(params);
      const { data, total } = resp;
      this.setState({
        data,
        total: total || 0,
        isLoading: false,
      });
      if (isFunction(onDataLoaded)) {
        onDataLoaded(resp);
      }

      if (advanceFilter && data.length <= 0) {
        this.props.appWarning('No se encontraron datos en la busqueda');
      }
    } catch (error) {
      genericExceptionHandler(error, this);
    }
  };

  // Funcion que llama las correspondientes funciones segun el caso
  processData = () => {
    const { order, orderBy } = this.state;
    const orderData = this.orderData(order, orderBy);
    this.setState({ data: orderData, total: orderData.length });
  };

  onCheckedAll = (data) => {
    this.setState(prevState => ({
      ...prevState,
      checkedAll: data,
    }));
  };

  render() {
    const {
      columns, title, onCreate, createPermissions, onRowsSelect, expandable, ExtraToolBar,
      date, onDownloadExcel, onDownloadPdf, disableCreateButton, filtersTable, maxWidthFilter,
      disableExcelButton, fechaCirugia, disableCheck, selectAllData, selectAllUrl, titleHelpMessage,
      onSendExcel, genericSearch, updatePermissions,
    } = this.props;
    const {
      page, rowsPerPage, order, orderBy, data, total, filters, isLoading, checkedAll, sendEmailExcelPermissions,
    } = this.state;
    return (
      <Box>
        <Hidden smDown>
          <ToolBar
            title={title}
            genericSearch={genericSearch}
            titleHelpMessage={titleHelpMessage}
            columns={columns}
            filters={filters}
            filtersTable={filtersTable}
            setFilter={this.setFilter}
            setFilterSearch={this.setFilterSearch}
            setGenericSearch={this.setGenericSearch}
            onCreate={onCreate}
            onDownloadPdf={onDownloadPdf}
            onDownloadExcel={onDownloadExcel}
            onSendExcel={onSendExcel}
            createPermissions={createPermissions}
            updatePermissions={updatePermissions}
            sendEmailExcelPermissions={sendEmailExcelPermissions}
            ExtraToolBar={ExtraToolBar}
            dates={date}
            fechaCirugia={fechaCirugia}
            disableCreateButton={disableCreateButton || false}
            disableExcelButton={disableExcelButton || false}
            maxWidthFilter={maxWidthFilter}
          />
          <TableContainer style={{ maxHeight: 800 }}>
            <TableM size={DEFAULT_MUI_DATATABLE_SIZE} stickyHeader>
              <Head
                columns={columns}
                data={this.pageData(data)}
                order={order}
                orderBy={orderBy}
                setOrderBy={this.setOrderBy}
                extraColumn={Boolean(expandable || onRowsSelect)}
                expandable={expandable}
                onRowsSelect={onRowsSelect}
                onCheckedAll={this.onCheckedAll}
                checkedAll={checkedAll}
                disableCheck={disableCheck === false || disableCheck === true}
                selectAllUrl={selectAllUrl}
                selectAllData={selectAllData}
              />
              {Boolean(onRowsSelect) && (
                <BodySelect
                  columns={columns}
                  data={this.pageData(data)}
                  isLoading={isLoading}
                  checkedAll={checkedAll}
                  onRowsSelect={onRowsSelect}
                  disableCheck={disableCheck}
                />
              )}
              {Boolean(expandable) && (
                <BodyExpandable
                  columns={columns}
                  data={this.pageData(data)}
                  isLoading={isLoading}
                  expandable={expandable}
                />
              )}
              {!expandable && !onRowsSelect && (
                <Body
                  columns={columns}
                  data={this.pageData(data)}
                  isLoading={isLoading}
                />
              )}
            </TableM>
          </TableContainer>
          <Pagination
            page={page}
            rowsPerPage={rowsPerPage}
            total={total}
            setPage={this.setPage}
            setRowsPerPage={this.setRowsPerPage}
          />
        </Hidden>
        <Hidden smUp>
          <SmallView
            columns={columns}
            data={this.pageData(data)}
            isLoading={isLoading}
            onCreate={onCreate}
            onSendExcel={onSendExcel}
            onDownloadPdf={onDownloadPdf}
            onDownloadExcel={onDownloadExcel}
            createPermissions={createPermissions}
            disableCreateButton={disableCreateButton || false}
            setGenericSearch={this.setGenericSearch}
            checkedAll={checkedAll}
            onRowsSelect={onRowsSelect}
            disableCheck={disableCheck}
          />
        </Hidden>
      </Box>
    );
  }
}

Table.defaultProps = {
  data: [],
  date: false,
  filtersTable: true,
  advanceFilter: false,
  orderByType: '',
  orderByColumn: '',
  genericSearch: true,
};

Table.propTypes = {
  // Array de columnas, cada columna puede tener varias llaves
  // label: Es el texto que se quiere mostrar en el Toobar de la tabla
  // name: Texto que identificara datos a poner en cada columna y registros a buscar
  // align: alineacion de las columnas left, rigth, center, por defecto es left
  // component: React component o función, en caso de ser funcion pasa como parametro el registro
  // filter: booleano para filtrar registros, por defecto true
  filtersTable: PropTypes.bool,
  // width: tamaño de la columna
  // type: tipo de columna, acepta 'number', MULTIPLEVAL_TYPE, check, multiChips, chip
  columns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  // el tipo de ordenamiento que se aplicará inicialmente. por defecto asc cuando orderByColumn
  orderByType: PropTypes.oneOf(['asc', 'desc', '']),
  // la columna por la que se ordenará inicialmente la tabla
  orderByColumn: PropTypes.string,
  // Array de objetos para mostrar en en la tabla
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  // Titulo de la tabla
  title: PropTypes.string,
  // Mensaje de recomendaciones
  titleHelpMessage: PropTypes.string,
  // Url a la cual se buscaran los datos
  serverSideUrl: PropTypes.string,
  // Data para la url en caso de necesitar enviar algun parametro extra
  serverSideData: PropTypes.oneOfType([PropTypes.object]),
  // Funcion para crear nuevos registros
  onCreate: PropTypes.func,
  // Funcion para descargar un excel
  onDownloadExcel: PropTypes.func,
  // Funcion para enviar un excel
  onSendExcel: PropTypes.func,
  // Funcion para descargar un pdf
  onDownloadPdf: PropTypes.func,
  // Funcion para retornar la data que llega del servidor
  onDataLoaded: PropTypes.func,
  // Funcion para expandir la columna, pasa como parametro el registro
  expandable: PropTypes.func,
  // Funcion que devuelve las columnas seleccionadas
  onRowsSelect: PropTypes.func,
  // Elemento para crear nuevos botones que se deseen adicionar en la parte superior de la tabla.
  ExtraToolBar: PropTypes.node,
  // Permisos para el boton de crear
  createPermissions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  // Permisos para acciones de table
  updatePermissions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  doGet: PropTypes.func,
  // appError: PropTypes.func.isRequired,
  appWarning: PropTypes.func.isRequired,
  forwardedRef: PropTypes.oneOfType([PropTypes.object]),
  // Filtrar por las fechas de cirugía
  fechaCirugia: PropTypes.bool,
  // Muestra las fechas en los filtros
  date: PropTypes.bool,
  // Prop para habilitar o deshabilitar el boton de create.
  disableCreateButton: PropTypes.bool,
  // Prop para habilitar o deshabilitar el boton de descarga de excel.
  disableExcelButton: PropTypes.bool,
  // Se usa para mostrar un mensaje en caso de que venga como true para filros avanzados
  advanceFilter: PropTypes.bool,
  // Setea un valor para agrandar o minimizar el ancho de la modal de filtro
  maxWidthFilter: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  disableCheck: PropTypes.bool,
  selectAllUrl: PropTypes.string,
  selectAllData: PropTypes.oneOfType([PropTypes.object]),
  genericSearch: PropTypes.bool,
};

export default withApi(withNotification(Table));
