import React from 'react';
import { Redirect } from 'react-router-dom';
import loadable from '@loadable/component';
import MainLayout from 'layouts/MainLayout';
import AuthGuard from 'components/Gaurds/AuthGuard';
import GuestGuard from 'components/Gaurds/GuestGuard';
import LoadingScreen from 'components/LoadingScreen';

const fallBack = { fallback: <LoadingScreen /> };

export const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/login" />,
  },
  {
    exact: true,
    path: '/404',
    component: loadable(() => import('views/pages/Error404View'), fallBack),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: loadable(() => import('views/auth/LoginView'), fallBack),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/resetPassword/:token',
    component: loadable(() => import('views/auth/ResetPasswordView'), fallBack),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: loadable(() => import('views/auth/RegisterView'), fallBack),
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />,
      },
      {
        exact: true,
        path: '/app/enconstruccion',
        component: loadable(() => import('views/pages/EnConstruccion'), fallBack),
      },
      {
        exact: true,
        path: '/app/pruebaws',
        component: loadable(() => import('views/pruebaws'), fallBack),
      },
      {
        exact: true,
        path: '/app/account',
        component: loadable(() => import('views/pages/AccountView'), fallBack),
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: loadable(() => import('views/reports/DashboardView'), fallBack),
      },
      {
        path: '/app/rpdental',
        routes: [
          // {
          //   exact: true,
          //   path: '/app/rpdental/programacion',
          //   component: loadable(() =>
          // import('views/crm/procesos/programacion/List'),fallBack), },
          //   {
          //   component: loadable(() =>
          //   import('views/crm/procesos/programacion/List'), fallBack), }, {
          //   exact: true,
          //   path: '/app/rpdental/programacion/listtable',
          //   component: loadable(() => import('views/crm/procesos
          // /programacion/ListTable'), fallBack),
          //   permission: ['rpdental:asignacionessoportesquirurgicos:list'],
          // },
          // {
          //   exact: true,
          //   path: '/app/rpdental/programacion/form/:idOrdenProduccion/new/:idProceso',
          //   component: loadable(() => import('views/crm/procesos/programacion/New'), fallBack),
          // },
          // {
          //   exact: true,
          //   path: '/app/rpdental/programacion/asignacionsoportequirurgico',
          // component: loadable(() => import('views/crm/procesos/programacion
          // /procesoCreacion/ListOrdenProduccionCirugia'), fallBack),
          // },
          // {
          //   exact: true,
          //   path: '/app/rpdental/programacion/form/edit/:id',
          //   component: loadable(() => import('views/crm/procesos/programacion/Edit'), fallBack),
          // },
          {
            exact: true,
            path: '/app/rpdental/reportegastos',
            component: loadable(() => import('views/rpDental/procesos/reporteGastos/List'), fallBack),
            permission: ['rpdental:reportesgastos:list'],
          },
          {
            exact: true,
            path: '/app/rpdental/reportegastos/form/:id/:ordenproduccionid/:idProceso/:bodegaId/:reporteGastoId',
            component: loadable(() => import('views/rpDental/procesos/reporteGastos/Edit'), fallBack),
          },
          {
            path: '/app/rpdental/informes',
            routes: [
              {
                exact: true,
                path: '/app/rpdental/informes/programacionsoporte',
                component: loadable(() => import('views/rpDental/informes/programacion/List')),
                permission: ['rpdental:informes:informesasignacionessoportesquirurgicos:list'],
              },
              {
                exact: true,
                path: '/app/rpdental/informes/reportegastos',
                component: loadable(() => import('views/rpDental/informes/reporteGastos/List')),
                permission: ['rpdental:informes:informesreportesgastos:list'],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        path: '/app/crm',
        routes: [
          {
            exact: true,
            path: '/app/crm/cotizaciones',
            component: loadable(() => import('views/crm/procesos/cotizaciones/List'), fallBack),
            permission: ['app:general:crm:cotizaciones:view'],
          },
          {
            exact: true,
            path: '/app/crm/cotizaciones/new',
            component: loadable(() => import('views/crm/procesos/cotizaciones/New'), fallBack),
            permission: ['app:general:crm:cotizaciones:new'],
          },
          {
            exact: true,
            path: '/app/crm/cotizaciones/edit/:id',
            component: loadable(() => import('views/crm/procesos/cotizaciones/Edit'), fallBack),
            permission: ['app:general:crm:cotizaciones:edit'],
          },
          {
            exact: true,
            path: '/app/crm/consultas/cotizaciones',
            component: loadable(() => import('views/crm/consultas/cotizaciones/List'), fallBack),
            permission: ['app:general:crm:consultas:cotizaciones:view'],
          },
          /*
          {
            exact: true,
            path: '/app/crm/cotizacionescomercioexterior',
            component: loadable(
              () => import('views/crm/procesos/cotizacionesComercioExterior/List'), fallBack),
            permission: ['crm:cotizacionescomercioexterior:list'],
          },
          {
            exact: true,
            path: '/app/crm/cotizacionescomercioexterior/new',
            component: loadable(
              () => import('views/crm/procesos/cotizacionesComercioExterior/New'), fallBack),
          },
          {
            exact: true,
            path: '/app/crm/cotizacionescomercioexterior/edit/:id',
            component: loadable(
              () => import('views/crm/procesos/cotizacionesComercioExterior/Edit'), fallBack),
          },
          */
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        path: '/app/ventas',
        routes: [
          {
            exact: true,
            path: '/app/venta/prepedido',
            component: loadable(() => import('views/venta/procesos/prePedido/List'), fallBack),
            permission: ['venta:prepedidos:list'],
          },
          {
            exact: true,
            path: '/app/venta/prepedido/edit/:id',
            component: loadable(() => import('views/venta/procesos/prePedido/Edit'), fallBack),
          },
          {
            exact: true,
            path: '/app/venta/prepedido/new',
            component: loadable(() => import('views/venta/procesos/prePedido/New'), fallBack),
          },
          // {
          //   exact: true,
          //   path: '/app/venta/pedido',
          //   component: loadable(() => import('views/venta/procesos/pedido/List'), fallBack),
          //   permission: ['venta:pedidos:list'],
          // },
          // {
          //   exact: true,
          //   path: '/app/venta/pedido/new',
          //   component: loadable(() => import('views/venta/procesos/pedido/New'), fallBack),
          // },
          // {
          //   exact: true,
          //   path: '/app/venta/pedido/edit/:id',
          //   component: loadable(() => import('views/venta/procesos/pedido/Edit'), fallBack),
          // },
          {
            exact: true,
            path: '/app/ventas/pedidos',
            component: loadable(() => import('views/venta/procesos/ventaDirecta/List'), fallBack),
            permission: ['app:general:ventas:pedidos:view'],
          },
          {
            exact: true,
            path: '/app/ventas/pedidos/new',
            component: loadable(() => import('views/venta/procesos/ventaDirecta/New'), fallBack),
            permission: ['app:general:ventas:pedidos:new'],
          },
          {
            exact: true,
            path: '/app/ventas/pedidos/:param/:id',
            component: loadable(() => import('views/venta/procesos/ventaDirecta/Edit'), fallBack),
            permission: ['app:general:ventas:pedidos:edit'],
          },
          {
            exact: true,
            path: '/app/ventas/facturas',
            component: loadable(() => import('views/venta/procesos/factura/List'), fallBack),
            permission: ['app:general:ventas:facturas:view'],
          },
          {
            exact: true,
            path: '/app/ventas/facturas/new',
            component: loadable(() => import('views/venta/procesos/factura/New'), fallBack),
            permission: ['app:general:ventas:facturas:new'],
          },
          {
            exact: true,
            path: '/app/ventas/facturas/:param/:id',
            component: loadable(() => import('views/venta/procesos/factura/Edit'), fallBack),
            permission: ['app:general:ventas:facturas:edit'],
          },
          {
            exact: true,
            path: '/app/ventas/pendientesporfacturar',
            component: loadable(() => import('views/venta/procesos/pendienteFacturar/List'), fallBack),
            permission: ['app:general:ventas:pendientesporfacturar:view'],
          },
          {
            exact: true,
            path: '/app/ventas/notascredito',
            component: loadable(() => import('views/tesoreria/procesos/notasCredito/List'), fallBack),
            permission: ['app:general:ventas:notascredito:view'],
          },
          {
            exact: true,
            path: '/app/ventas/notascredito/new',
            component: loadable(() => import('views/tesoreria/procesos/notasCredito/New'), fallBack),
            permission: ['app:general:ventas:notascredito:new'],
          },
          {
            exact: true,
            path: '/app/ventas/notascredito/:param/:id',
            component: loadable(() => import('views/tesoreria/procesos/notasCredito/Edit'), fallBack),
            permission: ['app:general:ventas:notascredito:edit'],
          },
          {
            exact: true,
            path: '/app/ventas/notasdebito',
            component: loadable(() => import('views/tesoreria/procesos/notasDebito/List'), fallBack),
            permission: ['app:general:ventas:notasdebito:view'],
          },
          {
            exact: true,
            path: '/app/ventas/notasdebito/new',
            component: loadable(() => import('views/tesoreria/procesos/notasDebito/New'), fallBack),
            permission: ['app:general:ventas:notasdebito:new'],
          },
          {
            exact: true,
            path: '/app/ventas/notasdebito/:param/:id',
            component: loadable(() => import('views/tesoreria/procesos/notasDebito/Edit'), fallBack),
            permission: ['app:general:ventas:notasdebito:edit'],
          },
          {
            path: '/app/ventas/consultas',
            routes: [
              {
                exact: true,
                path: '/app/ventas/consultas/pedidos',
                component: loadable(() => import('views/venta/consultas/ventas/List'), fallBack),
                permission: ['app:general:ventas:consultas:pedidos:view'],
              },
              {
                exact: true,
                path: '/app/ventas/consultas/ventas',
                component: loadable(() => import('views/venta/consultas/factura/List'), fallBack),
                permission: ['app:general:ventas:consultas:ventas:view'],
              },
              {
                exact: true,
                path: '/app/ventas/consultas/notascredito',
                component: loadable(() => import('views/venta/consultas/notasCredito/List'), fallBack),
                permission: ['app:general:ventas:consultas:ventas:view'],
              },
              {
                exact: true,
                path: '/app/ventas/consultas/margen',
                component: loadable(() => import('views/venta/consultas/factura/productosFacturados/List'), fallBack),
                permission: ['app:general:ventas:consultas:margen:view'],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        path: '/app/compras',
        routes: [
          {
            exact: true,
            path: '/app/compras/sir',
            component: loadable(() => import('views/scm/compra/procesos/sirs/List'), fallBack),
            permission: ['app:general:compras:sir:view'],
          },
          {
            exact: true,
            path: '/app/compras/sir/new',
            component: loadable(() => import('views/scm/compra/procesos/sirs/New'), fallBack),
            permission: ['app:general:compras:sir:new'],
          },
          {
            exact: true,
            path: '/app/compras/sir/:param/:id',
            component: loadable(() => import('views/scm/compra/procesos/sirs/Edit'), fallBack),
            permission: ['app:general:compras:sir:edit'],
          },
          {
            exact: true,
            path: '/app/compras/ordenescompras',
            component: loadable(() => import('views/scm/compra/procesos/ordenesCompras'), fallBack),
            permission: ['app:general:compras:ordenescompras:view'],
          },
          {
            exact: true,
            path: '/app/compras/ordenescompras/new/:documentoId',
            component: loadable(() => import('views/scm/compra/procesos/ordenesCompras/New'), fallBack),
            permission: ['app:general:compras:ordenescompras:new']
          },
          {
            exact: true,
            path: '/app/compras/ordenescompras/:param/:id',
            component: loadable(() => import('views/scm/compra/procesos/ordenesCompras/Edit'), fallBack),
            permission: ['app:general:compras:ordenescompras:edit']
          },
          {
            path: '/app/compras/consultas',
            routes: [
              {
                exact: true,
                path: '/app/compras/consultas/ordenescompras',
                component: loadable(() => import('views/scm/compra/informes/ordenCompra/List')),
                permission: ['app:general:compras:consultas:ordenescompras:view'],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        path: '/app/distribucion',
        routes: [
          {
            exact: true,
            path: '/app/distribucion/programacionestomasinventario',
            component: loadable(() => import(
              'views/scm/inventario/generales/procesos/tomaDeInventario/List'
            ), fallBack),
            permission: ['app:general:distribucion:programacionestomasinventario:view'],
          },
          {
            exact: true,
            path: '/app/distribucion/programacionestomasinventario/new',
            component: loadable(() => import(
              'views/scm/inventario/generales/procesos/tomaDeInventario/New'
            ), fallBack),
            permission: ['app:general:distribucion:programacionestomasinventario:new']
          },
          {
            exact: true,
            path: '/app/distribucion/programacionestomasinventario/:param/:id',
            component: loadable(() => import(
              'views/scm/inventario/generales/procesos/tomaDeInventario/Edit'
            ), fallBack),
            permission: ['app:general:distribucion:programacionestomasinventario:edit']
          },
          {
            exact: true,
            path: '/app/distribucion/ejecucionestomasinventario',
            component: loadable(() => import(
              'views/scm/inventario/generales/procesos/ejecucionTomaInventario/List'
            ), fallBack),
            permission: ['app:general:distribucion:ejecucionestomasinventario:view'],
          },
          {
            exact: true,
            path: '/app/distribucion/ejecucionestomasinventario/:id',
            component: loadable(() => import(
              'views/scm/inventario/generales/procesos/ejecucionTomaInventario/DetalleToma'
            ), fallBack),
            permission: ['app:general:distribucion:ejecucionestomasinventario:edit']
          },
          {
            exact: true,
            path: '/app/distribucion/ajustesinventario',
            component: loadable(() => import(
              'views/scm/inventario/generales/procesos/ajusteDeInventario/List'
            ), fallBack),
            permission: ['app:general:distribucion:ajustesinventario:view'],
          },
          {
            exact: true,
            path: '/app/distribucion/ajustesinventario/detalle/:id',
            component: loadable(() => import(
              'views/scm/inventario/generales/procesos/ajusteDeInventario/Detalle'
            ), fallBack),
            permission: ['app:general:distribucion:ajustesinventario:edit']
          },
          {
            path: '/app/distribucion/inventariogeneral',
            routes: [
              {
                exact: true,
                path: '/app/distribucion/inventariogeneral/traslados',
                component: loadable(() => import(
                  'views/scm/inventario/inventarioGeneral/procesos/traslados'
                ), fallBack),
                permission: ['app:general:distribucion:inventariogeneral:traslados:view'],
              },
              {
                exact: true,
                path: '/app/distribucion/inventariogeneral/traslados/new',
                component: loadable(() => import(
                  'views/scm/inventario/inventarioGeneral/procesos/traslados/New'
                ), fallBack),
                permission: ['app:general:distribucion:inventariogeneral:traslados:new'],
              },
              {
                exact: true,
                path: '/app/distribucion/inventariogeneral/traslados/:param/:id',
                component: loadable(() => import(
                  'views/scm/inventario/inventarioGeneral/procesos/traslados/Edit'
                ), fallBack),
                permission: ['app:general:distribucion:inventariogeneral:traslados:edit'],
              },
              {
                exact: true,
                path: '/app/distribucion/inventariogeneral/:clasificacion(E)',
                component: loadable(() => import(
                  'views/scm/inventario/inventarioGeneral/procesos/salidas-entradas'
                ), fallBack),
                permission: ['app:general:distribucion:inventariogeneral:entradas:view'],
              },
              {
                exact: true,
                path: '/app/distribucion/inventariogeneral/:clasificacion(S)',
                component: loadable(() => import(
                  'views/scm/inventario/inventarioGeneral/procesos/salidas-entradas'
                ), fallBack),
                permission: ['app:general:distribucion:inventariogeneral:salidas:view'],
              },
              {
                exact: true,
                path: '/app/distribucion/inventariogeneral/:clasificacion/new/:documentodefault',
                component: loadable(() => import(
                  'views/scm/inventario/inventarioGeneral/procesos/salidas-entradas/New'
                ), fallBack),
              },
              {
                exact: true,
                path: '/app/distribucion/inventariogeneral/:clasificacion/:param/:id',
                component: loadable(() => import(
                  'views/scm/inventario/inventarioGeneral/procesos/salidas-entradas/Edit'
                ), fallBack),
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/distribucion/materiaprima',
            routes: [
              {
                exact: true,
                path: '/app/distribucion/materiaprima/traslados',
                component: loadable(() => import(
                  'views/scm/inventario/materiaPrima/procesos/traslados/List'
                ), fallBack),
                permission: ['scm:inventario:materiaprima:traslados:list'],
              },
              {
                exact: true,
                path: '/app/distribucion/materiaprima/traslados/new',
                component: loadable(() => import('views/scm/inventario/materiaPrima/procesos/traslados/New'), fallBack),
              },
              {
                exact: true,
                path: '/app/distribucion/materiaprima/traslados/edit/:id',
                component: loadable(() => import(
                  'views/scm/inventario/materiaPrima/procesos/traslados/Edit'
                ), fallBack),
              },
              {
                exact: true,
                path: '/app/distribucion/materiaprima/:clasificacion(E)',
                component: loadable(() => import(
                  'views/scm/inventario/materiaPrima/procesos/salidas-entradas'
                ), fallBack),
                permission: ['scm:inventario:materiaprima:entradas:list'],
              },
              {
                exact: true,
                path: '/app/distribucion/materiaprima/:clasificacion(S)',
                component: loadable(() => import(
                  'views/scm/inventario/materiaPrima/procesos/salidas-entradas'
                ), fallBack),
                permission: ['scm:inventario:materiaprima:salidas:list'],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/distribucion/productoterminado',
            routes: [
              {
                exact: true,
                path: '/app/distribucion/productoterminado/solicitudestraslados',
                component: loadable(
                  () => import('views/scm/inventario/productoTerminado/procesos/solicitudesTraslado/List'),
                  fallBack
                ),
                permission: ['app:general:distribucion:productoterminado:solicituddetraslados:view'],
              },
              {
                exact: true,
                path: '/app/distribucion/productoterminado/solicitudestraslados/new',
                component: loadable(
                  () => import('views/scm/inventario/productoTerminado/procesos/solicitudesTraslado/New'),
                  fallBack
                ),
                permission: ['app:general:distribucion:productoterminado:solicituddetraslados:new'],
              },
              {
                exact: true,
                path: '/app/distribucion/productoterminado/solicitudestraslados/edit/:id',
                component: loadable(
                  () => import('views/scm/inventario/productoTerminado/procesos/solicitudesTraslado/Edit'),
                  fallBack
                ),
                permission: ['app:general:distribucion:productoterminado:solicituddetraslados:edit'],
              },
              {
                exact: true,
                path: '/app/distribucion/productoterminado/traslados',
                component: loadable(() => import(
                  'views/scm/inventario/productoTerminado/procesos/traslados'
                ), fallBack),
                permission: ['app:general:distribucion:productoterminado:traslados:view'],
              },
              {
                exact: true,
                path: '/app/distribucion/productoterminado/traslados/new',
                component: loadable(() => import(
                  'views/scm/inventario/productoTerminado/procesos/traslados/New'
                ), fallBack),
                permission: ['app:general:distribucion:productoterminado:traslados:new'],
              },
              {
                exact: true,
                path: '/app/distribucion/productoterminado/traslados/:param/:id',
                component: loadable(() => import(
                  'views/scm/inventario/productoTerminado/procesos/traslados/Edit'
                ), fallBack),
                permission: ['app:general:distribucion:productoterminado:traslados:edit'],
              },
              {
                exact: true,
                path: '/app/distribucion/productoterminado/:clasificacion(E)',
                component: loadable(() => import(
                  'views/scm/inventario/productoTerminado/procesos/salidas-entradas'
                ), fallBack),
                permission: ['app:general:distribucion:productoterminado:entradas:view'],
              },
              {
                exact: true,
                path: '/app/distribucion/productoterminado/:clasificacion(S)',
                component: loadable(() => import(
                  'views/scm/inventario/productoTerminado/procesos/salidas-entradas'
                ), fallBack),
                permission: ['app:general:distribucion:productoterminado:salidas:view'],
              },
              {
                exact: true,
                path: '/app/distribucion/productoterminado/:clasificacion/new/:documentodefault',
                component: loadable(() => import(
                  'views/scm/inventario/productoTerminado/procesos/salidas-entradas/New'
                ), fallBack),
              },
              {
                exact: true,
                path: '/app/distribucion/productoterminado/:clasificacion/:param/:id',
                component: loadable(() => import(
                  'views/scm/inventario/productoTerminado/procesos/salidas-entradas/Edit'
                ), fallBack),
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/distribucion/consultas',
            routes: [
              {
                exac: true,
                path: '/app/distribucion/consultas/bodegas',
                component: loadable(() => import('views/scm/inventario/generales/informes/bodegas/List'), fallBack),
                permission: ['app:general:distribucion:consultas:bodegas:view'],
              },
              {
                exact: true,
                path: '/app/distribucion/consultas/historicoajustes',
                component: loadable(() => import(
                  'views/scm/inventario/generales/informes/tomaAjusteInventario/historicoAjusteInventario/List'
                ), fallBack),
                permission: ['app:general:distribucion:consultas:historicoajusteinventario:view'],
              },
              {
                exact: true,
                path: '/app/distribucion/consultas/proximosavencer',
                component: loadable(() => import(
                  'views/scm/inventario/generales/informes/proximosVencer/List'
                ), fallBack),
                permission: ['app:general:distribucion:consultas:productosproximosavences:view'],
              },
              {
                path: '/app/distribucion/consultas/inventariogeneral',
                routes: [
                  {
                    exact: true,
                    path: '/app/distribucion/consultas/inventariogeneral/existencias',
                    component: loadable(() => import(
                      'views/scm/inventario/inventarioGeneral/consultas/existencias/List'
                    ), fallBack),
                    permission: ['app:general:distribucion:consultas:inventariogeneral:existencias:view'],
                  },
                  {
                    exact: true,
                    path: '/app/distribucion/consultas/inventariogeneral/activofijo',
                    component: loadable(() => import(
                      'views/scm/inventario/inventarioGeneral/consultas/activoFijo/List'
                    ), fallBack),
                    permission: ['app:general:distribucion:consultas:inventariogeneral:activosfijos:view'],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                path: '/app/distribucion/consultas/materiaprima',
                routes: [
                  {
                    exact: true,
                    path: '/app/distribucion/consultas/materiaprima/existencias',
                    component: loadable(() => import(
                      'views/scm/inventario/materiaPrima/consultas/existencias/List'
                    ), fallBack),
                    permission: ['scm:inventario:inventariogeneral:consulta:existencia:list'],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                path: '/app/distribucion/consultas/productoterminado',
                routes: [
                  {
                    exact: true,
                    path: '/app/distribucion/consultas/productoterminado/existencias',
                    component: loadable(() => import(
                      'views/scm/inventario/productoTerminado/consultas/existencias/List'
                    ), fallBack),
                    permission: ['app:general:distribucion:consultas:productoterminado:existencias:view'],
                  },
                  {
                    exact: true,
                    path: '/app/distribucion/consultas/productoterminado/inventariovalorizable',
                    component: loadable(() => import(
                      'views/scm/inventario/productoTerminado/consultas/inventarioValorizable/List'
                    ), fallBack),
                    permission: ['app:general:distribucion:consultas:productoterminado:inventariovalorizable:view'],
                  },
                  {
                    exact: true,
                    path: '/app/distribucion/consultas/productoterminado/reservas',
                    component: loadable(() => import(
                      'views/scm/inventario/productoTerminado/consultas/reservas/List'
                    ), fallBack),
                    permission: ['app:general:distribucion:consultas:productoterminado:reservas:view'],
                  },
                  {
                    exact: true,
                    path: '/app/distribucion/consultas/productoterminado/reportesdeusos',
                    component: loadable(() => import(
                      'views/scm/inventario/productoTerminado/consultas/reporteUso/List'
                    ), fallBack),
                    permission: ['app:general:distribucion:consultas:productoterminado:reportedeuso:view'],
                  },
                  {
                    exact: true,
                    path: '/app/distribucion/consultas/productoterminado/semaforo',
                    component: loadable(() => import(
                      'views/scm/inventario/productoTerminado/consultas/semaforo/List'
                    ), fallBack),
                    permission: ['app:general:distribucion:consultas:productoterminado:semaforo:view'],
                  },
                  {
                    exact: true,
                    path: '/app/distribucion/consultas/productoterminado/mercanciaentransito',
                    component: loadable(() => import(
                      'views/scm/inventario/productoTerminado/consultas/mercanciaEnTransito/List'
                    ), fallBack),
                    permission: ['app:general:distribucion:consultas:productoterminado:mercanciasentransito:view'],
                  },
                  {
                    exact: true,
                    path: '/app/distribucion/consultas/productoterminado/trazabilidad/:tipo',
                    component: loadable(() => import(
                      'views/scm/inventario/productoTerminado/consultas/trazabilidad'
                    ), fallBack),
                    permission: ['app:general:distribucion:consultas:productoterminado:'
                      + 'trazabilidadporrecepcionyproducto:view'],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        path: '/app/logistica',
        routes: [
          {
            exact: true,
            path: '/app/logistica/solicitudeslogisticas',
            component: loadable(() => import('views/scm/wms/procesos/solicitudLogistica/List'), fallBack),
            permission: ['app:general:logistica:solicitudeslogisticas:view'],
          },
          {
            exact: true,
            path: '/app/logistica/solicitudeslogisticas/new',
            component: loadable(() => import('views/scm/wms/procesos/solicitudLogistica/New'), fallBack),
            permission: ['app:general:logistica:solicitudeslogisticas:new'],
          },
          {
            exact: true,
            path: '/app/logistica/solicitudeslogisticas/edit/:id/:abreviaturaTipo',
            component: loadable(() => import('views/scm/wms/procesos/solicitudLogistica/Edit'), fallBack),
            permission: ['app:general:logistica:solicitudeslogisticas:edit'],
          },
          {
            exact: true,
            path: '/app/logistica/transporteterceros',
            component: loadable(() => import('views/scm/wms/procesos/transporteTercero/List'), fallBack),
            permission: ['app:general:logistica:transporteterceros:view'],
          },
          {
            path: '/app/logistica/logisticaentrada',
            routes: [
              {
                exact: true,
                path: '/app/logistica/logisticaentrada/recepcionmercancia',
                component: loadable(() => import('views/scm/wms/procesos/recepcion'), fallBack),
                permission: ['app:general:logistica:logisticaentrada:recepcionmercancia:view'],
              },
              {
                exact: true,
                path: '/app/logistica/logisticaentrada/recepcionmercancia/new/:documentoId',
                component: loadable(() => import('views/scm/wms/procesos/recepcion/New'), fallBack),
                permission: ['app:general:logistica:logisticaentrada:recepcionmercancia:new'],
              },
              {
                exact: true,
                path: '/app/logistica/logisticaentrada/recepcionmercancia/:param/:id/:tipoRecepcion',
                component: loadable(() => import('views/scm/wms/procesos/recepcion/Edit'), fallBack),
                permission: ['app:general:logistica:logisticaentrada:recepcionmercancia:edit'],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/logistica/logisticasalida',
            routes: [
              {
                exact: true,
                path: '/app/logistica/logisticasalida/programacionalistamiento',
                component: loadable(
                  () => import('views/scm/wms/procesos/programacionPickingExistencias/List'),
                  fallBack
                ),
                permission: ['app:general:logistica:logisticasalida:programacionalistamiento:view'],
              },
              {
                exact: true,
                path: '/app/logistica/logisticasalida/programacionalistamiento/:param/:id',
                component: loadable(
                  () => import('views/scm/wms/procesos/programacionPickingExistencias/Edit'),
                  fallBack
                ),
                permission: ['app:general:logistica:logisticasalida:programacionalistamiento:edit'],
              },
              {
                exact: true,
                path: '/app/logistica/logisticasalida/alistamiento',
                component: loadable(() => import('views/scm/wms/procesos/recoleccion/List'), fallBack),
                permission: ['app:general:logistica:logisticasalida:alistamiento:view'],
              },
              {
                exact: true,
                path: '/app/logistica/logisticasalida/alistamiento/new',
                component: loadable(() => import('views/scm/wms/procesos/picking/New'), fallBack),
              },
              {
                exact: true,
                path: '/app/logistica/logisticasalida/alistamiento/:param/:id',
                component: loadable(() => import('views/scm/wms/procesos/recoleccion/Edit'), fallBack),
                permission: ['app:general:logistica:logisticasalida:alistamiento:edit'],
              },
              {
                exact: true,
                path: '/app/logistica/logisticasalida/despachos',
                component: loadable(() => import('views/scm/wms/procesos/despachos/List'), fallBack),
                permission: ['app:general:logistica:logisticasalida:despachos:view'],
              },
              {
                exact: true,
                path: '/app/logistica/logisticasalida/despachos/new',
                component: loadable(() => import('views/scm/wms/procesos/despachos/New'), fallBack),
                permission: ['app:general:logistica:logisticasalida:despachos:new'],
              },
              {
                exact: true,
                path: '/app/logistica/logisticasalida/despachos/:param/:id',
                component: loadable(() => import('views/scm/wms/procesos/despachos/Edit'), fallBack),
                permission: ['app:general:logistica:logisticasalida:despachos:edit'],
              },
              {
                exact: true,
                path: '/app/logistica/logisticasalida/hojasderutas',
                component: loadable(() => import('views/scm/wms/procesos/hojasRuta/List'), fallBack),
                permission: ['app:general:logistica:logisticasalida:hojasderuta:view'],
              },
              {
                exact: true,
                path: '/app/logistica/logisticasalida/hojasderutas/new',
                component: loadable(() => import('views/scm/wms/procesos/hojasRuta/New'), fallBack),
                permission: ['app:general:logistica:logisticasalida:hojasderuta:new'],
              },
              {
                exact: true,
                path: '/app/logistica/logisticasalida/hojasderutas/edit/:id',
                component: loadable(() => import('views/scm/wms/procesos/hojasRuta/Edit'), fallBack),
                permission: ['app:general:logistica:logisticasalida:hojasderuta:edit'],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        path: '/app/contabilidad',
        routes: [
          {
            path: '/app/contabilidad/comprobantescontables',
            routes: [
              {
                exact: true,
                path: '/app/contabilidad/comprobantescontables',
                component: loadable(() => import('views/contabilidad/procesos/Cards')),
                permission: ['app:general:contabilidad:otroscomprobantescontables:view'],
              },
              {
                exact: true,
                path: '/app/contabilidad/comprobantescontables/ajustecontable',
                component: loadable(() => import('views/contabilidad/procesos/ajusteContable/List'), fallBack),
              },
              {
                exact: true,
                path: '/app/contabilidad/comprobantescontables/ajustecontable/new',
                component: loadable(() => import('views/contabilidad/procesos/ajusteContable/New'), fallBack),
              },
              {
                exact: true,
                path: '/app/contabilidad/comprobantescontables/ajustecontable/edit/:id',
                component: loadable(() => import('views/contabilidad/procesos/ajusteContable/Edit'), fallBack),
              },
              {
                exact: true,
                path: '/app/contabilidad/comprobantescontables/trasladosfondos',
                component: loadable(() => import('views/contabilidad/procesos/trasladoFondos/List'), fallBack),
                permission: ['app:general:contabilidad:otroscomprobantescontables:trasladofondos:view'],
              },
              {
                exact: true,
                path: '/app/contabilidad/comprobantescontables/trasladosfondos/new',
                component: loadable(() => import('views/contabilidad/procesos/trasladoFondos/New'), fallBack),
                permission: ['app:general:contabilidad:otroscomprobantescontables:trasladofondos:new'],
              },
              {
                exact: true,
                path: '/app/contabilidad/comprobantescontables/trasladosfondos/edit/:id',
                component: loadable(() => import('views/contabilidad/procesos/trasladoFondos/Edit'), fallBack),
              },
              {
                exact: true,
                path: '/app/contabilidad/comprobantescontables/causacion',
                component: loadable(() => import('views/contabilidad/procesos/causacion/List'), fallBack),
              },
              {
                exact: true,
                path: '/app/contabilidad/comprobantescontables/egresos',
                component: loadable(() => import('views/tesoreria/procesos/egresos/List'), fallBack),
                permission: ['contabilidad:comprobantescontables:egresos:list'],
              },
              {
                exact: true,
                path: '/app/contabilidad/comprobantescontables/egresos/new',
                component: loadable(() => import('views/tesoreria/procesos/egresos/New'), fallBack),
              },
              {
                exact: true,
                path: '/app/contabilidad/comprobantescontables/egresos/edit/:id',
                component: loadable(() => import('views/tesoreria/procesos/egresos/Edit'), fallBack),
              },
              {
                exact: true,
                path: '/app/tesoreria/comprobantescontables/gastosrecurrentes',
                component: loadable(() => import('views/contabilidad/procesos/gastosRecurrentes/List'), fallBack),
              },
              {
                exact: true,
                path: '/app/contabilidad/comprobantescontables/documentosoporte',
                component: loadable(() => import('views/contabilidad/procesos/documentoSoporte/List'), fallBack),
              },
              {
                exact: true,
                path: '/app/contabilidad/comprobantescontables/forward',
                component: loadable(() => import('views/tesoreria/procesos/forward/List'), fallBack),
                permission: ['contabilidad:comprobantescontables:forward:list'],
              },
              {
                exact: true,
                path: '/app/contabilidad/comprobantescontables/forward/new',
                component: loadable(() => import('views/tesoreria/procesos/forward/New'), fallBack),
              },
              {
                exact: true,
                path: '/app/contabilidad/comprobantescontables/forward/edit/:id',
                component: loadable(() => import('views/tesoreria/procesos/forward/Edit'), fallBack),
              },
              {
                exact: true,
                path: '/app/contabilidad/comprobantescontables/otroscomprobantes',
                component: loadable(() => import('views/contabilidad/procesos/otrosComprobantes/List'), fallBack),
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/contabilidad/tesoreria',
            routes: [
              {
                exact: true,
                path: '/app/contabilidad/tesoreria/flujodecaja',
                component: loadable(() => import('views/tesoreria/procesos/flujoCaja/List'), fallBack),
              },
              {
                exact: true,
                path: '/app/contabilidad/tesoreria/cajadiario',
                component: loadable(() => import('views/tesoreria/procesos/cajaDiario/List'), fallBack),
              },
              {
                exact: true,
                path: '/app/contabilidad/tesoreria/diariogeneral',
                component: loadable(() => import('views/tesoreria/procesos/diarioGeneral/List'), fallBack),
              },
              {
                exact: true,
                path: '/app/contabilidad/tesoreria/diariolegal',
                component: loadable(() => import('views/tesoreria/procesos/diarioLegal/List'), fallBack),
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/contabilidad/reportescontables',
            routes: [
              {
                exact: true,
                path: '/app/contabilidad/reportescontables/estadoresultados',
                component: loadable(() => import(
                  'views/tesoreria/reportesContables/estadoResultados/List'
                ), fallBack),
                permission: ['app:general:contabilidad:'
                + 'reportescontables:estadoderesultados:view'],
              },
              {
                exact: true,
                path: '/app/contabilidad/reportescontables/balancegeneral',
                component: loadable(() => import(
                  'views/tesoreria/reportesContables/balanceGeneral/List'
                ), fallBack),
                permission: ['app:general:contabilidad:'
                + 'reportescontables:balancegeneral:view'],
              },
              {
                exact: true,
                path: '/app/contabilidad/reportescontables/balanceprueba',
                component: loadable(() => import(
                  'views/tesoreria/reportesContables/balancePrueba/List'
                ), fallBack),
                permission: ['app:general:contabilidad:'
                + 'reportescontables:balancedepruebas:view'],
              },
              {
                exact: true,
                path: '/app/contabilidad/reportescontables/balancepruebatercero',
                component: loadable(() => import(
                  'views/tesoreria/reportesContables/balancePruebaTercero/List'
                ), fallBack),
                permission: ['app:general:contabilidad:'
                + 'reportescontables:balancedepruebasportercero:view'],
              },
              {
                exact: true,
                path: '/app/contabilidad/reportescontables/reportediarioventas',
                component: loadable(() => import(
                  'views/tesoreria/reportesContables/reporteDiarioVentas/List'
                ), fallBack),
                permission: ['app:general:contabilidad:'
                + 'reportescontables:reportediariodeventas:view'],
              },
              {
                exact: true,
                path: '/app/contabilidad/reportescontables/impuestosretenciones',
                component: loadable(() => import(
                  'views/tesoreria/reportesContables/impuestosRetenciones/List'
                ), fallBack),
                permission: ['app:general:contabilidad:'
                + 'reportescontables:impuestosyretenciones:view'],
              },
              {
                exact: true,
                path: '/app/contabilidad/reportescontables/movimientocontable',
                component: loadable(() => import('views/contabilidad/procesos/movimientoContable/List'), fallBack),
                permission: ['contabilidad:reportescontables:movimientoscontables:list'],
              },
              {
                exact: true,
                path: '/app/contabilidad/reportescontables/movimientocontable/edit/:id',
                component: loadable(() => import('views/contabilidad/procesos/movimientoContable/Edit'), fallBack),
              },
              {
                exact: true,
                path: '/app/contabilidad/reportescontables/librodiario',
                component: loadable(() => import(
                  'views/tesoreria/reportesContables/libroDiario/List'
                ), fallBack),
                permission: ['app:general:contabilidad:'
                + 'reportescontables:librodiario:view'],
              },
              {
                exact: true,
                path: '/app/contabilidad/reportescontables/auxiliartercero',
                component: loadable(() => import(
                  'views/tesoreria/reportesContables/auxiliarTercero/List'
                ), fallBack),
                permission: ['app:general:contabilidad:'
                + 'reportescontables:auxiliarportercero:view'],
              },
              {
                exact: true,
                path: '/app/contabilidad/reportescontables/libromayor',
                component: loadable(() => import(
                  'views/tesoreria/reportesContables/libroMayor/List'
                ), fallBack),
                permission: ['app:general:contabilidad:'
                + 'reportescontables:libromayor:view'],
              },
              {
                exact: true,
                path: '/app/contabilidad/reportescontables/industriacomercio',
                component: loadable(() => import(
                  'views/tesoreria/reportesContables/industriaComercio/List'
                ), fallBack),
                permission: ['app:general:contabilidad:'
                + 'reportescontables:industriaycomercio:view'],
              },
              {
                exact: true,
                path: '/app/contabilidad/reportescontables/supersociedades',
                component: loadable(() => import(
                  'views/tesoreria/reportesContables/supersociedades/List'
                ), fallBack),
                permission: ['app:general:contabilidad:'
                + 'reportescontables:supersociedades:view'],
              },
              {
                exact: true,
                path: '/app/contabilidad/reportescontables/dane',
                component: loadable(() => import(
                  'views/tesoreria/reportesContables/dane/List'
                ), fallBack),
                permission: ['app:general:contabilidad:'
                + 'reportescontables:dane:view'],
              },
              {
                exact: true,
                path: '/app/contabilidad/reportescontables/pruebaacida',
                component: loadable(() => import(
                  'views/tesoreria/reportesContables/pruebaAcida/List'
                ), fallBack),
                permission: ['app:general:contabilidad:'
                + 'reportescontables:pruebaacida:view'],
              },
              {
                exact: true,
                path: '/app/contabilidad/reportescontables/informeroi',
                component: loadable(() => import(
                  'views/tesoreria/reportesContables/informeRoi/List'
                ), fallBack),
                permission: ['app:general:contabilidad:'
                + 'reportescontables:consultaroi:view'],
              },
              {
                exact: true,
                path: '/app/contabilidad/reportescontables/informerentabilidadbruto',
                component: loadable(() => import(
                  'views/tesoreria/reportesContables/informeRentabilidadBruto/List'
                ), fallBack),
                permission: ['app:general:contabilidad:'
                 + 'reportescontables:consultarentabilidadbruto:view'],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/contabilidad/niif/',
            routes: [
              {
                exact: true,
                path: '/app/contabilidad/niif/catalogocuentasniif',
                component: loadable(() => import(
                  'views/contabilidad/niif/catalogoCuentas/List'
                ), fallBack),
                permission: ['app:general:contabilidad:'
                + 'niif:catalogodecuentasniif:view'],
              },
              {
                path: '/app/contabilidad/niif/estadosfinancieros',
                routes: [
                  {
                    exact: true,
                    path: '/app/contabilidad/niif/estadosfinancieros/estadoresultados',
                    component: loadable(() => import(
                      'views/contabilidad/niif/estadosFinancieros/estadoResultados/List'
                    ), fallBack),
                    permission: ['app:general:contabilidad:'
                    + 'niif:estadosfinancieros:estadoderesultadosroi:view'],
                  },
                  {
                    exact: true,
                    path: '/app/contabilidad/niif/estadosfinancieros/estadosituacionfinanciera',
                    component: loadable(() => import(
                      'views/contabilidad/niif/estadosFinancieros/estadoSituacionFinanciera/List'
                    ), fallBack),
                    permission: ['app:general:contabilidad:'
                    + 'niif:estadosfinancieros:estadodesituacionfinanciera:view'],
                  },
                  {
                    exact: true,
                    path: '/app/contabilidad/niif/estadosfinancieros/estadocambiospatrimonio',
                    component: loadable(() => import(
                      'views/contabilidad/niif/estadosFinancieros/estadoCambiosPatrimonio/List'
                    ), fallBack),
                    permission: ['app:general:contabilidad:'
                    + 'niif:estadosfinancieros:estadodecambiosenelpatrimonio:view'],
                  },
                  {
                    exact: true,
                    path: '/app/contabilidad/niif/estadosfinancieros/estadoflujosefectivo',
                    component: loadable(() => import(
                      'views/contabilidad/niif/estadosFinancieros/estadoFlujosEfectivos/List'
                    ), fallBack),
                    permission: ['app:general:contabilidad:'
                    + 'niif:estadosfinancieros:estadodeflujosdeefectivo:view'],
                  },
                  {
                    exact: true,
                    path: '/app/contabilidad/niif/estadosfinancieros/notaestadosfinancieros',
                    component: loadable(() => import(
                      'views/contabilidad/niif/estadosFinancieros/notaEstadosFinancieros/List'
                    ), fallBack),
                    permission: ['app:general:contabilidad:'
                    + 'niif:estadosfinancieros:notasalosestadosfinancieros:view'],
                  },
                ],
              },
              {
                exact: true,
                path: '/app/contabilidad/niif/informacionexogenia',
                component: loadable(() => import(
                  'views/contabilidad/niif/informeExogenia/List'
                ), fallBack),
                permission: ['app:general:contabilidad:'
                + 'niif:informacionexogena:view'],
              },
              {
                exact: true,
                path: '/app/contabilidad/niif/certificadosretencion',
                component: loadable(() => import('views/contabilidad/niif/certificadosRetencion/List'), fallBack),
                permission: ['app:general:contabilidad:'
                + 'niif:certificadosderetencion:view'],
              },
            ],
          },
          {
            exact: true,
            path: '/app/contabilidad/certificadosretencion',
            component: loadable(() => import('views/contabilidad/procesos/certificadosRetenciones/List'), fallBack),
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        path: '/app/tesoreria',
        routes: [
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos',
            component: loadable(() => import('views/tesoreria/procesos/comprobantesIngresosEgresos/index')),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:view'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/gastotesoreria',
            component: loadable(() => import('views/tesoreria/procesos/gastoTesoreria/List'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:egresos:gastos:view'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/gastotesoreria/new',
            component: loadable(() => import('views/tesoreria/procesos/gastoTesoreria/New'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:egresos:gastos:new'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/gastotesoreria/edit/:id',
            component: loadable(() => import('views/tesoreria/procesos/gastoTesoreria/Edit'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:egresos:gastos:edit'],
          },
          {
            exact: true,
            path: '/app/tesoreria/conciliacionbancaria',
            component: loadable(() => import('views/tesoreria/procesos/conciliacionBancaria/List'), fallBack),
          },
          {
            exact: true,
            path: '/app/tesoreria/conciliacionbancaria/new',
            component: loadable(() => import('views/tesoreria/procesos/conciliacionBancaria/New'), fallBack),
          },
          {
            exact: true,
            path: '/app/tesoreria/conciliacionbancaria/edit/:id',
            component: loadable(() => import('views/tesoreria/procesos/conciliacionBancaria/Edit'), fallBack),
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/gastorecurrente',
            component: loadable(() => import('views/tesoreria/procesos/gastoRecurrente/List'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:egresos:gastosrecurrentes:view'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/gastorecurrente/new',
            component: loadable(() => import('views/tesoreria/procesos/gastoRecurrente/New'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:egresos:gastosrecurrentes:new'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/gastorecurrente/edit/:id',
            component: loadable(() => import('views/tesoreria/procesos/gastoRecurrente/Edit'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:egresos:gastosrecurrentes:edit'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/cajamenor',
            component: loadable(() => import('views/tesoreria/procesos/cajaMenor/List'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:egresos:cajamenor:view'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/cajamenor/new',
            component: loadable(() => import('views/tesoreria/procesos/cajaMenor/New'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:egresos:cajamenor:new'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/cajamenor/edit/:id',
            component: loadable(() => import('views/tesoreria/procesos/cajaMenor/Edit'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:egresos:cajamenor:edit'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/pagos',
            component: loadable(() => import('views/tesoreria/procesos/pagos/List'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:egresos:pagos:view'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/pagos/new',
            component: loadable(() => import('views/tesoreria/procesos/pagos/New'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:egresos:pagos:new'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/pagos/edit/:id',
            component: loadable(() => import('views/tesoreria/procesos/pagos/Edit'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:egresos:pagos:edit'],
          },
          {
            exact: true,
            path: '/app/tesoreria/pagosrecurrentes',
            component: loadable(() => import('views/tesoreria/procesos/pagoRecurrente/List')),
          },
          {
            exact: true,
            path: '/app/tesoreria/pagosrecurrentes/new',
            component: loadable(() => import('views/tesoreria/procesos/pagoRecurrente/New')),
          },
          {
            exact: true,
            path: '/app/tesoreria/pagosrecurrentes/edit/:id',
            component: loadable(() => import('views/tesoreria/procesos/pagoRecurrente/Edit')),
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/causacioncredito',
            component: loadable(() => import('views/tesoreria/procesos/causacionCredito/List'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:egresos:causacioncreditos:view'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/causacioncredito/new',
            component: loadable(() => import('views/tesoreria/procesos/causacionCredito/New'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:egresos:causacioncreditos:new'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/causacioncredito/edit/:id',
            component: loadable(() => import('views/tesoreria/procesos/causacionCredito/Edit'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:egresos:causacioncreditos:edit'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/compras',
            component: loadable(() => import('views/tesoreria/procesos/compras/List'), fallBack),
            permission: ['contabilidad:tesoreria:pagos:list'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/conciliacioncompras',
            component: loadable(() => import('views/tesoreria/procesos/conciliacionCompra/List'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:egresos:conciliacioncompras:view'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/conciliacioncompras/new',
            component: loadable(() => import('views/tesoreria/procesos/conciliacionCompra/New'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:egresos:conciliacioncompras:new'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/conciliacioncompras/edit/:id',
            component: loadable(() => import('views/tesoreria/procesos/conciliacionCompra/Edit'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:egresos:conciliacioncompras:edit'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/programacionpago',
            component: loadable(() => import('views/tesoreria/procesos/programacionPago/List'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:egresos:programacionespagos:view'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/programacionpago/new',
            component: loadable(() => import('views/tesoreria/procesos/programacionPago/New'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:egresos:programacionespagos:new'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/programacionpago/edit/:id',
            component: loadable(() => import('views/tesoreria/procesos/programacionPago/Edit'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:egresos:programacionespagos:edit'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/ingresosotrosingresos',
            component: loadable(() => import('views/tesoreria/procesos/otrosIngresos/List'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:ingresos:otrosingresos:view'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/ingresosotrosingresos/new/:uuid',
            component: loadable(() => import('views/tesoreria/procesos/otrosIngresos/New'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:ingresos:otrosingresos:new'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantesingresosegresos/ingresosotrosingresos/edit/:uuid/:id',
            component: loadable(() => import('views/tesoreria/procesos/otrosIngresos/Edit'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:ingresos:otrosingresos:edit'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantescontables/ingresosFacturas',
            component: loadable(() => import('views/tesoreria/procesos/reciboCaja/ListFacturas'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:ingresos:pagofacturas:view'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantescontables/ingresosAnticipos',
            component: loadable(() => import('views/tesoreria/procesos/reciboCaja/ListAnticipos'), fallBack),
            permission: ['app:general:tesoreria:comprobantesingresosegresos:ingresos:anticipos:view'],
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantescontables/ingresos/new/:abreviatura',
            component: loadable(() => import('views/tesoreria/procesos/reciboCaja/New'), fallBack),
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantescontables/ingresos/new/:abreviatura/:facturaId/:clienteId/:clienteNombre',
            component: loadable(() => import('views/tesoreria/procesos/reciboCaja/New'), fallBack),
          },
          {
            exact: true,
            path: '/app/tesoreria/comprobantescontables/ingresos/edit/:abreviatura/:id',
            component: loadable(() => import('views/tesoreria/procesos/reciboCaja/Edit'), fallBack),
          },
          {
            exact: true,
            path: '/app/tesoreria/consignacionesporidentificar',
            component: loadable(() => import('views/tesoreria/procesos/consignacionesIdentificar/List'), fallBack),
            permission: ['contabilidad:tesoreria:consignacionesporidentificar:list'],
          },
          {
            exact: true,
            path: '/app/tesoreria/consignacionesporidentificar/edit/:id',
            component: loadable(() => import('views/tesoreria/procesos/consignacionesIdentificar/Edit'), fallBack),
          },
          {
            exact: true,
            path: '/app/tesoreria/consignacionesporidentificar/new',
            component: loadable(() => import('views/tesoreria/procesos/consignacionesIdentificar/New'), fallBack),
          },
          {
            exact: true,
            path: '/app/tesoreria/transaccionesfinancieras',
            component: loadable(() => import('views/tesoreria/procesos/documentoFinanciero/List'), fallBack),
            permission: ['contabilidad:tesoreria:documentofinanciero:list'],
          },
          {
            exact: true,
            path: '/app/tesoreria/transaccionesfinancieras/new',
            component: loadable(() => import('views/tesoreria/procesos/documentoFinanciero/New'), fallBack),
          },
          {
            exact: true,
            path: '/app/tesoreria/transaccionesfinancieras/edit/:id',
            component: loadable(() => import('views/tesoreria/procesos/documentoFinanciero/Edit'), fallBack),
          },
          {
            exact: true,
            path: '/app/tesoreria/cuentascobrar',
            component: loadable(() => import('views/tesoreria/consultas/cuentasPorCobrar/index'), fallBack),
          },
          {
            exact: true,
            path: '/app/tesoreria/cuentaspagar',
            component: loadable(() => import(
              'views/tesoreria/consultas/cuentasPorPagar/CuentasPorPagarList'
            ), fallBack),
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        path: '/app/gestionactivosfijos',
        routes: [
          {
            exact: true,
            path: '/app/gestionactivosfijos/administracionactivosfijos',
            component: loadable(
              () => import('views/gestionActivos/procesos/administracionActivosFijos/List'),
              fallBack
            ),
            permission: ['app:general:gestionactivosfijos:administracionactivosfijos:view'],
          },
          {
            exact: true,
            path: '/app/gestionactivosfijos/conceptosmovimientoactivos',
            component: loadable(
              () => import('views/gestionActivos/procesos/conceptosMovimientoActivos/List'),
              fallBack
            ),
            permission: ['app:general:gestionactivosfijos:conceptosmovimientoactivos:view'],
          },
          {
            exact: true,
            path: '/app/gestionactivosfijos/conceptosmovimientoactivos/new',
            component: loadable(() => import('views/gestionActivos/procesos/conceptosMovimientoActivos/New'), fallBack),
            permission: ['app:general:gestionactivosfijos:conceptosmovimientoactivos:new'],
          },
          {
            exact: true,
            path: '/app/gestionactivosfijos/conceptosmovimientoactivos/edit/:id',
            component: loadable(
              () => import('views/gestionActivos/procesos/conceptosMovimientoActivos/Edit'),
              fallBack
            ),
            permission: ['app:general:gestionactivosfijos:conceptosmovimientoactivos:edit'],
          },
          {
            exact: true,
            path: '/app/gestionactivosfijos/mantenimientos',
            component: loadable(
              () => import('views/scm/inventario/inventarioGeneral/procesos/mantenimiento/List'),
              fallBack
            ),
            permission: ['app:general:gestionactivosfijos:mantenimientos:view'],
          },
          {
            exact: true,
            path: '/app/gestionactivosfijos/mantenimientos/new',
            component: loadable(
              () => import('views/scm/inventario/inventarioGeneral/procesos/mantenimiento/New'),
              fallBack
            ),
            permission: ['app:general:gestionactivosfijos:mantenimientos:new'],
          },
          {
            exact: true,
            path: '/app/gestionactivosfijos/mantenimientos/edit/:id',
            component: loadable(
              () => import('views/scm/inventario/inventarioGeneral/procesos/mantenimiento/Edit'),
              fallBack
            ),
            permission: ['app:general:gestionactivosfijos:mantenimientos:edit'],
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        path: '/app/inteligenciadenegocio',
        routes: [
          {
            path: '/app/inteligenciadenegocio/inventario',
            routes: [
              {
                exact: true,
                path: '/app/inteligenciadenegocio/inventario/puntoreorden',
                component: loadable(() => import('views/bi/inventario/puntoReorden/List')),
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        path: '/app/recursoshumanos',
        routes: [
          {
            path: '/app/recursoshumanos/gestiondeltalento',
            routes: [
              {
                exact: true,
                path: '/app/recursoshumanos/gestiondeltalento/solicitudrecursoshumanos',
                component: loadable(
                  () => import('views/hrm/procesos/gestionTalento/solicitudRecursosHumanos/List')
                ),
                permission: ['app:general:recursoshumanos:gestiondeltalento:solicitudrecursoshumanos:view'],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/gestiondeltalento/solicitudrecursoshumanos/new',
                component: loadable(() => import('views/hrm/procesos/gestionTalento/solicitudRecursosHumanos/New')),
                permission: ['app:general:recursoshumanos:gestiondeltalento:solicitudrecursoshumanos:new'],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/gestiondeltalento/solicitudrecursoshumanos/:param/:id',
                component: loadable(() => import('views/hrm/procesos/gestionTalento/solicitudRecursosHumanos/Edit')),
                permission: ['app:general:recursoshumanos:gestiondeltalento:solicitudrecursoshumanos:edit'],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/gestiondeltalento/programacionesgestiondeltalento',
                component: loadable(() => import('views/hrm/procesos/reclutamiento/programacionEntrevistas/List')),
                permission: ['app:general:recursoshumanos:gestiondeltalento:programacionesgestiondeltalento:view'],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/gestiondeltalento/programacionesgestiondeltalento/new',
                component: loadable(() => import('views/hrm/procesos/reclutamiento/programacionEntrevistas/New')),
                permission: ['app:general:recursoshumanos:gestiondeltalento:programacionesgestiondeltalento:new'],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/gestiondeltalento/programacionesgestiondeltalento/edit/:id',
                component: loadable(() => import('views/hrm/procesos/reclutamiento/programacionEntrevistas/Edit')),
                permission: ['app:general:recursoshumanos:gestiondeltalento:programacionesgestiondeltalento:edit'],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/gestiondeltalento/evaluacioncandidatos',
                component: loadable(() => import('views/hrm/procesos/reclutamiento/evaluacionCandidatos/List')),
                permission: ['app:general:recursoshumanos:gestiondeltalento:evaluacioncandidatos:view'],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/recursoshumanos/seguimientoyretencion',
            routes: [
              {
                exact: true,
                path: '/app/recursoshumanos/seguimientoyretencion/ordenaprovisionamiento',
                component: loadable(() => import('views/hrm/procesos/seguimientoYretencion/ordenAprovicionamiento')),
                permission: ['app:general:recursoshumanos:seguimientoyretencion:ordenaprovisionamiento:view'],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/seguimientoyretencion/ordenaprovisionamiento/new',
                component: loadable(
                  () => import('views/hrm/procesos/seguimientoYretencion/ordenAprovicionamiento/New')
                ),
                permission: ['app:general:recursoshumanos:seguimientoyretencion:ordenaprovisionamiento:new'],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/seguimientoyretencion/ordenaprovisionamiento/edit/:id',
                component: loadable(
                  () => import('views/hrm/procesos/seguimientoYretencion/ordenAprovicionamiento/Edit')
                ),
                permission: ['app:general:recursoshumanos:seguimientoyretencion:ordenaprovisionamiento:edit'],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/seguimientoyretencion/programarcapacitaciones',
                component: loadable(() => import('views/hrm/procesos/seguimientoYretencion/capacitaciones/List')),
                permission: ['app:general:recursoshumanos:seguimientoyretencion:programarcapacitaciones:view'],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/seguimientoyretencion/programarcapacitaciones/new',
                component: loadable(() => import('views/hrm/procesos/seguimientoYretencion/capacitaciones/New')),
                permission: ['app:general:recursoshumanos:seguimientoyretencion:programarcapacitaciones:new'],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/seguimientoyretencion/programarcapacitaciones/:param/:id',
                component: loadable(() => import('views/hrm/procesos/seguimientoYretencion/capacitaciones/Edit')),
                permission: ['app:general:recursoshumanos:seguimientoyretencion:programarcapacitaciones:edit'],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/seguimientoyretencion/evaluarcapacitaciones',
                component: loadable(
                  () => import('views/hrm/procesos/seguimientoYretencion/evaluarCapacitaciones/List')
                ),
                permission: ['app:general:recursoshumanos:seguimientoyretencion:evaluarcapacitaciones:view'],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/seguimientoyretencion/evaluarcapacitaciones/edit/:id',
                component: loadable(
                  () => import('views/hrm/procesos/seguimientoYretencion/evaluarCapacitaciones/Edit')
                ),
                permission: ['app:general:recursoshumanos:seguimientoyretencion:evaluarcapacitaciones:edit'],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/seguimientoyretencion/programacionevaluaciondesempeno',
                component: loadable(
                  () => import('views/hrm/procesos/seguimientoYretencion/progEvaluacionDesempeno/List')
                ),
                permission: ['app:general:recursoshumanos:seguimientoyretencion:programacionevaluaciondesempeno:view']
              },
              {
                exact: true,
                path: '/app/recursoshumanos/seguimientoyretencion/programacionevaluaciondesempeno/new',
                component: loadable(
                  () => import('views/hrm/procesos/seguimientoYretencion/progEvaluacionDesempeno/New')
                ),
                permission: ['app:general:recursoshumanos:seguimientoyretencion:programacionevaluaciondesempeno:new']
              },
              {
                exact: true,
                path: '/app/recursoshumanos/seguimientoyretencion/programacionevaluaciondesempeno/edit/:id',
                component: loadable(
                  () => import('views/hrm/procesos/seguimientoYretencion/progEvaluacionDesempeno/Edit')
                ),
                permission: ['app:general:recursoshumanos:seguimientoyretencion:programacionevaluaciondesempeno:edit']
              },
              {
                exact: true,
                path: '/app/recursoshumanos/seguimientoyretencion/evaluaciondesempeno',
                component: loadable(
                  () => import('views/hrm/procesos/seguimientoYretencion/evaluacionDesempeno')
                ),
                permission: ['app:general:recursoshumanos:seguimientoyretencion:evaluaciondesempeno:view']
              },
              {
                exact: true,
                path: '/app/recursoshumanos/seguimientoyretencion/evaluaciondesempeno/calificacion/:id',
                component: loadable(() => import(
                  'views/hrm/procesos/seguimientoYretencion/evaluacionDesempeno/UpdateView'
                )),
                permission: ['rrhh:seguimientoyretencion:evaluaciondesempeno:update']
              },
              {
                exact: true,
                path: '/app/recursoshumanos/seguimientoyretencion/evaluaciondesempeno/view/:id/:evaluadoId',
                component: loadable(() => import(
                  'views/hrm/procesos/seguimientoYretencion/evaluacionDesempeno/GraphicsView'
                )),
                permission: ['rrhh:seguimientoyretencion:evaluaciondesempeno:view']
              },
              {
                exact: true,
                path: '/app/recursoshumanos/seguimientoyretencion/plandesempenoindividual',
                component: loadable(() => import('views/hrm/procesos/seguimientoYretencion/planDesempeno')),
                permission: ['app:general:recursoshumanos:seguimientoyretencion:plandesempenoindividual:view']
              },
              {
                exact: true,
                path: '/app/recursoshumanos/seguimientoyretencion/plandesempenoindividual/new/:planeado/:id',
                component: loadable(() => import('views/hrm/procesos/seguimientoYretencion/planDesempeno/New')),
                permission: ['rrhh:seguimientoyretencion:plandesempenoindividual:create']
              },
              {
                exact: true,
                path: '/app/recursoshumanos/seguimientoyretencion/plandesempenoindividual/edit/:id',
                component: loadable(() => import('views/hrm/procesos/seguimientoYretencion/planDesempeno/Edit')),
                permission: ['rrhh:seguimientoyretencion:plandesempenoindividual:view']
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/recursoshumanos/procesosdisciplinarios',
            routes: [
              {
                exact: true,
                path: '/app/recursoshumanos/procesosdisciplinarios/procesodisciplinario',
                component: loadable(() => import('views/hrm/procesos/seguimientoYretencion/procesoDisciplinario/List')),
                permission: ['app:general:recursoshumanos:procesosdisciplinarios:procesodisciplinario:view']
              },
              {
                exact: true,
                path: '/app/recursoshumanos/procesosdisciplinarios/procesodisciplinario/new',
                component: loadable(() => import('views/hrm/procesos/seguimientoYretencion/procesoDisciplinario/New')),
                permission: ['app:general:recursoshumanos:procesosdisciplinarios:procesodisciplinario:new']
              },
              {
                exact: true,
                path: '/app/recursoshumanos/procesosdisciplinarios/procesodisciplinario/edit/:id',
                component: loadable(() => import('views/hrm/procesos/seguimientoYretencion/procesoDisciplinario/Edit')),
                permission: ['app:general:recursoshumanos:procesosdisciplinarios:procesodisciplinario:edit']
              },
              /*
              {
                exact: true,
                path: '/app/rrhh/seguimientoyretencion/procesosdisciplinarios/llamadosatencion',
                component: loadable(
                  () => import('views/hrm/procesos/seguimientoYretencion/llamadosAtencion/List')),
                permission: ['rrhh:seguimientoyretencion:llamadoatencion:list'],
              },
              {
                exact: true,
                path: '/app/rrhh/seguimientoyretencion/procesosdisciplinarios/llamadosatencion/new',
                component: loadable(
                  () => import('views/hrm/procesos/seguimientoYretencion/llamadosAtencion/New')),
                permission: ['rrhh:seguimientoyretencion:llamadoatencion:create'],
              },
              {
                exact: true,
                path:
                '/app/rrhh/seguimientoyretencion/procesosdisciplinarios/llamadosatencion/edit/:id',
                component: loadable(
                  () => import('views/hrm/procesos/seguimientoYretencion/llamadosAtencion/Edit')),
                permission: ['rrhh:seguimientoyretencion:llamadoatencion:update'],
              },
              {
                exact: true,
                path: '/app/rrhh/seguimientoyretencion/procesosdisciplinarios/descargos',
                component: loadable(
                  () => import('views/hrm/procesos/seguimientoYretencion/descargos')),
                permission: ['rrhh:seguimientoyretencion:descargos:list'],
              },
              {
                exact: true,
                path: '/app/rrhh/seguimientoyretencion/procesosdisciplinarios/descargos/new',
                component: loadable(
                  () => import('views/hrm/procesos/seguimientoYretencion/descargos/New')),
                permission: ['rrhh:seguimientoyretencion:descargos:create'],
              },
              {
                exact: true,
                path: '/app/rrhh/seguimientoyretencion/procesosdisciplinarios/descargos/edit/:id',
                component: loadable(
                  () => import('views/hrm/procesos/seguimientoYretencion/descargos/Edit')),
                permission: ['rrhh:seguimientoyretencion:descargos:update'],
              },
              {
                exact: true,
                path: '/app/rrhh/seguimientoyretencion/procesosdisciplinarios/suspenciones',
                component: loadable(
                  () => import('views/hrm/procesos/seguimientoYretencion/suspencion/List')),
                permission: ['rrhh:seguimientoyretencion:suspenciones:list']
              },
              {
                exact: true,
                path: '/app/rrhh/seguimientoyretencion/procesosdisciplinarios/suspenciones/new',
                component: loadable(
                  () => import('views/hrm/procesos/seguimientoYretencion/suspencion/New')),
                permission: ['rrhh:seguimientoyretencion:suspenciones:create']
              },
              {
                exact: true,
                path:
                '/app/rrhh/seguimientoyretencion/procesosdisciplinarios/suspenciones/edit/:id',
                component: loadable(
                  () => import('views/hrm/procesos/seguimientoYretencion/suspencion/Edit')),
                permission: ['rrhh:seguimientoyretencion:suspenciones:update']
              },
              */
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/recursoshumanos/bienestarlaboral',
            routes: [
              {
                exact: true,
                path: '/app/recursoshumanos/bienestarlaboral/solicitudesbeneficios',
                component: loadable(() => import('views/hrm/procesos/solicitudesBeneficios/List'), fallBack),
                permission: ['app:general:recursoshumanos:bienestarlaboral:solicitudesbeneficios:view'],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/bienestarlaboral/solicitudesbeneficios/new',
                component: loadable(() => import('views/hrm/procesos/solicitudesBeneficios/New'), fallBack),
                permission: ['app:general:recursoshumanos:bienestarlaboral:solicitudesbeneficios:new'],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/bienestarlaboral/solicitudesbeneficios/edit/:id',
                component: loadable(() => import('views/hrm/procesos/solicitudesBeneficios/Edit'), fallBack),
                permission: ['app:general:recursoshumanos:bienestarlaboral:solicitudesbeneficios:edit'],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/bienestarlaboral/bonificaciones',
                component: loadable(() => import('views/hrm/procesos/bonificaciones/List'), fallBack),
                permission: ['app:general:recursoshumanos:bienestarlaboral:bonificaciones:view'],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/bienestarlaboral/bonificaciones/new',
                component: loadable(() => import('views/hrm/procesos/bonificaciones/New'), fallBack),
                permission: ['app:general:recursoshumanos:bienestarlaboral:bonificaciones:new'],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/bienestarlaboral/bonificaciones/edit/:id',
                component: loadable(() => import('views/hrm/procesos/bonificaciones/Edit'), fallBack),
                permission: ['app:general:recursoshumanos:bienestarlaboral:bonificaciones:edit'],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/recursoshumanos/gestiondeltiempo',
            routes: [
              {
                path: '/app/recursoshumanos/gestiondeltiempo/tareas',
                routes: [
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/tareas/asignaciondetareas',
                    component: loadable(
                      () => import('views/hrm/procesos/gestionTiempo/asignacionTareas/List'),
                      fallBack
                    ),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:tareas:asignaciondetareas:view'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/tareas/asignaciondetareas/new',
                    component: loadable(
                      () => import('views/hrm/procesos/gestionTiempo/asignacionTareas/New'),
                      fallBack
                    ),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:tareas:asignaciondetareas:new'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/tareas/:tipo/edit/:id',
                    component: loadable(
                      () => import('views/hrm/procesos/gestionTiempo/asignacionTareas/Edit'),
                      fallBack
                    ),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:tareas:asignaciondetareas:edit'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/tareas/gestiondetareas',
                    component: loadable(() => import('views/hrm/procesos/gestionTiempo/gestionTareas/List'), fallBack),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:tareas:gestiondetareas:view'],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                path: '/app/recursoshumanos/gestiondeltiempo/gestiondeltiempo',
                routes: [
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/gestiondeltiempo/programacionturnos',
                    component: loadable(
                      () => import('views/hrm/procesos/programacionTurnos/List')
                    ),
                    permission: ['app:general:recursoshumanos:'
                      + 'gestiondeltiempo:gestiondeltiempo:programacionturnos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/gestiondeltiempo/programacionturnos/new',
                    component: loadable(
                      () => import('views/hrm/procesos/programacionTurnos/New')
                    ),
                    permission: ['app:general:recursoshumanos:'
                      + 'gestiondeltiempo:gestiondeltiempo:programacionturnos:new'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/gestiondeltiempo/programacionturnos/edit/:id',
                    component: loadable(
                      () => import('views/hrm/procesos/programacionTurnos/Edit')
                    ),
                    permission: ['app:general:recursoshumanos:'
                      + 'gestiondeltiempo:gestiondeltiempo:programacionturnos:edit'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/gestiondeltiempo/reportedetiempo',
                    component: loadable(() => import('views/hrm/procesos/gestionTiempo/reporteTiempo/List'), fallBack),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:gestiondeltiempo:reportedetiempo:view'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/gestiondeltiempo/reportedetiempo/new',
                    component: loadable(() => import('views/hrm/procesos/gestionTiempo/reporteTiempo/New'), fallBack),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:gestiondeltiempo:reportedetiempo:new'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/gestiondeltiempo/reportedetiempo/edit/:id',
                    component: loadable(() => import('views/hrm/procesos/gestionTiempo/reporteTiempo/Edit'), fallBack),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:gestiondeltiempo:reportedetiempo:edit'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/gestiondeltiempo/solicitudmodalidadtrabajo',
                    component: loadable(
                      () => import('views/hrm/procesos/gestionTiempo/solicitudModalidadTrabajo/List'),
                      fallBack
                    ),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:gestiondeltiempo:'
                      + 'solicitudmodalidaddetrabajo:view'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/gestiondeltiempo/solicitudmodalidadtrabajo/new',
                    component: loadable(
                      () => import('views/hrm/procesos/gestionTiempo/solicitudModalidadTrabajo/New'),
                      fallBack
                    ),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:gestiondeltiempo:'
                      + 'solicitudmodalidaddetrabajo:new'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/gestiondeltiempo/solicitudmodalidadtrabajo/:param/:id',
                    component: loadable(
                      () => import('views/hrm/procesos/gestionTiempo/solicitudModalidadTrabajo/Edit'),
                      fallBack
                    ),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:gestiondeltiempo:'
                      + 'solicitudmodalidaddetrabajo:edit'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/gestiondeltiempo/reportehorasextras',
                    component: loadable(() => import('views/hrm/procesos/reporteHorasExtras/List'), fallBack),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:'
                      + 'gestiondeltiempo:reportehorasextras:view'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/gestiondeltiempo/reportehorasextras/new',
                    component: loadable(() => import('views/hrm/procesos/reporteHorasExtras/New'), fallBack),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:'
                      + 'gestiondeltiempo:reportehorasextras:new'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/gestiondeltiempo/reportehorasextras/edit/:id',
                    component: loadable(() => import('views/hrm/procesos/reporteHorasExtras/Edit'), fallBack),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:'
                      + 'gestiondeltiempo:reportehorasextras:edit'],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                path: '/app/recursoshumanos/gestiondeltiempo/ausencias',
                routes: [
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/ausencias/solicitudespermisos',
                    component: loadable(() => import('views/hrm/procesos/solicitudesPermisos/List'), fallBack),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:ausencias:solicitudespermisos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/ausencias/solicitudespermisos/new',
                    component: loadable(() => import('views/hrm/procesos/solicitudesPermisos/New'), fallBack),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:ausencias:solicitudespermisos:new'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/ausencias/solicitudespermisos/edit/:id',
                    component: loadable(() => import('views/hrm/procesos/solicitudesPermisos/Edit'), fallBack),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:ausencias:solicitudespermisos:edit'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/ausencias/solicitudesvacaciones',
                    component: loadable(() => import('views/hrm/procesos/solicitudesVacaciones/List'), fallBack),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:ausencias:solicitudesvacaciones:view'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/ausencias/solicitudesvacaciones/new',
                    component: loadable(() => import('views/hrm/procesos/solicitudesVacaciones/New'), fallBack),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:ausencias:solicitudesvacaciones:new'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/ausencias/solicitudesvacaciones/edit/:id',
                    component: loadable(() => import('views/hrm/procesos/solicitudesVacaciones/Edit'), fallBack),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:ausencias:solicitudesvacaciones:edit'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/ausencias/incapacidadesmedicas',
                    component: loadable(() => import('views/hrm/procesos/incapacidadesMedicas/List'), fallBack),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:ausencias:incapacidadesmedicas:view'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/ausencias/incapacidadesmedicas/new',
                    component: loadable(() => import('views/hrm/procesos/incapacidadesMedicas/New'), fallBack),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:ausencias:incapacidadesmedicas:new'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/ausencias/incapacidadesmedicas/edit/:id',
                    component: loadable(() => import('views/hrm/procesos/incapacidadesMedicas/Edit'), fallBack),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:ausencias:incapacidadesmedicas:edit'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/ausencias/registroslicencias',
                    component: loadable(() => import('views/hrm/procesos/registrosLicencias/List'), fallBack),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:ausencias:registrolicencias:view'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/ausencias/registroslicencias/new',
                    component: loadable(() => import('views/hrm/procesos/registrosLicencias/New'), fallBack),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:ausencias:registrolicencias:new'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/gestiondeltiempo/ausencias/registroslicencias/edit/:id',
                    component: loadable(() => import('views/hrm/procesos/registrosLicencias/Edit'), fallBack),
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:ausencias:registrolicencias:edit'],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/recursoshumanos/nomina',
            routes: [
              {
                path: '/app/recursoshumanos/nomina/liquidaciones',
                routes: [
                  {
                    exact: true,
                    path: '/app/recursoshumanos/nomina/liquidaciones/liquidacionvacaciones',
                    component: loadable(() => import('views/hrm/nomina/procesos/liquidacionesVacaciones/List')),
                    permission: ['app:general:recursoshumanos:nomina:liquidaciones:liquidacionvacaciones:view'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/nomina/liquidaciones/liquidacionvacaciones/new',
                    component: loadable(() => import('views/hrm/nomina/procesos/liquidacionesVacaciones/New')),
                    permission: ['app:general:recursoshumanos:nomina:liquidaciones:liquidacionvacaciones:new'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/nomina/liquidaciones/liquidacionvacaciones/edit/:id',
                    component: loadable(() => import('views/hrm/nomina/procesos/liquidacionesVacaciones/Edit')),
                    permission: ['app:general:recursoshumanos:nomina:liquidaciones:liquidacionvacaciones:edit'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/nomina/liquidaciones/liquidacionprimadeservicios',
                    component: loadable(() => import('views/hrm/nomina/procesos/liquidacionPrimaServicios/List')),
                    permission: ['app:general:recursoshumanos:nomina:liquidaciones:liquidacionprimadeservicios:view'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/nomina/liquidaciones/liquidacionprimadeservicios/new',
                    component: loadable(() => import('views/hrm/nomina/procesos/liquidacionPrimaServicios/New')),
                    permission: ['app:general:recursoshumanos:nomina:liquidaciones:liquidacionprimadeservicios:new'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/nomina/liquidaciones/liquidacionprimadeservicios/edit/:id',
                    component: loadable(() => import('views/hrm/nomina/procesos/liquidacionPrimaServicios/Edit')),
                    permission: ['app:general:recursoshumanos:nomina:liquidaciones:liquidacionprimadeservicios:edit'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/nomina/liquidaciones/liquidacionauxiliocesantias',
                    component: loadable(() => import('views/hrm/nomina/procesos/liquidacionesAuxilioCesantias/List')),
                    permission: ['app:general:recursoshumanos:nomina:liquidaciones:liquidacionauxiliocesantias:view'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/nomina/liquidaciones/liquidacionauxiliocesantias/new',
                    component: loadable(() => import('views/hrm/nomina/procesos/liquidacionesAuxilioCesantias/New')),
                    permission: ['app:general:recursoshumanos:nomina:liquidaciones:liquidacionauxiliocesantias:new'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/nomina/liquidaciones/liquidacionauxiliocesantias/edit/:id',
                    component: loadable(() => import('views/hrm/nomina/procesos/liquidacionesAuxilioCesantias/Edit')),
                    permission: ['app:general:recursoshumanos:nomina:liquidaciones:liquidacionauxiliocesantias:edit'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/nomina/liquidaciones/liquidacioninterescesantias',
                    component: loadable(() => import('views/hrm/nomina/procesos/liquidacionesInteresesCesantias/List')),
                    permission: ['app:general:recursoshumanos:nomina:liquidaciones:liquidacioninterescesantias:view'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/nomina/liquidaciones/liquidacioninterescesantias/new',
                    component: loadable(() => import('views/hrm/nomina/procesos/liquidacionesInteresesCesantias/New')),
                    permission: ['app:general:recursoshumanos:nomina:liquidaciones:liquidacioninterescesantias:new'],
                  },
                  {
                    exact: true,
                    path: '/app/recursoshumanos/nomina/liquidaciones/liquidacioninterescesantias/edit/:id',
                    component: loadable(() => import('views/hrm/nomina/procesos/liquidacionesInteresesCesantias/Edit')),
                    permission: ['app:general:recursoshumanos:nomina:liquidaciones:liquidacioninterescesantias:edit'],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/nomina/comprobantesnomina',
                component: loadable(() => import('views/hrm/nomina/procesos/comprobanteNomina/List'), fallBack),
                permission: ['app:general:recursoshumanos:nomina:liquidaciones:comprobantesnomina:view'],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/nomina/comprobantesnomina/new',
                component: loadable(() => import('views/hrm/nomina/procesos/comprobanteNomina/New'), fallBack),
                permission: ['app:general:recursoshumanos:nomina:liquidaciones:comprobantesnomina:new'],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/nomina/comprobantesnomina/edit/:id',
                component: loadable(() => import('views/hrm/nomina/procesos/comprobanteNomina/Edit'), fallBack),
                permission: ['app:general:recursoshumanos:nomina:liquidaciones:comprobantesnomina:edit'],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/nomina/colillasdepago',
                component: loadable(() => import('views/hrm/nomina/procesos/collilasPagos/List'), fallBack),
                // Falta por permisos
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/recursoshumanos/certificados',
            routes: [
              {
                exact: true,
                path: '/app/recursoshumanos/certificados/certificadoslaborales',
                component: loadable(() => import(
                  'views/hrm/procesos/certificados/certificadosLaborales/List'
                ), fallBack),
                // Falta por permisos
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/recursoshumanos/provisiones',
            routes: [
              {
                exact: true,
                path: '/app/recursoshumanos/provisiones/provisiones',
                component: loadable(() => import('views/hrm/procesos/provisiones/provision/List'), fallBack),
                // Falta por permisos
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/recursoshumanos/desvinculacionlaboral',
            routes: [
              {
                exact: true,
                path: '/app/recursoshumanos/desvinculacionlaboral/desvinculacionlaboral',
                component: loadable(() => import(
                  'views/hrm/procesos/desvinculacionLaboral/desvinculacionLaboral/List'
                )),
                // Falta por permisos
              },
              {
                exact: true,
                path: '/app/recursoshumanos/desvinculacionlaboral/desvinculacionlaboral/new',
                component: loadable(() => import('views/hrm/procesos/desvinculacionLaboral/desvinculacionLaboral/New')),
                // Falta por permisos
              },
              {
                exact: true,
                path: '/app/recursoshumanos/desvinculacionlaboral/desvinculacionlaboral/edit/:id',
                component: loadable(() => import(
                  'views/hrm/procesos/desvinculacionLaboral/desvinculacionLaboral/Edit'
                )),
                // Falta por permisos
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/recursoshumanos/saludyseguridadeneltrabajo',
            routes: [
              {
                exact: true,
                path: '/app/recursoshumanos/saludyseguridadeneltrabajo/identificacionevaluacionriesgos',
                component: loadable(() => import('views/sst/procesos/identificacionEvaluacionRiesgo/List'), fallBack),
                // Falta por permisos
              },
              {
                exact: true,
                path: '/app/recursoshumanos/saludyseguridadeneltrabajo/identificacionevaluacionriesgos/new',
                component: loadable(() => import('views/sst/procesos/identificacionEvaluacionRiesgo/New'), fallBack),
              },
              {
                exact: true,
                path: '/app/recursoshumanos/saludyseguridadeneltrabajo/identificacionevaluacionriesgos/edit/:id',
                component: loadable(() => import('views/sst/procesos/identificacionEvaluacionRiesgo/Edit'), fallBack),
              },
              {
                exact: true,
                path: '/app/recursoshumanos/saludyseguridadeneltrabajo/registrodeaccidenteslaborales',
                component: loadable(() => import('views/sst/procesos/registroAccidentesLaborales/List'), fallBack),
                // Falta por permisos
              },
              {
                exact: true,
                path: '/app/recursoshumanos/saludyseguridadeneltrabajo/registrodeaccidenteslaborales/new',
                component: loadable(() => import('views/sst/procesos/registroAccidentesLaborales/New'), fallBack),
              },
              {
                exact: true,
                path: '/app/recursoshumanos/saludyseguridadeneltrabajo/registrodeaccidenteslaborales/edit/:id',
                component: loadable(() => import('views/sst/procesos/registroAccidentesLaborales/Edit'), fallBack),
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/recursoshumanos/gestiondeacceso',
            routes: [
              {
                exact: true,
                path: '/app/recursoshumanos/gestiondeacceso/accesosempleados',
                component: loadable(() => import('views/registroAcceso/accesosEmpleados'), fallBack),
                permission: ['app:general:recursoshumanos:gestiondeacceso:accesoempleados:view'],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/recursoshumanos/consultas/gestiondeltalento',
            routes: [
              {
                exact: true,
                path: '/app/recursoshumanos/consultas/gestiondeltalento/vacantesempleo',
                component: loadable(() => import('views/hrm/procesos/reclutamiento/consultas/vacantesEmpleo/List')),
                permission: ['app:general:recursoshumanos:consultas:gestiondeltalento:vacantesempleo:view'],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/consultas/gestiondeltalento/vacantesempleo/view/:id',
                component: loadable(() => import('views/hrm/procesos/reclutamiento/consultas/vacantesEmpleo/View')),
              },
              {
                exact: true,
                path: '/app/recursoshumanos/consultas/gestiondeltalento/solicitudrecursoshumanos',
                component: loadable(() => import('views/hrm/consultas/solicitudRecursosHumanos/List'), fallBack),
                permission: ['app:general:recursoshumanos:consultas:gestiondeltalento:solicitudrecursoshumanos:view'],
              },
              {
                exact: true,
                path: '/app/recursoshumanos/consultas/gestiondeltalento/evaluacioncandidatos',
                component: loadable(() => import('views/hrm/consultas/evaluacionCandidatos/List'), fallBack),
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        path: '/app/gobiernocorporativo',
        routes: [
          {
            path: '/app/gobiernocorporativo/gestionempresarial',
            routes: [
              {
                exact: true,
                path: '/app/gobiernocorporativo/gestionempresarial/gestionempresarial',
                component: loadable(
                  () => import('views/gobiernoCorporativo/gestionEmpresarial/procesos/gestionEmpresarial/List'),
                  fallBack
                ),
                permission: ['app:general:gobiernocorporativo:gestionempresarial:gestionempresarial:view'],
              },
              {
                exact: true,
                path: '/app/gobiernocorporativo/gestionempresarial/gestionempresarial/new',
                component: loadable(
                  () => import('views/gobiernoCorporativo/gestionEmpresarial/procesos/gestionEmpresarial/New'),
                  fallBack
                ),
                permission: ['app:general:gobiernocorporativo:gestionempresarial:gestionempresarial:new'],
              },
              {
                exact: true,
                path: '/app/gobiernocorporativo/gestionempresarial/gestionempresarial/:param/:id',
                component: loadable(
                  () => import('views/gobiernoCorporativo/gestionEmpresarial/procesos/gestionEmpresarial/Edit'),
                  fallBack
                ),
                permission: ['app:general:gobiernocorporativo:gestionempresarial:gestionempresarial:edit'],
              },
              {
                exact: true,
                path: '/app/gobiernocorporativo/gestionempresarial/reglamentointernodetrabajo',
                component: loadable(
                  () => import('views/gobiernoCorporativo/gestionEmpresarial/procesos/reglamentoInternoTrabajo/List'),
                  fallBack
                ),
                permission: ['app:general:gobiernocorporativo:gestionempresarial:reglamentointernodetrabajo:view'],
              },
              {
                exact: true,
                path: '/app/gobiernocorporativo/gestionempresarial/reglamentointernodetrabajo/new',
                component: loadable(
                  () => import('views/gobiernoCorporativo/gestionEmpresarial/procesos/reglamentoInternoTrabajo/New'),
                  fallBack
                ),
                permission: ['app:general:gobiernocorporativo:gestionempresarial:reglamentointernodetrabajo:new'],
              },
              {
                exact: true,
                path: '/app/gobiernocorporativo/gestionempresarial/reglamentointernodetrabajo/edit/:id',
                component: loadable(
                  () => import('views/gobiernoCorporativo/gestionEmpresarial/procesos/reglamentoInternoTrabajo/Edit'),
                  fallBack
                ),
                permission: ['app:general:gobiernocorporativo:gestionempresarial:reglamentointernodetrabajo:edit'],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            exact: true,
            path: '/app/gobiernocorporativo/organigrama',
            component: loadable(() => import('views/gobiernoCorporativo/procesos/organigrama'), fallBack),
            permission: ['app:general:gobiernocorporativo:organigrama:view'],
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        path: '/app/gestiondocumental',
        routes: [
          {
            exact: true,
            path: '/app/gestiondocumental/politicas',
            component: loadable(
              () => import('views/gobiernoCorporativo/gestionEmpresarial/maestros/politicas/List'),
              fallBack
            ),
            permission: ['app:general:gestiondocumental:politicas:view'],
          },
          {
            exact: true,
            path: '/app/gestiondocumental/politicas/new',
            component: loadable(
              () => import('views/gobiernoCorporativo/gestionEmpresarial/maestros/politicas/New'),
              fallBack
            ),
            permission: ['app:general:gestiondocumental:politicas:new'],
          },
          {
            exact: true,
            path: '/app/gestiondocumental/politicas/edit/:id',
            component: loadable(
              () => import('views/gobiernoCorporativo/gestionEmpresarial/maestros/politicas/Edit'),
              fallBack
            ),
            permission: ['app:general:gestiondocumental:politicas:edit'],
          },
          {
            exact: true,
            path: '/app/gestiondocumental/manualescorporativos',
            component: loadable(
              () => import('views/gobiernoCorporativo/gestionEmpresarial/maestros/manualesCorporativos/List'),
              fallBack
            ),
            permission: ['app:general:gestiondocumental:manualescorporativos:view'],
          },
          {
            exact: true,
            path: '/app/gestiondocumental/manualescorporativos/new',
            component: loadable(
              () => import('views/gobiernoCorporativo/gestionEmpresarial/maestros/manualesCorporativos/New'),
              fallBack
            ),
            permission: ['app:general:gestiondocumental:manualescorporativos:new'],
          },
          {
            exact: true,
            path: '/app/gestiondocumental/manualescorporativos/edit/:id',
            component: loadable(
              () => import('views/gobiernoCorporativo/gestionEmpresarial/maestros/manualesCorporativos/Edit'),
              fallBack
            ),
            permission: ['app:general:gestiondocumental:manualescorporativos:edit'],
          },
          {
            exact: true,
            path: '/app/gestiondocumental/procedimientos',
            component: loadable(() => import('views/gestionDocumental/procesos/procedimientos/List'), fallBack),
            // Falta por permisos
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        path: '/app/gestiondecontratos',
        routes: [
          {
            exact: true,
            path: '/app/gestiondecontratos/contratos',
            component: loadable(() => import('views/gestionContratos/procesos/solicitudContratos/List')),
            permission: ['app:general:gestiondecontratos:contratos:view'],
          },
          {
            exact: true,
            path: '/app/gestiondecontratos/contratos/new',
            component: loadable(() => import('views/gestionContratos/procesos/solicitudContratos/New')),
            permission: ['app:general:gestiondecontratos:contratos:new'],
          },
          {
            exact: true,
            path: '/app/gestiondecontratos/contratos/edit/:id',
            component: loadable(() => import('views/gestionContratos/procesos/solicitudContratos/Edit')),
            permission: ['app:general:gestiondecontratos:contratos:edit'],
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        path: '/app/gestionsolicitudes',
        routes: [
          {
            exact: true,
            path: '/app/gestionsolicitudes/gestionsolicitudespqrsf',
            component: loadable(() => import('views/calidad/procesos/gestionSolicitudes/List'), fallBack),
            permission: ['app:general:gestionsolicitudes:gestionsolicitudespqrsf:view'],
          },
          {
            exact: true,
            path: '/app/gestionsolicitudes/gestionsolicitudespqrsf/new',
            component: loadable(() => import('views/calidad/procesos/gestionSolicitudes/New'), fallBack),
            permission: ['app:general:gestionsolicitudes:gestionsolicitudespqrsf:new'],
          },
          {
            exact: true,
            path: '/app/gestionsolicitudes/gestionsolicitudespqrsf/:tipo/:id',
            component: loadable(() => import('views/calidad/procesos/gestionSolicitudes/Edit'), fallBack),
            permission: ['app:general:gestionsolicitudes:gestionsolicitudespqrsf:edit'],
          },
          {
            exact: true,
            path: '/app/gestionsolicitudes/seguimientosolicitudes',
            component: loadable(() => import('views/calidad/procesos/seguimientoSolicitudes/List')),
            permission: ['app:general:gestionsolicitudes:seguimientosolicitudes:view'],
          },
          {
            exact: true,
            path: '/app/gestionsolicitudes/cierresolicitudes',
            component: loadable(() => import('views/calidad/procesos/cierreSolicitudes/List')),
            permission: ['app:general:gestionsolicitudes:gestionsolicitudespqrsf:edit'],
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        path: '/app/centrodeayuda',
        routes: [
          {
            exact: true,
            path: '/app/centrodeayuda/sugerencias',
            component: loadable(() => import('views/centroAyuda/procesos/sugerencias/List')),
            permission: ['app:general:centrodeayuda:sugerencias:view'],
          },
          {
            exact: true,
            path: '/app/centrodeayuda/agendardemostracion',
            component: loadable(() => import('views/centroAyuda/procesos/agendarDemostracion/List')),
            permission: ['app:general:centrodeayuda:agendardemostracion:view'],
          },
          {
            exact: true,
            path: '/app/centrodeayuda/enviarpreguntas',
            component: loadable(() => import('views/centroAyuda/procesos/enviarPreguntas/List')),
            permission: ['app:general:centrodeayuda:enviarpreguntas:view'],
          },
          {
            exact: true,
            path: '/app/centrodeayuda/consultas/preguntasfrecuentes',
            component: loadable(() => import('views/centroAyuda/consultas/PreguntasFrecuentes/List')),
            permission: ['app:general:centrodeayuda:consultas:preguntasfrecuentes:view'],
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      /*
      {
        path: '/app/scm',
        routes: [
          {
            path: '/app/scm/inventario',
            routes: [
              {
                path: '/app/scm/inventario/generales',
                routes: [
                  {
                    exact: true,
                    path: '/app/scm/inventario/generales/solicitudestraslados',
                    component: loadable(() =>
                    import(
                      'views/scm/inventario/productoTerminado/procesos/solicitudesTraslado/List'
                      ), fallBack),
                    permission: ['scm:inventario:generales:solicitudestraslado:list'],
                  },
                  {
                    path: '/app/scm/inventario/generales/informes',
                    routes: [
                      {
                        component: () => <Redirect to="/404" />,
                      },
                    ],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/scm/logistica/',
            routes: [
              {
                exact: true,
                path: '/app/scm/logistica/solicitudeslogisticas',
                component: loadable(() =>
                import('views/scm/wms/procesos/solicitudLogistica/List'), fallBack),
                permission: ['scm:logistica:solicitudeslogisticas:list'],
              },
              {
                exact: true,
                path: '/app/scm/logistica/solicitudeslogisticas/new',
                component: loadable(() =>
                import('views/scm/wms/procesos/solicitudLogistica/New'), fallBack),
              },
              {
                exact: true,
                path: '/app/scm/logistica/solicitudeslogisticas/edit/:id/:abreviaturaTipo',
                component: loadable(() =>
                import('views/scm/wms/procesos/solicitudLogistica/Edit'), fallBack),
              },
              {
                exact: true,
                path: '/app/scm/logistica/transporteterceros',
                component: loadable(() =>
                import('views/scm/wms/procesos/transporteTercero/List'), fallBack),
                permission: ['scm:logistica:transporteterceros:list'],
              },
              {
                path: '/app/logistica/logisticaentrada',
                routes: [
                  {
                    exact: true,
                    path: '/app/logistica/logisticaentrada/recepcion',
                    component: loadable(() => import('views/scm/wms/procesos/recepcion'), fallBack),
                    permission: ['scm:logistica:entradas:recepciones:list'],
                  },
                  {
                    exact: true,
                    path: '/app/scm/logistica/entrada/recepcion/new/:documentoId',
                    component: loadable(() =>
                    import('views/scm/wms/procesos/recepcion/New'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/scm/logistica/entrada/recepcion/edit/:id/:tipoRecepcion',
                    component: loadable(() =>
                    import('views/scm/wms/procesos/recepcion/Edit'), fallBack),
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                path: '/app/scm/logistica/salida',
                routes: [
                  {
                    exact: true,
                    path: '/app/scm/logistica/salida/programacionpicking',
                    component: loadable(() =>
                    import('views/scm/wms/procesos/programacionPickingExistencias/List'), fallBack),
                    permission: ['scm:logistica:salidas:programacionpicking:list'],
                  },
                  {
                    exact: true,
                    path: '/app/scm/logistica/salida/programacionpicking/edit/:id',
                    component: loadable(() =>
                    import('views/scm/wms/procesos/programacionPickingExistencias/Edit'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/scm/logistica/salida/picking',
                    component: loadable(() =>
                    import('views/scm/wms/procesos/recoleccion/List'), fallBack),
                    permission: ['scm:logistica:salidas:picking:list'],
                  },
                  {
                    exact: true,
                    path: '/app/scm/logistica/salida/picking/new',
                    component: loadable(() =>
                    import('views/scm/wms/procesos/picking/New'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/scm/logistica/salida/picking/edit/:id',
                    component: loadable(() =>
                    import('views/scm/wms/procesos/recoleccion/Edit'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/scm/logistica/salida/despacho',
                    component: loadable(() =>
                    import('views/scm/wms/procesos/despachos/List'), fallBack),
                    permission: ['scm:logistica:salidas:despachos:list'],
                  },
                  {
                    exact: true,
                    path: '/app/scm/logistica/salida/despacho/new',
                    component: loadable(() =>
                    import('views/scm/wms/procesos/despachos/New'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/scm/logistica/salida/despacho/edit/:id',
                    component: loadable(() =>
                    import('views/scm/wms/procesos/despachos/Edit'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/scm/logistica/salida/hojasderutas',
                    component: loadable(() =>
                    import('views/scm/wms/procesos/hojasRuta/List'), fallBack),
                    permission: ['scm:logistica:salidas:hojasderutas:list'],
                  },
                  {
                    exact: true,
                    path: '/app/scm/logistica/salida/hojasderutas/new',
                    component: loadable(() =>
                    import('views/scm/wms/procesos/hojasRuta/New'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/scm/logistica/salida/hojasderutas/edit/:id',
                    component: loadable(() =>
                    import('views/scm/wms/procesos/hojasRuta/Edit'), fallBack),
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      */
      {
        path: '/app/configuracion',
        routes: [
          {
            path: '/app/configuracion/rpdental',
            routes: [
              {
                exact: true,
                path: '/app/configuracion/rpdental/especialidad',
                component: loadable(() => import('views/rpDental/maestros/especialidad'), fallBack),
                permission: ['configuracion:rpdental:especialidades:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/rpdental/especialidad/:param',
                component: loadable(() => import('views/rpDental/maestros/especialidad'), fallBack),
              },
              {
                exact: true,
                path: '/app/configuracion/rpdental/diagnostico',
                component: loadable(() => import('views/rpDental/maestros/diagnostico/List'), fallBack),
                permission: ['configuracion:rpdental:diagnosticos:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/rpdental/diagnostico/edit/:id',
                component: loadable(() => import('views/rpDental/maestros/diagnostico/Edit'), fallBack),
              },
              {
                exact: true,
                path: '/app/configuracion/rpdental/diagnostico/new',
                component: loadable(() => import('views/rpDental/maestros/diagnostico/New'), fallBack),
              },
              {
                path: '/app/configuracion/rpdental/terceros',
                routes: [
                  {
                    exact: true,
                    path: '/app/configuracion/rpdental/terceros/instituciones',
                    component: loadable(() => import('views/rpDental/terceros/institucion/List'), fallBack),
                    permission: ['configuracion:rpdental:terceros:instituciones:list'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rpdental/terceros/instituciones/:id',
                    component: loadable(() => import('views/rpDental/terceros/institucion/List'), fallBack),
                  },

                  {
                    exact: true,
                    path: '/app/configuracion/rpdental/terceros/instituciones/:param',
                    component: loadable(() => import('views/rpDental/terceros/institucion'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rpdental/terceros/pacientes',
                    component: loadable(() => import('views/rpDental/terceros/pacientes/List'), fallBack),
                    permission: ['configuracion:rpdental:terceros:pacientes:list'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rpdental/terceros/pacientes/new',
                    component: loadable(() => import('views/rpDental/terceros/pacientes/New'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rpdental/terceros/pacientes/edit/:id',
                    component: loadable(() => import('views/rpDental/terceros/pacientes/Edit'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rpdental/terceros/cirujano',
                    component: loadable(() => import('views/rpDental/terceros/profesional/List'), fallBack),
                    permission: ['configuracion:rpdental:terceros:cirujanos:list'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rpdental/terceros/cirujano/new',
                    component: loadable(() => import('views/rpDental/terceros/profesional/New'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rpdental/terceros/cirujano/edit/:id',
                    component: loadable(() => import('views/rpDental/terceros/profesional/Edit'), fallBack),
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },

                ],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/configuracion/crm',
            routes: [
              {
                exact: true,
                path: '/app/configuracion/crm/consecutivoscrm',
                component: loadable(() => import('views/crm/maestros/consecutivos'), fallBack),
                permission: ['app:general:configuracion:crm:consecutivoscrm:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/consecutivoscrm/:param',
                component: loadable(() => import('views/crm/maestros/consecutivos'), fallBack),
                permission: ['app:general:configuracion:crm:consecutivoscrm:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/transaccionescrm',
                component: loadable(() => import('views/crm/maestros/documentos'), fallBack),
                permission: ['app:general:configuracion:crm:transaccionescrm:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/transaccionescrm/:param',
                component: loadable(() => import('views/crm/maestros/documentos'), fallBack),
                permission: ['app:general:configuracion:crm:transaccionescrm:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/prospectos',
                component: loadable(() => import('views/crm/maestros/prospectos/List'), fallBack),
                permission: ['app:general:configuracion:crm:prospectos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/prospectos/new',
                component: loadable(() => import('views/crm/maestros/prospectos/New'), fallBack),
                permission: ['app:general:configuracion:crm:prospectos:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/prospectos/edit/:id',
                component: loadable(() => import('views/crm/maestros/prospectos/Edit'), fallBack),
                permission: ['app:general:configuracion:crm:prospectos:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/clasificacionesclientes',
                component: loadable(() => import('views/crm/maestros/clasificacionCliente'), fallBack),
                permission: ['app:general:configuracion:crm:clasificacionesclientes:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/clasificacionesclientes/:param',
                component: loadable(() => import('views/crm/maestros/clasificacionCliente'), fallBack),
                permission: ['app:general:configuracion:crm:clasificacionesclientes:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/clientes',
                component: loadable(() => import('views/crm/maestros/cliente/List'), fallBack),
                permission: ['app:general:configuracion:crm:clientes:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/clientes/new',
                component: loadable(() => import('views/crm/maestros/cliente/New'), fallBack),
                permission: ['app:general:configuracion:crm:clientes:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/clientes/edit/:id',
                component: loadable(() => import('views/crm/maestros/cliente/Edit'), fallBack),
                permission: ['app:general:configuracion:crm:clientes:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/clientes/edit/:id/:tab',
                component: loadable(() => import('views/crm/maestros/cliente/Edit'), fallBack),
              },
              {
                exact: true,
                path: '/app/configuracion/crm/habeasdata',
                component: loadable(() => import('views/crm/maestros/habeasData'), fallBack),
                permission: ['app:general:configuracion:crm:habeasdata:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/habeasdata/:param',
                component: loadable(() => import('views/crm/maestros/habeasData'), fallBack),
                permission: ['app:general:configuracion:crm:habeasdata:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/medioscomunicacioncliente',
                component: loadable(() => import('views/crm/maestros/mediosComunicacionClientes'), fallBack),
                permission: ['app:general:configuracion:crm:medioscomunicacioncliente:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/medioscomunicacioncliente/:param',
                component: loadable(() => import('views/crm/maestros/mediosComunicacionClientes'), fallBack),
                permission: ['app:general:configuracion:crm:medioscomunicacioncliente:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/carguemasivo',
                component: loadable(() => import('views/crm/procesos/cargueMasivo/List'), fallBack),
                permission: ['app:general:configuracion:crm:cargarclientes:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/tiposlistasprecios',
                component: loadable(() => import('views/crm/maestros/tiposListasPrecios'), fallBack),
                permission: ['app:general:configuracion:crm:tiposlistasdeprecios:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/tiposlistasprecios/:param',
                component: loadable(() => import('views/crm/maestros/tiposListasPrecios'), fallBack),
                permission: ['app:general:configuracion:crm:tiposlistasdeprecios:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/listasdeprecios',
                component: loadable(() => import('views/crm/maestros/listasPrecios/List'), fallBack),
                permission: ['app:general:configuracion:crm:listasdeprecios:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/listasdeprecios/new',
                component: loadable(() => import('views/crm/maestros/listasPrecios/New'), fallBack),
                permission: ['app:general:configuracion:crm:listasdeprecios:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/listasdeprecios/edit/:id/:clasificacion',
                component: loadable(() => import('views/crm/maestros/listasPrecios/Edit'), fallBack),
                permission: ['app:general:configuracion:crm:listasdeprecios:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/cotizacion/tiposcotizacion',
                component: loadable(() => import('views/crm/maestros/tiposCotizaciones'), fallBack),
                permission: ['app:general:configuracion:crm:cotizacion:tiposcotizacion:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/cotizacion/tiposcotizacion/:param',
                component: loadable(() => import('views/crm/maestros/tiposCotizaciones'), fallBack),
                permission: ['app:general:configuracion:crm:cotizacion:tiposcotizacion:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/cotizacion/clasificacioncotizacion',
                component: loadable(() => import('views/crm/maestros/clasificacionCotizaciones'), fallBack),
                permission: ['app:general:configuracion:crm:cotizacion:clasificacioncotizacion:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/cotizacion/clasificacioncotizacion/:param',
                component: loadable(() => import('views/crm/maestros/clasificacionCotizaciones'), fallBack),
                permission: ['app:general:configuracion:crm:cotizacion:clasificacioncotizacion:view'],
              },
              /*
              {
                exact: true,
                path: '/app/configuracion/crm/temporadas',
                component: loadable(() => import('views/crm/maestros/temporadas/List'), fallBack),
                permission: ['configuracion:crm:temporadas:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/crm/temporadas/new',
                component: loadable(() => import('views/crm/maestros/temporadas/New'), fallBack),
              },
              {
                exact: true,
                path: '/app/configuracion/crm/temporadas/edit/:id',
                component: loadable(() => import('views/crm/maestros/temporadas/Edit'), fallBack),
              },
              */
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/configuracion/ventas',
            routes: [
              {
                exact: true,
                path: '/app/configuracion/ventas/consecutivosventas',
                component: loadable(() => import('views/venta/maestros/consecutivoVenta'), fallBack),
                permission: ['app:general:configuracion:ventas:consecutivosventas:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/ventas/consecutivosventas/:param',
                component: loadable(() => import('views/venta/maestros/consecutivoVenta'), fallBack),
                permission: ['app:general:configuracion:ventas:consecutivosventas:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/ventas/transaccionesventas',
                component: loadable(() => import('views/venta/maestros/documentoVenta'), fallBack),
                permission: ['app:general:configuracion:ventas:transaccionesventas:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/ventas/transaccionesventas/:param',
                component: loadable(() => import('views/venta/maestros/documentoVenta'), fallBack),
                permission: ['app:general:configuracion:ventas:transaccionesventas:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/ventas/cajasregistradoras',
                component: loadable(() => import('views/venta/maestros/cajasRegistradoras'), fallBack),
                permission: ['app:general:configuracion:ventas:cajasregistradoras:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/ventas/cajasregistradoras/new',
                component: loadable(() => import('views/venta/maestros/cajasRegistradoras/New'), fallBack),
                permission: ['app:general:configuracion:ventas:cajasregistradoras:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/ventas/cajasregistradoras/edit/:id',
                component: loadable(() => import('views/venta/maestros/cajasRegistradoras/Edit'), fallBack),
                permission: ['app:general:configuracion:ventas:cajasregistradoras:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/ventas/clasificacionesservicios',
                component: loadable(() => import('views/venta/maestros/clasificacionServicio'), fallBack),
                permission: ['app:general:configuracion:ventas:clasificacionesservicios:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/ventas/clasificacionesservicios/:param',
                component: loadable(() => import('views/venta/maestros/clasificacionServicio'), fallBack),
                permission: ['app:general:configuracion:ventas:clasificacionesservicios:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/ventas/servicios',
                component: loadable(() => import('views/venta/maestros/servicios/List'), fallBack),
                permission: ['app:general:configuracion:ventas:servicios:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/ventas/servicios/new',
                component: loadable(() => import('views/venta/maestros/servicios/New'), fallBack),
                permission: ['app:general:configuracion:ventas:servicios:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/ventas/servicios/edit/:id',
                component: loadable(() => import('views/venta/maestros/servicios/Edit'), fallBack),
                permission: ['app:general:configuracion:ventas:servicios:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/ventas/descuentos',
                component: loadable(() => import('views/venta/maestros/descuentos'), fallBack),
                permission: ['app:general:configuracion:ventas:descuentos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/ventas/descuentos/:param',
                component: loadable(() => import('views/venta/maestros/descuentos'), fallBack),
                permission: ['app:general:configuracion:ventas:descuentos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/ventas/operacionesdian',
                component: loadable(() => import('views/venta/maestros/operacionesDian'), fallBack),
                permission: ['app:general:configuracion:ventas:operacionesdian:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/ventas/operacionesdian/:param',
                component: loadable(() => import('views/venta/maestros/operacionesDian'), fallBack),
                permission: ['app:general:configuracion:ventas:operacionesdian:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/ventas/puntosventa',
                component: loadable(() => import('views/venta/maestros/puntosVenta'), fallBack),
                permission: ['app:general:configuracion:ventas:puntosdeventa:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/ventas/puntosventa/new',
                component: loadable(() => import('views/venta/maestros/puntosVenta/New'), fallBack),
                permission: ['app:general:configuracion:ventas:puntosdeventa:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/ventas/puntosventa/edit/:id',
                component: loadable(() => import('views/venta/maestros/puntosVenta/Edit'), fallBack),
                permission: ['app:general:configuracion:ventas:puntosdeventa:edit'],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/configuracion/gestionproveedores',
            routes: [
              {
                exact: true,
                path: '/app/configuracion/gestionproveedores/consecutivogestiondeproveedores',
                component: loadable(() => import('views/gestionProveedores/maestros/consecutivos'), fallBack),
                permission: ['app:general:configuracion:gestionproveedores:consecutivosgestiondeproveedores:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionproveedores/consecutivogestiondeproveedores/:param',
                component: loadable(() => import('views/gestionProveedores/maestros/consecutivos'), fallBack),
                permission: ['app:general:configuracion:gestionproveedores:consecutivosgestiondeproveedores:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionproveedores/transaccionesgestiondeproveedores',
                component: loadable(() => import('views/gestionProveedores/maestros/documentos'), fallBack),
                permission: ['app:general:configuracion:gestionproveedores:transaccionesgestiondeproveedores:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionproveedores/transaccionesgestiondeproveedores/:param',
                component: loadable(() => import('views/gestionProveedores/maestros/documentos'), fallBack),
                permission: ['app:general:configuracion:gestionproveedores:transaccionesgestiondeproveedores:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionproveedores/clasificacionesproveedores',
                component: loadable(() => import('views/scm/compra/maestros/clasificacionProveedor'), fallBack),
                permission: ['app:general:configuracion:gestionproveedores:clasificacionesproveedores:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionproveedores/clasificacionesproveedores/:param',
                component: loadable(() => import('views/scm/compra/maestros/clasificacionProveedor'), fallBack),
                permission: ['app:general:configuracion:gestionproveedores:clasificacionesproveedores:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionproveedores/proveedores',
                component: loadable(() => import('views/scm/compra/maestros/proveedores/List'), fallBack),
                permission: ['app:general:configuracion:gestionproveedores:proveedores:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionproveedores/proveedores/new',
                component: loadable(() => import('views/scm/compra/maestros/proveedores/New'), fallBack),
                permission: ['app:general:configuracion:gestionproveedores:proveedores:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionproveedores/proveedores/edit/:id',
                component: loadable(() => import('views/scm/compra/maestros/proveedores/Edit'), fallBack),
                permission: ['app:general:configuracion:gestionproveedores:proveedores:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionproveedores/requisitosproveedores',
                component: loadable(() => import('views/scm/compra/maestros/requisitoProveedor'), fallBack),
                permission: ['app:general:configuracion:gestionproveedores:requisitosproveedores:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionproveedores/requisitosproveedores/:param',
                component: loadable(() => import('views/scm/compra/maestros/requisitoProveedor'), fallBack),
                permission: ['app:general:configuracion:gestionproveedores:requisitosproveedores:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionproveedores/criteriosevaluacion',
                component: loadable(() => import('views/scm/compra/maestros/criterioEvaluacion'), fallBack),
                permission: ['app:general:configuracion:gestionproveedores:criteriosevaluacion:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionproveedores/criteriosevaluacion/:param',
                component: loadable(() => import('views/scm/compra/maestros/criterioEvaluacion'), fallBack),
                permission: ['app:general:configuracion:gestionproveedores:criteriosevaluacion:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionproveedores/carguemasivo',
                component: loadable(() => import('views/scm/compra/procesos/cargueMasivo/List'), fallBack),
                permission: ['app:general:configuracion:gestionproveedores:cargarproveedores:view'],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/configuracion/compras',
            routes: [
              {
                exact: true,
                path: '/app/configuracion/compras/consecutivoscompras',
                component: loadable(() => import('views/scm/compra/maestros/consecutivoCompra'), fallBack),
                permission: ['app:general:configuracion:compras:consecutivoscompras:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/compras/consecutivoscompras/:param',
                component: loadable(() => import('views/scm/compra/maestros/consecutivoCompra'), fallBack),
                permission: ['app:general:configuracion:compras:consecutivoscompras:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/compras/transaccionescompras',
                component: loadable(() => import('views/scm/compra/maestros/documentoCompra/List'), fallBack),
                permission: ['pp:general:configuracion:compras:transaccionescompras:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/compras/transaccionescompras/:param',
                component: loadable(() => import('views/scm/compra/maestros/documentoCompra/List'), fallBack),
                permission: ['pp:general:configuracion:compras:transaccionescompras:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/compras/acuerdoscomerciales',
                component: loadable(() => import('views/scm/compra/maestros/acuerdoComercial/List'), fallBack),
                permission: ['app:general:configuracion:compras:acuerdoscomerciales:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/compras/acuerdoscomerciales/new',
                component: loadable(() => import('views/scm/compra/maestros/acuerdoComercial/New'), fallBack),
                permission: ['app:general:configuracion:compras:acuerdoscomerciales:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/compras/acuerdoscomerciales/edit/:id',
                component: loadable(() => import('views/scm/compra/maestros/acuerdoComercial/Edit'), fallBack),
                permission: ['app:general:configuracion:compras:acuerdoscomerciales:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/compras/agentesaduaneros',
                component: loadable(() => import('views/scm/compra/maestros/agenteAduanero/List'), fallBack),
                permission: ['app:general:configuracion:compras:agentesaduaneros:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/compras/agentesaduaneros/new',
                component: loadable(() => import('views/scm/compra/maestros/agenteAduanero/New'), fallBack),
                permission: ['app:general:configuracion:compras:agentesaduaneros:new']
              },
              {
                exact: true,
                path: '/app/configuracion/compras/agentesaduaneros/edit/:id',
                component: loadable(() => import('views/scm/compra/maestros/agenteAduanero/Edit'), fallBack),
                permission: ['app:general:configuracion:compras:agentesaduaneros:edit']
              },
              {
                exact: true,
                path: '/app/configuracion/compras/unidadesempaques',
                component: loadable(() => import('views/scm/compra/maestros/unidadEmpaque'), fallBack),
                permission: ['app:general:configuracion:compras:unidadesdeempaque:view']
              },
              {
                exact: true,
                path: '/app/configuracion/compras/unidadesempaques/:param',
                component: loadable(() => import('views/scm/compra/maestros/unidadEmpaque'), fallBack),
                permission: ['app:general:configuracion:compras:unidadesdeempaque:view']
              },
              {
                exact: true,
                path: '/app/configuracion/compras/incoterms',
                component: loadable(() => import('views/scm/compra/maestros/incoterms'), fallBack),
                permission: ['app:general:configuracion:compras:incoterms:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/compras/incoterms/:param',
                component: loadable(() => import('views/scm/compra/maestros/incoterms'), fallBack),
                permission: ['app:general:configuracion:compras:incoterms:view']
              },
              {
                exact: true,
                path: '/app/configuracion/compras/registrosinvima',
                component: loadable(() => import('views/scm/compra/maestros/registrosinvima/List'), fallBack),
                permission: ['app:general:configuracion:compras:registrosinvima:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/compras/registrosinvima/new',
                component: loadable(() => import('views/scm/compra/maestros/registrosinvima/New'), fallBack),
                permission: ['app:general:configuracion:compras:registrosinvima:new']
              },
              {
                exact: true,
                path: '/app/configuracion/compras/registrosinvima/edit/:id',
                component: loadable(() => import('views/scm/compra/maestros/registrosinvima/Edit'), fallBack),
                permission: ['app:general:configuracion:compras:registrosinvima:edit']
              },
              {
                exact: true,
                path: '/app/configuracion/compras/requisitosimportacion',
                component: loadable(() => import('views/scm/compra/maestros/requisitosImportacion'), fallBack),
                permission: ['app:general:configuracion:compras:requisitosimportacion:view']
              },
              {
                exact: true,
                path: '/app/configuracion/compras/requisitosimportacion/:param',
                component: loadable(() => import('views/scm/compra/maestros/requisitosImportacion'), fallBack),
                permission: ['app:general:configuracion:compras:requisitosimportacion:view']
              },
              {
                exact: true,
                path: '/app/configuracion/compras/requisitosaduaneros',
                component: loadable(() => import('views/scm/compra/maestros/requisitosAduaneros'), fallBack),
                permission: ['app:general:configuracion:compras:requisitosaduaneros:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/compras/requisitosaduaneros/:param',
                component: loadable(() => import('views/scm/compra/maestros/requisitosAduaneros'), fallBack),
                permission: ['app:general:configuracion:compras:requisitosaduaneros:view'],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/configuracion/distribucion',
            routes: [
              {
                path: '/app/configuracion/distribucion/generales',
                routes: [
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/generales/centrosdedistribucion',
                    component: loadable(() => import(
                      'views/scm/inventario/generales/centrosDistribucion/List'
                    ), fallBack),
                    permission: ['app:general:configuracion:distribucion:generales:centrosdedistribucion:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/generales/centrosdedistribucion/new',
                    component: loadable(() => import(
                      'views/scm/inventario/generales/centrosDistribucion/New'
                    ), fallBack),
                    permission: ['app:general:configuracion:distribucion:generales:centrosdedistribucion:new'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/generales/centrosdedistribucion/edit/:id',
                    component: loadable(() => import(
                      'views/scm/inventario/generales/centrosDistribucion/Edit'
                    ), fallBack),
                    permission: ['app:general:configuracion:distribucion:generales:centrosdedistribucion:edit'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/generales/bodegas',
                    component: loadable(() => import('views/scm/inventario/generales/bodegas/List'), fallBack),
                    permission: ['app:general:configuracion:distribucion:generales:bodegas:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/generales/bodegas/new',
                    component: loadable(() => import('views/scm/inventario/generales/bodegas/New'), fallBack),
                    permission: ['app:general:configuracion:distribucion:generales:bodegas:new'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/generales/bodegas/edit/:id',
                    component: loadable(() => import('views/scm/inventario/generales/bodegas/Edit'), fallBack),
                    permission: ['app:general:configuracion:distribucion:generales:bodegas:edit'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/generales/almacenes',
                    component: loadable(() => import('views/scm/inventario/generales/almacenes/List'), fallBack),
                    permission: ['app:general:configuracion:distribucion:generales:almacenes:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/generales/almacenes/new',
                    component: loadable(() => import('views/scm/inventario/generales/almacenes/New'), fallBack),
                    permission: ['app:general:configuracion:distribucion:generales:almacenes:new'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/generales/almacenes/edit/:id',
                    component: loadable(() => import('views/scm/inventario/generales/almacenes/Edit'), fallBack),
                    permission: ['app:general:configuracion:distribucion:generales:almacenes:edit'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/generales/zonas',
                    component: loadable(() => import('views/scm/wms/maestros/zona'), fallBack),
                    permission: ['app:general:configuracion:distribucion:generales:zonas:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/generales/zonas/:param',
                    component: loadable(() => import('views/scm/wms/maestros/zona'), fallBack),
                    permission: ['app:general:configuracion:distribucion:generales:zonas:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/generales/tiposinventario',
                    component: loadable(() => import('views/scm/inventario/generales/tiposInventario'), fallBack),
                    permission: ['app:general:configuracion:distribucion:generales:tiposinventario:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/generales/tiposinventario/:param',
                    component: loadable(() => import('views/scm/inventario/generales/tiposInventario'), fallBack),
                    permission: ['app:general:configuracion:distribucion:generales:tiposinventario:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/generales/unidadesdemedida',
                    component: loadable(() => import('views/scm/inventario/generales/unidadesMedida'), fallBack),
                    permission: ['app:general:configuracion:distribucion:generales:unidadesdemedida:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/generales/unidadesdemedida/:param',
                    component: loadable(() => import('views/scm/inventario/generales/unidadesMedida'), fallBack),
                    permission: ['app:general:configuracion:distribucion:generales:unidadesdemedida:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/generales/clasificacion1',
                    component: loadable(() => import('views/scm/inventario/generales/clasificacion1'), fallBack),
                    permission: ['app:general:configuracion:distribucion:generales:clasificacion1:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/generales/clasificacion1/:param',
                    component: loadable(() => import('views/scm/inventario/generales/clasificacion1'), fallBack),
                    permission: ['app:general:configuracion:distribucion:generales:clasificacion1:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/generales/clasificacion2',
                    component: loadable(() => import('views/scm/inventario/generales/clasificacion2'), fallBack),
                    permission: ['app:general:configuracion:distribucion:generales:clasificacion2:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/generales/clasificacion2/:param',
                    component: loadable(() => import('views/scm/inventario/generales/clasificacion2'), fallBack),
                    permission: ['app:general:configuracion:distribucion:generales:clasificacion2:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/generales/clasificacion3',
                    component: loadable(() => import('views/scm/inventario/generales/clasificacion3/List'), fallBack),
                    permission: ['app:general:configuracion:distribucion:generales:clasificacion3:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/generales/clasificacion3/:param',
                    component: loadable(() => import('views/scm/inventario/generales/clasificacion3/List'), fallBack),
                    permission: ['app:general:configuracion:distribucion:generales:clasificacion3:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/generales/carguemasivo',
                    component: loadable(() => import(
                      'views/scm/inventario/generales/procesos/cargueMasivo/List'
                    ), fallBack),
                    permission: ['app:general:configuracion:distribucion:generales:cargarproductos:view'],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                path: '/app/configuracion/distribucion/inventariogeneral',
                routes: [
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/inventariogeneral/consecutivosinventariogeneral',
                    component: loadable(() => import(
                      'views/scm/inventario/inventarioGeneral/maestros/consecutivoInventarioGeneral'
                    ), fallBack),
                    permission: ['app:general:configuracion:distribucion:'
                      + 'inventariogeneral:consecutivosinventariogeneral:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/inventariogeneral/consecutivosinventariogeneral/:param',
                    component: loadable(() => import(
                      'views/scm/inventario/inventarioGeneral/maestros/consecutivoInventarioGeneral'
                    ), fallBack),
                    permission: ['app:general:configuracion:distribucion:'
                      + 'inventariogeneral:consecutivosinventariogeneral:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/inventariogeneral/transaccionesinventariogeneral',
                    component: loadable(() => import(
                      'views/scm/inventario/inventarioGeneral/maestros/documentoInventarioGeneral/List'
                    ), fallBack),
                    permission: ['app:general:configuracion:distribucion:'
                      + 'inventariogeneral:transaccionesinventariogeneral:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/inventariogeneral/transaccionesinventariogeneral/:param',
                    component: loadable(() => import(
                      'views/scm/inventario/inventarioGeneral/maestros/documentoInventarioGeneral/List'
                    ), fallBack),
                    permission: ['app:general:configuracion:distribucion:'
                      + 'inventariogeneral:transaccionesinventariogeneral:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/inventariogeneral/tipoproductoinventariogeneral',
                    component: loadable(() => import(
                      'views/scm/inventario/inventarioGeneral/maestros/tipoProductoInventarioGeneral'
                    ), fallBack),
                    permission: ['app:general:configuracion:distribucion:inventariogeneral:tiposproductos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/inventariogeneral/tipoproductoinventariogeneral/:param',
                    component: loadable(() => import(
                      'views/scm/inventario/inventarioGeneral/maestros/tipoProductoInventarioGeneral'
                    ), fallBack),
                    permission: ['app:general:configuracion:distribucion:inventariogeneral:tiposproductos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/inventariogeneral/:referencia/:clasificacion',
                    component: loadable(() => import('views/plm/maestros/producto/List'), fallBack),
                    permission: ['app:general:configuracion:distribucion:inventariogeneral:productos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/inventariogeneral/:referencia/:clasificacion/new',
                    component: loadable(() => import('views/plm/maestros/producto/New'), fallBack),
                    permission: ['app:general:configuracion:distribucion:inventariogeneral:productos:new'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/inventariogeneral/:referencia/:clasificacion/edit/:id',
                    component: loadable(() => import('views/plm/maestros/producto/Edit'), fallBack),
                    permission: ['app:general:configuracion:distribucion:inventariogeneral:productos:edit'],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                path: '/app/configuracion/distribucion/materiaprima',
                routes: [
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/materiaprima/consecutivomateriaprima',
                    component: loadable(() => import(
                      'views/scm/inventario/materiaPrima/maestros/consecutivoMateriaPrima'
                    ), fallBack),
                    permission: ['configuracion:scm:inventario:inventariogeneral:consecutivos:list'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/materiaprima/consecutivomateriaprima/:param',
                    component: loadable(() => import(
                      'views/scm/inventario/materiaPrima/maestros/consecutivoMateriaPrima'
                    ), fallBack),
                    permission: ['configuracion:scm:inventario:inventariogeneral:consecutivos:list'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/materiaprima/transaccionesmateriaprima',
                    component: loadable(() => import(
                      'views/scm/inventario/materiaPrima/maestros/documentoMateriaPrima/List'
                    ), fallBack),
                    permission: ['configuracion:scm:inventario:inventariogeneral:documento:list'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/materiaprima/transaccionesmateriaprima/:param',
                    component: loadable(() => import(
                      'views/scm/inventario/materiaPrima/maestros/documentoMateriaPrima/List'
                    ), fallBack),
                    permission: ['configuracion:scm:inventario:inventariogeneral:documento:list'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/materiaprima/tiposproductos',
                    component: loadable(() => import(
                      'views/scm/inventario/materiaPrima/maestros/tipoProductoMateriaPrima'
                    ), fallBack),
                    permission: ['configuracion:scm:inventario:inventariogeneral:tipoproducto:list'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/materiaprima/tiposproductos/:param',
                    component: loadable(() => import(
                      'views/scm/inventario/materiaPrima/maestros/tipoProductoMateriaPrima'
                    ), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/materiaprima/:referencia/:clasificacion',
                    component: loadable(() => import('views/plm/maestros/producto/List'), fallBack),
                    permission: ['configuracion:scm:inventario:inventariogeneral:producto:list'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/materiaprima/:referencia/:clasificacion/new',
                    component: loadable(() => import('views/plm/maestros/producto/New'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/materiaprima/:referencia/:clasificacion/edit/:id',
                    component: loadable(() => import('views/plm/maestros/producto/Edit'), fallBack),
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                path: '/app/configuracion/distribucion/productoterminado',
                routes: [
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/productoterminado/consecutivosproductoterminado',
                    component: loadable(() => import(
                      'views/scm/inventario/productoTerminado/maestros/consecutivos'
                    ), fallBack),
                    permission: ['app:general:configuracion:distribucion:'
                      + 'productoterminado:consecutivosproductoterminado:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/productoterminado/consecutivosproductoterminado/:param',
                    component: loadable(() => import(
                      'views/scm/inventario/productoTerminado/maestros/consecutivos'
                    ), fallBack),
                    permission: ['app:general:configuracion:distribucion:'
                      + 'productoterminado:consecutivosproductoterminado:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/productoterminado/transaccionesproductoterminado',
                    component: loadable(() => import(
                      'views/scm/inventario/productoTerminado/maestros/documentos/List'
                    ), fallBack),
                    permission: ['app:general:configuracion:distribucion:'
                      + 'productoterminado:transaccionesproductoterminado:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/productoterminado/transaccionesproductoterminado/:param',
                    component: loadable(() => import(
                      'views/scm/inventario/productoTerminado/maestros/documentos/List'
                    ), fallBack),
                    permission: ['app:general:configuracion:distribucion:'
                      + 'productoterminado:transaccionesproductoterminado:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/productoterminado/categorias',
                    component: loadable(() => import(
                      'views/scm/inventario/productoTerminado/maestros/categorias'
                    ), fallBack),
                    permission: ['configuracion:scm:inventario:productoterminado:categorias:list'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/productoterminado/categoriascomposicion',
                    component: loadable(() => import(
                      'views/scm/inventario/productoTerminado/maestros/categoriaComposicion'
                    ), fallBack),
                    permission: ['app:general:configuracion:distribucion:productoterminado:categoriascomposicion:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/productoterminado/categoriascomposicion/:param',
                    component: loadable(() => import(
                      'views/scm/inventario/productoTerminado/maestros/categoriaComposicion'
                    ), fallBack),
                    permission: ['app:general:configuracion:distribucion:productoterminado:categoriascomposicion:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/productoterminado/tiposproductos/',
                    component: loadable(() => import(
                      'views/scm/inventario/productoTerminado/maestros/tiposProductos/List'
                    ), fallBack),
                    permission: ['app:general:configuracion:distribucion:productoterminado:tiposproductos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/productoterminado/tiposproductos/:param',
                    component: loadable(() => import(
                      'views/scm/inventario/productoTerminado/maestros/tiposProductos/List'
                    ), fallBack),
                    permission: ['app:general:configuracion:distribucion:productoterminado:tiposproductos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/productoterminado/:referencia/:clasificacion',
                    component: loadable(() => import('views/plm/maestros/producto/List'), fallBack),
                    permission: ['app:general:configuracion:distribucion:productoterminado:productosterminados:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/productoterminado/:referencia/:clasificacion/new',
                    component: loadable(() => import('views/plm/maestros/producto/New'), fallBack),
                    permission: ['app:general:configuracion:distribucion:productoterminado:productosterminados:new'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/distribucion/productoterminado/:referencia/:clasificacion/edit/:id',
                    component: loadable(() => import('views/plm/maestros/producto/Edit'), fallBack),
                    permission: ['app:general:configuracion:distribucion:productoterminado:productosterminados:edit'],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/desvinculacionlaboral/pazysalvo/new',
                    component: loadable(() => import(
                      'views/hrm/maestros/desvinculacionLaboral/pazYsalvo/New'
                    ), fallBack),
                    // Falta por permisos
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/desvinculacionlaboral/pazysalvo/edit/:id',
                    component: loadable(() => import(
                      'views/hrm/maestros/desvinculacionLaboral/pazYsalvo/Edit'
                    ), fallBack),
                    // Falta por permisos
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                path: '/app/configuracion/rrhh/gestiontiempo',
                routes: [
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/tareas/equiposdetrabajo',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/equiposTrabajo/List'), fallBack),
                    permission: ['configuracion:rrhh:gestiondeltiempo:tareas:equipostrabajos:list'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/tareas/equiposdetrabajo/new',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/equiposTrabajo/New'), fallBack),
                    permission: ['configuracion:rrhh:gestiondeltiempo:tareas:equipostrabajos:create'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/tareas/equiposdetrabajo/edit/:id',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/equiposTrabajo/Edit'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/tareas/hitos',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/hitos'), fallBack),
                    // Falta por permisos
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/tareas/hitos/:param',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/hitos'), fallBack),
                    // Falta por permisos
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/tareas/proyectos',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/proyectos/List'), fallBack),
                    // Falta por permisos
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/tareas/proyectos/new',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/proyectos/New'), fallBack),
                    // Falta por permisos
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/tareas/proyectos/edit/:id',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/proyectos/Edit'), fallBack),
                    // Falta por permisos
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/gestiondeltiempo/turnos',
                    component: loadable(() => import('views/hrm/procesos/turnos/List'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/gestiondeltiempo/turnos/new',
                    component: loadable(() => import('views/hrm/procesos/turnos/New'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/gestiondeltiempo/turnos/edit/:id',
                    component: loadable(() => import('views/hrm/procesos/turnos/Edit'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/gestiondeltiempo/tiposturnos',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/tiposTurnos'), fallBack),
                    permission: ['configuracion:rrhh:gestiondeltiempo:gestiondeltiempo:tiposturnos:list'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/gestiondeltiempo/tiposturnos/:param',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/tiposTurnos'), fallBack),
                    permission: ['configuracion:rrhh:gestiondeltiempo:gestiondeltiempo:tiposturnos:list'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/gestiondeltiempo/unidadestiempo',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/unidadesTiempo'), fallBack),
                    permission: ['configuracion:rrhh:gestiondeltiempo:gestiondeltiempo:unidadtiempo:list'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/gestiondeltiempo/unidadestiempo/:param',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/unidadesTiempo'), fallBack),
                    permission: ['configuracion:rrhh:gestiondeltiempo:gestiondeltiempo:unidadtiempo:list'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/gestiondeltiempo/horasextras',
                    component: loadable(() => import('views/hrm/maestros/horasExtras/List'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/gestiondeltiempo/horasextras/new',
                    component: loadable(() => import('views/hrm/maestros/horasExtras/New'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/gestiondeltiempo/horasextras/edit/:id',
                    component: loadable(() => import('views/hrm/maestros/horasExtras/Edit'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/gestiondeltiempo/recesos',
                    component: loadable(() => import('views/hrm/maestros/recesos/List'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/gestiondeltiempo/recesos/new',
                    component: loadable(() => import('views/hrm/maestros/recesos/New'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/gestiondeltiempo/recesos/edit/:id',
                    component: loadable(() => import('views/hrm/maestros/recesos/Edit'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/gestiondeltiempo/tiposmodalidadesdetrabajo',
                    component: loadable(() => import(
                      'views/hrm/maestros/gestionTiempo/tiposModalidadesTrabajo'
                    ), fallBack),
                    // Falta por permisos
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/gestiondeltiempo/tiposmodalidadesdetrabajo/:param',
                    component: loadable(() => import(
                      'views/hrm/maestros/gestionTiempo/tiposModalidadesTrabajo'
                    ), fallBack),
                    // Falta por permisos
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/gestiondeltiempo/modalidadesdetrabajo',
                    component: loadable(() => import(
                      'views/hrm/maestros/gestionTiempo/modalidadesTrabajo/List'
                    ), fallBack),
                    // Falta por permisos
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/gestiondeltiempo/modalidadesdetrabajo/new',
                    component: loadable(() => import(
                      'views/hrm/maestros/gestionTiempo/modalidadesTrabajo/New'
                    ), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/gestiondeltiempo/modalidadesdetrabajo/edit/:id',
                    component: loadable(() => import(
                      'views/hrm/maestros/gestionTiempo/modalidadesTrabajo/Edit'
                    ), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/ausencias/licencias',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/licencias'), fallBack),
                    permission: ['configuracion:rrhh:gestiondeltiempo:ausencias:licencias:list'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/ausencias/licencias/:param',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/licencias'), fallBack),
                    permission: ['configuracion:rrhh:gestiondeltiempo:ausencias:licencias:list'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/ausencias/tiposincapacidades',
                    component: loadable(() => import('views/hrm/maestros/tiposIncapacidades/List'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/ausencias/tiposincapacidades/new',
                    component: loadable(() => import('views/hrm/maestros/tiposIncapacidades/New'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiontiempo/ausencias/tiposincapacidades/:param/:id',
                    component: loadable(() => import('views/hrm/maestros/tiposIncapacidades/Edit'), fallBack),
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/configuracion/logistica',
            routes: [
              {
                exact: true,
                path: '/app/configuracion/logistica/consecutivoslogistica',
                component: loadable(() => import('views/scm/wms/maestros/consecutivoWms'), fallBack),
                permission: ['app:general:configuracion:logistica:consecutivoslogistica:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/logistica/consecutivoslogistica/:param',
                component: loadable(() => import('views/scm/wms/maestros/consecutivoWms'), fallBack),
                permission: ['app:general:configuracion:logistica:consecutivoslogistica:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/logistica/transaccioneslogistica',
                component: loadable(() => import('views/scm/wms/maestros/documentoWms/List'), fallBack),
                permission: ['app:general:configuracion:logistica:transaccioneslogistica:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/logistica/transaccioneslogistica/:param',
                component: loadable(() => import('views/scm/wms/maestros/documentoWms/List'), fallBack),
                permission: ['app:general:configuracion:logistica:transaccioneslogistica:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/logistica/tiposcontenedores',
                component: loadable(() => import('views/scm/wms/maestros/tipoContenedor/List'), fallBack),
                permission: ['app:general:configuracion:logistica:tiposcontenedores:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/logistica/tiposcontenedores/:param',
                component: loadable(() => import('views/scm/wms/maestros/tipoContenedor/List'), fallBack),
                permission: ['app:general:configuracion:logistica:tiposcontenedores:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/logistica/contenedores',
                component: loadable(() => import('views/scm/wms/maestros/contenedor/List'), fallBack),
                permission: ['app:general:configuracion:logistica:contenedores:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/logistica/contenedores/new',
                component: loadable(() => import('views/scm/wms/maestros/contenedor/New'), fallBack),
                permission: ['app:general:configuracion:logistica:contenedores:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/logistica/contenedores/edit/:id',
                component: loadable(() => import('views/scm/wms/maestros/contenedor/Edit'), fallBack),
                permission: ['app:general:configuracion:logistica:contenedores:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/logistica/tipostransportes',
                component: loadable(() => import('views/scm/wms/maestros/tipoTransporte'), fallBack),
                permission: ['app:general:configuracion:logistica:tipostransportes:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/logistica/tipostransportes/:param',
                component: loadable(() => import('views/scm/wms/maestros/tipoTransporte'), fallBack),
                permission: ['app:general:configuracion:logistica:tipostransportes:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/logistica/clasificacionestransportadores',
                component: loadable(() => import('views/scm/wms/maestros/clasificacionTransportador'), fallBack),
                permission: ['app:general:configuracion:logistica:clasificacionestransportadores:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/logistica/clasificacionestransportadores/:param',
                component: loadable(() => import('views/scm/wms/maestros/clasificacionTransportador'), fallBack),
                permission: ['app:general:configuracion:logistica:clasificacionestransportadores:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/logistica/transportadores',
                component: loadable(() => import('views/scm/wms/maestros/transportador/List'), fallBack),
                permission: ['app:general:configuracion:logistica:transportadores:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/logistica/transportadores/new',
                component: loadable(() => import('views/scm/wms/maestros/transportador/New'), fallBack),
                permission: ['app:general:configuracion:logistica:transportadores:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/logistica/transportadores/edit/:id',
                component: loadable(() => import('views/scm/wms/maestros/transportador/Edit'), fallBack),
                permission: ['app:general:configuracion:logistica:transportadores:edit'],
              },
              /*
              {
                exact: true,
                path: '/app/configuracion/logistica/puertos',
                component: loadable(() => import('views/scm/wms/maestros/puerto'), fallBack),
                permission: ['configuracion:scm:logistica:puertos:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/scm/logistica/tiposcodigosbarras',
                component: loadable(()
                => import('views/scm/wms/maestros/tipoCodigoBarras'), fallBack),
                permission: ['configuracion:scm:logistica:tiposcodigosbarras:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/scm/logistica/codigosean',
                component: loadable(()
                => import('views/scm/inventario/generales/codigosEan'), fallBack),
                permission: ['configuracion:scm:logistica:codigosean:list'],
              },
              */
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/configuracion/contabilidad',
            routes: [
              {
                path: '/app/configuracion/contabilidad/niif',
                routes: [
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/niif/configuracionempresa',
                    component: loadable(() => import(
                      'views/contabilidad/maestros/configuracionEmpresa/List'
                    ), fallBack),
                    permission: ['app:general:configuracion:contabilidad:'
                    + 'niif:configuracionempresa:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/niif/basesporcentajesimpuestos',
                    component: loadable(() => import(
                      'views/contabilidad/maestros/basesPorcentajesImpuestos/List'
                    ), fallBack),
                    permission: ['app:general:configuracion:contabilidad:'
                    + 'niif:basesyporcentajesdetiposimpuestos:view'],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                path: '/app/configuracion/contabilidad/tributarios',
                routes: [
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/tributarios/tiposImpuestos',
                    component: loadable(() => import('views/contabilidad/maestros/tiposImpuestos'), fallBack),
                    permission: ['app:general:configuracion:contabilidad:tributarios:tiposdeimpuestos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/tributarios/tiposImpuestos/:param',
                    component: loadable(() => import('views/contabilidad/maestros/tiposImpuestos'), fallBack),
                    permission: ['app:general:configuracion:contabilidad:tributarios:tiposdeimpuestos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/tributarios/tiposRetenciones',
                    component: loadable(() => import('views/contabilidad/maestros/tiposRetenciones'), fallBack),
                    permission: ['app:general:configuracion:contabilidad:tributarios:tiposderetenciones:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/tributarios/tiposRetenciones/:param',
                    component: loadable(() => import('views/contabilidad/maestros/tiposRetenciones'), fallBack),
                    permission: ['app:general:configuracion:contabilidad:tributarios:tiposderetenciones:view'],
                  },
                  /*
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/tributarios/impuestos',
                    component:
                     loadable(() => import('views/contabilidad/maestros/impuestos'), fallBack),
                    permission: ['configuracion:tesoreria:impuestos:impuestos:list'],
                  },
                  */
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/tributarios/clasificaciontributariaproducto',
                    component: loadable(() => import(
                      'views/contabilidad/maestros/clasificacionTributariaProducto/List'
                    )),
                    permission: ['app:general:configuracion:contabilidad:'
                    + 'tributarios:clasificaciontributariaproducto:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/tributarios/clasificaciontributariaproducto/new',
                    component: loadable(() => import(
                      'views/contabilidad/maestros/clasificacionTributariaProducto/New'
                    )),
                    permission: ['configuracion:contabilidad:clasificacionestributariasproductos:create'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/tributarios/clasificaciontributariaproducto/edit/:id',
                    component: loadable(() => import(
                      'views/contabilidad/maestros/clasificacionTributariaProducto/Edit'
                    )),
                    permission: ['configuracion:contabilidad:clasificacionestributariasproductos:update'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/tributarios/clasificaciontributariagasto',
                    component: loadable(() => import('views/contabilidad/maestros/clasificacionTributariaGastos/List')),
                    permission: ['app:general:configuracion:contabilidad:'
                    + 'tributarios:clasificaciontributariagastos:view']
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/tributarios/clasificaciontributariagasto/new',
                    component: loadable(() => import('views/contabilidad/maestros/clasificacionTributariaGastos/New')),
                    permission: ['configuracion:contabilidad:clasificacionestributariasgastos:create'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/tributarios/clasificaciontributariagasto/edit/:id',
                    component: loadable(() => import('views/contabilidad/maestros/clasificacionTributariaGastos/Edit')),
                    permission: ['configuracion:contabilidad:clasificacionestributariasgastos:update'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/tributarios/clasificaciontributariaservicio',
                    component: loadable(() => import(
                      'views/contabilidad/maestros/clasificacionTributariaServicios/List'
                    )),
                    permission: ['app:general:configuracion:contabilidad:'
                    + 'tributarios:clasificaciontributariaservicios:view']
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/tributarios/clasificaciontributariaservicio/new',
                    component: loadable(() => import(
                      'views/contabilidad/maestros/clasificacionTributariaServicios/New'
                    )),
                    permission: ['configuracion:contabilidad:clasificacionestributariasservicios:create'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/tributarios/clasificaciontributariaservicio/edit/:id',
                    component: loadable(() => import(
                      'views/contabilidad/maestros/clasificacionTributariaServicios/Edit'
                    )),
                    permission: ['configuracion:contabilidad:clasificacionestributariasservicios:update'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/tributarios/clasificaciontributariaactivo',
                    component: loadable(() => import(
                      'views/contabilidad/maestros/clasificacionTributariaActivos/List'
                    ), fallBack),
                    permission: ['app:general:configuracion:contabilidad:'
                    + 'tributarios:clasificaciontributariaactivo:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/tributarios/clasificaciontributariaactivo/new',
                    component: loadable(() => import(
                      'views/contabilidad/maestros/clasificacionTributariaActivos/New'
                    ), fallBack),
                    permission: ['configuracion:contabilidad:clasificacionestributariasactivos:create'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/tributarios/clasificaciontributariaactivo/edit/:id',
                    component: loadable(() => import(
                      'views/contabilidad/maestros/clasificacionTributariaActivos/Edit'
                    ), fallBack),
                    permission: ['configuracion:contabilidad:clasificacionestributariasactivos:update'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/tributarios/retenciones',
                    component: loadable(() => import('views/contabilidad/maestros/retenciones/List'), fallBack),
                    permission: ['app:general:configuracion:contabilidad:tributarios:retenciones:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/tributarios/retenciones/new',
                    component: loadable(() => import('views/contabilidad/maestros/retenciones/New'), fallBack),
                    permission: ['app:general:configuracion:contabilidad:tributarios:retenciones:new'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/tributarios/retenciones/edit/:id',
                    component: loadable(() => import('views/contabilidad/maestros/retenciones/Edit'), fallBack),
                    permission: ['app:general:configuracion:contabilidad:tributarios:retenciones:edit'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/tributarios/impuestos',
                    component: loadable(() => import('views/contabilidad/maestros/impuestos/List'), fallBack),
                    permission: ['app:general:configuracion:contabilidad:tributarios:impuestos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/tributarios/impuestos/new',
                    component: loadable(() => import('views/contabilidad/maestros/impuestos/New'), fallBack),
                    permission: ['app:general:configuracion:contabilidad:tributarios:impuestos:new'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/contabilidad/tributarios/impuestos/edit/:id',
                    component: loadable(() => import('views/contabilidad/maestros/impuestos/Edit'), fallBack),
                    permission: ['app:general:configuracion:contabilidad:tributarios:impuestos:edit'],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/catalogoscuentas',
                component: loadable(() => import('views/contabilidad/maestros/cuentaContable/AccordionList'), fallBack),
                permission: ['app:general:configuracion:contabilidad:catalogodecuentas:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/catalogoscuentas/new',
                component: loadable(() => import('views/contabilidad/maestros/cuentaContable/New'), fallBack),
                permission: ['app:general:configuracion:contabilidad:catalogodecuentas:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/catalogoscuentas/edit/:id',
                component: loadable(() => import('views/contabilidad/maestros/cuentaContable/Edit'), fallBack),
                permission: ['app:general:configuracion:contabilidad:catalogodecuentas:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/actividadeseconomicas',
                component: loadable(() => import('views/contabilidad/maestros/actividadEconomica'), fallBack),
                permission: ['app:general:configuracion:contabilidad:actividadeseconomicas:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/actividadeseconomicas/:param',
                component: loadable(() => import('views/contabilidad/maestros/actividadEconomica'), fallBack),
                permission: ['app:general:configuracion:contabilidad:actividadeseconomicas:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/centroscostos/:id',
                component: loadable(() => import('views/contabilidad/maestros/centroCosto'), fallBack),
                permission: ['app:general:configuracion:contabilidad:centrosdecostos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/centroscostos',
                component: loadable(() => import('views/contabilidad/maestros/centroCosto/List'), fallBack),
                permission: ['app:general:configuracion:contabilidad:centrosdecostos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/centrosnegocios/:param',
                component: loadable(() => import('views/contabilidad/maestros/centroNegocio'), fallBack),
                permission: ['app:general:configuracion:contabilidad:centrosdenegocios:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/centrosnegocios',
                component: loadable(() => import('views/contabilidad/maestros/centroNegocio'), fallBack),
                permission: ['app:general:configuracion:contabilidad:centrosdenegocios:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/gruposunidadesnegocios',
                component: loadable(() => import('views/contabilidad/maestros/grupoUnidadNegocio'), fallBack),
                permission: ['configuracion:contabilidad:gruposunidadesnegocios:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/gruposunidadesnegocios/:param',
                component: loadable(() => import('views/contabilidad/maestros/grupoUnidadNegocio'), fallBack),
                permission: ['configuracion:contabilidad:gruposunidadesnegocios:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/unidadesnegocios',
                component: loadable(() => import('views/contabilidad/maestros/unidadNegocio'), fallBack),
                permission: ['configuracion:contabilidad:unidadesnegocios:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/unidadesnegocios/:param',
                component: loadable(() => import('views/contabilidad/maestros/unidadNegocio'), fallBack),
                permission: ['configuracion:contabilidad:unidadesnegocios:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/libroscontables',
                component: loadable(() => import('views/contabilidad/maestros/librosContables'), fallBack),
                permission: ['configuracion:contabilidad:libroscontables:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/libroscontables/:param',
                component: loadable(() => import('views/contabilidad/maestros/librosContables'), fallBack),
                permission: ['configuracion:contabilidad:libroscontables:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/clasificacioncuentacontable',
                component: loadable(() => import('views/contabilidad/maestros/clasificacionCuentaContable'), fallBack),
                permission: ['app:general:configuracion:contabilidad:clasificacioncuentacontable:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/clasificacioncuentacontable/:param',
                component: loadable(() => import('views/contabilidad/maestros/clasificacionCuentaContable'), fallBack),
                permission: ['app:general:configuracion:contabilidad:clasificacioncuentacontable:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/clasificacionesjuridicas',
                component: loadable(() => import('views/contabilidad/maestros/clasificacionesJuridicas'), fallBack),
                permission: ['app:general:configuracion:contabilidad:clasificacionjuridica:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/clasificacionesjuridicas/:param',
                component: loadable(() => import('views/contabilidad/maestros/clasificacionesJuridicas'), fallBack),
                permission: ['app:general:configuracion:contabilidad:clasificacionjuridica:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/configuracionestadosfinancieros',
                component: loadable(() => import(
                  'views/contabilidad/maestros/configuracionEstadoFinanciero'
                ), fallBack),
                permission: ['configuracion:contabilidad:configuracionesestadosfinancieros:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/configuracionestadosfinancieros/:param',
                component: loadable(() => import(
                  'views/contabilidad/maestros/configuracionEstadoFinanciero'
                ), fallBack),
                permission: ['configuracion:contabilidad:configuracionesestadosfinancieros:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/consecutivos',
                component: loadable(() => import('views/contabilidad/maestros/consecutivoContabilidad'), fallBack),
                permission: ['configuracion:contabilidad:consecutivos:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/consecutivos/:param',
                component: loadable(() => import('views/contabilidad/maestros/consecutivoContabilidad'), fallBack),
                permission: ['configuracion:contabilidad:consecutivos:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/documentoscontabilidad',
                component: loadable(() => import('views/contabilidad/maestros/documentoContabilidad'), fallBack),
                permission: ['configuracion:contabilidad:documentos:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/documentoscontabilidad/:param',
                component: loadable(() => import('views/contabilidad/maestros/documentoContabilidad'), fallBack),
                permission: ['configuracion:contabilidad:documentos:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/configuracioncontable',
                component: loadable(() => import('views/contabilidad/maestros/configuracionContable'), fallBack),
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/responsabilidadesFiscales',
                component: loadable(() => import('views/contabilidad/maestros/responsabilidadesFiscales'), fallBack),
                permission: ['app:general:configuracion:contabilidad:responsabilidadesfiscales:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/responsabilidadesFiscales/:param',
                component: loadable(() => import('views/contabilidad/maestros/responsabilidadesFiscales'), fallBack),
                permission: ['app:general:configuracion:contabilidad:responsabilidadesfiscales:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/responsabilidadestributarias',
                component: loadable(() => import(
                  'views/contabilidad/maestros/responsabilidadTributaria/List'
                ), fallBack),
                permission: [
                  'app:general:configuracion:contabilidad:responsabilidadestributarias:view'
                ],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/responsabilidadestributarias/new',
                component: loadable(() => import(
                  'views/contabilidad/maestros/responsabilidadTributaria/New'
                ), fallBack),
                permission: [
                  'app:general:configuracion:contabilidad:responsabilidadestributarias:new'
                ],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/responsabilidadestributarias/edit/:id',
                component: loadable(() => import(
                  'views/contabilidad/maestros/responsabilidadTributaria/Edit'
                ), fallBack),
                permission: [
                  'app:general:configuracion:contabilidad:responsabilidadestributarias:edit'
                ],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/rubros',
                component: loadable(() => import('views/contabilidad/maestros/rubro'), fallBack),
                permission: ['configuracion:contabilidad:rubros:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/rubros/:param',
                component: loadable(() => import('views/contabilidad/maestros/rubro'), fallBack),
                permission: ['configuracion:contabilidad:rubros:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/uvt',
                component: loadable(() => import('views/contabilidad/maestros/uvt'), fallBack),
                permission: ['configuracion:contabilidad:uvt:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/contabilidad/uvt/:param',
                component: loadable(() => import('views/contabilidad/maestros/uvt'), fallBack),
                permission: ['configuracion:contabilidad:uvt:list'],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/configuracion/tesoreria',
            routes: [
              {
                exact: true,
                path: '/app/configuracion/tesoreria/frecuenciasdepagos',
                component: loadable(() => import(
                  'views/hrm/maestros/frecuenciasPagos'
                ), fallBack),
                permission: ['hrm:maestros:frecuenciaspagos:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/frecuenciasdepagos:param',
                component: loadable(() => import(
                  'views/hrm/maestros/frecuenciasPagos'
                ), fallBack),
                permission: ['hrm:maestros:frecuenciaspagos:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/bancos',
                component: loadable(() => import(
                  'views/tesoreria/maestros/bancos/List'
                ), fallBack),
                permission: ['app:general:configuracion:tesoreria:bancos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/bancos/new',
                component: loadable(() => import(
                  'views/tesoreria/maestros/bancos/New'
                ), fallBack),
                permission: ['app:general:configuracion:tesoreria:bancos:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/bancos/edit/:id',
                component: loadable(() => import(
                  'views/tesoreria/maestros/bancos/Edit'
                ), fallBack),
                permission: ['app:general:configuracion:tesoreria:bancos:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/cajas/',
                component: loadable(() => import(
                  'views/tesoreria/maestros/cajas/List'
                ), fallBack),
                permission: ['configuracion:tesoreria:cajas:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/cajas/new',
                component: loadable(() => import(
                  'views/tesoreria/maestros/cajas/New'
                ), fallBack),
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/cajas/edit/:id',
                component: loadable(() => import(
                  'views/tesoreria/maestros/cajas/Edit'
                ), fallBack),
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/clasificacionbanco',
                component: loadable(() => import(
                  'views/tesoreria/maestros/clasificacionBanco'
                ), fallBack),
                permission: ['configuracion:tesoreria:clasificacionesbancos:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/clasificacionbanco/:param',
                component: loadable(() => import(
                  'views/tesoreria/maestros/clasificacionBanco'
                ), fallBack),
                permission: ['configuracion:tesoreria:clasificacionesbancos:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/clasificacionesgastos',
                component: loadable(() => import(
                  'views/scm/compra/maestros/clasificacionGasto'
                ), fallBack),
                permission: ['configuracion:scm:compra:clasificacionesgastos:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/clasificacionesgastos/:param',
                component: loadable(() => import(
                  'views/scm/compra/maestros/clasificacionGasto'
                ), fallBack),
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/gastos',
                component: loadable(() => import(
                  'views/scm/compra/maestros/gastos/List'
                ), fallBack),
                permission: ['app:general:configuracion:tesoreria:gastos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/gastos/new',
                component: loadable(() => import(
                  'views/scm/compra/maestros/gastos/New'
                ), fallBack),
                permission: ['app:general:configuracion:tesoreria:gastos:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/gastos/edit/:id',
                component: loadable(() => import(
                  'views/scm/compra/maestros/gastos/Edit'
                ), fallBack),
                permission: ['app:general:configuracion:tesoreria:gastos:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/consecutivos',
                component: loadable(() => import(
                  'views/tesoreria/maestros/consecutivos'
                ), fallBack),
                permission: ['app:general:configuracion:'
                + 'tesoreria:consecutivostesoreria:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/consecutivos/:param',
                component: loadable(() => import(
                  'views/tesoreria/maestros/consecutivos'
                ), fallBack),
                permission: ['app:general:configuracion:'
                + 'tesoreria:consecutivostesoreria:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/cuentasbancarias',
                component: loadable(() => import(
                  'views/tesoreria/maestros/cuentasBancarias/List'
                ), fallBack),
                permission: ['app:general:configuracion:tesoreria:cuentasbancarias:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/cuentasbancarias/new',
                component: loadable(() => import(
                  'views/tesoreria/maestros/cuentasBancarias/New'
                ), fallBack),
                permission: ['app:general:configuracion:tesoreria:cuentasbancarias:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/cuentasbancarias/edit/:id',
                component: loadable(() => import(
                  'views/tesoreria/maestros/cuentasBancarias/Edit'
                ), fallBack),
                permission: ['app:general:configuracion:tesoreria:cuentasbancarias:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/documentostesoreria',
                component: loadable(() => import(
                  'views/tesoreria/maestros/documentosTesoreria/List'
                ), fallBack),
                permission: ['app:general:configuracion:'
                + 'tesoreria:transaccionestesoreria:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/documentostesoreria/new',
                component: loadable(() => import(
                  'views/tesoreria/maestros/documentosTesoreria/New'
                ), fallBack),
                permission: ['app:general:configuracion:'
                + 'tesoreria:transaccionestesoreria:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/documentostesoreria/edit/:id',
                component: loadable(() => import(
                  'views/tesoreria/maestros/documentosTesoreria/Edit'
                ), fallBack),
                permission: ['app:general:configuracion:'
                + 'tesoreria:transaccionestesoreria:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/formaspago',
                component: loadable(() => import('views/tesoreria/maestros/formasPago'), fallBack),
                permission: ['app:general:configuracion:tesoreria:formasdepago:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/formaspago/:param',
                component: loadable(() => import('views/tesoreria/maestros/formasPago'), fallBack),
                permission: ['app:general:configuracion:tesoreria:formasdepago:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/grupoconceptotesoreria',
                component: loadable(() => import('views/tesoreria/maestros/grupoConceptoTesoreria'), fallBack),
                permission: ['configuracion:tesoreria:gruposconceptostesoreria:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/grupoconceptotesoreria/:param',
                component: loadable(() => import('views/tesoreria/maestros/grupoConceptoTesoreria'), fallBack),
                permission: ['configuracion:tesoreria:gruposconceptostesoreria:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/conceptostesoreria',
                component: loadable(() => import('views/tesoreria/maestros/conceptoTesoreria/List'), fallBack),
                permission: ['configuracion:tesoreria:conceptostesoreria:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/conceptostesoreria/new',
                component: loadable(() => import('views/tesoreria/maestros/conceptoTesoreria/New'), fallBack),
                permission: ['configuracion:tesoreria:conceptostesoreria:create'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/conceptostesoreria/edit/:id',
                component: loadable(() => import('views/tesoreria/maestros/conceptoTesoreria/Edit'), fallBack),
                permission: ['configuracion:tesoreria:conceptostesoreria:update'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/mediospago',
                component: loadable(() => import('views/tesoreria/maestros/mediosPago'), fallBack),
                permission: ['app:general:configuracion:tesoreria:mediosdepago:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/mediospago/:param',
                component: loadable(() => import('views/tesoreria/maestros/mediosPago'), fallBack),
                permission: ['app:general:configuracion:tesoreria:mediosdepago:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/monedas',
                component: loadable(() => import(
                  'views/tesoreria/maestros/monedas/List'
                ), fallBack),
                permission: ['app:general:configuracion:tesoreria:monedas:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/monedas/new',
                component: loadable(() => import(
                  'views/tesoreria/maestros/monedas/New'
                ), fallBack),
                permission: ['app:general:configuracion:tesoreria:monedas:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/monedas/edit/:id',
                component: loadable(() => import(
                  'views/tesoreria/maestros/monedas/Edit'
                ), fallBack),
                permission: ['app:general:configuracion:tesoreria:monedas:view'],
              },
              /*
              {
                exact: true,
                path: '/app/configuracion/tesoreria/tarjetascredito',
                component: loadable(()
                => import('views/tesoreria/maestros/tarjetasCredito'), fallBack),
                permission: ['configuracion:tesoreria:tarjetascredito:list'],
              },
              */
              {
                exact: true,
                path: '/app/configuracion/tesoreria/tarjetascredito',
                component: loadable(() => import('views/tesoreria/maestros/tarjetasCredito/List'), fallBack),
                permission: ['configuracion:tesoreria:tarjetascredito:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/tarjetascredito/new',
                component: loadable(() => import('views/tesoreria/maestros/tarjetasCredito/New'), fallBack),
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/tarjetascredito/edit/:id',
                component: loadable(() => import('views/tesoreria/maestros/tarjetasCredito/Edit'), fallBack),
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/tiposnotacredito',
                component: loadable(() => import('views/tesoreria/maestros/tiposNotasCredito'), fallBack),
                permission: ['app:general:configuracion:tesoreria:tiposdenotacredito:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/tiposnotacredito/:param',
                component: loadable(() => import('views/tesoreria/maestros/tiposNotasCredito'), fallBack),
                permission: ['app:general:configuracion:tesoreria:tiposdenotacredito:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/tiposnotadebito',
                component: loadable(() => import('views/tesoreria/maestros/tiposNotasDebito'), fallBack),
                permission: ['app:general:configuracion:tesoreria:tiposdenotadebito:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/tiposnotadebito/:param',
                component: loadable(() => import('views/tesoreria/maestros/tiposNotasDebito'), fallBack),
                permission: ['app:general:configuracion:tesoreria:tiposdenotadebito:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/tiposdedocumentoselectronicos',
                component: loadable(() => import('views/tesoreria/maestros/tiposDocumentosElectronicos'), fallBack),
                permission: ['app:general:configuracion:tesoreria:tiposdedocumentoselectronicos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/tiposdedocumentoselectronicos/:param',
                component: loadable(() => import('views/tesoreria/maestros/tiposDocumentosElectronicos'), fallBack),
                permission: ['app:general:configuracion:tesoreria:tiposdedocumentoselectronicos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/tiposcuentasbancarias',
                component: loadable(() => import('views/tesoreria/maestros/tiposCuentasBancarias'), fallBack),
                permission: ['app:general:configuracion:tesoreria:tiposdecuentasbancarias:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/tiposcuentasbancarias/:param',
                component: loadable(() => import('views/tesoreria/maestros/tiposCuentasBancarias'), fallBack),
                permission: ['app:general:configuracion:tesoreria:tiposdecuentasbancarias:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/tipospagos',
                component: loadable(() => import('views/tesoreria/maestros/tiposPagos'), fallBack),
                permission: ['app:general:configuracion:tesoreria:tiposdepagos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/tipospagos/:param',
                component: loadable(() => import('views/tesoreria/maestros/tiposPagos'), fallBack),
                permission: ['app:general:configuracion:tesoreria:tiposdepagos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/tipostasas',
                component: loadable(() => import('views/tesoreria/maestros/tiposTasas'), fallBack),
                permission: ['configuracion:tesoreria:tipostasas:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/tipostasas/:param',
                component: loadable(() => import('views/tesoreria/maestros/tiposTasas'), fallBack),
                permission: ['configuracion:tesoreria:tipostasas:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/categorizaciongastos',
                component: loadable(() => import('views/tesoreria/maestros/categorizacionGastos'), fallBack),
                permission: ['configuracion:tesoreria:categorizaciongastos:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/categorizaciongastos/:param',
                component: loadable(() => import('views/tesoreria/maestros/categorizacionGastos'), fallBack),
                permission: ['configuracion:tesoreria:categorizaciongastos:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/costos',
                component: loadable(() => import('views/scm/compra/maestros/costos/List'), fallBack),
                permission: ['configuracion:general:costos:list'],
              },
              {
                exact: true,
                path: '/app/configuracion/tesoreria/costos/:param',
                component: loadable(() => import('views/scm/compra/maestros/costos/List'), fallBack),
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/configuracion/gestionactivosfijos',
            routes: [
              {
                exact: true,
                path: '/app/configuracion/gestionactivosfijos/consecutivosgestionactivos',
                component: loadable(() => import('views/gestionActivos/maestros/consecutivos'), fallBack),
                permission: ['app:general:configuracion:gestionactivosfijos:consecutivosgestionactivos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionactivosfijos/consecutivosgestionactivos/:param',
                component: loadable(() => import('views/gestionActivos/maestros/consecutivos'), fallBack),
                permission: ['app:general:configuracion:gestionactivosfijos:consecutivosgestionactivos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionactivosfijos/transaccionesgestionactivos',
                component: loadable(() => import('views/gestionActivos/maestros/documentos/List'), fallBack),
                permission: ['app:general:configuracion:gestionactivosfijos:transaccionesgestionactivos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionactivosfijos/transaccionesgestionactivos/new',
                component: loadable(() => import('views/gestionActivos/maestros/documentos/New'), fallBack),
                permission: ['app:general:configuracion:gestionactivosfijos:transaccionesgestionactivos:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionactivosfijos/transaccionesgestionactivos/edit/:id',
                component: loadable(() => import('views/gestionActivos/maestros/documentos/Edit'), fallBack),
                permission: ['app:general:configuracion:gestionactivosfijos:transaccionesgestionactivos:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionactivosfijos/tiposactivos',
                component: loadable(() => import('views/gestionActivos/maestros/tipoActivo'), fallBack),
                permission: ['app:general:configuracion:gestionactivosfijos:tiposactivos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionactivosfijos/tiposactivos/:param',
                component: loadable(() => import('views/gestionActivos/maestros/tipoActivo'), fallBack),
                permission: ['app:general:configuracion:gestionactivosfijos:tiposactivos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionactivosfijos/categoriasactivos',
                component: loadable(() => import('views/gestionActivos/maestros/categoriaActivo'), fallBack),
                permission: ['app:general:configuracion:gestionactivosfijos:categoriasactivos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionactivosfijos/categoriasactivos/:param',
                component: loadable(() => import('views/gestionActivos/maestros/categoriaActivo'), fallBack),
                permission: ['app:general:configuracion:gestionactivosfijos:categoriasactivos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionactivosfijos/gruposactivos',
                component: loadable(() => import('views/gestionActivos/maestros/gruposActivos'), fallBack),
                permission: ['app:general:configuracion:gestionactivosfijos:gruposactivos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionactivosfijos/gruposactivos/:param',
                component: loadable(() => import('views/gestionActivos/maestros/gruposActivos'), fallBack),
                permission: ['app:general:configuracion:gestionactivosfijos:gruposactivos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionactivosfijos/activos',
                component: loadable(() => import('views/gestionActivos/maestros/activo/List'), fallBack),
                permission: ['app:general:configuracion:gestionactivosfijos:activos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionactivosfijos/activos/new',
                component: loadable(() => import('views/gestionActivos/maestros/activo/New'), fallBack),
                permission: ['app:general:configuracion:gestionactivosfijos:activos:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionactivosfijos/activos/edit/:id',
                component: loadable(() => import('views/gestionActivos/maestros/activo/Edit'), fallBack),
                permission: ['app:general:configuracion:gestionactivosfijos:activos:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionactivosfijos/metodosdepreciacion',
                component: loadable(() => import('views/gestionActivos/maestros/metodoDepreciacion'), fallBack),
                permission: ['app:general:configuracion:gestionactivosfijos:metodosdedepreciacion:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionactivosfijos/metodosdepreciacion/:param',
                component: loadable(() => import('views/gestionActivos/maestros/metodoDepreciacion'), fallBack),
                permission: ['app:general:configuracion:gestionactivosfijos:metodosdedepreciacion:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionactivosfijos/mantenimientos/tiposmantenimientos',
                component: loadable(() => import(
                  'views/scm/inventario/inventarioGeneral/maestros/tipoMantenimiento'
                ), fallBack),
                permission: ['app:general:configuracion:gestionactivosfijos:'
                  + 'mantenimientos:tiposmantenimientos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionactivosfijos/mantenimientos/tiposmantenimientos/:param',
                component: loadable(() => import(
                  'views/scm/inventario/inventarioGeneral/maestros/tipoMantenimiento'
                ), fallBack),
                permission: ['app:general:configuracion:gestionactivosfijos:'
                  + 'mantenimientos:tiposmantenimientos:view'],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/configuracion/recursoshumanos',
            routes: [
              {
                exact: true,
                path: '/app/configuracion/recursoshumanos/consecutivosrrhh',
                component: loadable(() => import('views/hrm/maestros/consecutivos')),
                permission: ['app:general:configuracion:recursoshumanos:consecutivosrrhh:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/recursoshumanos/consecutivosrrhh/:param',
                component: loadable(() => import('views/hrm/maestros/consecutivos')),
                permission: ['app:general:configuracion:recursoshumanos:consecutivosrrhh:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/recursoshumanos/transaccionesrrhh',
                component: loadable(() => import('views/hrm/maestros/documentos/List')),
                permission: ['app:general:configuracion:recursoshumanos:transaccionesrrhh:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/recursoshumanos/transaccionesrrhh/:param',
                component: loadable(() => import('views/hrm/maestros/documentos')),
                permission: ['app:general:configuracion:recursoshumanos:transaccionesrrhh:view'],
              },
              {
                path: '/app/configuracion/recursoshumanos/generales',
                routes: [
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/generales/tiposempleados',
                    component: loadable(() => import('views/hrm/maestros/tiposEmpleados'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:generales:tiposempleados:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/generales/tiposempleados/:param',
                    component: loadable(() => import('views/hrm/maestros/tiposEmpleados'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:generales:tiposempleados:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/generales/subtipoempleado',
                    component: loadable(() => import('views/hrm/maestros/subTiposEmpleados'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:generales:subtipoempleado:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/generales/subtipoempleado/:param',
                    component: loadable(() => import('views/hrm/maestros/subTiposEmpleados'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:generales:subtipoempleado:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/generales/niveleseducativos',
                    component: loadable(() => import('views/hrm/maestros/gestionTalento/nivelesEducativos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:generales:niveleseducativos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/generales/niveleseducativos/:param',
                    component: loadable(() => import('views/hrm/maestros/gestionTalento/nivelesEducativos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:generales:niveleseducativos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/generales/clasesderiesgoarl',
                    component: loadable(() => import('views/hrm/maestros/clasesRiesgos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:generales:clasesderiesgoarl:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/generales/clasesderiesgoarl/:param',
                    component: loadable(() => import('views/hrm/maestros/clasesRiesgos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:generales:clasesderiesgoarl:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/generales/altoriesgo',
                    component: loadable(() => import('views/hrm/maestros/altoRiesgo'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:generales:altoriesgo:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/generales/altoriesgo/:param',
                    component: loadable(() => import('views/hrm/maestros/altoRiesgo'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:generales:altoriesgo:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/generales/eps',
                    component: loadable(() => import('views/hrm/maestros/eps'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:generales:eps:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/generales/eps/:param',
                    component: loadable(() => import('views/hrm/maestros/eps'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:generales:eps:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/generales/tiposdecontratos',
                    component: loadable(() => import('views/hrm/maestros/tiposContratos/List'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:generales:tiposdecontratos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/generales/tiposdecontratos/new',
                    component: loadable(() => import('views/hrm/maestros/tiposContratos/New'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:generales:tiposdecontratos:new'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/generales/tiposdecontratos/:param/:id',
                    component: loadable(() => import('views/hrm/maestros/tiposContratos/Edit'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:generales:tiposdecontratos:edit'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/generales/tiposrestricciones',
                    component: loadable(() => import('views/hrm/maestros/gestionTalento/tiposRestricciones'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:generales:tiposrestricciones:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/generales/tiposrestricciones/:param',
                    component: loadable(() => import('views/hrm/maestros/gestionTalento/tiposRestricciones'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:generales:tiposrestricciones:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/generales/restriccioneslaborales',
                    component: loadable(() => import(
                      'views/hrm/maestros/gestionTiempo/restriccionesLaborales/List'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:generales:restriccioneslaborales:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/generales/restriccioneslaborales/new',
                    component: loadable(() => import(
                      'views/hrm/maestros/gestionTiempo/restriccionesLaborales/New'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:generales:restriccioneslaborales:new'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/generales/restriccioneslaborales/edit/:id',
                    component: loadable(() => import(
                      'views/hrm/maestros/gestionTiempo/restriccionesLaborales/Edit'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:generales:restriccioneslaborales:edit'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/generales/empleados',
                    component: loadable(() => import('views/hrm/maestros/empleados/List'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:generales:empleados:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/generales/empleados/new',
                    component: loadable(() => import('views/hrm/maestros/empleados/New'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:generales:empleados:new'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/generales/empleados/edit/:id',
                    component: loadable(() => import('views/hrm/maestros/empleados/Edit'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:generales:empleados:edit'],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                path: '/app/configuracion/recursoshumanos/gestiondeltalento',
                routes: [
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltalento/cargos',
                    component: loadable(() => import('views/hrm/procesos/gestionTalento/cargos/List')),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltalento:cargos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltalento/cargos/new',
                    component: loadable(() => import('views/hrm/procesos/gestionTalento/cargos/New')),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltalento:cargos:new'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltalento/cargos/edit/:id',
                    component: loadable(() => import('views/hrm/procesos/gestionTalento/cargos/Edit')),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltalento:cargos:edit'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltalento/requisitoscargos',
                    component: loadable(() => import('views/hrm/maestros/reclutamiento/requisitosCargos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltalento:requisitoscargos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltalento/requisitoscargos/:param',
                    component: loadable(() => import('views/hrm/maestros/reclutamiento/requisitosCargos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltalento:requisitoscargos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltalento/filtrosgestiondeltalento',
                    component: loadable(() => import(
                      'views/hrm/maestros/gestionTalento/filtrosGestionTalento'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'gestiondeltalento:filtrosgestiondeltalento:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltalento/filtrosgestiondeltalento/:param',
                    component: loadable(() => import(
                      'views/hrm/maestros/gestionTalento/filtrosGestionTalento'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'gestiondeltalento:filtrosgestiondeltalento:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltalento/pruebasdeseleccion',
                    component: loadable(() => import('views/hrm/maestros/reclutamiento/pruebaSeleccion'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltalento:pruebasdeseleccion:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltalento/pruebasdeseleccion/:param',
                    component: loadable(() => import('views/hrm/maestros/reclutamiento/pruebaSeleccion'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltalento:pruebasdeseleccion:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltalento/examenesmedicos',
                    component: loadable(() => import(
                      'views/hrm/maestros/reclutamiento/examenesMedicos/List'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltalento:examenesmedicos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltalento/examenesmedicos/new',
                    component: loadable(() => import(
                      'views/hrm/maestros/reclutamiento/examenesMedicos/New'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltalento:examenesmedicos:new'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltalento/examenesmedicos/edit/:id',
                    component: loadable(() => import(
                      'views/hrm/maestros/reclutamiento/examenesMedicos/Edit'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltalento:examenesmedicos:edit'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltalento/catalogodehabilidades',
                    component: loadable(() => import('views/hrm/maestros/reclutamiento/catalogoHabilidades'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'gestiondeltalento:catalogodehabilidades:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltalento/catalogodehabilidades/:param',
                    component: loadable(() => import('views/hrm/maestros/reclutamiento/catalogoHabilidades'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'gestiondeltalento:catalogodehabilidades:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltalento/catalogodecompetencias',
                    component: loadable(() => import(
                      'views/hrm/maestros/reclutamiento/catalogoCompetencias'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'gestiondeltalento:catalogodecompetencias:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltalento/catalogodecompetencias/:param',
                    component: loadable(() => import(
                      'views/hrm/maestros/reclutamiento/catalogoCompetencias'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'gestiondeltalento:catalogodecompetencias:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltalento/entrevistas',
                    component: loadable(() => import('views/hrm/maestros/reclutamiento/entrevistas'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltalento:entrevistas:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltalento/entrevistas/:param',
                    component: loadable(() => import('views/hrm/maestros/reclutamiento/entrevistas'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltalento:entrevistas:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltalento/candidatos',
                    component: loadable(() => import('views/hrm/maestros/reclutamiento/candidatos/List'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltalento:candidatos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/'
                      + 'gestiondeltalento/candidatos/new/:cargo/:cargoId/:solicitudId',
                    component: loadable(() => import('views/hrm/maestros/reclutamiento/candidatos/New'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltalento:candidatos:new'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltalento/candidatos/:edit/:id',
                    component: loadable(() => import('views/hrm/maestros/reclutamiento/candidatos/Edit'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltalento:candidatos:edit'],
                  },
                  /*
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiondeltalento/responsabilidades',
                    component: loadable(()
                    => import('views/hrm/maestros/responsabilidades'), fallBack),
                    // Falta por permisos
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiondeltalento/responsabilidades/:param',
                    component: loadable(()
                    => import('views/hrm/maestros/responsabilidades'), fallBack),
                    // Falta por permisos
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiondeltalento/tipoafiliacion',
                    component: loadable(()
                    => import('views/hrm/maestros/tipoAfiliacion'), fallBack),
                    permission: ['configuracion:rrhh:gestiondeltalento:eps:list'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/rrhh/gestiondeltalento/tipoafiliacion/:param',
                    component: loadable(()
                    => import('views/hrm/maestros/tipoAfiliacion'), fallBack),
                  },
                  */
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                path: '/app/configuracion/recursoshumanos/seguimientoyretencion',
                routes: [
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/seguimientoyretencion/requisitoscontratacionrrhh',
                    component: loadable(() => import('views/hrm/maestros/requisitosContratos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'seguimientoyretencion:requisitoscontratacionrrhh:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/seguimientoyretencion/requisitoscontratacionrrhh/:param',
                    component: loadable(() => import('views/hrm/maestros/requisitosContratos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'seguimientoyretencion:requisitoscontratacionrrhh:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/seguimientoyretencion/tiposcapacitaciones',
                    component: loadable(() => import(
                      'views/hrm/maestros/seguimientoyRetencion/tiposCapacitaciones'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'seguimientoyretencion:tiposcapacitaciones:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/seguimientoyretencion/tiposcapacitaciones/:param',
                    component: loadable(() => import(
                      'views/hrm/maestros/seguimientoyRetencion/tiposCapacitaciones'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'seguimientoyretencion:tiposcapacitaciones:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/seguimientoyretencion/temascapacitaciones',
                    component: loadable(() => import(
                      'views/hrm/maestros/seguimientoyRetencion/temasCapacitaciones'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'seguimientoyretencion:temascapacitaciones:view'],
                  },
                  {
                    path: '/app/configuracion/recursoshumanos/seguimientoyretencion/temascapacitaciones/:param',
                    component: loadable(() => import(
                      'views/hrm/maestros/seguimientoyRetencion/temasCapacitaciones'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'seguimientoyretencion:temascapacitaciones:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/seguimientoyretencion/encuestasevaluacioncapacitaciones',
                    component: loadable(() => import(
                      'views/hrm/maestros/seguimientoyRetencion/evaluacionCapacitacionEncuesta/List'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:seguimientoyretencion:'
                      + 'encuestasevaluacioncapacitaciones:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/seguimientoyretencion/evaluacioncapacitacionencuesta/new',
                    component: loadable(() => import(
                      'views/hrm/maestros/seguimientoyRetencion/evaluacionCapacitacionEncuesta/New'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:seguimientoyretencion:'
                      + 'encuestasevaluacioncapacitaciones:new'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos'
                      + '/seguimientoyretencion/evaluacioncapacitacionencuesta/edit/:id',
                    component: loadable(() => import(
                      'views/hrm/maestros/seguimientoyRetencion/evaluacionCapacitacionEncuesta/Edit'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:seguimientoyretencion:'
                      + 'encuestasevaluacioncapacitaciones:edit'],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                path: '/app/configuracion/recursoshumanos/procesosdisciplinarios',
                routes: [
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/procesosdisciplinarios/disciplinarios',
                    component: loadable(() => import('views/hrm/maestros/seguimientoyRetencion/disciplinarios/List')),
                    permission: ['app:general:configuracion:recursoshumanos:procesosdisciplinarios:disciplinarios:view']
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/procesosdisciplinarios/disciplinarios/new',
                    component: loadable(() => import('views/hrm/maestros/seguimientoyRetencion/disciplinarios/New')),
                    permission: ['app:general:configuracion:recursoshumanos:procesosdisciplinarios:disciplinarios:new']
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/procesosdisciplinarios/disciplinarios/edit/:id',
                    component: loadable(() => import('views/hrm/maestros/seguimientoyRetencion/disciplinarios/Edit')),
                    permission: ['app:general:configuracion:recursoshumanos:procesosdisciplinarios:disciplinarios:edit']
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                path: '/app/configuracion/recursoshumanos/bienestarlaboral',
                routes: [
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/bienestarlaboral/tiposbeneficios',
                    component: loadable(() => import('views/hrm/maestros/tiposBeneficios'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:bienestarlaboral:tiposbeneficios:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/bienestarlaboral/tiposbeneficios/:param',
                    component: loadable(() => import('views/hrm/maestros/tiposBeneficios'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:bienestarlaboral:tiposbeneficios:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/bienestarlaboral/categoriasbeneficios',
                    component: loadable(() => import('views/hrm/maestros/categoriasBeneficios'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'bienestarlaboral:categoriasbeneficios:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/bienestarlaboral/categoriasbeneficios/:param',
                    component: loadable(() => import('views/hrm/maestros/categoriasBeneficios'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'bienestarlaboral:categoriasbeneficios:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/bienestarlaboral/beneficios',
                    component: loadable(() => import('views/hrm/procesos/beneficios/List'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:bienestarlaboral:beneficios:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/bienestarlaboral/beneficios/new',
                    component: loadable(() => import('views/hrm/procesos/beneficios/New'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:bienestarlaboral:beneficios:new'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/bienestarlaboral/beneficios/:param/:id',
                    component: loadable(() => import('views/hrm/procesos/beneficios/Edit'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:bienestarlaboral:beneficios:edit'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/bienestarlaboral/tipodebonificacion',
                    component: loadable(() => import('views/hrm/maestros/tiposBonificaciones'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:bienestarlaboral:tipodebonificacion:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/bienestarlaboral/tipodebonificacion/:param',
                    component: loadable(() => import('views/hrm/maestros/tiposBonificaciones'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:bienestarlaboral:tipodebonificacion:view'],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                path: '/app/configuracion/recursoshumanos/gestiondeltiempo',
                routes: [
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/unidadesdetiempo',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/unidadesTiempo'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltiempo:unidadesdetiempo:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/unidadesdetiempo/:param',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/unidadesTiempo'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltiempo:unidadesdetiempo:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/recesos',
                    component: loadable(() => import('views/hrm/maestros/recesos/List'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltiempo:recesos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/recesos/new',
                    component: loadable(() => import('views/hrm/maestros/recesos/New'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltiempo:recesos:new'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/recesos/edit/:id',
                    component: loadable(() => import('views/hrm/maestros/recesos/Edit'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltiempo:recesos:edit'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/tiposturnos',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/tiposTurnos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltiempo:tiposturnos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/tiposturnos/:param',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/tiposTurnos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltiempo:tiposturnos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/turnos',
                    component: loadable(() => import('views/hrm/procesos/turnos/List'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltiempo:turnos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/turnos/new',
                    component: loadable(() => import('views/hrm/procesos/turnos/New'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltiempo:turnos:new'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/turnos/edit/:id',
                    component: loadable(() => import('views/hrm/procesos/turnos/Edit'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltiempo:turnos:edit'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/equiposdetrabajo',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/equiposTrabajo/List'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltiempo:equiposdetrabajo:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/equiposdetrabajo/new',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/equiposTrabajo/New'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltiempo:equiposdetrabajo:new'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/equiposdetrabajo/edit/:id',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/equiposTrabajo/Edit'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltiempo:equiposdetrabajo:edit'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/proyectos',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/proyectos/List'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltiempo:proyectos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/proyectos/new',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/proyectos/New'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltiempo:proyectos:new'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/proyectos/edit/:id',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/proyectos/Edit'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltiempo:proyectos:edit'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/hitos',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/hitos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltiempo:hitos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/hitos/:param',
                    component: loadable(() => import('views/hrm/maestros/gestionTiempo/hitos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltiempo:hitos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/tipodehoraextra',
                    component: loadable(() => import('views/hrm/maestros/tiposHorasExtras'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltiempo:tipodehoraextra:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/tipodehoraextra/:param',
                    component: loadable(() => import('views/hrm/maestros/tiposHorasExtras'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltiempo:tipodehoraextra:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/horasextras',
                    component: loadable(() => import('views/hrm/maestros/horasExtras/List'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/horasextras/new',
                    component: loadable(() => import('views/hrm/maestros/horasExtras/New'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/horasextras/edit/:id',
                    component: loadable(() => import('views/hrm/maestros/horasExtras/Edit'), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/tiposmodalidadesdetrabajo',
                    component: loadable(() => import(
                      'views/hrm/maestros/gestionTiempo/tiposModalidadesTrabajo'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'gestiondeltiempo:tiposmodalidadesdetrabajo:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/tiposmodalidadesdetrabajo/:param',
                    component: loadable(() => import(
                      'views/hrm/maestros/gestionTiempo/tiposModalidadesTrabajo'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'gestiondeltiempo:tiposmodalidadesdetrabajo:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/modalidadesdetrabajo',
                    component: loadable(() => import(
                      'views/hrm/maestros/gestionTiempo/modalidadesTrabajo/List'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'gestiondeltiempo:modalidadesdetrabajo:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/modalidadesdetrabajo/new',
                    component: loadable(() => import(
                      'views/hrm/maestros/gestionTiempo/modalidadesTrabajo/New'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'gestiondeltiempo:modalidadesdetrabajo:new'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/modalidadesdetrabajo/edit/:id',
                    component: loadable(() => import(
                      'views/hrm/maestros/gestionTiempo/modalidadesTrabajo/Edit'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'gestiondeltiempo:modalidadesdetrabajo:edit'],
                  },
                  {
                    path: '/app/configuracion/recursoshumanos/gestiondeltiempo/ausencias',
                    routes: [
                      {
                        exact: true,
                        path: '/app/configuracion/recursoshumanos/gestiondeltiempo/ausencias/tipospermisos',
                        component: loadable(() => import('views/hrm/maestros/tiposPermisos'), fallBack),
                        permission: ['app:general:configuracion:recursoshumanos:'
                          + 'gestiondeltiempo:ausencias:tipospermisos:view'],
                      },
                      {
                        exact: true,
                        path: '/app/configuracion/recursoshumanos/gestiondeltiempo/ausencias/tipospermisos/:param',
                        component: loadable(() => import('views/hrm/maestros/tiposPermisos'), fallBack),
                        permission: ['app:general:configuracion:recursoshumanos:'
                          + 'gestiondeltiempo:ausencias:tipospermisos:view'],
                      },
                      {
                        exact: true,
                        path: '/app/configuracion/recursoshumanos/gestiondeltiempo/ausencias/licencias',
                        component: loadable(() => import('views/hrm/maestros/gestionTiempo/licencias'), fallBack),
                        permission: ['app:general:configuracion:recursoshumanos:'
                          + 'gestiondeltiempo:ausencias:licencias:view'],
                      },
                      {
                        exact: true,
                        path: '/app/configuracion/recursoshumanos/gestiondeltiempo/ausencias/licencias/:param',
                        component: loadable(() => import('views/hrm/maestros/gestionTiempo/licencias'), fallBack),
                        permission: ['app:general:configuracion:recursoshumanos:'
                          + 'gestiondeltiempo:ausencias:licencias:view'],
                      },
                      {
                        exact: true,
                        path: '/app/configuracion/recursoshumanos/gestiondeltiempo/ausencias/tipodeincapacidad',
                        component: loadable(() => import('views/hrm/maestros/tiposIncapacidades/List'), fallBack),
                        permission: ['app:general:configuracion:recursoshumanos:'
                          + 'gestiondeltiempo:ausencias:tipodeincapacidad:view'],
                      },
                      {
                        exact: true,
                        path: '/app/configuracion/recursoshumanos/gestiondeltiempo/ausencias/tipodeincapacidad/new',
                        component: loadable(() => import('views/hrm/maestros/tiposIncapacidades/New'), fallBack),
                        permission: ['app:general:configuracion:recursoshumanos:'
                          + 'gestiondeltiempo:ausencias:tipodeincapacidad:new'],
                      },
                      {
                        exact: true,
                        path: '/app/configuracion/recursoshumanos/'
                          + 'gestiondeltiempo/ausencias/tipodeincapacidad/:param/:id',
                        component: loadable(() => import('views/hrm/maestros/tiposIncapacidades/Edit'), fallBack),
                        permission: ['app:general:configuracion:recursoshumanos:'
                          + 'gestiondeltiempo:ausencias:tipodeincapacidad:edit'],
                      },
                      {
                        component: () => <Redirect to="/404" />,
                      },
                    ],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                path: '/app/configuracion/recursoshumanos/nomina',
                routes: [
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/nomina/conceptosnomina',
                    component: loadable(() => import('views/hrm/nomina/maestros/conceptosNomina/List'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:nomina:conceptosnomina:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/nomina/smlvauxiliodetransporte',
                    component: loadable(() => import('views/hrm/nomina/maestros/salariosMinimosVigentes'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:nomina:smlvauxiliodetransporte:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/nomina/smlvauxiliodetransporte/:param',
                    component: loadable(() => import('views/hrm/nomina/maestros/salariosMinimosVigentes'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:nomina:smlvauxiliodetransporte:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/nomina/prestacionessociales',
                    component: loadable(() => import('views/hrm/nomina/maestros/prestacionesSociales'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:nomina:prestacionessociales:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/nomina/prestacionessociales/:param',
                    component: loadable(() => import('views/hrm/nomina/maestros/prestacionesSociales'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:nomina:prestacionessociales:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/nomina/frecuenciasdepagos',
                    component: loadable(() => import('views/hrm/maestros/frecuenciasPagos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:nomina:frecuenciasdepagos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/nomina/frecuenciasdepagos/:param',
                    component: loadable(() => import('views/hrm/maestros/frecuenciasPagos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:nomina:frecuenciasdepagos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/nomina/tiposdeducciones',
                    component: loadable(() => import('views/hrm/maestros/tiposDeducciones'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:nomina:tiposdeducciones:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/nomina/tiposdeducciones/:param',
                    component: loadable(() => import('views/hrm/maestros/tiposDeducciones'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:nomina:tiposdeducciones:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/nomina/deducciones',
                    component: loadable(() => import('views/hrm/maestros/deducciones/List'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:nomina:deducciones:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/nomina/deducciones/new',
                    component: loadable(() => import('views/hrm/maestros/deducciones/New'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:nomina:deducciones:new'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/nomina/deducciones/edit/:id',
                    component: loadable(() => import('views/hrm/maestros/deducciones/Edit'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:nomina:deducciones:edit'],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                path: '/app/configuracion/recursoshumanos/pila',
                routes: [
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/pila/tiposdecotizantes',
                    component: loadable(() => import('views/hrm/maestros/tiposCotizantes'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:pila:tiposdecotizantes:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/pila/tiposdecotizantes/:param',
                    component: loadable(() => import('views/hrm/maestros/tiposCotizantes'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:pila:tiposdecotizantes:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/pila/tiposdeplanillas',
                    component: loadable(() => import('views/hrm/maestros/tiposPlanillas'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:pila:tiposdeplantillas:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/pila/tiposdeplanillas/:param',
                    component: loadable(() => import('views/hrm/maestros/tiposPlanillas'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:pila:tiposdeplantillas:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/pila/clasesaportantes',
                    component: loadable(() => import('views/hrm/maestros/pila/maestros/clasesAportantes'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:pila:clasesaportantes:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/pila/clasesaportantes/:param',
                    component: loadable(() => import('views/hrm/maestros/pila/maestros/clasesAportantes'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:pila:clasesaportantes:view'],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                path: '/app/configuracion/recursoshumanos/desvinculacionlaboral',
                routes: [
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/desvinculacionlaboral/requisitospazysalvo',
                    component: loadable(() => import(
                      'views/hrm/maestros/desvinculacionLaboral/requisitosPazYSalvo'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'desvinculacionlaboral:requisitospazysalvo:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/desvinculacionlaboral/requisitospazysalvo/:param',
                    component: loadable(() => import(
                      'views/hrm/maestros/desvinculacionLaboral/requisitosPazYSalvo'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'desvinculacionlaboral:requisitospazysalvo:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/desvinculacionlaboral/pazysalvo',
                    component: loadable(() => import(
                      'views/hrm/maestros/desvinculacionLaboral/pazYsalvo/List'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:desvinculacionlaboral:pazysalvo:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/desvinculacionlaboral/pazysalvo/new',
                    component: loadable(() => import(
                      'views/hrm/maestros/desvinculacionLaboral/pazYsalvo/New'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:desvinculacionlaboral:pazysalvo:new'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/desvinculacionlaboral/pazysalvo/edit/:id',
                    component: loadable(() => import(
                      'views/hrm/maestros/desvinculacionLaboral/pazYsalvo/Edit'
                    ), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:desvinculacionlaboral:pazysalvo:edit'],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                path: '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo',
                routes: [
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/consecutivossst',
                    component: loadable(() => import('views/sst/maestros/consecutivos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:consecutivossst:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/consecutivossst/:param',
                    component: loadable(() => import('views/sst/maestros/consecutivos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:consecutivossst:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/transaccionessst',
                    component: loadable(() => import('views/sst/maestros/documentos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:transaccionessst:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/transaccionessst/:param',
                    component: loadable(() => import('views/sst/maestros/documentos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:transaccionessst:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/tipoderiesgos',
                    component: loadable(() => import('views/sst/maestros/tiposRiesgos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:tipoderiesgos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/tipoderiesgos/:param',
                    component: loadable(() => import('views/sst/maestros/tiposRiesgos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:tipoderiesgos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/clasificacionderiesgos',
                    component: loadable(() => import('views/sst/maestros/clasificacionesRiesgos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:clasificacionderiesgos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/clasificacionderiesgos/:param',
                    component: loadable(() => import('views/sst/maestros/clasificacionesRiesgos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:clasificacionderiesgos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/probabilidadderiesgos',
                    component: loadable(() => import('views/sst/maestros/probabilidadRiesgos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:probabilidadderiesgos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/probabilidadderiesgos/:param',
                    component: loadable(() => import('views/sst/maestros/probabilidadRiesgos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:probabilidadderiesgos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/impactoderiesgos',
                    component: loadable(() => import('views/sst/maestros/impactoRiesgos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:impactoderiesgos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/impactoderiesgos/:param',
                    component: loadable(() => import('views/sst/maestros/impactoRiesgos'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:impactoderiesgos:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/tipoequipotrabajo',
                    component: loadable(() => import('views/sst/maestros/tiposEquiposTrabajo'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:tipoequipodetrabajo:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/tipoequipotrabajo/:param',
                    component: loadable(() => import('views/sst/maestros/tiposEquiposTrabajo'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:tipoequipodetrabajo:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/'
                      + 'saludyseguridadeneltrabajo/elementosdeproteccionprofesional',
                    component: loadable(() => import('views/sst/maestros/elementosProteccionPersonal/List'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:elementosdeproteccionprofesional:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/'
                      + 'saludyseguridadeneltrabajo/elementosdeproteccionprofesional/new',
                    component: loadable(() => import('views/sst/maestros/elementosProteccionPersonal/New'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:elementosdeproteccionprofesional:new'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/'
                      + 'elementosdeproteccionprofesional/edit/:id',
                    component: loadable(() => import('views/sst/maestros/elementosProteccionPersonal/Edit'), fallBack),
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:elementosdeproteccionprofesional:edit'],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                exact: true,
                path: '/app/configuracion/rrhh/bonificaciones',
                component: loadable(() => import('views/hrm/maestros/bonificaciones/List'), fallBack),
              },
              {
                exact: true,
                path: '/app/configuracion/rrhh/bonificaciones/new',
                component: loadable(() => import('views/hrm/maestros/bonificaciones/New'), fallBack),
              },
              {
                exact: true,
                path: '/app/configuracion/rrhh/bonificaciones/edit/:id',
                component: loadable(() => import('views/hrm/maestros/bonificaciones/Edit'), fallBack),
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/configuracion/gobiernocorporativo',
            routes: [
              {
                exact: true,
                path: '/app/configuracion/gobiernocorporativo/consecutivosgobiernocorporativo',
                component: loadable(() => import('views/gobiernoCorporativo/maestros/consecutivos'), fallBack),
                permission: ['app:general:configuracion:gobiernocorporativo:'
                  + 'consecutivosgobiernocorporativo:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gobiernocorporativo/consecutivosgobiernocorporativo/:param',
                component: loadable(() => import('views/gobiernoCorporativo/maestros/consecutivos'), fallBack),
                permission: ['app:general:configuracion:gobiernocorporativo:'
                  + 'consecutivosgobiernocorporativo:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gobiernocorporativo/transaccionesgobiernocorporativo',
                component: loadable(() => import('views/gobiernoCorporativo/maestros/documentos'), fallBack),
                permission: ['app:general:configuracion:gobiernocorporativo:'
                  + 'transaccionesgobiernocorporativo:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gobiernocorporativo/transaccionesgobiernocorporativo/:param',
                component: loadable(() => import('views/gobiernoCorporativo/maestros/documentos'), fallBack),
                permission: ['app:general:configuracion:gobiernocorporativo:'
                  + 'transaccionesgobiernocorporativo:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gobiernocorporativo/configuracionempresa',
                component: loadable(() => import('views/general/configuracion/configuracion'), fallBack),
                permission: ['app:general:configuracion:gobiernocorporativo:configuracionempresa:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/gobiernocorporativo/sedesempresa',
                component: loadable(() => import('views/gobiernoCorporativo/maestros/sedesEmpresa/List'), fallBack),
                permission: ['app:general:configuracion:gobiernocorporativo:sedesempresa:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gobiernocorporativo/sedesempresa/new',
                component: loadable(() => import('views/gobiernoCorporativo/maestros/sedesEmpresa/New'), fallBack),
                permission: ['app:general:configuracion:gobiernocorporativo:sedesempresa:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/gobiernocorporativo/sedesempresa/edit/:id',
                component: loadable(() => import('views/gobiernoCorporativo/maestros/sedesEmpresa/Edit'), fallBack),
                permission: ['app:general:configuracion:gobiernocorporativo:sedesempresa:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/gobiernocorporativo/clasificacioncalendario',
                component: loadable(() => import('views/gobiernoCorporativo/maestros/tiposCalendarios'), fallBack),
                permission: ['app:general:configuracion:gobiernocorporativo:clasificacioncalendario:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gobiernocorporativo/clasificacioncalendario/:param',
                component: loadable(() => import('views/gobiernoCorporativo/maestros/tiposCalendarios'), fallBack),
                permission: ['app:general:configuracion:gobiernocorporativo:clasificacioncalendario:view'],
              },
              {
                path: '/app/configuracion/gobiernocorporativo/gestionempresarial',
                routes: [
                  {
                    exact: true,
                    path: '/app/configuracion/gobiernocorporativo/gestionempresarial/faltas',
                    component: loadable(() => import(
                      'views/gobiernoCorporativo/gestionEmpresarial/maestros/faltas/List'
                    ), fallBack),
                    permission: ['app:general:configuracion:gobiernocorporativo:gestionempresarial:faltas:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/gobiernocorporativo/gestionempresarial/faltas/new',
                    component: loadable(() => import(
                      'views/gobiernoCorporativo/gestionEmpresarial/maestros/faltas/New'
                    ), fallBack),
                    permission: ['app:general:configuracion:gobiernocorporativo:gestionempresarial:faltas:new'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/gobiernocorporativo/gestionempresarial/faltas/edit/:id',
                    component: loadable(() => import(
                      'views/gobiernoCorporativo/gestionEmpresarial/maestros/faltas/Edit'
                    ), fallBack),
                    permission: ['app:general:configuracion:gobiernocorporativo:gestionempresarial:faltas:edit'],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                path: '/app/configuracion/gobiernocorporativo/organigrama',
                routes: [
                  {
                    exact: true,
                    path: '/app/configuracion/gobiernocorporativo/organigrama/estructuraorganizacional',
                    component: loadable(() => import(
                      'views/gobiernoCorporativo/gestionEmpresarial/maestros/tipoOrganigrama'
                    ), fallBack),
                    permission: ['app:general:configuracion:gobiernocorporativo:'
                      + 'organigrama:estructuraorganizacional:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/gobiernocorporativo/organigrama/estructuraorganizacional/:param',
                    component: loadable(() => import(
                      'views/gobiernoCorporativo/gestionEmpresarial/maestros/tipoOrganigrama'
                    ), fallBack),
                    permission: ['app:general:configuracion:gobiernocorporativo:'
                      + 'organigrama:estructuraorganizacional:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/gobiernocorporativo/organigrama/estructuraorganizacional/:id',
                    component: loadable(() => import(
                      'views/gobiernoCorporativo/gestionEmpresarial/maestros/tipoOrganigrama'
                    ), fallBack),
                    permission: ['app:general:configuracion:gobiernocorporativo:'
                      + 'organigrama:estructuraorganizacional:view'],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                path: '/app/configuracion/gobiernocorporativo/calidad',
                routes: [
                  {
                    exact: true,
                    path: '/app/configuracion/gobiernocorporativo/calidad/consecutivoscalidad',
                    component: loadable(() => import('views/pqrsf/maestros/consecutivos'), fallBack),
                    permission: ['app:general:configuracion:gobiernocorporativo:calidad:consecutivoscalidad:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/gobiernocorporativo/calidad/consecutivoscalidad/:param',
                    component: loadable(() => import('views/pqrsf/maestros/consecutivos'), fallBack),
                    permission: ['app:general:configuracion:gobiernocorporativo:calidad:consecutivoscalidad:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/gobiernocorporativo/calidad/transaccionescalidad',
                    component: loadable(() => import('views/pqrsf/maestros/documentos'), fallBack),
                    permission: ['app:general:configuracion:gobiernocorporativo:calidad:transaccionescalidad:view'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/gobiernocorporativo/calidad/transaccionescalidad/:param',
                    component: loadable(() => import('views/pqrsf/maestros/documentos'), fallBack),
                    permission: ['app:general:configuracion:gobiernocorporativo:calidad:transaccionescalidad:view'],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/configuracion/gestiondocumental',
            routes: [
              {
                exact: true,
                path: '/app/configuracion/gestiondocumental/clasificacionpoliticas',
                component: loadable(() => import(
                  'views/gobiernoCorporativo/gestionEmpresarial/maestros/clasificacionesPoliticas'
                ), fallBack),
                permission: ['app:general:configuracion:gestiondocumental:clasificacionpoliticas:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestiondocumental/clasificacionpoliticas/:param',
                component: loadable(() => import(
                  'views/gobiernoCorporativo/gestionEmpresarial/maestros/clasificacionesPoliticas'
                ), fallBack),
                permission: ['app:general:configuracion:gestiondocumental:clasificacionpoliticas:view'],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/configuracion/gestiondecontratos',
            routes: [
              {
                exact: true,
                path: '/app/configuracion/gestiondecontratos/consecutivosgestiondecontratos',
                component: loadable(() => import('views/gestionContratos/maestros/consecutivos'), fallBack),
                permission: ['app:general:configuracion:gestiondecontratos:consecutivosgestiondecontratos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestiondecontratos/consecutivosgestiondecontratos/:param',
                component: loadable(() => import('views/gestionContratos/maestros/consecutivos'), fallBack),
                permission: ['app:general:configuracion:gestiondecontratos:consecutivosgestiondecontratos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestiondecontratos/transaccionesgestiondecontratos',
                component: loadable(() => import('views/gestionContratos/maestros/documentos'), fallBack),
                permission: ['app:general:configuracion:gestiondecontratos:transaccionesgestiondecontratos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestiondecontratos/transaccionesgestiondecontratos/:param',
                component: loadable(() => import('views/gestionContratos/maestros/documentos'), fallBack),
                permission: ['app:general:configuracion:gestiondecontratos:transaccionesgestiondecontratos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestiondecontratos/tiposdocumentoscontratos',
                component: loadable(() => import('views/gestionContratos/maestros/tipoDocumentoContrato'), fallBack),
                permission: ['app:general:configuracion:gestiondecontratos:tiposdocumentoscontratos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestiondecontratos/tiposdocumentoscontratos/:param',
                component: loadable(() => import('views/gestionContratos/maestros/tipoDocumentoContrato'), fallBack),
                permission: ['app:general:configuracion:gestiondecontratos:tiposdocumentoscontratos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestiondecontratos/clasificacioncontratos',
                component: loadable(() => import('views/gestionContratos/maestros/clasificacionContrato'), fallBack),
                permission: ['app:general:configuracion:gestiondecontratos:clasificacioncontratos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestiondecontratos/clasificacioncontratos/:param',
                component: loadable(() => import('views/gestionContratos/maestros/clasificacionContrato'), fallBack),
                permission: ['app:general:configuracion:gestiondecontratos:clasificacioncontratos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestiondecontratos/partesinvolucradas',
                component: loadable(() => import('views/gestionContratos/maestros/partesInvolucradas'), fallBack),
                permission: ['app:general:configuracion:gestiondecontratos:partesinvolucradas:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestiondecontratos/partesinvolucradas/:param',
                component: loadable(() => import('views/gestionContratos/maestros/partesInvolucradas'), fallBack),
                permission: ['app:general:configuracion:gestiondecontratos:partesinvolucradas:view'],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/configuracion/gestionsolicitudes',
            routes: [
              {
                exact: true,
                path: '/app/configuracion/gestionsolicitudes/categoriassolicitud',
                component: loadable(() => import('views/pqrsf/maestros/tipoSolicitud'), fallBack),
                permission: ['app:general:configuracion:gestionsolicitudes:categoriassolicitud:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionsolicitudes/categoriassolicitud/:param',
                component: loadable(() => import('views/pqrsf/maestros/tipoSolicitud'), fallBack),
                permission: ['app:general:configuracion:gestionsolicitudes:categoriassolicitud:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionsolicitudes/niveldeservicio',
                component: loadable(() => import('views/calidad/maestros/nivelesServicios/List'), fallBack),
                permission: ['app:general:configuracion:gestionsolicitudes:niveldeservicio:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionsolicitudes/niveldeservicio/new',
                component: loadable(() => import('views/calidad/maestros/nivelesServicios/New'), fallBack),
                permission: ['app:general:configuracion:gestionsolicitudes:niveldeservicio:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionsolicitudes/niveldeservicio/edit/:id',
                component: loadable(() => import('views/calidad/maestros/nivelesServicios/Edit'), fallBack),
                permission: ['app:general:configuracion:gestionsolicitudes:niveldeservicio:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionsolicitudes/nivel',
                component: loadable(() => import('views/calidad/maestros/niveles'), fallBack),
                permission: ['app:general:configuracion:gestionsolicitudes:nivel:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionsolicitudes/nivel/:param',
                component: loadable(() => import('views/calidad/maestros/niveles'), fallBack),
                permission: ['app:general:configuracion:gestionsolicitudes:nivel:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionsolicitudes/prioridadimpacto',
                component: loadable(() => import('views/calidad/maestros/prioridadImpacto'), fallBack),
                permission: ['app:general:configuracion:gestionsolicitudes:prioridadimpacto:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/gestionsolicitudes/prioridadimpacto/:param',
                component: loadable(() => import('views/calidad/maestros/prioridadImpacto'), fallBack),
                permission: ['app:general:configuracion:gestionsolicitudes:prioridadimpacto:view'],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/configuracion/centroayuda',
            routes: [
              {
                exact: true,
                path: '/app/configuracion/centroayuda/ayudasgraficas',
                component: loadable(() => import('views/centroAyuda/maestros/ayudasGraficas/List'), fallBack),
                permission: ['app:general:configuracion:centrodeayuda:ayudasgraficas:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/centroayuda/ayudasgraficas/new',
                component: loadable(() => import('views/centroAyuda/maestros/ayudasGraficas/New'), fallBack),
                permission: ['app:general:configuracion:centrodeayuda:ayudasgraficas:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/centroayuda/ayudasgraficas/edit/:id',
                component: loadable(() => import('views/centroAyuda/maestros/ayudasGraficas/Edit'), fallBack),
                permission: ['app:general:configuracion:centrodeayuda:ayudasgraficas:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/centroayuda/preguntasfrecuentes',
                component: loadable(() => import('views/centroAyuda/procesos/preguntasFrecuentes/List'), fallBack),
                permission: ['app:general:configuracion:centrodeayuda:preguntasfrecuentes:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/centroayuda/preguntasfrecuentes/new',
                component: loadable(() => import('views/centroAyuda/procesos/preguntasFrecuentes/New'), fallBack),
                permission: ['app:general:configuracion:centrodeayuda:preguntasfrecuentes:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/centroayuda/preguntasfrecuentes/edit/:id',
                component: loadable(() => import('views/centroAyuda/procesos/preguntasFrecuentes/Edit'), fallBack),
                permission: ['app:general:configuracion:centrodeayuda:preguntasfrecuentes:edit'],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/configuracion/general',
            routes: [
              {
                exact: true,
                path: '/app/configuracion/general/atributosgenerales',
                component: loadable(() => import('views/general/maestros/atributos/atributosGenerales/List'), fallBack),
                permission: ['app:general:configuracion:general:atributosgenerales:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/general/atributosgenerales/new',
                component: loadable(() => import('views/general/maestros/atributos/atributosGenerales/New'), fallBack),
                permission: ['app:general:configuracion:general:atributosgenerales:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/general/atributosgenerales/edit/:id',
                component: loadable(() => import('views/general/maestros/atributos/atributosGenerales/Edit'), fallBack),
                permission: ['app:general:configuracion:general:atributosgenerales:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/general/imprimibles',
                component: loadable(() => import('views/general/maestros/imprimibles/List')),
                permission: ['app:general:configuracion:general:imprimibles:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/general/imprimibles/new',
                component: loadable(() => import('views/general/maestros/imprimibles/New')),
                permission: ['app:general:configuracion:general:imprimibles:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/general/imprimibles/edit/:id',
                component: loadable(() => import('views/general/maestros/imprimibles/Edit')),
                permission: ['app:general:configuracion:general:imprimibles:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/general/encuestas',
                component: loadable(() => import('views/general/maestros/encuestas/List'), fallBack),
                permission: ['app:general:configuracion:general:encuestas:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/general/encuestas/new',
                component: loadable(() => import('views/general/maestros/encuestas/New'), fallBack),
                permission: ['app:general:configuracion:general:encuestas:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/general/encuestas/edit/:id',
                component: loadable(() => import('views/general/maestros/encuestas/Edit'), fallBack),
                permission: ['app:general:configuracion:general:encuestas:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/general/tiposadjuntos',
                component: loadable(() => import('views/general/maestros/tiposDocumentos/List'), fallBack),
                permission: ['app:general:configuracion:general:tiposadjuntos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/general/tiposadjuntos/new',
                component: loadable(() => import('views/general/maestros/tiposDocumentos/New'), fallBack),
                permission: ['app:general:configuracion:general:tiposadjuntos:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/general/tiposadjuntos/edit/:id',
                component: loadable(() => import('views/general/maestros/tiposDocumentos/Edit'), fallBack),
                permission: ['app:general:configuracion:general:tiposadjuntos:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/general/tiposdeidentificacion',
                component: loadable(() => import('views/general/maestros/tiposIdentificacion'), fallBack),
                permission: ['app:general:configuracion:general:tiposdeidentificacion:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/general/tiposdeidentificacion/:param',
                component: loadable(() => import('views/general/maestros/tiposIdentificacion'), fallBack),
                permission: ['app:general:configuracion:general:tiposdeidentificacion:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/general/nomenclaturas',
                component: loadable(() => import('views/general/maestros/nomenclaturas'), fallBack),
                permission: ['app:general:configuracion:general:nomenclaturas:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/general/nomenclaturas/:param',
                component: loadable(() => import('views/general/maestros/nomenclaturas'), fallBack),
                permission: ['app:general:configuracion:general:nomenclaturas:view'],
              },
              {
                path: '/app/configuracion/general/atributos',
                routes: [
                  {
                    exact: true,
                    path: '/app/configuracion/general/atributos/etiquetas',
                    component: loadable(() => import('views/general/maestros/atributos/etiquetas'), fallBack),
                    permission: ['configuracion:general:atributos:etiquetas:list'],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                exact: true,
                path: '/app/configuracion/general/documentoselectronicos',
                component: loadable(() => import('views/general/maestros/documentosElectronicos'), fallBack),
                permission: ['configuracion:general:documentoselectronicos:list'],
              },
              {
                path: '/app/configuracion/general/trazabilidad',
                routes: [
                  {
                    exact: true,
                    path: '/app/configuracion/general/trazabilidad/puntocontrol',
                    component: loadable(() => import(
                      'views/general/maestros/trazabilidad/puntoControl/List'
                    ), fallBack),
                    permission: ['configuracion:general:trazabilidad:puntoscontrol:list'],
                  },

                  {
                    exact: true,
                    path: '/app/configuracion/general/trazabilidad/mastertrazabilidad',
                    component: loadable(() => import(
                      'views/general/maestros/trazabilidad/masterTrazabilidad/List'
                    ), fallBack),
                    permission: ['configuracion:general:trazabilidad:mastertrazabilidad:list'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/general/trazabilidad/mastertrazabilidad/new',
                    component: loadable(() => import(
                      'views/general/maestros/trazabilidad/masterTrazabilidad/New'
                    ), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/general/trazabilidad/mastertrazabilidad/edit/:id',
                    component: loadable(() => import(
                      'views/general/maestros/trazabilidad/masterTrazabilidad/Edit'
                    ), fallBack),
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
              {
                path: '/app/configuracion/general/tributarios',
                routes: [
                  {
                    exact: true,
                    path: '/app/configuracion/general/tributarios/clasificacionTributariaTercero',
                    component: loadable(() => import(
                      'views/general/maestros/tributarios/clasificacionTributariaTercero/List'
                    ), fallBack),
                    permission: ['configuracion:general:tributarios:clasificacionestributariasterceros:list'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/general/tributarios/clasificacionTributarioTercero/edit/:id',
                    component: loadable(() => import(
                      'views/general/maestros/tributarios/clasificacionTributariaTercero/Edit'
                    ), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/general/tributarios/clasificacionTributarioItem',
                    component: loadable(() => import(
                      'views/general/maestros/tributarios/clasificacionTributarioItem/List'
                    ), fallBack),
                    permission: ['configuracion:general:tributarios:clasificacionestributariasitems:list'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/general/tributarios/clasificacionTributarioItem/edit/:id',
                    component: loadable(() => import(
                      'views/general/maestros/tributarios/clasificacionTributarioItem/Edit'
                    ), fallBack),
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/general/tributarios/conceptos',
                    component: loadable(() => import(
                      'views/general/maestros/tributarios/conceptoTributario'
                    ), fallBack),
                    permission: ['configuracion:general:tributarios:conceptostributarios:list'],
                  },
                  {
                    exact: true,
                    path: '/app/configuracion/general/tributariosclasificacionTributariaTercero',
                    component: loadable(() => import(
                      'views/general/maestros/tributarios/clasificacionTributariaTercero/List'
                    ), fallBack),
                    permission: ['app:general:configuracion:contabilidad:tributarios:clasificaciontributaria:view'],
                  },
                  {
                    component: () => <Redirect to="/404" />,
                  },
                ],
              },
            ],
          },
          {
            path: '/app/configuracion/divisionpolitica',
            routes: [
              {
                exact: true,
                path: '/app/configuracion/divisionpolitica/paises',
                component: loadable(() => import('views/general/maestros/divisionPolitica/paises'), fallBack),
                permission: ['app:general:configuracion:divisionpolitica:paises:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/divisionpolitica/paises/:param',
                component: loadable(() => import('views/general/maestros/divisionPolitica/paises'), fallBack),
                permission: ['app:general:configuracion:divisionpolitica:paises:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/divisionpolitica/paises/:id',
                component: loadable(() => import('views/general/maestros/divisionPolitica/paises'), fallBack),
              },
              {
                exact: true,
                path: '/app/configuracion/divisionpolitica/departamentos',
                component: loadable(() => import('views/general/maestros/divisionPolitica/departamentos'), fallBack),
                permission: ['app:general:configuracion:divisionpolitica:departamentos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/divisionpolitica/departamentos/:param',
                component: loadable(() => import('views/general/maestros/divisionPolitica/departamentos'), fallBack),
                permission: ['app:general:configuracion:divisionpolitica:departamentos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/divisionpolitica/ciudades',
                component: loadable(() => import('views/general/maestros/divisionPolitica/ciudades/List'), fallBack),
                permission: ['app:general:configuracion:divisionpolitica:ciudades:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/divisionpolitica/ciudades/:param',
                component: loadable(() => import('views/general/maestros/divisionPolitica/ciudades/List'), fallBack),
                permission: ['app:general:configuracion:divisionpolitica:ciudades:view'],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/configuracion/notificaciones',
            routes: [
              {
                exact: true,
                path: '/app/configuracion/notificaciones/notificacionesprocesos',
                component: loadable(() => import('views/general/maestros/notificaciones/List'), fallBack),
                permission: ['app:general:configuracion:notificaciones:notificacionesprocesos:view'],
              },
              // {
              //   exact: true,
              //   path: '/app/configuracion/general/notificaciones/new',
              // eslint-disable-next-line max-len
              //   component: loadable(() => import('views/general/maestros/notificaciones/New'), fallBack),
              // },
              {
                exact: true,
                path: '/app/configuracion/notificaciones/notificacionesprocesos/edit/:id',
                component: loadable(() => import('views/general/maestros/notificaciones/Edit'), fallBack),
              },
              {
                exact: true,
                path: '/app/configuracion/notificaciones/correosnotificaciones',
                component: loadable(() => import('views/general/maestros/emailNotifications/List'), fallBack),
                permission: ['app:general:configuracion:notificaciones:correosnotificaciones:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/notificaciones/correosnotificaciones/:param',
                component: loadable(() => import('views/general/maestros/emailNotifications/List'), fallBack),
                permission: ['app:general:configuracion:notificaciones:correosnotificaciones:view'],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: '/app/configuracion/seguridad',
            routes: [
              {
                exact: true,
                path: '/app/configuracion/seguridad/usuarios',
                component: loadable(() => import('views/seguridad/usuarios/List'), fallBack),
                permission: ['app:general:configuracion:seguridad:usuarios:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/seguridad/usuarios/new',
                component: loadable(() => import('views/seguridad/usuarios/New'), fallBack),
                permission: ['app:general:configuracion:seguridad:usuarios:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/seguridad/usuarios/edit/:id',
                component: loadable(() => import('views/seguridad/usuarios/Edit'), fallBack),
                permission: ['app:general:configuracion:seguridad:usuarios:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/seguridad/cambiodecontraseña',
                component: loadable(() => import('views/seguridad/perfil'), fallBack),
                permission: ['app:general:configuracion:seguridad:cambiodecontrasena:general:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/seguridad/roles',
                component: loadable(() => import('views/seguridad/roles/List'), fallBack),
                permission: ['app:general:configuracion:seguridad:roles:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/seguridad/roles/new',
                component: loadable(() => import('views/seguridad/roles/New'), fallBack),
                permission: ['app:general:configuracion:seguridad:roles:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/seguridad/roles/edit/:id',
                component: loadable(() => import('views/seguridad/roles/Edit'), fallBack),
                permission: ['app:general:configuracion:seguridad:roles:edit'],
              },
              {
                exact: true,
                path: '/app/configuracion/seguridad/grupospermisos',
                component: loadable(() => import('views/seguridad/grupoPermisos/List'), fallBack),
                permission: ['app:general:configuracion:seguridad:grupospermisos:view'],
              },
              {
                exact: true,
                path: '/app/configuracion/seguridad/grupospermisos/new',
                component: loadable(() => import('views/seguridad/grupoPermisos/New'), fallBack),
                permission: ['app:general:configuracion:seguridad:grupospermisos:new'],
              },
              {
                exact: true,
                path: '/app/configuracion/seguridad/grupospermisos/edit/:id',
                component: loadable(() => import('views/seguridad/grupoPermisos/Edit'), fallBack),
                permission: ['app:general:configuracion:seguridad:grupospermisos:edit'],
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
        ],
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
