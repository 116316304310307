import React from 'react';
import PropTypes from 'prop-types';
import {
  Switch,
  FormControlLabel,
} from '@material-ui/core';

const SwitchBase = React.forwardRef(({
  label,
  field: {
    name, value,
  },
  form: {
    setFieldValue, setFieldTouched, ...form
  },
  onChangeProps,
  ...props
}, ref) => {
  const handleChange = (event) => {
    if (onChangeProps) {
      onChangeProps(event, form);
    }
    setFieldTouched(name, true);
    setFieldValue(name, event.target.checked);
  };
  return (
    <FormControlLabel
      control={(
        <Switch
          id={name}
          inputRef={ref}
          checked={Boolean(value)}
          value={name}
          onChange={handleChange}
          {...props}
        />
      )}
      label={label}
    />
  );
});

SwitchBase.displayName = 'SwitchBase';

SwitchBase.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.any,
    ]),
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape(),
    touched: PropTypes.shape(),
    setFieldTouched: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  label: PropTypes.string,
  onChangeProps: PropTypes.func,
};

SwitchBase.defaultProps = {
  label: '',
};

export default SwitchBase;
