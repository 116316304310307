import React, { useState, useRef } from 'react';
import endPoints from 'endPoints/endPoints';
import { masterMessages, messages } from 'constantes';
import PropTypes from 'prop-types';
import DefaultActions from 'components/Table/DefaultActions';
import { withApi, withNotification } from 'wrappers';
import {
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import ConfirmModal from '../dialogs/ConfirmModal';
import Tab from '../Tab/Tab';
import Table from '../Table/Table';
import CalendarioTrabajoForm from './CalendarioTrabajoForm';
import Excepcion from './Excepciones/Excepcion';
import Horario from './Horario/Horario';

/**
 * @name Permissions
 * @desc Objeto que contiene los permisos de calendario trabajo en configuracion empresa
 * @type {{createPermissions: [], updatePermissions: [], deletePermissions: []}}
 */
const permissions = {
  createPermissions: ['app:general:configuracion:gobiernocorporativo:configuracionempresa:calendariotrabajo:new'],
  updatePermissions: ['app:general:configuracion:gobiernocorporativo:configuracionempresa:calendariotrabajo:edit'],
  deletePermissions: ['app:general:configuracion:gobiernocorporativo:configuracionempresa:calendariotrabajo:delete'],
};

const CalendarioTrabajo = ({
  entidadId,
  abreviatura,
  doPut,
  doPost,
  doDelete,
  appInfo,
  appSuccess,
  appWarning,
  genericException,
}) => {
  const calMessages = masterMessages.general.calendarioTrabajo;
  const endPoint = endPoints.general.calendarioTrabajo;
  const child = useRef();
  const [idToDelete, setIdToDelete] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [idEdit, setIdEdit] = useState(null);

  const handleModalDelete = ({ id = null } = {}) => setIdToDelete(id);

  const handleFormModal = () => setIsOpenModal(!isOpenModal);

  const handleEditModal = ({ id = null } = {}) => setIdEdit(id);

  const columns = [
    {
      name: 'nombre',
      label: 'nombre',
      type: 'autocomplete',
      extra: {
        url: endPoints.general.calendarioTrabajo.autocomplete,
        requestParams: { filter: true },
      },
    },
    {
      label: 'Clasificación calendario',
      name: 'tipoCalendario'
    },
    {
      name: 'principal',
      label: 'principal',
      type: 'check',
    },
    {
      name: 'activo',
      label: 'activo',
      type: 'active',
    },
    {
      name: 'acciones',
      label: 'acciones',
      filter: false,
      component: row => (
        <DefaultActions
          onDelete={handleModalDelete}
          onEdit={handleEditModal}
          row={row}
          updatePermissions={permissions.updatePermissions}
          deletePermissions={permissions.deletePermissions}
        />
      ),
    },
  ];

  const deleteRegister = async () => {
    try {
      await doDelete({
        url: `${endPoint.base}/${idToDelete}`,
      });
      child.current.refresh();
      appWarning(messages.crud.delete);
      handleModalDelete();
    } catch (e) {
      genericException(e);
    }
  };

  const mapValues = values => ({
    ...values,
    limitar_calendario_empleados: values.limitarCalendarioEmpleado,
    abreviatura,
    entidad_id: entidadId,
    tipo_calendario_id: values.tipoCalendario,
  });

  const onSubmit = async (formValues) => {
    try {
      if (idEdit) {
        await doPut({
          url: `${endPoint.base}/${idEdit}`,
          data: mapValues(formValues),
        });
        appInfo(messages.crud.update);
        handleEditModal();
      } else {
        await doPost({
          url: endPoint.base,
          data: mapValues(formValues),
        });
        appSuccess(messages.crud.new);
        handleFormModal();
      }
      child.current.refresh();
    } catch (e) {
      genericException(e);
    }
  };

  const tabConfig = [
    {
      label: 'Calendario',
      component: <CalendarioTrabajoForm
        idEdit={idEdit}
        onSubmit={onSubmit}
        closeModal={handleEditModal}
      />,
    },
    {
      label: 'excepciones',
      component: <Excepcion calendarioTrabajoId={idEdit} />,
    },
    {
      label: 'horario',
      component: <Horario calendarioTrabajoId={idEdit} />,
    },
  ];

  return (
    <>
      <Dialog open={isOpenModal} onClose={handleFormModal} fullWidth maxWidth="xl">
        <DialogTitle>
          Creación calendario de trabajo
        </DialogTitle>
        <DialogContent>
          <CalendarioTrabajoForm onSubmit={onSubmit} closeModal={handleFormModal} />
        </DialogContent>
      </Dialog>
      <Dialog open={Boolean(idEdit)} onClose={handleEditModal} fullWidth maxWidth="xl">
        <Tab config={tabConfig} />
      </Dialog>
      <ConfirmModal
        open={Boolean(idToDelete)}
        onAccept={deleteRegister}
        onClose={handleModalDelete}
        onCancel={handleModalDelete}
        title={calMessages.deleteTitle}
        message={calMessages.deleteMessage}
      />
      <Table
        forwardedRef={child}
        serverSideUrl={endPoint.base}
        serverSideData={{ entidadId, abreviatura }}
        columns={columns}
        onCreate={handleFormModal}
        createPermissions={permissions.createPermissions}
      />
    </>
  );
};

CalendarioTrabajo.propTypes = {
  entidadId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  abreviatura: PropTypes.string.isRequired,
  doPut: PropTypes.func.isRequired,
  doPost: PropTypes.func.isRequired,
  doDelete: PropTypes.func.isRequired,
  appInfo: PropTypes.func.isRequired,
  appSuccess: PropTypes.func.isRequired,
  appWarning: PropTypes.func.isRequired,
  genericException: PropTypes.func.isRequired,
};

export default withApi(withNotification(CalendarioTrabajo));
