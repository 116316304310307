import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@material-ui/core';

/**
 * This component allows to render a button specific to aprobar actions.
 * @param {*} param0
 */
function ButtonAprobar({
  className, style, disabled, label = 'Aprobar', ...rest
}) {
  return (
    <Button
      className={className}
      style={style}
      variant="contained"
      type="submit"
      color="primary"
      disabled={disabled}
      {...rest}
    >
      {label}
    </Button>
  );
}

ButtonAprobar.defaultProps = {
  className: '',
  style: {},
  disabled: false,
};

ButtonAprobar.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  style: PropTypes.shape(),
};

export default ButtonAprobar;
