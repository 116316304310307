import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { dotNotationExtractor } from 'utils/functions';
import { isFunction } from 'formik';

function Row({ item, columns }) {
  if (!item || !columns || !columns.length) {
    return null;
  }
  const cells = columns.map((column, index) => {
    const { align = 'left', name = '', content } = column;
    let cellContent = content || dotNotationExtractor(item, name);
    cellContent = isFunction(cellContent) ? cellContent(item) : cellContent;
    return (
      <TableCell
        key={String(index)}
        align={align}
      >
        {cellContent}
      </TableCell>
    );
  });

  return (
    <TableRow>
      {cells}
    </TableRow>
  );
}

Row.propTypes = {
  item: PropTypes.shape(),
  columns: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
    // contenido específico para la celda
    content: PropTypes.oneOfType([
      // puede ser un componente de react ya listo
      PropTypes.node,
      // puede ser una función a la cual se le entregará el item por parametro
      PropTypes.func,
      // un string
      PropTypes.string,
      // un numero
      PropTypes.number,
    ]),
  })).isRequired,
};

export default Row;
