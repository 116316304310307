import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import { Abreviaturas } from 'constantes';
import SwitchBase from './SwitchBase';
import TextBase from './TextBase';
import SelectBase from './SelectBase';
import NumberBase from './NumberBase';
import DropdownMultiple from './DropdownMultiple';
import DefaultActions from '../Table/DefaultActions';
import DatePickerBase from '../Pickers/DatePickerBase';
import PercentBase from './PercentBase';

/**
 * This component allows to render a text area ready to work with formitk
 * @param {*} param0
 */
const FormDinamicBase = ({
  atributosArray, openModal, deletePermissions, deleteBool = true,
}) => {
  const formAtributos = atributosArray || [];

  /**
     * @name geDinamicForm
     * @desc Funcion donde realiza la selección del campo a mostrar luego de la iteración
     * @param elementSchema
     */
  const getDinamicForm = (elementSchema) => {
    const selectItems = [];
    let field;
    if (elementSchema.seleccion) {
      elementSchema.seleccion.forEach((item) => {
        selectItems.push({ value: item, label: item });
      });
    }

    const props = {
      name: elementSchema.nombre || '',
      label: elementSchema.nombre || '',
      items: selectItems || [],
    };

    if (elementSchema.extraProps) {
      elementSchema.extraProps.forEach((item) => {
        props[item.propName] = item.propValue;
      });
    }

    switch (elementSchema.tipoAbreviatura) {
    case Abreviaturas.FIELD_TYPE_TEXT:
      field = <Field component={TextBase} {...props} />;
      break;
    case Abreviaturas.FIELD_TYPE_SELECT:
      field = <Field component={SelectBase} {...props} />;
      break;
    case Abreviaturas.FIELD_TYPE_NUMBERM:
      field = <Field component={NumberBase} {...props} />;
      break;
    case Abreviaturas.FIELD_TYPE_NUMBER:
      field = <Field type="number" component={TextBase} {...props} />;
      break;
    case Abreviaturas.FIELD_TYPE_DATE:
      field = <Field component={DatePickerBase} {...props} />;
      break;
    case Abreviaturas.FIELD_TYPE_LOGIC:
      field = <Field component={SwitchBase} {...props} />;
      break;
    case Abreviaturas.FIELD_TYPE_MULTIPLE:
      field = <Field component={DropdownMultiple} {...props} />;
      break;
    case Abreviaturas.FIELD_TYPE_PERCENT:
      field = <Field component={PercentBase} {...props} />;
      break;
    default:
      field = <Field component={TextBase} {...props} />;
    }
    return field;
  };

  return (
    <Grid container direction="row" spacing={3}>
      {Object.keys(formAtributos).map(key => (
        <Grid item xs={12} md={6} xl={6} key={key} style={{ display: 'flex' }}>
          {getDinamicForm(formAtributos[key])}
          {deleteBool && (
            <DefaultActions
              onDelete={() => openModal(formAtributos[key])}
              row={formAtributos[key]}
              deletePermissions={deletePermissions}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

FormDinamicBase.propTypes = {
  openModal: PropTypes.func,
  atributosArray: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  deleteBool: PropTypes.bool,
  deletePermissions: PropTypes.oneOfType([PropTypes.array]),
};

export default FormDinamicBase;
