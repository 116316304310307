import React from 'react';
import PropTypes from 'prop-types';
import { TableBody } from '@material-ui/core';
import Row from './Row';

function Body({ items, columns }) {
  if (!items || !items.length || !columns || !columns.length) {
    return null;
  }
  return (
    <TableBody>
      {items.map((item, index) => (
        <Row
          key={`row-${String(index)}`}
          item={item}
          columns={columns}
        />
      ))}
    </TableBody>
  );
}

Body.propTypes = {
  items: PropTypes.arrayOf(PropTypes.objectOf([
    PropTypes.object,
  ])),
  columns: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
  })).isRequired,
};

export default Body;
