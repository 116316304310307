import React from 'react';
import PropTypes from 'prop-types';
import { withPermissionsChecker } from 'wrappers';
import { green } from '@material-ui/core/colors';
import ButtonGeneral from './ButtonGeneral';

/**
 * This component allows to render a button specific to save actions.
 * @param {*} param0
 */
function CreateFormButton({
  onClick,
  requiredPermissions = [],
  checkAccess,
  startIcon,
  disabled = false,
  label = 'Agregar',
  color = green[500],
  ...rest
}) {
  return (
    <ButtonGeneral
      label={label}
      style={{ marginRight: 15 }}
      color={color}
      disabled={!checkAccess(requiredPermissions) || disabled}
      onClick={() => onClick()}
      icon={startIcon}
      {...rest}
    />
  );
}

CreateFormButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  startIcon: PropTypes.node,
  onClick: PropTypes.func,
  checkAccess: PropTypes.func,
  color: PropTypes.string,
  requiredPermissions: PropTypes.arrayOf(PropTypes.string),
};

export default withPermissionsChecker(CreateFormButton);
