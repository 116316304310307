/**
 * @author John Montoya
 * @description The TabControl component handles the rendering of tabs with optional permissions and state persistence.
 */

import React from 'react';
import {
  Typography, Tabs, Tab, Box, Paper, AppBar, Divider, withStyles,
} from '@material-ui/core';
import Cookie from 'js-cookie';
import PropTypes from 'prop-types';
import { HelpCircle } from 'react-feather';
import { withRouter } from 'react-router-dom';
import { withPermissionsChecker } from 'wrappers';
import { Alert } from '@material-ui/lab';
import Loader from '../LoadingScreen';

/**
 * Props:
 * - config (required): Array of tab configurations containing label, component, and optional permissions.
 * - selected (optional): Index of the initially selected tab.
 * - keepSelected (optional): Boolean to determine if the selected tab should be saved in cookies.
 * - setSelected (optional): Function to be called when a new tab is selected.
 * - loading (optional): Boolean indicating whether the component is in a loading state.
 * - reference (optional): Reference to control the state of the component externally.
 * - checkAccess (optional): Function to check user access for a particular tab based on permissions.
 */

const styles = theme => ({
  tab: {
    '& .MuiTab-wrapper': {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
  },
  tabSize: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: window.screen.width - 30,
    },
  },
});

class TabControl extends React.Component {
  constructor(props) {
    super(props);

    const { selected, reference } = props;

    // Initialize the state with the selected tab and visibility settings.
    this.state = {
      selectedTab: selected,
      visible: true,
      hasTab: false,
    };

    // Set the external reference to allow state manipulation from outside.
    if (reference) {
      reference.current = {
        setState: newState => this.setState(newState),
      };
    }
  }

  componentDidMount() {
    const {
      keepSelected,
      location,
    } = this.props;

    // Restore selected tab from cookies if keepSelected is enabled.
    if (keepSelected) {
      const cookieSelected = Cookie.get('selectedTab');
      if (this.isValidJson(cookieSelected)) {
        const parsed = JSON.parse(cookieSelected);
        if (parsed.view === location.pathname) {
          this.setState({ selectedTab: parsed.selected });
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      selected,
    } = this.props;

    // Update selected tab if props have changed.
    if (selected !== prevProps.selected) {
      this.setState({ selectedTab: selected });
    }
  }

  /**
   * Check if a string is valid JSON.
   * @param {string} jsonString - The string to check.
   * @returns {boolean} - True if valid JSON, false otherwise.
   */
  isValidJson = jsonString => {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * Handle tab change event.
   * @param {Event} event - The tab change event.
   * @param {number} nextSelectedTab - The index of the newly selected tab.
   */
  handleChange = (_event, nextSelectedTab) => {
    const { selectedTab } = this.state;
    const { setSelected, keepSelected, location } = this.props;

    if (selectedTab !== nextSelectedTab) {
      this.setState({ selectedTab: nextSelectedTab });

      if (keepSelected) {
        Cookie.set(
          'selectedTab',
          JSON.stringify({ selected: nextSelectedTab, view: location.pathname }),
        );
      }

      if (setSelected) {
        setSelected(nextSelectedTab);
      }
    }
  };

  render() {
    const { selectedTab, visible } = this.state;
    const { config, loading, classes, checkAccess } = this.props;

    if (loading) {
      return <Loader />;
    }

    // Filter visible tabs and those with allowed access
    const visibleTabs = config.filter((element, _index) => {
      if (element.invisible) return false;
      if (element.permission && !checkAccess(element.permission)) return false;
      return true;
    });

    // Determine the index of the selected tab within the visible tabs
    const visibleTabIndex = visibleTabs.findIndex((_tab, index) => index === selectedTab);

    // The component should be based on the index of the visible tab
    const tabConfig = visibleTabs[visibleTabIndex] || null;
    const { component: Component, wrap = true } = tabConfig || {};

    // Check if there is at least one visible tab
    const hasVisibleTab = visibleTabs.length > 0;

    return (
      visible && (
        <>
          <AppBar position="static" color="inherit" className={classes.tabSize}>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value={selectedTab}
              onChange={this.handleChange}
              variant="scrollable"
            >
              {visibleTabs.map((element, index) => (
                <Tab
                  key={`tab${index}`}
                  label={element.label}
                  icon={
                    element.actions && Object.keys(element.actions).length > 0 ? (
                      <HelpCircle
                        style={{ marginTop: 5, marginLeft: 10 }}
                        onMouseEnter={e => element.actions.openPopover(e, element.actions.message)}
                        onMouseLeave={element.actions.closePopover}
                        onMouseDown={e => element.actions.openPopover(e, element.actions.message)}
                      />
                    ) : null
                  }
                  className={classes.tab}
                  value={index}
                  style={{ textTransform: 'none', backgroundColor: element.color || '' }}
                  disabled={!!element.disabled}
                />
              ))}
            </Tabs>
          </AppBar>

          {hasVisibleTab ? (
            wrap ? (
              <Paper>
                <Divider />
                <Typography component="div" role="tabpanel">
                  <Box p={3}>{Component}</Box>
                </Typography>
              </Paper>
            ) : (
                Component
              )
          ) : (
              <Paper style={{ padding: 10 }}>
                <Divider />
                <Alert severity="info">You do not have permissions for any tab</Alert>
              </Paper>
            )}
        </>
      )
    );
  }
}

TabControl.propTypes = {
  config: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired,
      permission: PropTypes.string,
      invisible: PropTypes.bool,
      disabled: PropTypes.bool,
      color: PropTypes.string,
      actions: PropTypes.shape({
        openPopover: PropTypes.func,
        closePopover: PropTypes.func,
        message: PropTypes.string,
      }),
    })
  ).isRequired,
  selected: PropTypes.number,
  loading: PropTypes.bool,
  setSelected: PropTypes.func,
  keepSelected: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  reference: PropTypes.shape({
    current: PropTypes.shape({
      setState: PropTypes.func,
    }),
  }),
  checkAccess: PropTypes.func,
};

TabControl.defaultProps = {
  selected: 0,
  keepSelected: false,
};

export default withRouter(withStyles(styles)(withPermissionsChecker(TabControl)));
