import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import endPoints from 'endPoints/endPoints';
import { messages } from 'constantes';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { withNotification, withApi } from 'wrappers';
import GeneralPopover from 'components/Popover/GeneralPopover';
import { HelpCircle } from 'react-feather';
import BaseModal from './BaseModal';
import {
  ButtonCancelar,
  ButtonSave,
  SelectBase,
  TagInput,
  TextAreaBase,
} from '../Controls';
import Loader from '../LoadingScreen';

const validationSchema = Yup.object({
  plantilla: Yup.string().required('El campo plantilla es requerido'),
  tag: Yup.array().min(1, 'Debe haber al menos un destinatario'),
});

const styles = {
  helpCircle: {
    marginLeft: 5,
    flex: '0 0 5%',
  },
};

class EmailNotifierModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      isWaiting: false,
      plantillas: [],
      plantilla: '',
      correos: [],
      observaciones: '',
      tag: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.init();
  }

  getOptionsItems = data => data.map(item => (
    { value: item.id, label: item.nombre, porDefecto: item.por_defecto }
  ));

  init = async () => {
    const {
      doGet,
      process,
      appError,
    } = this.props;
    const url = endPoints.general.maestros.emailNotifications.getNotificationsByProcess;
    const data = { abreviatura: process };
    try {
      const resp = await doGet({ url, data });
      const notifications = this.getOptionsItems(resp);
      let plantilla = notifications.find(({ porDefecto }) => porDefecto === true);
      if (plantilla) {
        plantilla = plantilla.value;
      }
      this.setState({
        plantillas: notifications || [],
        isLoading: false,
        plantilla: plantilla || '',
      });
    } catch (e) {
      appError(messages.dataFetch.fail, e);
    }
  };

  onSubmit = async (formValues) => {
    const {
      doPost,
      genericException,
      appSuccess,
      onClose,
    } = this.props;
    this.setState({ isWaiting: true });
    const data = this.mapValues(formValues);
    const url = endPoints.general.maestros.emailNotifications.sendNotification;
    try {
      const resp = await doPost({ url, data });
      appSuccess(resp.message);
      onClose();
    } catch (e) {
      genericException(e);
    } finally {
      this.setState({ isWaiting: false });
    }
  }

  mapValues = (values) => {
    const { id } = this.props;
    const {
      tag,
      plantilla,
      observaciones,
    } = values;
    return {
      correos: tag,
      notificationId: plantilla,
      processId: id,
      observaciones: observaciones.toUpperCase(),
    };
  }

  findTag = async (target, formikBag) => {
    formikBag.setValues({
      ...formikBag.values,
      tag: target.value,
    });
  };

  openPopover = (event, message) => {
    this.setState({ anchorEl: event.currentTarget, message });
  }

  closePopover = () => this.setState({ anchorEl: null })

  render() {
    const {
      open,
      onClose,
      title,
      modalProps,
      isSmallView,
      classes,
    } = this.props;
    const {
      plantillas,
      plantilla,
      correos,
      tag,
      isLoading,
      observaciones,
      isWaiting,
      anchorEl,
      message,
    } = this.state;

    const openPopover = Boolean(anchorEl);

    return (
      <>
        <GeneralPopover open={openPopover} anchorEl={anchorEl} onClose={this.closePopover}>
          <Typography style={{ padding: 3 }}>
            {message}
          </Typography>
        </GeneralPopover>
        <BaseModal
          open={open}
          onClose={onClose}
          title={title}
          {...modalProps}
        >
          {title && (<DialogTitle align="left">{title}</DialogTitle>)}
          {isLoading ? <Loader /> : (
            <Formik
              initialValues={{
                plantillas,
                plantilla,
                observaciones,
                correos,
                tag,
              }}
              validationSchema={validationSchema}
              onSubmit={this.onSubmit}
            >
              {subProps => (
                <Form>
                  <DialogContent>
                    <Grid container direction="row">
                      <Grid item xs={12} md={12} xl={12}>
                        <Field
                          name="plantilla"
                          label="Plantilla"
                          component={SelectBase}
                          items={plantillas}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        xl={12}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Field
                            name="correos"
                            label="Destinatarios"
                            error={subProps.errors.tag}
                            helperText={subProps.errors.tag ? subProps.errors.tag : null}
                            component={TagInput}
                            value={subProps.values.tag}
                            placeholder="Agregar destinatarios"
                            email
                            onChange={({ target }) => this.findTag(target, subProps)}
                            style={{ flex: '0 0 95%' }}
                          />
                          <HelpCircle
                            className={classes.helpCircle}
                            onMouseEnter={e => this.openPopover(e, 'Agregar uno a uno los email y dar enter')}
                            onMouseLeave={this.closePopover}
                            onMouseDown={e => this.openPopover(e, 'Agregar uno a uno los email y dar enter')}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} xl={12}>
                      <Field
                        label="Observaciones"
                        name="observaciones"
                        component={TextAreaBase}
                      />
                    </Grid>
                  </DialogContent>
                  <DialogActions style={{ justifyContent: isSmallView ? 'center' : null }}>
                    <ButtonCancelar onClick={onClose} />
                    <ButtonSave label="Aceptar" disabled={subProps.isSubmitting || isWaiting} />
                  </DialogActions>
                </Form>
              )}
            </Formik>
          )}
        </BaseModal>
      </>
    );
  }
}

EmailNotifierModal.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]),
  doGet: PropTypes.func,
  isSmallView: PropTypes.bool,
  doPost: PropTypes.func,
  doPut: PropTypes.func,
  onCancel: PropTypes.func,
  appSuccess: PropTypes.func,
  modalProps: PropTypes.oneOfType([PropTypes.object]),
  appError: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  genericException: PropTypes.func,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  process: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default withStyles(styles)(withApi(withNotification(EmailNotifierModal)));
