const PENDIENTE = 'PENDIENTE';
const EJECUTADO = 'EJECUTADO';
const ESTADOS = [PENDIENTE, EJECUTADO];

export default {
  PENDIENTE,
  EJECUTADO,
  ESTADOS,
  labelValue: () => (ESTADOS.map(estado => ({ label: estado, value: estado }))),
  enableActions: estado => (estado !== EJECUTADO),
};
