import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  // Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {
  Trash2 as DeleteIcon,
} from 'react-feather';
import bytesToSize from 'utils/bytesToSize';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  root: {},
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(6),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    textAlign: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer',
    },
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5,
  },
  error: {
    border: 'red 1px dashed',
    borderRadius: 15,
  },
  image: {
    width: 130,
    marginBottom: 20,
  },
  info: {
    marginTop: theme.spacing(1),
  },
  list: {
    maxHeight: 320,
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justify: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  deleteIcon: {
    marginRight: 10,
  },
}));

function FilesDropzone({
  className,
  field: { name },
  form: { setFieldValue, errors },
  disabled,
  accept = '.pdf, .xlsx',
  ...rest
}) {
  const hasError = errors[name];
  const classes = useStyles();
  const [files, setFiles] = useState([]);

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const handleDrop = async (file) => {
    setFiles(prevFiles => [...prevFiles].concat(file));
    if (file && file.length > 0) {
      setFieldValue(name, await toBase64(file[0]));
    }
  };

  const handleRemoveAll = () => {
    setFieldValue(name, {});
    setFiles([]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop, multiple: false,
  });

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      {disabled && (
        <Alert severity="info">
          Debe seleccionar un tipo de adjunto para continuar.
          <br />
          El peso máximo de cada archivo, no debe superar las 48 Megas.
        </Alert>
      )}
      {!disabled && (
        <>
          {files.length <= 0 && (
            <>
              <div
                className={clsx({
                  [classes.dropZone]: true,
                  [classes.dragActive]: isDragActive,
                  [classes.error]: Boolean(hasError),
                })}
                {...getRootProps()}
              >
                <input {...getInputProps()} accept={accept} />
                <div>
                  <img
                    alt="Select file"
                    className={classes.image}
                    src="/static/images/undraw_add_file2_gvbb.svg"
                  />
                </div>
                <div>
                  <Typography
                    gutterBottom
                    variant="h3"
                  >
                    Seleccionar archivo
                  </Typography>
                  <Box mt={2}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      Arrastre su archivo o simplemente dar click aquí para buscarlo en su equipo.
                    </Typography>
                  </Box>
                </div>
              </div>
              {Boolean(hasError) && (
                <div>
                  <Typography variant="caption" color="error">
                    {hasError}
                  </Typography>
                </div>
              )}
            </>
          )}
          {files.length > 0 && (
            <>
              <PerfectScrollbar options={{ suppressScrollX: true }}>
                <List className={classes.list}>
                  {files.map((file, i) => (
                    <ListItem
                      divider={i < files.length - 1}
                      key={`list-item-${String(i)}`}
                    >
                      <Tooltip title="Eliminar">
                        <IconButton
                          edge="end"
                          onClick={handleRemoveAll}
                          className={classes.deleteIcon}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      <ListItemIcon>
                        <FileCopyIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={file.name}
                        primaryTypographyProps={{ variant: 'h5' }}
                        secondary={bytesToSize(file.size)}
                      />
                    </ListItem>
                  ))}
                </List>
              </PerfectScrollbar>
              {/* <div className={classes.actions}>
            <Button
              onClick={handleRemoveAll}
              size="small"
            >
              Eliminar todos
            </Button>
          </div> */}
            </>
          )}
        </>
      )}
    </div>
  );
}

FilesDropzone.propTypes = {
  className: PropTypes.string,
  field: PropTypes.shape({
    value: PropTypes.string,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape(),
    touched: PropTypes.shape(),
    setFieldTouched: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  accept: PropTypes.string,
};

export default FilesDropzone;
