import React, { useState, useRef } from 'react';
import endPoints from 'endPoints/endPoints';
import PropTypes from 'prop-types';
import { withApi, withNotification } from 'wrappers';
import { messages } from 'constantes';
import * as Yup from 'yup';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import moment from 'moment';
import { SwitchBase, TextBase } from 'components/Controls';
import ConfirmModal from '../../dialogs/ConfirmModal';
import TimePickerBase from '../../Pickers/TimePickerBase';
import ButtonCancelar from '../../Controls/ButtonCancelar';
import ButtonSave from '../../Controls/ButtonSave';
import Table from '../../Table/Table';
import DefaultActions from '../../Table/DefaultActions';
import { TP_NO_SECONDS_FORMAT } from '../../Pickers/constants';

const Horario = ({
  calendarioTrabajoId,
  doPut,
  doPost,
  doDelete,
  appInfo,
  appWarning,
  appSuccess,
  genericException,
}) => {
  const validationSchema = Yup.object({
    horaInicio: Yup.string().required('El campo hora inicio es requerido').nullable(),
    horaFin: Yup.string().required('El campo hora fin es requerido').nullable(),
  });

  const child = useRef();
  // eslint-disable-next-line
  const [state, setState] = useState({
    horaInicio: null,
    horaFin: null,
    cantidadHoras: '',
    lunes: false,
    martes: false,
    miercoles: false,
    jueves: false,
    viernes: false,
    sabado: false,
    domingo: false,
  });
  const [idToDelete, setIdToDelete] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [idToUpdate, setIdToUpdate] = useState(false);

  const endPoint = endPoints.general.calendarioTrabajo.horario;

  const handleFormModal = () => setIsOpenModal(!isOpenModal);

  const handleDeleteModal = ({ id = null } = {}) => setIdToDelete(id);

  const openModalEdit = (row) => {
    const {
      id,
      hora_inicio: horaInicio,
      hora_fin: horaFin,
      cantidad_horas: cantidadHoras,
      dias,
    } = row;

    const {
      lunes,
      martes,
      miercoles,
      jueves,
      viernes,
      sabado,
      domingo,
    } = dias;

    setState({
      horaInicio,
      horaFin,
      cantidadHoras,
      lunes,
      martes,
      miercoles,
      jueves,
      viernes,
      sabado,
      domingo,
    });
    setIdToUpdate(id);
  };

  const closeModalEdit = () => {
    setState({
      horaInicio: null,
      horaFin: null,
      cantidadHoras: '',
      lunes: false,
      martes: false,
      miercoles: false,
      jueves: false,
      viernes: false,
      sabado: false,
      domingo: false,
    });
    setIdToUpdate(null);
  };

  const difHoraFin = (horaFin, formikBag) => {
    const { horaInicio } = formikBag.values;
    const horaI = moment(horaInicio, 'HH:mm');
    const horaF = moment(horaFin, 'HH:mm');
    if (horaI.isBefore(horaF, 'minutes')) {
      formikBag.setValues({
        ...formikBag.values,
        horaFin,
        cantidadHoras: (horaF.diff(horaI, 'minutes', true) / 60),
      });
    } else if (horaF.isBefore(horaI, 'minutes')) {
      const horaITo24 = moment('24:00', 'HH:mm').diff(horaI, 'minutes', true);
      const startOfDayToHoraF = horaF.diff(moment('00:00', 'HH:mm'), 'minutes', true);
      formikBag.setValues({
        ...formikBag.values,
        horaFin,
        cantidadHoras: ((horaITo24 + startOfDayToHoraF) / 60),
      });
    } else {
      formikBag.setValues({
        ...formikBag.values,
        horaFin,
        cantidadHoras: horaInicio ? 24 : 0,
      });
    }
  };

  const difHoraInicio = (horaInicio, formikBag) => {
    const { horaFin } = formikBag.values;
    const horaI = moment(horaInicio, 'HH:mm');
    const horaF = moment(horaFin, 'HH:mm');
    if (horaI.isBefore(horaF, 'minutes')) {
      formikBag.setValues({
        ...formikBag.values,
        horaInicio,
        cantidadHoras: (horaF.diff(horaI, 'minutes', true) / 60),
      });
    } else if (horaF.isBefore(horaI, 'minutes')) {
      const horaITo24 = moment('24:00', 'HH:mm').diff(horaI, 'minutes', true);
      const startOfDayToHoraF = horaF.diff(moment('00:00', 'HH:mm'), 'minutes', true);
      formikBag.setValues({
        ...formikBag.values,
        horaInicio,
        cantidadHoras: ((horaITo24 + startOfDayToHoraF) / 60),
      });
    } else {
      formikBag.setValues({
        ...formikBag.values,
        horaInicio,
        cantidadHoras: horaFin ? 24 : 0,
      });
    }
  };

  const columns = [
    {
      name: 'hora_inicio',
      label: 'hora inicio',
      type: 'time',
    },
    {
      name: 'hora_fin',
      label: 'hora fin',
      type: 'time',
    },
    {
      name: 'cantidad_horas',
      label: 'Horas de trabajo',
    },
    {
      name: 'dias.lunes',
      label: 'Lunes',
      type: 'check',
    },
    {
      name: 'dias.martes',
      label: 'Martes',
      type: 'check',
    },
    {
      name: 'dias.miercoles',
      label: 'Miércoles',
      type: 'check',
    },
    {
      name: 'dias.jueves',
      label: 'Jueves',
      type: 'check',
    },
    {
      name: 'dias.viernes',
      label: 'Viernes',
      type: 'check',
    },
    {
      name: 'dias.sabado',
      label: 'Sábado',
      type: 'check',
    },
    {
      name: 'dias.domingo',
      label: 'Domingo',
      type: 'check',
    },
    {
      name: 'acciones',
      label: 'acciones',
      filter: false,
      component: row => (
        <DefaultActions
          onDelete={handleDeleteModal}
          onEdit={openModalEdit}
          row={row}
        />
      ),
    },
  ];

  const mapValues = (values) => {
    const {
      horaInicio,
      horaFin,
      lunes,
      martes,
      miercoles,
      jueves,
      viernes,
      sabado,
      domingo,
      cantidadHoras,
    } = values;

    return {
      hora_inicio: horaInicio,
      hora_fin: horaFin,
      cantidad_horas: cantidadHoras,
      calendario_trabajo_id: calendarioTrabajoId,
      dias: {
        lunes,
        martes,
        miercoles,
        jueves,
        viernes,
        sabado,
        domingo,
      }
    };
  };

  const submitForm = async (formValues) => {
    try {
      if (idToUpdate) {
        await doPut({
          url: `${endPoint.base}/${idToUpdate}`,
          data: mapValues(formValues),
        });
        appInfo(messages.crud.update);
        closeModalEdit();
      } else {
        await doPost({
          url: endPoint.base,
          data: mapValues(formValues),
        });
        appSuccess(messages.crud.new);
        handleFormModal();
      }
      child.current.refresh();
    } catch (e) {
      genericException(e);
    }
  };

  const deleteRegister = async () => {
    try {
      await doDelete({
        url: `${endPoint.base}/${idToDelete}`,
      });
      appWarning(messages.crud.delete);
      child.current.refresh();
      handleDeleteModal();
    } catch (e) {
      genericException(e);
    }
  };

  return (
    <>
      <ConfirmModal
        open={Boolean(idToDelete)}
        onClose={handleDeleteModal}
        onAccept={deleteRegister}
        title="eliminar horario"
        message="¿Seguro desea eliminar el horario"
      />
      <Dialog
        open={idToUpdate ? Boolean(idToUpdate) : isOpenModal}
        onClose={idToUpdate ? closeModalEdit : handleFormModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{idToUpdate ? 'Actualización de horario' : 'Creación de horario'}</DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize
            initialValues={state}
            onSubmit={submitForm}
            validationSchema={validationSchema}
          >
            {subProps => (
              <Form>
                <Grid container direction="row" justify="space-between" spacing={2}>
                  <Grid item xs={12} md={6} xl={6}>
                    <Field
                      name="lunes"
                      label="Lunes"
                      component={SwitchBase}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={6}>
                    <Field
                      name="martes"
                      label="Martes"
                      component={SwitchBase}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={6}>
                    <Field
                      name="miercoles"
                      label="Miércoles"
                      component={SwitchBase}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={6}>
                    <Field
                      name="jueves"
                      label="Jueves"
                      component={SwitchBase}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={6}>
                    <Field
                      name="viernes"
                      label="Viernes"
                      component={SwitchBase}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={6}>
                    <Field
                      name="sabado"
                      label="Sábado"
                      component={SwitchBase}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} xl={12}>
                    <Field
                      name="domingo"
                      label="Domingo"
                      component={SwitchBase}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} xl={4}>
                    <Field
                      name="horaInicio"
                      label="hora inicio"
                      ampm={false}
                      component={TimePickerBase}
                      onChangeProps={difHoraInicio}
                      format={TP_NO_SECONDS_FORMAT}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} xl={4}>
                    <Field
                      name="horaFin"
                      label="hora fin"
                      ampm={false}
                      component={TimePickerBase}
                      onChangeProps={difHoraFin}
                      format={TP_NO_SECONDS_FORMAT}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} xl={4}>
                    <Field
                      name="cantidadHoras"
                      label="Horas de trabajo"
                      component={TextBase}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-end" spacing={2}>
                  <Grid item>
                    <ButtonCancelar onClick={idToUpdate ? closeModalEdit : handleFormModal} />
                  </Grid>
                  <Grid item>
                    <ButtonSave disabed={subProps.isSubmitting} label="Guardar" />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Table
        forwardedRef={child}
        serverSideUrl={endPoint.base}
        serverSideData={{ calendarioTrabajoId }}
        columns={columns}
        onCreate={handleFormModal}
      />
    </>
  );
};

Horario.propTypes = {
  calendarioTrabajoId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  doPut: PropTypes.func.isRequired,
  doPost: PropTypes.func.isRequired,
  doDelete: PropTypes.func.isRequired,
  appInfo: PropTypes.func.isRequired,
  appWarning: PropTypes.func.isRequired,
  appSuccess: PropTypes.func.isRequired,
  genericException: PropTypes.func.isRequired,
};

export default withApi(withNotification(Horario));
