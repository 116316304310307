import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Grid,
} from '@material-ui/core';
import { ButtonCancelar, ButtonSave, TextBase } from 'components';
import { messages } from 'constantes';
import endPoints from 'endPoints/endPoints';
import { Field, Formik, Form } from 'formik';
import * as Yup from 'yup';
import { withApi, withNotification } from 'wrappers';

const validationSchema = Yup.object({
  pregunta: Yup.string().required('El campo pregunta es requerido'),
});

const EnviarPreguntaForm = ({
  open,
  doPost,
  onClose,
  appSuccess,
  genericException,
}) => {
  const ep = endPoints.centroAyuda.procesos.enviarPreguntas;
  const state = {
    funcionalidad: '',
    pregunta: '',
    modulo: '',
  };

  const mapValues = (values) => {
    const {
      funcionalidad,
      pregunta,
      modulo,
    } = values;

    return {
      funcionalidad,
      pregunta,
      modulo,
    };
  };

  const onSubmit = async (formValues) => {
    try {
      await doPost({
        url: ep.base,
        data: mapValues(formValues),
      });
      appSuccess(messages.crud.new);
      onClose();
    } catch (e) {
      genericException(e);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          ENVIAR PREGUNTAS
        </DialogTitle>
        <Formik
          enableReinitialize
          onSubmit={onSubmit}
          initialValues={state}
          validationSchema={validationSchema}
        >
          {() => (
            <Form>
              <DialogContent>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    md={12}
                    xl={12}
                  >
                    <Field
                      label="Pregunta"
                      name="pregunta"
                      component={TextBase}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    xl={12}
                  >
                    <Field
                      label="Módulo"
                      name="modulo"
                      component={TextBase}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    xl={12}
                  >
                    <Field
                      label="Funcionalidad"
                      name="funcionalidad"
                      component={TextBase}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <ButtonCancelar onClick={onClose} />
                <ButtonSave label="Guardar" />
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

EnviarPreguntaForm.propTypes = {
  doPost: PropTypes.func,
  appSuccess: PropTypes.func,
  genericException: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default withApi(withNotification(EnviarPreguntaForm));
