import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Link } from 'components/commons';

/**
 * @name BreadLink
 * @desc Componente que procesa los links a mostrar de los Breadcums
 * @param {*} param0
 */
const BreadLink = ({ label, path }) => {
  const text = (<Typography>{label}</Typography>);
  return (
    path ? (
      <Link
        variant="body1"
        color="inherit"
        to={path}
      >
        {text}
      </Link>
    ) : label
  );
};

BreadLink.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string,
};

export default BreadLink;
