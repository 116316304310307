import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton, makeStyles, Tooltip,
} from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircleOutline';
import { withPermissionsChecker } from 'wrappers';

const useStyles = makeStyles(theme => ({
  icon: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

/**
 * This component allows to render a button specific to create.
 * @param {*} param0
 */
const CreateButton = ({
  onClick, requiredPermissions = [], checkAccess, disabled, ...rest
}) => {
  const classes = useStyles();
  return (
    <Tooltip title="Crear">
      <IconButton
        component="div"
        className={classes.icon}
        onClick={() => onClick()}
        disabled={!checkAccess(requiredPermissions) || disabled}
        {...rest}
      >
        <AddCircle className={classes.icon} fontSize="large" />
      </IconButton>
    </Tooltip>
  );
};

CreateButton.defaultProps = {
  requiredPermissions: [],
};

CreateButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  checkAccess: PropTypes.func,
  requiredPermissions: PropTypes.arrayOf(PropTypes.string),
};

export default withPermissionsChecker(CreateButton);
