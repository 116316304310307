import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Auth from 'components/Auth/Auth';
import GoogleAnalytics from 'components/GoogleAnalytics';
import ScrollReset from 'components/ScrollReset';
import useSettings from 'hooks/useSettings';
import { createTheme } from 'theme';
// import Routes from 'Routes';
import { ApplicationContextProvider } from './context/ApplicationContext';
import { SocketContextProvider } from './context/SocketContext';
import RoutesDevelop from './RoutesDevelop';

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
      textTransform: 'uppercase',
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%',
    },
    body: {
      height: '100%',
      width: '100%',
    },
    '#root': {
      height: '100%',
      width: '100%',
    },
  },
}));

function App()
{
  useStyles();

  const { settings } = useSettings();

  return (
    <ThemeProvider theme={createTheme(settings)}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ApplicationContextProvider>
            <SocketContextProvider>
              <SnackbarProvider maxSnack={1}>
                <Router history={history}>
                  <Auth>
                    <ScrollReset />
                    <GoogleAnalytics />
                    {/* <Routes /> */}
                    <RoutesDevelop />
                  </Auth>
                </Router>
              </SnackbarProvider>
            </SocketContextProvider>
          </ApplicationContextProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;