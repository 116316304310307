import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Grid,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import DropZoneBase from '../Controls/FilesDropzone';
import ButtonSave from '../Controls/ButtonSave';
import ButtonCancelar from '../Controls/ButtonCancelar';

class UploadFileModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
    };
  }

  render() {
    const { file } = this.state;
    const { isOpen, onSubmit, closeModal } = this.props;
    return (
      <Dialog open={isOpen} onClose={closeModal} fullWidth maxWidth="sm">
        <DialogTitle>
          Adjuntar documento
        </DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize
            initialValues={{ file }}
            onSubmit={onSubmit}
          >
            {subProps => (
              <Form>
                <Grid container direction="row">
                  <Grid item xs={12} md={12} xl={12}>
                    <Field
                      label="File"
                      name="file"
                      component={DropZoneBase}
                    />
                  </Grid>
                </Grid>
                <DialogActions>
                  <ButtonCancelar onClick={closeModal} />
                  <ButtonSave label="Guardar" disabled={subProps.isSubmitting} />
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

    );
  }
}

UploadFileModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default UploadFileModal;
