import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton, makeStyles, Tooltip,
} from '@material-ui/core';
import {
  Trash2 as DeleteIcon,
} from 'react-feather';
import { DEFAULT_MUI_DATATABLE_BUTTON_SIZE } from 'constantes/constants';
import { withPermissionsChecker } from 'wrappers';

const useStyles = makeStyles(theme => ({
  icon: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

/**
 * This component allows to render a button specific to delete.
 * @param {*} param0
 */
const DeleteButton = ({
  onClick, requiredPermissions = [], checkAccess,
  disabled, ...rest
}) => {
  const { icon } = useStyles();
  return (
    <Tooltip title="Eliminar">
      <IconButton
        component="div"
        size={DEFAULT_MUI_DATATABLE_BUTTON_SIZE}
        className={icon}
        onClick={onClick}
        disabled={!checkAccess(requiredPermissions) || disabled}
        {...rest}
      >
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
};

DeleteButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  checkAccess: PropTypes.func,
  requiredPermissions: PropTypes.arrayOf(PropTypes.string),
};

export default withPermissionsChecker(DeleteButton);
