import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const DropdownMultiple = ({
  field, items, disabled, form, fullWidth = true, ...otherProps
}) => {
  const { value: valueField, name } = field;
  const { touched, errors } = form;
  const onchange = (opcion, value) => {
    const { setFieldValue } = form;
    const values = value.map(item => item.value);
    setFieldValue(name, values);
  };
  const selected = items.filter(item => valueField.includes(item.value));
  const hasError = touched[name] && Boolean(errors[name]);
  delete otherProps.proxyonblur;
  delete otherProps.proxyonchange;

  return (
    <Autocomplete
      multiple
      size="small"
      options={items}
      getOptionLabel={option => option.label}
      onChange={onchange}
      value={selected}
      fullWidth={fullWidth}
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          error={hasError}
          helperText={hasError && errors[name]}
          variant="outlined"
          margin="dense"
          {...otherProps}
        />
      )}
    />

  );
};

DropdownMultiple.propTypes = {
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  items: PropTypes.oneOfType([PropTypes.any]),
  field: PropTypes.oneOfType([PropTypes.any]),
  form: PropTypes.oneOfType([PropTypes.any]),
  proxyonchange: PropTypes.func,
};

export default DropdownMultiple;
