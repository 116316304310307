import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  FormGroup,
  withStyles,
} from '@material-ui/core';
import {
  Chip, ItemsWrapper, Wrapper,
} from './styled';

const KEY_ENTER = 13;

const styles = {
  email: {
    '& .MuiInputBase-input': {
      textTransform: 'none',
    },
    '& .MuiChip-label': {
      textTransform: 'none',
    },
  },
};

class TagInput extends React.Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = {
      addedItems: value,
      tagName: '',
      componentError: false,
      componentErrorText: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { reset } = this.props;
    if (prevProps.reset !== reset) {
      this.setItem([]);
    }
  }

  setItem = (value) => {
    this.setState({ addedItems: value });
  }

  setTagName = (value) => {
    this.setState({ tagName: value });
  }

  handleOnChange = (items) => {
    const { onChange, name } = this.props;
    if (onChange) {
      onChange({
        target: {
          name,
          value: items,
        },
      });
    }
  }

  onRemove = (tag) => {
    const { addedItems } = this.state;
    const newItems = addedItems.filter(item => item !== tag);
    this.setItem(newItems);
    this.handleOnChange(newItems);
  }

  onAdd = () => {
    const { addedItems, tagName } = this.state;
    if (!addedItems.includes(tagName)) {
      const newItems = [...addedItems, tagName];
      this.setItem(newItems);
      this.setTagName('');
      this.handleOnChange(newItems);
    }
  };

  handleKey = (event) => {
    const { keyCode } = event;
    const { tagName, addedItems } = this.state;
    const { email } = this.props;
    if (keyCode === KEY_ENTER) {
      event.preventDefault();
      if (email) {
        const validRegex = /\S+@\S+\.\S+/;
        if (tagName !== '') {
          if (tagName.match(validRegex)) {
            this.onAdd();
            this.setState({
              componentError: false,
              componentErrorText: null,
            });
          } else {
            this.setState({
              componentError: true,
              componentErrorText: 'El email es invalido',
            });
          }
          if (addedItems.includes(tagName)) {
            this.setState({
              componentError: true,
              componentErrorText: 'No se pueden agregar items repetidos',
            });
          }
        } else {
          this.setState({
            componentError: false,
            componentErrorText: null,
          });
        }
      } else {
        this.onAdd();
      }
    }
  };

  render() {
    const {
      email,
      error,
      helperText,
      label,
      placeholder,
      style,
      classes,
    } = this.props;
    const {
      tagName,
      addedItems,
      componentError,
      componentErrorText,
    } = this.state;
    return (
      <Wrapper style={style}>
        <FormGroup>
          <TextField
            className={email ? classes.email : null}
            variant="outlined"
            margin="dense"
            autoComplete="off"
            error={error || componentError}
            helperText={error ? helperText : componentErrorText}
            value={tagName}
            onChange={({ target }) => this.setTagName(target.value)}
            onKeyDown={this.handleKey}
            label={label}
            placeholder={placeholder}
          />
        </FormGroup>
        <ItemsWrapper>
          {addedItems.map((item, key) => {
            const itemKey = `${item}-${key}`;
            return (
              <Chip
                className={email ? classes.email : null}
                key={itemKey}
                label={item}
                onDelete={() => this.onRemove(item)}
              />
            );
          })}
        </ItemsWrapper>
      </Wrapper>
    );
  }
}

TagInput.defaultProps = {
  placeholder: 'Agregar tags',
  value: [],
  email: false,
};

TagInput.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  reset: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.array]),
  // Boolean para validacion de email
  email: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object])
};

export default withStyles(styles)(TagInput);
