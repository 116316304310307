import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
} from '@material-ui/core';
import { Menu as MenuIcon, X, Home } from 'react-feather';
import Logo from 'components/Logo';
import THEMES from 'constantes/theme';
import Account from './Account';
import Notifications from './Notifications';
import CentroAyuda from './CentroAyuda';
// import Search from './Search';
// import Settings from './Settings';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default,
    } : {},
  },
  toolbar: {
    minHeight: 64,
  },
}));

function TopBar({
  className,
  onMobileNavOpen,
  isMobileNavOpen,
  ...rest
}) {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Hidden>
        {/* Pantalla grande */}
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              {isMobileNavOpen ? (<X />) : (<MenuIcon />)}
            </SvgIcon>
          </IconButton>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <IconButton
              component={RouterLink}
              to="/"
              style={{ color: 'white' }}
            >
              <Home />
            </IconButton>
          </Box>
        </Hidden>
        {/* Pantalla Mediana */}
        <Hidden mdDown>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              {isMobileNavOpen ? (<X />) : (<MenuIcon />)}
            </SvgIcon>
          </IconButton>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <IconButton
              component={RouterLink}
              to="/"
              style={{ color: 'white' }}
            >
              <Home />
            </IconButton>
          </Box>
        </Hidden>
        <Box
          ml={2}
          flexGrow={1}
        />
        <CentroAyuda />
        {/* <Search /> */}
        <Notifications />
        {/* <Settings /> */}
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
  isMobileNavOpen: PropTypes.bool,
};

export default TopBar;
