import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { dotNotationExtractor, isFunction } from 'utils/functions';
import { formatter } from 'constantes/constants';
import {
  CircularProgress,
  Grid,
  Typography,
  Card,
  CardContent,
  makeStyles,
  Checkbox,
} from '@material-ui/core';
import {
  DownloadExcelButton,
  DownloadPdfButton,
  CreateButton,
} from 'components/Controls';
import SendExcelMailButton from 'components/Controls/SendExcelMailButton';
import MultipleRenderer from './multiple';
import GeneralSearch from './GeneralSearch';
import Chip, { ChipColors } from '../Chip';
import Check from '../Check';
import MultiChips from '../multiChips';

const KEY_ENTER = 13;

const useStyles = makeStyles(() => ({
  tableRow: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: '#dfdfdf',
    },
  },
  email: {
    textTransform: 'none',
  },
}));

const SmallView = ({
  columns,
  data,
  isLoading,
  setGenericSearch,
  onCreate,
  checked,
  onSendExcel,
  onRowsSelect,
  disableCheck,
  onDownloadPdf,
  onDownloadExcel,
  createPermissions,
  disableButtonCreate,
}) => {
  const classes = useStyles();
  const [query, setQuery] = useState('');
  const [state, setState] = useState({
    selected: [],
  });

  const verifyExist = (row) => {
    let index = -1;
    state.selected.forEach((element, i) => {
      if (Object.is(element, row)) {
        index = i;
      }
    });
    return index;
  };

  const setCheck = (row, fromAll = false) => {
    const index = verifyExist(row);
    if (index >= 0) {
      const datos = state.selected;
      datos.splice(index, 1);
      setState({
        selected: datos,
      });
      if (fromAll) {
        return;
      }
      onRowsSelect(datos);
    } else {
      setState({
        selected: [...state.selected, row],
      });
      if (fromAll) {
        return;
      }
      onRowsSelect([...state.selected, row]);
    }
  };

  const loadData = () => {
    const cards = [];
    data.forEach((item) => {
      const innerInfo = [];
      columns.forEach((i, index) => {
        let cellContent = i.component || dotNotationExtractor(item, i.name);
        cellContent = isFunction(cellContent) ? cellContent(item) : cellContent;
        switch (i.type) {
        case 'number': cellContent = formatter.format(cellContent ?? 0);
          break;
        case 'money': cellContent = `$${formatter.format(Math.trunc(cellContent ?? 0))}`;
          break;
        case 'money_decimal': cellContent = `$${formatter.format(cellContent ?? 0)}`;
          break;
        case 'MULTIPLEVAL_TYPE': cellContent = <MultipleRenderer value={cellContent} index={index} />;
          break;
        case 'check': cellContent = <Check activo={cellContent} />;
          break;
        case 'multiChips': cellContent = <MultiChips data={cellContent} />;
          break;
        case 'active': cellContent = (
          <Chip
            activo={cellContent}
            label={cellContent ? 'activo' : 'inactivo'}
            color={cellContent ? ChipColors.GREEN : ChipColors.RED}
          />
        );
          break;
        case 'percent': cellContent = `${Number(cellContent).toFixed(2)}%` ?? 0;
          break;
        case 'percentage': cellContent = `${Number(cellContent).toFixed(0)}%` ?? 0;
          break;
        case 'email': cellContent = (
          <div className={classes.email}>{cellContent.toLowerCase()}</div>
        );
          break;
        default:
          cellContent = (
            <Typography variant="body1">
              {cellContent}
            </Typography>
          );
          break;
        }
        innerInfo.push((
          <Grid container direction="row">
            <Grid item style={{ alignItems: 'center' }}>
              <Typography variant="h6">
                {`${i.label}: `}
              </Typography>
              {cellContent}
            </Grid>
          </Grid>
        ));
      });
      cards.push((
        <Card style={{ marginTop: 15, width: '100%' }}>
          <CardContent>
            {onRowsSelect && (
              <Checkbox
                checked={checked}
                onChange={() => setCheck(item)}
                disabled={checked ? false : disableCheck}
              />
            )}
            {innerInfo}
          </CardContent>
        </Card>
      ));
    });
    return cards;
  };

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleQueryChangeKeyUp = (event) => {
    if (event.keyCode === KEY_ENTER) {
      event.persist();
      const { target: { value } } = event;
      setGenericSearch(value);
    }
  };

  return (
    <>
      {isLoading ? (
        <Grid container direction="row" justify="center">
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Grid container direction="row" justify="center">
            <Grid container direction="row">
              <Grid item xs={12}>
                <GeneralSearch
                  value={query}
                  width="100%"
                  onChange={handleQueryChange}
                  onKeyUp={handleQueryChangeKeyUp}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="flex-end"
              style={{
                alignItems: 'center',
              }}
            >
              <Grid item>
                {onCreate && (
                  <CreateButton
                    onClick={onCreate}
                    requiredPermissions={createPermissions}
                    disabled={disableButtonCreate}
                  />
                )}
              </Grid>
              <Grid item>
                {onDownloadExcel && (
                  <DownloadExcelButton
                    onClick={onDownloadExcel}
                  />
                )}
              </Grid>
              <Grid item>
                {onDownloadPdf && (
                  <DownloadPdfButton
                    onClick={onDownloadPdf}
                  />
                )}
              </Grid>
              <Grid item>
                {onSendExcel && (
                  <SendExcelMailButton
                    onClick={onSendExcel}
                  />
                )}
              </Grid>
            </Grid>
            {loadData()}
          </Grid>
        </>
      )}
    </>
  );
};

SmallView.propTypes = {
  columns: PropTypes.arrayOf([PropTypes.object]),
  data: PropTypes.arrayOf([PropTypes.object]),
  setGenericSearch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onCreate: PropTypes.func,
  onDownloadPdf: PropTypes.func,
  onDownloadExcel: PropTypes.func,
  onSendExcel: PropTypes.func,
  createPermissions: PropTypes.arrayOf([PropTypes.string]),
  checked: PropTypes.bool,
  onRowsSelect: PropTypes.func,
  disableCheck: PropTypes.bool,
  disableButtonCreate: PropTypes.bool,
};

export default SmallView;
