import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
} from '@material-ui/core';
import FormLoader from 'components/FormLoader';
import GeneralPopover from 'components/Popover/GeneralPopover';
import { HelpCircle } from 'react-feather';
import BaseModal from './BaseModal';

/**
 * This component allows to wrap the unique modal structure
 * for the advanced form.
 * Todo: Refactor this code.
 */
const FormModal = ({
  children,
  modalProps,
  open,
  onClose,
  title,
  loading,
  formFields,
  maxWidth,
  titleHelpMessage,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openHelp, setOpenHelp] = React.useState(false);

  const openPopover = (event) => {
    setOpenHelp(true);
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setOpenHelp(false);
    setAnchorEl(null);
  };

  return (
    <>
      <BaseModal open={open} onClose={onClose} maxWidth={maxWidth} {...modalProps}>
        {title && (
          <>
            <Grid item style={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}>
              <DialogTitle style={{ paddingRight: 10 }}>
                {title}
              </DialogTitle>
              {titleHelpMessage && (
                <HelpCircle
                  onMouseEnter={e => openPopover(e)}
                  onMouseLeave={closePopover}
                  onMouseDown={e => openPopover(e)}
                />
              )}
            </Grid>
          </>
        )}
        {loading
          ? (<DialogContent><FormLoader formFields={formFields} /></DialogContent>)
          : children}
      </BaseModal>
      <GeneralPopover open={openHelp} anchorEl={anchorEl} onClose={closePopover}>
        <Typography style={{ padding: 3 }}>
          {titleHelpMessage}
        </Typography>
      </GeneralPopover>
    </>
  );
};

FormModal.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  modalProps: PropTypes.oneOfType([PropTypes.object]),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  maxWidth: PropTypes.string,
  titleHelpMessage: PropTypes.string,
  formFields: PropTypes.oneOfType([PropTypes.object]),
};

export default FormModal;
