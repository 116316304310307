import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@material-ui/core';

const ITEM_HEIGHT = 48;

export default function AutocompleteOptions(props) {
  const {
    anchorEl, optionsOpen, handleCloseOptions, labelKey, valueKey, currentValue,
    items, handleOptionClick,
  } = props;
  return (
    <Menu
      elevation={3}
      anchorEl={anchorEl}
      keepMounted
      open={optionsOpen}
      getContentAnchorEl={null}
      onClose={handleCloseOptions}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 4.5,
          width: anchorEl.clientWidth,
          marginTop: 5,
        },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {items && items.length > 0 ? items.map((option, index) => {
        option.$key = index;
        return (
          <MenuItem
            key={option.$key}
            selected={option[valueKey] === currentValue}
            onClick={() => handleOptionClick(option)}
          >
            {option[labelKey]}
          </MenuItem>
        );
      }) : (
        <MenuItem
          key={0}
          onClick={() => handleOptionClick(null)}
        >
          No hay registros...
        </MenuItem>
      )}
    </Menu>
  );
}

AutocompleteOptions.defaultProps = {
  anchorEl: null,
  currentValue: null,
};

AutocompleteOptions.propTypes = {
  anchorEl: PropTypes.shape(),
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleOptionClick: PropTypes.func.isRequired,
  handleCloseOptions: PropTypes.func.isRequired,
  optionsOpen: PropTypes.bool.isRequired,
  currentValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  labelKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
};
