import React from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Grid, Dialog, DialogContent, DialogActions, DialogTitle,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Check } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import { ButtonGeneral, TextAreaBase } from '../Controls';
import ButtonCancelar from '../Controls/ButtonCancelar';

class FormComentario extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comentario: '',
    };
  }

  render() {
    const { comentario } = this.state;
    const {
      open, close, onSubmit, estado, isWaiting,
    } = this.props;
    const messageEstado = `¿Desea cambiar el estado a ${estado}?`;
    return (
      <Dialog
        open={open}
        onClose={close}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {messageEstado}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ comentario }}
            onSubmit={onSubmit}
          >
            {() => (
              <Form>
                <Grid container direction="row" justify="center">
                  <Field
                    name="comentario"
                    label="Comentario"
                    component={TextAreaBase}
                  />
                </Grid>
                <DialogActions>
                  <ButtonCancelar onClick={close} />
                  <ButtonGeneral
                    label="Aceptar"
                    color={green[500]}
                    icon={<Check style={{ marginRight: 12, fontSize: 20 }} />}
                    type="submit"
                    disabled={isWaiting}
                  />
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

FormComentario.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  onSubmit: PropTypes.func,
  estado: PropTypes.string,
  isWaiting: PropTypes.bool,
};

export default FormComentario;
