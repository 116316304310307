import React, {
  createContext,
  useEffect,
  useReducer,
} from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'components';
import apiServiceFecth from 'services/ApiServiceFecth';
import authService from 'services/AuthService';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  permissions: [],
  token: null,
};

const reducer = (state, action) => {
  switch (action.type) {
  case 'INITIALISE': {
    const { isAuthenticated, user, permissions } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialised: true,
      user,
      permissions,
    };
  }
  case 'LOGIN': {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  }
  case 'PERMISSIONS': {
    const { permissions } = action.payload;
    return {
      ...state,
      permissions,
    };
  }
  case 'LOGOUT': {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  default: {
    return { ...state };
  }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  login: () => Promise.resolve(),
  getPermissions: () => Promise.resolve(),
  logout: () => () => Promise.resolve(),
  clearSession: () => { },
  checkAccess: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (username, password) => {
    await apiServiceFecth.login({ username, password }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw Error(response.statusText);
    }).then((response) => {
      const { accessToken, user } = response;

      authService.storeSessionData(accessToken);

      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });
    }).catch((error) => {
      const message = (error.response && error.response.data.message) || 'Credenciales inválidos';
      throw Error(message);
    });
  };

  const logout = async () => {
    await apiServiceFecth.logout().then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw Error(response.statusText);
    }).then(() => {
      authService.storeSessionData(null);
      dispatch({ type: 'LOGOUT' });
    }).catch((error) => {
      const message = (error.response && error.response.data.message);
      throw Error(message);
    });
  };

  const clearSession = () => {
    if (!authService.isTokenValid()) {
      authService.storeSessionData(null);
      dispatch({ type: 'LOGOUT' });
    }
  };

  const getPermissions = async () => {
    await apiServiceFecth.getUser().then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw Error(response.statusText);
    }).then((response) => {
      const { permissions } = response;

      dispatch({
        type: 'PERMISSIONS',
        payload: {
          permissions,
        },
      });
    }).catch((error) => {
      const message = (error.response && error.response.data.message) || 'Credenciales inválidos';
      throw Error(message);
    });
  };

  const checkAccess = (userPermission = []) => {
    const { permissions } = state;
    if (userPermission.length > 0) {
      return permissions.includes(userPermission[0] ?? []);
    }
    return true;
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        if (authService.isAuthenticated() && authService.isTokenValid()) {
          await apiServiceFecth.getUser().then((response) => {
            if (response.ok) {
              return response.json();
            }
            authService.storeSessionData(null);
            throw Error(response);
          }).then((response) => {
            const { user, permissions } = response;
            dispatch({
              type: 'INITIALISE',
              payload: {
                isAuthenticated: true,
                user,
                permissions,
              },
            });
          }).catch((error) => {
            const message = (error.response && error.response.data.message) || 'Credenciales inválidos';
            throw Error(message);
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null,
              permissions: [],
            },
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null,
            permissions: [],
          },
        });
      }
    };

    initialise();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        checkAccess,
        getPermissions,
        clearSession,
      }}
    >
      {state.isInitialised ? children : (<Loader />)}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthContextConsumer = AuthContext.Consumer;

export default AuthContext;
