import React from 'react';
import {
  Dialog,
} from '@material-ui/core';
import PropTypes from 'prop-types';

/**
 * This component allows to generate a base modal.
 */
const BaseModal = ({
  open,
  onClose,
  children,
  modalProps,
  maxWidth,
}) => (
  <Dialog
    {...modalProps}
    fullWidth
    open={open}
    onClose={onClose}
    keepMounted={false}
    maxWidth={maxWidth}
  >
    {children}
  </Dialog>
);

BaseModal.propTypes = {
  children: PropTypes.node.isRequired,
  modalProps: PropTypes.oneOfType([PropTypes.object]),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  maxWidth: PropTypes.string,
};

export default BaseModal;
