import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Menu, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withApi, withNotification, withPermissionsChecker } from 'wrappers';
import { Estados, messages } from 'constantes';
import { MenuItemBase } from '../Controls';
import ButtonEstados from '../Controls/ButtonEstados';
import FormComentario from './FormComentario';

function EstadoSelection({
  id,
  doPut,
  appInfo,
  history,
  getResp,
  refresh,
  endPoint,
  checkAccess,
  estadoActual,
  genericException,
  redirect = false,
  redirectUrl = '',
  disabled = false,
  terminado = true,
  specificList = [],
  requiredPermissions,
  extraFunction = null,
  canceladoCliente = false,
  canceladoEmpresa = false,
  ownResponseCode = false,
  openOwnChangeModal,
  ...rest
}) {
  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })(props => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [estado, setEstado] = useState('');
  const [isWaiting, setIsWaiting] = useState(false);

  const isSmallView = useMediaQuery(theme => theme.breakpoints.down('xs'));

  let list;

  switch (estadoActual) {
  case Estados.INICIAL:
    list = [
      { label: Estados.EN_PROCESO, visible: !checkAccess(requiredPermissions) },
      {
        label: Estados.CANCELADO_EMPRESA,
        visible: (!checkAccess(requiredPermissions) || canceladoEmpresa),
      },
      {
        label: Estados.CANCELADO_CLIENTE,
        visible: (!checkAccess(requiredPermissions) || canceladoCliente),
      },
      { label: Estados.PENDIENTE, visible: !checkAccess(requiredPermissions) },
      { label: Estados.ANULADO, visible: !checkAccess(requiredPermissions) },
    ];
    break;
  case Estados.APROBADO:
    list = [
      { label: Estados.ANULADO, visible: !checkAccess(requiredPermissions) },
      {
        label: Estados.CANCELADO_EMPRESA,
        visible: (!checkAccess(requiredPermissions) || canceladoEmpresa),
      },
      {
        label: Estados.CANCELADO_CLIENTE,
        visible: (!checkAccess(requiredPermissions) || canceladoCliente),
      },
      {
        label: Estados.TERMINADO,
        visible: (!checkAccess(requiredPermissions) || terminado),
      },
    ];
    break;
  case Estados.PENDIENTE:
    list = [
      { label: Estados.EN_PROCESO, visible: !checkAccess(requiredPermissions) },
      { label: Estados.ANULADO, visible: !checkAccess(requiredPermissions) },
      {
        label: Estados.CANCELADO_EMPRESA,
        visible: (!checkAccess(requiredPermissions) || canceladoEmpresa),
      },
      {
        label: Estados.CANCELADO_CLIENTE,
        visible: (!checkAccess(requiredPermissions) || canceladoCliente),
      },
      { label: Estados.APROBADO, visible: !checkAccess(requiredPermissions) },
    ];
    break;
  case Estados.EN_PROCESO:
    list = [
      { label: Estados.ANULADO, visible: !checkAccess(requiredPermissions) },
      {
        label: Estados.CANCELADO_EMPRESA,
        visible: (!checkAccess(requiredPermissions) || canceladoEmpresa),
      },
      {
        label: Estados.CANCELADO_CLIENTE,
        visible: (!checkAccess(requiredPermissions) || canceladoCliente),
      },
      { label: Estados.APROBADO, visible: !checkAccess(requiredPermissions) },
      { label: Estados.PENDIENTE, visible: !checkAccess(requiredPermissions) },
    ];
    break;
  default:
    list = [];
    break;
  }

  const handleClickOption = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOption = () => {
    setAnchorEl(null);
  };

  const openCambioEstado = (estadoNuevo) => {
    handleCloseOption();
    setConfirmModal(true);
    setEstado(estadoNuevo);
  };

  const closeCambioEstado = () => {
    setConfirmModal(false);
    setEstado('');
  };

  // eslint-disable-next-line consistent-return
  async function cambioEstado(formValues) {
    setIsWaiting(true);
    const url = `${endPoint}/${id}`;
    const data = { estado, ...formValues };
    let resp = [];
    try {
      resp = await doPut({ url, data });
      closeCambioEstado();
      if (redirect) {
        history.push(redirectUrl);
      } else {
        refresh();
      }
      if (extraFunction) {
        extraFunction(resp);
      }
      if (getResp) {
        getResp(resp);
      } else {
        if (ownResponseCode) {
          return null;
        }
        appInfo(messages.crud.update);
      }
    } catch (e) {
      genericException(e);
    } finally {
      setIsWaiting(false);
    }
  }

  return (
    <>
      <ButtonEstados
        onClick={handleClickOption}
        disabled={
          disabled
          || !checkAccess(requiredPermissions)
        }
        style={{ marginLeft: isSmallView ? 0 : 10 }}
        {...rest}
      />
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseOption}
      >
        {specificList.length === 0
          ? list.map((item) => {
            if (!item.visible) {
              return (
                <MenuItemBase
                  onClick={() => (openOwnChangeModal
                    ? openOwnChangeModal(item.label, handleCloseOption, id)
                    : openCambioEstado(item.label))}
                  label={item.label}
                  key={item.label}
                />
              );
            }
            return [];
          })
          : specificList.map((item) => {
            if (!item.visible) {
              return (
                <MenuItemBase
                  onClick={() => (openOwnChangeModal
                    ? openOwnChangeModal(item.label, handleCloseOption, id)
                    : openCambioEstado(item.label))}
                  label={item.label}
                  key={item.label}
                />
              );
            }
            return [];
          })}
      </StyledMenu>
      {(confirmModal && !openOwnChangeModal) && (
        <FormComentario
          open={confirmModal}
          close={closeCambioEstado}
          onSubmit={cambioEstado}
          estado={estado}
          isWaiting={isWaiting}
        />
      )}
    </>
  );
}

EstadoSelection.propTypes = {
  // Estado en el que se encuentra el proceso
  estadoActual: PropTypes.string.isRequired,
  // Ruta a la cual se va a hacer la peticion del cambio
  endPoint: PropTypes.string.isRequired,
  // Id del proceso al que se le va a hacer el cambio
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  // Boolean si se requiere redireccionar despues del cambio de estado
  redirect: PropTypes.bool,
  // Dirreccion a la cual se va a redireccionar
  redirectUrl: PropTypes.string,
  // Funcion encargada de refrescar los datos, se usa cuando no se redirrecciona
  refresh: PropTypes.func,
  // Boolean encargado de habilitar o deshabilitar el botón
  disabled: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
  }),
  // Booleano que indica si el estado aplica o no
  canceladoEmpresa: PropTypes.bool,
  // Booleano que indica si el estado aplica o no
  canceladoCliente: PropTypes.bool,
  // Booleano que indica si el estado aplica o no
  terminado: PropTypes.bool,
  doPut: PropTypes.func,
  appError: PropTypes.func,
  appInfo: PropTypes.func,
  specificList: PropTypes.oneOfType([PropTypes.array]),
  checkAccess: PropTypes.func,
  requiredPermissions: PropTypes.arrayOf(PropTypes.string),
  // Funcion que debe ser enviada desde el componente para obtener la
  // respuesta y hacer algo con ella
  getResp: PropTypes.func,
  generic: PropTypes.bool,
  genericException: PropTypes.func,
  // Funcion para ejecutarse despues de hacer el cambio de estado
  extraFunction: PropTypes.func,
  ownResponseCode: PropTypes.bool,
  openOwnChangeModal: PropTypes.func,
};

export default withApi(withNotification(withPermissionsChecker(EstadoSelection)));
