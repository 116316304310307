export default {
  crud: {
    new: 'Registro creado correctamente',
    newProceso: 'Registro creado correctamente con ID Proceso: #',
    updateProceso: 'Registro actualizado correctamente con ID Proceso: #',
    update: 'Registro actualizado correctamente',
    delete: 'Registro eliminado correctamente',
    fail: 'Ha ocurrido un error al momento de intentar grabar los datos',
    duplicate: 'El registro se ha duplicado corectamente',
    duplicateFail: 'El registro no ha podido ser duplicado',
  },
  dataFetch: {
    fail: 'Ha ocurrido un error al momento de cargar los datos',
  },
  fileUpload: {
    success: 'El archivo fue leído correctamente',
  },
  sendEmailTitle: 'Enviar correo electrónico',
  sendExcelEmailTitle: 'Enviar excel via correo electrónico',
  traslado: {
    new: 'Traslado realizado correctamente',
  },
  labels: {
    actions: 'Acciones',
    save: 'Guardar',
    cancel: 'Cancelar',
  },
  titles: {
    create: 'Crear',
    update: 'Actualizar',
  },
  statuses: {
    active: 'Activo',
    inactive: 'Inactivo',
    changeStateDate: 'Se cambia el estado de algunos registros a ANULADO'
      + ' debido a que la fecha de entrega máxima se ha superado',
  },
  errors: {
    submitting: 'Ocurrió un error al enviar la información',
    invalidFormat: 'Solicitud inválida',
    removingRecord: 'Ha ocurrido un error al remover el registro',
    requestError: 'Ocurrió un error al realizar la solicitud.',
    fetchingSources: 'Ocurrió un error al obtener información del formulario',
  },
  validations: {
    empty: 'Este campo es requerido',
    numeric: '%label% debe ser númerico',
    rangeMin: '%label% debe ser mayor o igual a %number%',
    rangeMax: '%label% debe ser menor o igual a %number%',
    rangeOut: '%label% debe estar entre %min% y %max%',
    minLength: '%label% debe ser mayor o igual a %number%',
    maxLength: '%label% debe ser menor o igual a %number%',
    lengthInRange: '%label% debe contener entre %min% y %max%',
    generic: '%label%',
  },
  dialogs: {
    confirm: {
      title: 'Confirmar acción',
      message: '¿Está seguro de realizar esta acción?',
    },
  },
  advancedGrid: {
    body: {
      noMatch: 'Lo sentimos, no se encontraron registros',
      toolTip: 'Ordenar',
      columnHeaderTooltip: column => `Ordenar por ${column.label}`,
    },
    pagination: {
      next: 'Siguiente Pagina',
      previous: 'Pagina Anterior',
      rowsPerPage: 'Filas por pagina:',
      displayRows: 'de',
    },
    toolbar: {
      search: 'Buscar',
      downloadCsv: 'Descargar CSV',
      print: 'Imprimir',
      viewColumns: 'Ver Columnas',
      filterTable: 'Filtrar Tabla',
    },
    filter: {
      all: 'TODO',
      title: 'FILTROS',
      reset: 'REINICIAR',
    },
    viewColumns: {
      title: 'Mostrar Columnas',
      titleAria: 'Mostrar/Ocultar Columnas de la tabla',
    },
    selectedRows: {
      text: 'fila(s) seleccionadas',
      delete: 'Eliminar',
      deleteAria: 'Eliminar Filas Seleccionadas',
    },
  },
  print: {
    contenedor: {
      etiquetaMasivo: 'Se imprimen las etiquetas correctamente.',
    },
  },
};
