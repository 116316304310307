import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

/**
 * This are the default configurations for the grid wrapper
 */
export const defaultWrapperProps = {
  xs: 12,
  md: 6,
  xl: 4,
};

const FieldLoader = ({ wrapper = defaultWrapperProps }) => (
  <Grid item {...wrapper}>
    <Skeleton width="100%" height={40} />
  </Grid>
);

FieldLoader.propTypes = {
  wrapper: PropTypes.oneOfType([PropTypes.object]),
};

export default FieldLoader;
