import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TableBody, CircularProgress, TableRow, TableCell, Grid, Typography,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import Row from './Row';

function Body({ columns, data, isLoading }) {
  const location = useLocation();
  const [selected, setSelected] = useState(null);

  const markSelected = ({ id }) => {
    setSelected(id);
    sessionStorage.setItem('selected', id);
    sessionStorage.setItem('modulo', location.pathname);
  };

  useEffect(() => {
    const modulo = sessionStorage.getItem('modulo');
    const slt = sessionStorage.getItem('selected');
    if (location.pathname === modulo && slt) {
      setSelected(Number(slt));
    }
  }, [location.pathname]);

  return (
    <TableBody>
      {isLoading || !Array.isArray(data) || data.length === 0 ? (
        <TableRow>
          <TableCell colSpan={columns.length}>
            <Grid style={{ display: 'flex', justifyContent: 'center', margin: 10 }}>
              {isLoading && <CircularProgress color="secondary" />}
              {!isLoading && <Typography>No hay datos para mostrar...</Typography>}
            </Grid>
          </TableCell>
        </TableRow>
      ) : Array.isArray(data) && data.map((row, index) => (
        <Row
          key={`R${String(index)}`}
          columns={columns}
          data={row}
          markSelected={markSelected}
          selected={selected}
        />
      ))}
    </TableBody>
  );
}

Body.propTypes = {
  columns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  data: PropTypes.oneOfType([PropTypes.array]),
  isLoading: PropTypes.bool,
};

export default Body;
