import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  TextField,
} from '@material-ui/core';

/**
 * This component allows to render a text field input
 * prepared to work with formik
 * @param {*} param0
 */
const TextBase = React.forwardRef((
  {
    field: {
      name, value, onBlur, onChange, type = 'text',
    }, form: { touched, errors },
    ref,
    error = null,
    helperText = null,
    moveWithMessages = false,
    ...props
  },
) => {
  const styles = makeStyles({
    textHelp: {
    },
  });
  const classes = styles();
  const hasError = touched[name] && Boolean(errors[name]);
  return (
    <TextField
      id={name}
      inputRef={ref}
      className={!moveWithMessages ? classes.textHelp : null}
      variant="outlined"
      type={type}
      autoComplete="off"
      error={error !== null ? error : hasError}
      helperText={helperText !== null ? helperText : hasError && errors[name]}
      fullWidth
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      margin="dense"
      {...props}
    />
  );
});

TextBase.displayName = 'TextBase';

TextBase.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.shape().isRequired,
    errors: PropTypes.shape().isRequired,
  }).isRequired,
  setRef: PropTypes.func,
  moveWithMessages: PropTypes.bool,
  ref: PropTypes.oneOfType([PropTypes.object]),
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default TextBase;
