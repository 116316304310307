import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import authService from 'services/AuthService';

function GuestGuard({ children }) {
  const account = useSelector(state => state.account);
  const session = authService.getSessionInfo();

  if (account.user && session) {
    return <Redirect to="/app" />;
  }

  return children;
}

GuestGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GuestGuard;
