export default {
  inventario: {
    materiaPrima: 'MP',
    inventarioGeneral: 'IG',
    productoTerminado: 'PT',
    entrada: 'E',
    salida: 'S',
    traslado: 'T',
  },
};

/**
 * This constant sets the size for the tables generated by
 * masterbase and table general
*/
export const DEFAULT_MUI_DATATABLE_SIZE = 'small';
/**
 * This constant sets the size for the action buttons for
 * the masterbase and general tables
 */
export const DEFAULT_MUI_DATATABLE_BUTTON_SIZE = 'medium';
/**
 * @desc Color para los tabs requeridos
 * @type {string}
 */
export const COLOR_TABS_REQUIRED = 'gold';
/**
 * This sets the default available options into the rowsPerPage select for
 * masterbase and general tables.
 */
export const DEFAULT_MUI_DATATABLE_ROWS_PER_PAGE_OPTIONS = [10, 30, 100];
/**
 * This var is the format lenguajes that receive the number
 */
export const DEFAULT_FORMAT_NUMBER = 'en-US';
export const formatter = new Intl.NumberFormat(DEFAULT_FORMAT_NUMBER);

export const mapEstadoLabelValue = estado => ({ label: estado.replace(/_/g, ' '), value: estado });
