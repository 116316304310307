import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar, Box, Grid, Step, StepConnector, StepLabel, Stepper,
  makeStyles, withStyles,
} from '@material-ui/core';

// Ejemplo para traer la data
/* const wizardConfig = [
  {
    label: 'Nombre',
    icon: UserIcon,
    component: <PacienteForm />,
  },
]; */

let step = [];
const CustomStepConnector = withStyles(theme => ({
  vertical: {
    marginLeft: 19,
    padding: 0,
  },
  line: {
    borderColor: theme.palette.divider,
  },
}))(StepConnector);

const useCustomStepIconStyles = makeStyles(theme => ({
  root: {},
  active: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: theme.shadows[10],
  },
  completed: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

function CustomStepIcon({ active, completed, icon }) {
  const classes = useCustomStepIconStyles();

  const Icon = step[icon - 1].icon;

  return (
    <Avatar
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      <Icon size="25" />
    </Avatar>
  );
}

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.number,
};

function WizardBaseComponent({
  config, orientation, activeStep,
}) {
  step = config;

  return (
    <Grid container>
      <Grid item xs={12} md={2}>
        <Stepper
          activeStep={activeStep}
          connector={<CustomStepConnector />}
          orientation={orientation}
          component={Box}
          bgcolor="transparent"
        >
          {config.map(steps => (
            <Step key={steps.label}>
              <StepLabel StepIconComponent={CustomStepIcon}>
                {steps.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid item xs={12} md={10}>
        {config.map((steps, index) => (
          <Grid item xs={12} md={12} key={steps.label}>
            {activeStep === index ? (
              <Box p={3}>
                {steps.component}
              </Box>
            ) : null}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

WizardBaseComponent.defaultProps = {
  orientation: 'vertical',
};

WizardBaseComponent.propTypes = {
  // Carga la configuración para iterar vista
  config: PropTypes.oneOfType([PropTypes.array]).isRequired,
  // Orientación del wizard { vertical, horizontal }
  orientation: PropTypes.string,
  // Se usa para conocer la posición desde el padre y manejar las funciones
  // Por defecto debe de venir en 0
  activeStep: PropTypes.number.isRequired,
};

export default WizardBaseComponent;
