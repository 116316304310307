import React, { useState } from 'react';
import { withApi, withNotification } from 'wrappers';
import {
  DialogActions,
  DialogContent,
  Grid,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import {
  ButtonCancelar, ButtonSave, DateTimePickerBase, GeneralPopover, TextBase,
} from 'components';
import endPoints from 'endPoints/endPoints';
import PropTypes from 'prop-types';
import {
  Formik, Form, Field, FieldArray, getIn,
} from 'formik';
import * as Yup from 'yup';
import { messages } from 'constantes';
import { HelpCircle } from 'react-feather';

const validationSchema = Yup.object({
  empresa: Yup.string().required('El campo empresa es requerido'),
  nombre: Yup.string().required('El campo nombre es requerido'),
  email: Yup.string()
    .email('El campo email es inválido')
    .required('El campo email es requerido'),
  cargo: Yup.string().required('El campo cargo es requerido'),
  celular: Yup.string().required('El campo celular es requerido'),
  opciones: Yup.array().of(Yup.object().shape({
    fecha: Yup.date().required('El campo fecha - hora es requerido').nullable(),
  })),
});

const AgendarDemostracionForm = ({
  genericException,
  doPost,
  appSuccess,
  onClose,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [message, setMessage] = useState('');

  const state = {
    empresa: '',
    nombre: '',
    email: '',
    cargo: '',
    celular: '',
    opciones: [
      { fecha: null },
      { fecha: null },
      { fecha: null },
    ],
  };

  const mapValues = (values) => {
    const {
      empresa,
      nombre,
      email,
      cargo,
      celular,
      opciones,
    } = values;

    return {
      empresa: empresa.toUpperCase(),
      nombre: nombre.toUpperCase(),
      email,
      cargo,
      celular,
      opciones,
    };
  };

  const onSubmit = async (formValues) => {
    const url = endPoints.centroAyuda.procesos.agendarDemostracion.base;
    const data = mapValues(formValues);
    try {
      await doPost({ url, data });
      appSuccess(messages.crud.new);
      onClose();
    } catch (e) {
      genericException(e);
    }
  };

  const openPopover = (event, messagePopover) => {
    setAnchorEl(event.currentTarget);
    setMessage(messagePopover);
  };

  const closePopover = () => setAnchorEl(null);

  return (
    <>
      <GeneralPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closePopover}
      >
        <Typography style={{ padding: 3 }}>
          {message}
        </Typography>
      </GeneralPopover>

      <DialogTitle>
        AGENDAR DEMOSTRACIONES
      </DialogTitle>
      <Formik
        enableReinitialize
        initialValues={state}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, touched, errors }) => (
          <Form>
            <DialogContent>
              <Grid container direction="row" spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  xl={12}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <>
                    <Field
                      label="Empresa"
                      name="empresa"
                      component={TextBase}
                    />
                    <HelpCircle
                      style={{ marginLeft: 5 }}
                      onMouseEnter={e => openPopover(e, 'Empresa de la cual se comunica')}
                      onMouseLeave={closePopover}
                      onMouseDown={e => openPopover(e, 'Empresa de la cual se comunica')}
                    />
                  </>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  xl={12}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <>
                    <Field
                      label="Nombre"
                      name="nombre"
                      component={TextBase}
                    />
                    <HelpCircle
                      style={{ marginLeft: 5 }}
                      onMouseEnter={e => openPopover(e, 'Nombre de la persona que requiere la demostración')}
                      onMouseLeave={closePopover}
                      onMouseDown={e => openPopover(e, 'Nombre de la persona que requiere la demostración')}
                    />
                  </>
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                  <Field
                    label="Email"
                    name="email"
                    component={TextBase}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  xl={12}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <>
                    <Field
                      label="Cargo"
                      name="cargo"
                      component={TextBase}
                    />
                    <HelpCircle
                      style={{ marginLeft: 5 }}
                      onMouseEnter={e => openPopover(e, 'Cargo de la persona que requiere la demostración')}
                      onMouseLeave={closePopover}
                      onMouseDown={e => openPopover(e, 'Cargo de la persona que requiere la demostración')}
                    />
                  </>
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                  <Field
                    label="Celular"
                    name="celular"
                    component={TextBase}
                  />
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                  <FieldArray key="field-array" name="opciones">
                    {() => (
                      <>
                        <Grid container direction="row" spacing={2}>
                          {values.opciones.map((item, i) => {
                            const fecha = `opciones[${i}].fecha`;
                            const touchedFecha = getIn(touched, fecha);
                            const errorFecha = getIn(errors, fecha);

                            return (
                              <>
                                <Typography variant="h5" style={{ marginLeft: 15 }}>
                                  {`opción: ${i + 1}`}
                                </Typography>
                                <HelpCircle
                                  style={{ marginLeft: 5 }}
                                  onMouseEnter={e => openPopover(
                                    e,
                                    'Indique la opción de disponibilidad para la demostración'
                                  )}
                                  onMouseLeave={closePopover}
                                  onMouseDown={e => openPopover(
                                    e,
                                    'Indique la opción de disponibilidad para la demostración'
                                  )}
                                />
                                <Grid key={`grid1-${String(i)}`} item xs={12} md={12} xl={12}>
                                  <Field
                                    key={`fecha-${String(i)}`}
                                    label="Fecha - Hora"
                                    name={fecha}
                                    helperText={
                                      touchedFecha && errorFecha
                                        ? errorFecha
                                        : ''
                                    }
                                    error={Boolean(touchedFecha && errorFecha)}
                                    component={DateTimePickerBase}
                                  />
                                </Grid>
                              </>
                            );
                          })}
                        </Grid>
                      </>
                    )}

                  </FieldArray>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <ButtonCancelar onClick={onClose} />
              <ButtonSave label="Guardar" />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
};

AgendarDemostracionForm.propTypes = {
  doPost: PropTypes.func,
  appSuccess: PropTypes.func,
  genericException: PropTypes.func,
  onClose: PropTypes.func,
};

export default withApi(withNotification(AgendarDemostracionForm));
