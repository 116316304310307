import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, IconButton } from '@material-ui/core';
import { KeyboardArrowRight, KeyboardArrowDown } from '@material-ui/icons';
import { dotNotationExtractor, isFunction } from 'utils/functions';
import { formatter, DEFAULT_MUI_DATATABLE_BUTTON_SIZE } from 'constantes/constants';

function Row({
  columns, data = {}, openExpandable, expanded,
}) {
  return (
    <TableRow>
      <TableCell>
        <IconButton onClick={openExpandable} size={DEFAULT_MUI_DATATABLE_BUTTON_SIZE}>
          {expanded
            ? <KeyboardArrowDown />
            : <KeyboardArrowRight />}
        </IconButton>
      </TableCell>
      {columns.map((col, index) => {
        let cellContent = col.component || dotNotationExtractor(data, col.name);
        cellContent = isFunction(cellContent) ? cellContent(data) : cellContent;
        switch (col.type) {
        case 'number': cellContent = formatter.format(cellContent ?? 0);
          break;
        case 'money': cellContent = `$${formatter.format(Math.trunc(cellContent ?? 0))}`;
          break;
        default:
          break;
        }
        return (
          <TableCell
            key={`Col${String(index)}`}
            align="center"
            style={{ width: col.width ? col.width : '', color: col.color }}
          >
            {cellContent}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

Row.propTypes = {
  columns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  data: PropTypes.oneOfType([PropTypes.object]),
  openExpandable: PropTypes.func,
  expanded: PropTypes.bool,
};

export default Row;
