import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';

const MenuItemBase = React.forwardRef(({
  Icon,
  label,
  onClick,
}, ref) => (
  <>
    {Icon ? (
      <MenuItem
        onClick={() => onClick()}
        ref={ref}
      >
        <ListItemIcon>
          <Icon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit" noWrap>
          {label}
        </Typography>
      </MenuItem>
    ) : (
      <MenuItem
        onClick={() => onClick()}
        ref={ref}
      >
        <Typography variant="inherit" noWrap>
          {label}
        </Typography>
      </MenuItem>
    )}
  </>
));

MenuItemBase.displayName = 'MenuItemBase';

MenuItemBase.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  Icon: PropTypes.shape(),
};

export default MenuItemBase;
