import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles, TextField, InputAdornment, SvgIcon,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import clsx from 'clsx';

/**
 * Componente que contiene input de busqueda masiva en tabla
 * @param {*} param0
 */
const GeneralSearch = ({
  onChange,
  onKeyUp,
  value,
  disabled,
  width,
}) => {
  const useStyles = makeStyles(() => ({
    queryField: {
      width: width || 450,
    },
    input: {
      height: 40,
    },
  }));
  const classes = useStyles();
  return (
    <TextField
      disabled={disabled}
      className={classes.queryField}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SvgIcon
              fontSize="small"
              color="action"
            >
              <SearchIcon />
            </SvgIcon>
          </InputAdornment>
        ),
        className: clsx(classes.input, { width: width || 450 }),
      }}
      onChange={onChange}
      onKeyUp={onKeyUp}
      placeholder="Buscar"
      value={value || ''}
      variant="outlined"
    />
  );
};

GeneralSearch.defaultProps = {
  value: '',
  disabled: false,
};

GeneralSearch.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func.isRequired,
  width: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default GeneralSearch;
