import { makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { withPermissionsChecker } from 'wrappers';

const useStyles = makeStyles(theme => ({
  linkTable: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textShadow: '1px 0px 0px black',
    },
  },
}));

const LabelLink = ({
  labelName, labelUrl, targetType, permission, disabled, labelTitle, checkAccess,
}) => {
  const classes = useStyles();
  return (
    <>
      {!checkAccess(permission) || disabled
        ? (
          <Typography style={{ fontSize: 14 }}>
            {labelName}
          </Typography>
        )
        : (
          <Link
            title={labelTitle}
            to={labelUrl}
            target={targetType}
            className={classes.linkTable}
          >
            {labelName}
          </Link>
        )}
    </>
  );
};

LabelLink.defaultProps = {
  permission: [],
};

LabelLink.propTypes = {
  labelName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  labelUrl: PropTypes.string,
  targetType: PropTypes.string,
  permission: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  labelTitle: PropTypes.string,
  checkAccess: PropTypes.func,
};

export default withPermissionsChecker(LabelLink);
