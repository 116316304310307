import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ApiServiceFecth from 'services/ApiServiceFecth';
import { blobToBase64 } from 'utils/helpers';

function Image({ alt, url, ...rest }) {
  const [src, setSrc] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const response = await ApiServiceFecth.get({ url });
      let data = '//';
      if (response.ok) {
        const blob = await response.blob();
        data = await blobToBase64(blob);
      }
      setSrc(data);
    };
    getData();
  }, [url]);

  return src && (
    <img src={src} alt={alt} {...rest} />
  );
}

Image.defaultProps = {
  alt: 'Image',
};

Image.propTypes = {
  alt: PropTypes.string,
  url: PropTypes.string.isRequired,
};

export default Image;
