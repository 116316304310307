import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  Badge,
  withStyles,
} from '@material-ui/core';
import {
  Bell as BellIcon,
} from 'react-feather';
import { SocketContext } from 'context/SocketContext';
import PropTypes from 'prop-types';

const styles = {
  popover: {
    width: 320,
  },
};

class Notifications extends React.Component {
  static contextType = SocketContext;

  constructor(props) {
    super(props);

    this.state = {
      aux: true,
      isOpen: false,
      notifications: [],
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    const { aux } = this.state;
    const { socket } = this.context;
    const src = '/static/audio/notification-sound.mp3';
    const audio = new Audio(src);
    if (aux) {
      this.setState({ aux: false });
      if (socket) {
        socket.emit('join', { room: 'app' });
      }
    }
    if (socket) {
      socket.on('my_response', async (msg) => {
        const { data } = msg;
        if (data.type === 'NOTIFICATION') {
          this.setState(prev => ({
            notifications: [...prev.notifications, data],
          }));
          await audio.play();
        }
      });
    }
  }

  handleOpen = () => {
    this.setState({ isOpen: true });
  }

  handleClose = () => {
    this.setState({ isOpen: false });
  }

  render() {
    const { isOpen, notifications } = this.state;
    const { classes } = this.props;
    return (
      <>
        <Tooltip title="Notificaciones">
          <IconButton
            color="inherit"
            ref={this.ref}
            onClick={this.handleOpen}
          >
            <Badge badgeContent={notifications.length} color="error">
              <SvgIcon>
                <BellIcon />
              </SvgIcon>
            </Badge>
          </IconButton>
        </Tooltip>
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          classes={{ paper: classes.popover }}
          anchorEl={this.ref.current}
          onClose={this.handleClose}
          open={isOpen}
        >
          <Box p={2}>
            <Typography
              variant="h5"
              color="textPrimary"
            >
              Notificaciones
            </Typography>
          </Box>
          {notifications.length === 0 ? (
            <Box p={2}>
              <Typography
                variant="h6"
                color="textPrimary"
              >
                No hay notificaciones
              </Typography>
            </Box>
          ) : (
            <>
              <List
                className={classes.list}
                disablePadding
              >
                {notifications.map(notification => (
                  <ListItem
                    className={classes.listItem}
                    component={RouterLink}
                    divider
                    key={notification.id}
                    to={notification.config.route}
                  >
                    <ListItemText
                      primary={notification.title}
                      primaryTypographyProps={{ variant: 'subtitle2', color: 'textPrimary' }}
                      secondary={notification.description}
                    />
                  </ListItem>
                ))}
              </List>
              <Box
                p={1}
                display="flex"
                justify="center"
              >
                <Button
                  component={RouterLink}
                  size="small"
                  to="#"
                  onClick={() => {
                    this.setState({ notifications: [] });
                    this.handleClose();
                  }}
                >
                  Marcar todas como leidas
                </Button>
              </Box>
            </>
          )}
        </Popover>
      </>
    );
  }
}

Notifications.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]),
};

export default withStyles(styles)(Notifications);
