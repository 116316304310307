// import { ReportableException } from 'exceptions';
import { Estados, messages } from 'constantes';
import ApiServiceFetch from 'services/ApiServiceFecth';
import axios from 'axios';

/**
 * This function allows to clean a string and used inside regular expressions.
 */
export const escapeRegExp = string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

/**
 * Función que intenta extraer de un objeto un elemento por medio
 * de notacion de punto
 *
 * @param {object} object el objeto al cual se el intentará extraer el campo u elemento
 * @param {string} dotNotation la notacion por la cual se accedería al campo por medio
 * de puntos.
 */
export const dotNotationExtractor = (object, dotNotation) => dotNotation.split('.')
  .reduce((total, currentValue) => {
    if (total) {
      return total[currentValue];
    }
    return null;
  }, object);

/**
 * Función que contiene una lógica básica para determinar si un elemento
 * es de tipo funcion.
 *
 * @param {any} toCheck el elemento a verificar
 * @return {boolean} true si el elemento pasa como funcion.
 */
export const isFunction = toCheck => typeof toCheck === 'function';

/**
 * Función genérica que busca manejar las exceptions lanzadas desde
 * la interaccion con el back. Detecta básicamente el tipo de exception
 * y la administra o notificando de diferente manera.
 *
 * @param {Error} exception un error entregado desde un catch
 * @param {ContenedoresCambio} object un descendente de withNotification
 * @see witNotification wrapper.
 */
export const genericExceptionHandler = (exception, object) => {
  // TODO: hacer que esta funcion pueda llegar a ser usada desde
  // componentes funcionales
  // verificar funcionalidad con otros componentes si no agregar al if
  // 'instanceof ReportableException'
  if (exception) {
    object.props.appWarning(exception.message);
  } else {
    object.props.appError(messages.crud.fail, exception);
  }
};

export default function CalcularDigitoVerificacion(parameter) {
  // FIXME: se debe validar cuando el parametro es un alfanumerico
  const vpri = [];
  let x;
  let y;
  let i;
  let nit = parameter;

  // Se limpia el Nit
  nit = nit.replace(/\s/g, ''); // Espacios
  nit = nit.replace(/,/g, ''); // Comas
  nit = nit.replace(/\./g, ''); // Puntos
  nit = nit.replace(/-/g, ''); // Guiones

  // Procedimiento
  const z = nit.length;

  vpri[1] = 3;
  vpri[2] = 7;
  vpri[3] = 13;
  vpri[4] = 17;
  vpri[5] = 19;
  vpri[6] = 23;
  vpri[7] = 29;
  vpri[8] = 37;
  vpri[9] = 41;
  vpri[10] = 43;
  vpri[11] = 47;
  vpri[12] = 53;
  vpri[13] = 59;
  vpri[14] = 67;
  vpri[15] = 71;

  x = 0;
  y = 0;
  for (i = 0; i < z; i++) {
    y = (nit.substr(i, 1));
    // console.log ( y + "x" + vpri[z-i] + ":" ) ;

    x += (y * vpri[z - i]);
    // console.log ( x ) ;
  }

  y = x % 11;
  // console.log ( y ) ;

  return (y > 1) ? 11 - y : y;
}

export const fireDownloadFromResponse = async (httpResponse, filename) => {
  const blob = await httpResponse.blob();
  const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.download = filename;
  a.click();
  const { parentNode } = a;
  if (parentNode) {
    parentNode.removeChild(a);
  }
};

export const genericDownload = async (url, name, data, object) => {
  try {
    const response = await ApiServiceFetch.download(url, data);
    await fireDownloadFromResponse(response, name);
  } catch (error) {
    genericExceptionHandler(error, object);
  }
};

export const isProcessEditable = (estado) => {
  switch (estado) {
  case Estados.INICIAL:
    return true;
  case Estados.EN_PROCESO:
    return true;
  case Estados.PENDIENTE:
    return true;
  case undefined:
    return true;
  case null:
    return true;
  default:
    return false;
  }
};

export const generateUUID16 = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let UUID = '';

  for (let i = 0; i < 16; i++) {
    const index = Math.floor(Math.random() * characters.length);
    UUID += characters.charAt(index);
  }

  return UUID;
};

export const downloadFromAriandel = async (data) => {
  const resp = await axios.get(`${process.env.REACT_APP_ARIANDEL_SERVER}generatepdf`, {
    params: {
      data: JSON.stringify({
        ...data,
        pathBack: process.env.REACT_APP_SERVER,
      }),
    },
    method: 'GET',
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/pdf',
      'X-Requested-With': 'XMLHttpRequest',
    },
    responseType: 'blob'
  });

  return resp;
};
