import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// FIXME:: importante para la versión 4.11 de material ui, los componentes de Expansion
// han sido renombrados a Accordion, para preparar el camino para material ui v5
// Estos componentes deben ser readaptados para que hagan uso de dichos componentes.
const Collapsible = ({
  expandAriaLabel, SummaryContent, PanelContent, expanded, ...rest
}) => (
  <Accordion {...rest}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-label={expandAriaLabel}
    >
      {SummaryContent}
    </AccordionSummary>
    {expanded && (
      <AccordionDetails style={{ padding: 0 }}>
        {PanelContent}
      </AccordionDetails>
    )}
  </Accordion>
);

Collapsible.defaultProps = {
  expandAriaLabel: 'Expandir',
  expanded: false,
};

Collapsible.propTypes = {
  expanded: PropTypes.bool,
  expandAriaLabel: PropTypes.string,
  // contenido de la parte superior del collapsible
  SummaryContent: PropTypes.node.isRequired,
  // contenido de la parte que se muestra y se oculta
  PanelContent: PropTypes.node.isRequired,
};

export default Collapsible;
