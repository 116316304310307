import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: other.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const PercentBase = React.forwardRef((
  {
    field: {
      name, value, onBlur, onChange,
    }, form: { touched, errors }, InputProps, ...props
  }, ref,
) => {
  const hasError = touched[name] && Boolean(errors[name]);
  return (
    <TextField
      id={name}
      inputRef={ref}
      variant="outlined"
      autoComplete="off"
      error={hasError}
      helperText={hasError && errors[name]}
      fullWidth
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      margin="dense"
      InputProps={{
        inputComponent: NumberFormatCustom,
        endAdornment: (
          <InputAdornment position="start">
            %
          </InputAdornment>
        ),
        ...InputProps,
      }}
      {...props}
    />
  );
});

PercentBase.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.shape().isRequired,
    errors: PropTypes.shape().isRequired,
  }).isRequired,
  setRef: PropTypes.func,
  InputProps: PropTypes.oneOfType([PropTypes.any]),
};

PercentBase.displayName = 'PercentBase';

export default PercentBase;
