import {
  red, green, yellow, blue, deepPurple, lightGreen, teal, orange,
} from '@material-ui/core/colors';

/**
   * Estilos basados en:
   *
   * https://material-ui.com/customization/color/
   */

export default {
  activo: {
    background: green[500],
    color: 'white',
  },
  inactivo: {
    background: red[500],
    color: 'white',
  },
  aprobado: {
    background: green[600],
    color: 'white',
    '&:hover': {
      background: green[800],
    },
  },
  aprobada: {
    background: green[600],
    color: 'white',
  },
  pendiente: {
    background: orange[500],
    color: 'white',
  },
  terminado: {
    background: blue[500],
    color: 'white',
  },
  'cancelado-empresa': {
    background: red[500],
    color: 'white',
    '&:hover': {
      background: red[700],
    },
  },
  'cancelado-cliente': {
    background: red[500],
    color: 'white',
    '&:hover': {
      background: red[700],
    },
  },
  anulado: {
    background: deepPurple[500],
    color: 'white',
    '&:hover': {
      background: deepPurple[700],
    },
  },
  rechazado: {
    background: red[500],
    color: 'white',
  },
  'en-proceso': {
    background: yellow[500],
    '&:hover': {
      background: yellow[700],
    },
  },
  autorizado: {
    background: green[700],
    color: 'white',
  },
  programado: {
    background: deepPurple[500],
    color: 'white',
  },
  picking: {
    background: lightGreen[500],
  },
  despacho: {
    background: teal[500],
    color: 'white',
  },
  bloqueado: {
    background: orange[500],
  },
  ocupado: {
    background: yellow[500],
  },
  disponible: {
    background: green[500],
    color: 'white',
  },
  inicial: {
    background: green[500],
  },
  alistamiento: {
    background: blue[500],
    color: 'white',
  },
  completado: {
    background: green[500],
    color: 'white',
  },
  'sin-alistar': {
    background: yellow[500],
  },
  almacenado: {
    background: green[500],
    color: 'white',
  },
  creado: {
    background: blue[500],
    color: 'white',
  },
  actualizado: {
    background: blue[500],
    color: 'white',
  },
  'paso 1 de 4': {
    background: orange[500],
    color: 'white',
  },
  'paso 2 de 4': {
    background: orange[500],
    color: 'white',
  },
  'paso 3 de 4': {
    background: orange[500],
    color: 'white',
  },
  'paso 4 de 4': {
    background: orange[500],
    color: 'white',
  },
  inválido: {
    background: red[500],
    color: 'white',
    '&:hover': {
      background: red[700],
    },
  },
  válido: {
    background: green[600],
    color: 'white',
    '&:hover': {
      background: green[800],
    },
  },
  'ad generado': {
    background: green[600],
    color: 'white',
    '&:hover': {
      background: green[800],
    },
  },
  enviado: {
    background: green[600],
    color: 'white',
    '&:hover': {
      background: green[800],
    },
  },
  fallido: {
    background: red[500],
    color: 'white',
    '&:hover': {
      background: red[700],
    },
  },
  'aceptación expresa': {
    background: orange[500],
    color: 'white',
  },
  'recibo bien o servicio': {
    background: orange[500],
    color: 'white',
  },
  acusado: {
    background: green[600],
    color: 'white',
    '&:hover': {
      background: green[800],
    },
  },
  'aceptación tácita': {
    background: orange[500],
    color: 'white',
  },
  reclamado: {
    background: orange[500],
    color: 'white',
  },
  contingencia: {
    background: red[500],
    color: 'white',
    '&:hover': {
      background: red[700],
    },
  },
  'pendiente por envío de correo': {
    background: orange[500],
    color: 'white',
  },
  'pendiente por validación dian': {
    background: orange[500],
    color: 'white',
  },
};
