import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { grey } from '@material-ui/core/colors';

const ButtonGeneral = ({
  label,
  icon,
  disabled,
  onClick,
  color,
  style,
  type,
  endIcon,
  ...rest
}) => {
  const styles = makeStyles({
    button: {
      boxShadow: 'none',
      textTransform: 'uppercase',
      fontSize: 16,
      padding: '2px 12px',
      border: '2px solid',
      borderRadius: 8,
      backgroundColor: 'white',
      borderColor: color || '#000000',
      color: color || '#000000',
      '&:hover': {
        backgroundColor: fade(color || '#000000', 0.1),
        borderColor: color || '#000000',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: fade(color || '#000000', 0.5),
        borderColor: color || '#000000',
      },
      '&:disabled': {
        borderColor: grey[300],
      },
    },
  });
  const classes = styles();

  return (
    <Button
      disabled={disabled}
      className={classes.button}
      onMouseUp={onClick}
      style={style}
      type={type}
      {...rest}
    >
      {icon}
      {label}
      {endIcon}
    </Button>
  );
};

ButtonGeneral.propTypes = {
  // Texto que se va a mostrar en el botón
  label: PropTypes.string,
  // Boolean que determina si esta deshabilitado
  disabled: PropTypes.bool,
  // Icono que se va a mostrar al inicio del botón
  icon: PropTypes.node,
  endIcon: PropTypes.node,
  // String estilo del boton
  style: PropTypes.oneOfType([PropTypes.object]),
  // Funcion a ejecutarse cuando se da click
  onClick: PropTypes.func,
  // String color html
  color: PropTypes.string,
  type: PropTypes.string,
};

export default ButtonGeneral;
