import React from 'react';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';
import { blue } from '@material-ui/core/colors';
import ButtonGeneral from './ButtonGeneral';

/**
 * This component allows to render a button with the specific go backaction.
 * @param {*} param0
 */
function GoBackButton({
  history: { goBack }, disabled, style, onClick, ...rest
}) {
  return (
    <ButtonGeneral
      disabled={disabled}
      onClick={onClick || goBack}
      label="Atrás"
      style={{ marginRight: 10, ...style }}
      color={blue[500]}
      {...rest}
      icon={<ArrowBackIcon style={{ marginRight: 12, fontSize: 20 }} />}
    />
  );
}

GoBackButton.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object]),
  onClick: PropTypes.func,
};

export default withRouter(GoBackButton);
